<template>
  <header class="bg-white" :class="{ open: isShowingMenu }">
    <div class="header--container">
      <router-link class="" :to="{ name: 'Home' }">
        <Logo class="text-contrast" />
      </router-link>
      <div class="header--nav--right">
        <button
          id="dropdownDefault"
          data-dropdown-toggle="dropdown"
          class="
            text-secondary-text
            bg-gray-50
            hover:bg-gray-100
            focus:ring-4 focus:outline-none focus:ring-blue-300
            font-medium
            rounded-lg
            text-sm
            px-4
            py-2.5
            text-center
            inline-flex
            items-center
          "
          type="button"
        >
          <svg
            class="ml-2 w-4 h-4"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </button>
        <!-- Dropdown menu -->
        <div
          id="dropdown"
          class="
            hidden
            z-10
            w-44
            bg-white
            rounded
            divide-y divide-gray-100
            shadow
            dark:bg-gray-700
          "
        >
          <ul
            class="py-1 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefault"
          >
            <li>
              <a
                href="#"
                class="
                  block
                  py-2
                  px-4
                  hover:bg-gray-100
                  dark:hover:bg-gray-600 dark:hover:text-white
                "
                >Español</a
              >
            </li>
            <li>
              <a
                href="#"
                class="
                  block
                  py-2
                  px-4
                  hover:bg-gray-100
                  dark:hover:bg-gray-600 dark:hover:text-white
                "
                >English</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import Logo from '@/components/svg/logo'

export default {
  name: 'HeaderClient',
  components: {
    Logo
  }
}
</script>
