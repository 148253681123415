<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4>{{ $t('profile-page.title-hard-skill') }}</h4>
      </template>
    </Title>
    <div class="text-center" v-if="assesments.length < 1 && !isPublic">
      <div class="flex justify-center space-x-6">
        <BadgeAward
          class="inline-block"
          imagen="icn-hard-skill.svg"
          :bloqueado="true"
          :titulo="$t('habilidades-page.title-hard-skill')"
        ></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-hard-skill-badge') }}
      </p>
      <div class="flex justify-center">
        <router-link
          :to="{
            name: 'Habilidades',
            params: { locale: this.$root.$i18n.locale || 'es' }
          }"
          class="btn btn-md btn-rounded-lg btn-primary mx-auto"
          role="button"
          :aria-label="$t('general.completar')"
        >
          {{ $t('general.completar') }}
        </router-link>
      </div>
    </div>
    <div
      v-else
      class="grid grid-cols-1 lg:gap-x-6 gap-y-8 items-center"
      :class="assesments.length > 1 ? 'lg:grid-cols-2' : 'lg:grid-cols-1'"
    >
      <div
        class="skill-wrapper"
        v-for="assesment in assesments"
        :key="assesment.id"
      >
        <div v-if="checkAssesment(assesment)">
          <h3 class="text-lg font-bold text-secondary-text mb-4">
            {{ assesment.name }}
          </h3>
          <CircleChart :respuestas="checkAssesment(assesment)" />
        </div>
        <div
          v-else-if="!isPublic"
          class="flex flex-col justify-center items-center"
        >
          <BadgeAward
            class="inline-block"
            :imagen="
              assesment.photo === null ? 'icn-hard-skill.svg' : assesment.photo
            "
            :bloqueado="true"
            :titulo="assesment.name"
          ></BadgeAward>
          <p class="text-secondary-text text-center">
            {{ $t('profile-page.description-win-badges-1') }}
            {{ assesment.name }}
            {{ $t('profile-page.description-win-badges-2') }}
          </p>
          <div class="flex justify-center">
            <router-link
              :to="{
                name: 'Habilidades',
                params: { locale: this.$root.$i18n.locale || 'es' }
              }"
              class="btn btn-md btn-rounded-lg btn-primary mx-auto"
              role="button"
              :aria-label="$t('general.completar')"
            >
              {{ $t('general.completar') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import BadgeAward from '@/components/BadgeAward'
import Title from '@/components/Title'
import ROUTES from '@/constants/routes'
import CircleChart from '@/components/CircleChart'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import { mapActions } from 'vuex'

export default {
  props: ['userHardSkill', 'isPublic'],
  data: function () {
    return {
      assesments: [],
      ROUTES
    }
  },
  name: 'HardSkillSection',
  components: {
    Title,
    BadgeAward,
    CircleChart
  },
  mounted: function () {
    this.getAssesmentCategories()
  },
  updated() {
    this.getAssesmentCategories()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getAssesmentCategories: async function () {
      const habilitiesQuery = await ApolloClient.query({
        context: {
          headers: {
            lang: this.$root.$i18n
          }
        },
        fetchPolicy: 'network-only',
        query: assesmentCategoriesQuery
      })
      this.assesments = habilitiesQuery.data.assesmentCategories[1].assesments
      this.setLoadingOff()
    },
    checkAssesment(assesment) {
      return this.userHardSkill.find((el) => {
        return el.assesment.id === assesment.id
      })
    }
  }
}
</script>
