<template>
  <div class="dark:text-white p-4">
    <div class="flex gap-4 flex-wrap mb-8">
      <img
        :src="require(`@/assets/images/summit/${filename}`)"
        class="w-14 h-14 rounded-full"
        alt="Utopicode"
      />
      <div class="w-full lg:w-8/12">
        <h2 class="flex text-contrast dark:text-white font-bold lg:text-xl">
          {{ name }}
        </h2>
        <p class="text-primary-text last">{{ role }}</p>
        <p class="text-summit text-sm last text-lg">
          {{ event }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Speaker',
  props: {
    name: String,
    role: String,
    event: String,
    filename: String
  }
}
</script>
