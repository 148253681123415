<template>
  <teleport to="#modals">
    <!-- Modal Loading -->
    <transition name="fade">
      <Loading
        v-if="loading"
        class="fixed w-full h-full z-20 left-0 top-0 loading--dark"
      ></Loading>
    </transition>
    <!-- End Modal Loading -->
  </teleport>
  <!-- End Modal Registro Evento -->
  <!-- Clients Sidebar -->
  <Sidebar />
  <!-- Clients Sidebar -->
</template>

<script>
import Loading from '@/components/Loading'
import Sidebar from '@/components/empresas/Sidebar'
// import HeaderClients from '@/components/empresas/HeaderClients'

import { mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    Loading,
    Sidebar
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapGetters(['loading'])
  }
}
</script>
