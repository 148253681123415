<template>
  <CardPage>
    <template #default>
      <!-- Title -->
      <div class="card--page--header">
        <Title>
          <template #title>
            <h5 class="mt-2">
              {{ assesment.assesment.name }}
            </h5>
          </template>
        </Title>
      </div>
      <!-- End Title -->
      <!-- Body -->
      <div class="card--page--body">
        <div class="circle-data">
          <ul>
            <li
              v-for="(item, index) in assesment.subAssesmentsResult"
              :key="index"
            >
              <div class="circle-data-row">
                <span class="circle-data-item">
                  {{ item.totalScore }} / {{ item.assesment.testLength }}
                </span>
                <p class="last">
                  <b>{{ item.assesment.name }}:</b>
                  {{ item.description }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- End Body -->
    </template>
  </CardPage>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'

export default {
  name: 'ChartReferenceBox',
  components: {
    CardPage,
    Title
  },
  props: {
    assesment: Object
  }
}
</script>
