<template>
  <div class="modal">
    <div class="modal--backdrop" @click="$emit('cerrarModal')"></div>
    <div class="modal--wrapper" :class="`modal--${size}`">
      <button
        type="button"
        class="modal--close"
        role="button"
        @click="$emit('cerrarModal')"
      >
        <img src="@/assets/images/icn-close.svg" :alt="$t('general.cerrar')" />
      </button>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['size', 'footer'],
  emits: ['cerrarModal']
}
</script>
