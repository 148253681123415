<template>
  <svg
    width="680"
    height="582"
    viewBox="0 0 680 582"
    class="
      fill-current
      bg-gradient-to-br
      from-tertiary
      to-linkedin
      text-main-light
      opacity-30
      dark:from-gradient-primary
      dark:to-gradient-secondary
      dark:opacity-100
      dark:text-main-dark
    "
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M455.714 0C601.508 0.00819769 680 121.432 680 211.386V0H455.714ZM455.684 0C433.005 0.00127517 408.697 2.94047 382.803 9.39297C289.344 32.682 266.809 113.244 246.418 186.142C224.836 263.297 205.655 331.866 107.331 314.863C-83.8917 281.795 -1.7959 553.356 213.455 544.338C277.062 541.673 343.816 553.533 406.303 564.635C555.278 591.103 680 613.263 680 424.01V582H0V0H455.684Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Shape02Flip'
}
</script>
