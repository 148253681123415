<template>
  <div class="cards p-6">
    <h4 class="title-lg dark:text-white text-contrast font-bold mb-6">
      {{ title }}
    </h4>
    <ol class="relative border-l border-gray-200 dark:border-gray-700 pl-2">
      <li class="mb-10 ml-6">
        <div class="absolute w-5 h-5 bg-secondary rounded-full -left-1.5"></div>
        <time class="mb-1 text-lg font-bold leading-none dark:text-white"
          >22 Nov</time
        >
        <p class="mb-4 dark:text-white text-contrast">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor nisi,
          dui, pretium .
        </p>
      </li>
      <li class="mb-10 ml-6">
        <div class="absolute w-5 h-5 bg-secondary rounded-full -left-1.5"></div>
        <time class="mb-1 text-lg font-bold leading-none dark:text-white"
          >30 Nov</time
        >
        <p class="dark:text-white text-contrast">
          Lorem ipsum dolor sit amet, consectetur .
        </p>
      </li>
      <li class="ml-6">
        <div class="absolute w-5 h-5 bg-secondary rounded-full -left-1.5"></div>
        <time class="mb-1 text-lg font-bold leading-none dark:text-white"
          >13 Dic</time
        >
        <p class="dark:text-white text-contrast">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tempor nisi,
          dui, pretium .
        </p>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: 'CardWidget',
  props: {
    title: String,
    data: Object
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    getWidgetType() {
      if (this.type === 'soy un chart') {
        this.chartType = true
      } else if (this.type === 'soy activity') {
        this.activityType = true
      } else {
        this.topType = true
      }
    }
  }
}
</script>
