<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4 class="mt-1">
          {{ $t('profile-page.title-certificaciones-y-cursos') }}
        </h4>
      </template>
      <template #options v-if="!isPublic && edit === false">
        <button
          @click="editSection()"
          type="button"
          role="button"
          aria-label="Edit"
          class="
            w-9
            h-9
            flex
            justify-center
            items-center
            rounded-md
            btn-secondary
            transform
            -translate-y-2
          "
        >
          <img src="@/assets/images/icn-edit.svg" />
        </button>
      </template>
    </Title>
    <!-- If the sections is not complete -->
    <div
      class="text-center"
      v-if="certifications.length <= 0 && edit === false"
    >
      <div class="flex justify-center space-x-6">
        <BadgeAward
          class="inline-block"
          imagen="icn-aprendizaje.svg"
          :bloqueado="true"
          :titulo="$t('habilidades-page.title-experiencia')"
        ></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-certificaciones-y-cursos-badge') }}
      </p>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-md btn-rounded-lg btn-primary mx-auto"
          role="button"
          :aria-label="$t('general.completar')"
          @click="editSection()"
        >
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <!-- If not complete and edit is true -->
    <Form @submit="validar" v-else-if="!isPublic && edit === true">
      <p class="dark:text-primary-text text-secondary-text">
        {{ $t('modal-certificaciones-cursos.description') }}
      </p>
      <div v-for="(certification, index) in certifications" :key="index">
        <!-- First Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 items-end">
          <div class="form--group">
            <label class="form--label" :for="`nombreDelCurso-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-nombre-del-curso')
            }}</label>
            <Field
              :name="`nombreDelCurso-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="certification.name"
            />
            <ErrorMessage
              :name="`nombreDelCurso-${index}`"
              class="form--error"
            />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`empresaEmisora-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-empresa-emisora')
            }}</label>
            <Field
              :name="`empresaEmisora-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="certification.certifiedBy"
            />
            <ErrorMessage
              :name="`empresaEmisora-${index}`"
              class="form--error"
            />
          </div>
        </div>
        <!-- End First Row -->
        <!-- Second Row -->
        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 items-end">
          <div
            class="form--group"
            :class="!certification.expires ? '' : 'col-span-2'"
          >
            <h4
              class="
                dark:text-primary-text
                text-secondary-text
                font-bold
                mb-2
                lg:col-span-2
              "
            >
              {{ $t('modal-experiencia-profesional.title-fecha-de-inicio') }}
            </h4>
            <Field
              :name="`startDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="certification.startDate"
              class="form--icon form--icon--left block"
            >
              <span class="icon--wrapper bg-secondary text-white rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                :max="new Date().toISOString().split('T')[0]"
              />
            </Field>
            <ErrorMessage :name="`startDate-${index}`" class="form--error" />
          </div>
          <div class="form--group" v-if="!certification.expires">
            <h4
              class="
                dark:text-primary-text
                text-secondary-text
                font-bold
                mb-2
                lg:col-span-2
              "
            >
              {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
            </h4>
            <Field
              :name="`endDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="certification.endDate"
              class="form--icon form--icon--left block"
            >
              <span class="icon--wrapper bg-secondary text-white rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                :min="
                  certification.startDate ||
                  new Date().toISOString().split('T')[0]
                "
              />
            </Field>
            <ErrorMessage :name="`endDate-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--checkbox lg:mb-3"
              ><input
                type="checkbox"
                :id="`remember-${index}`"
                value="first_checkbox"
                @change="checkExpires(index)"
                v-model="certification.expires"
              /><span>{{
                $t('modal-certificaciones-cursos.lbl-credencial-no-caduca')
              }}</span></label
            >
          </div>
        </div>
        <!-- End Second Row -->
        <!-- Third Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div class="form--group">
            <label class="form--label" :for="`idCertificacion-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-id-certificacion')
            }}</label>
            <Field
              :name="`idCertificacion-${index}`"
              type="text"
              class="form--element w-full"
              v-model="certification.certId"
            />
            <ErrorMessage
              :name="`idCertificacion-${index}`"
              class="form--error"
            />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`urlCertificacion-${index}`">{{
              $t('modal-certificaciones-cursos.lbl-url-certificacion')
            }}</label>
            <Field
              :name="`urlCertificacion-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateUrl"
              v-model="certification.certUrl"
            />
            <ErrorMessage
              :name="`urlCertificacion-${index}`"
              class="form--error"
            />
          </div>
        </div>
        <!-- End Third Row -->
        <div class="flex justify-end mb-6">
          <button
            class="
              w-9
              h-9
              flex
              justify-center
              items-center
              rounded-md
              btn-secondary
            "
            @click="removeRow(index)"
          >
            <img src="@/assets/images/icn-remove.svg" />
          </button>
        </div>
        <hr class="line-line border-dashed mb-6" />
      </div>
      <button
        class="btn btn-rounded-lg btn-secondary btn-md mb-8"
        @click="addRow"
        type="button"
        role="button"
        :aria-label="
          $t('modal-certificaciones-cursos.btn-agregar-otra-certificacion')
        "
      >
        {{ $t('modal-certificaciones-cursos.btn-agregar-otra-certificacion') }}
      </button>
      <div class="flex justify-center space-x-4">
        <button
          type="submit"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="cancelEdition"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary mx-auto btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </Form>
    <!-- Else if the section is complete -->
    <div v-else>
      <ul class="list--education list--education--small">
        <li v-for="(cert, index) in certifications" :key="index" class="pb-8">
          <span class="list--education--bullets"></span>
          <time class="dark:text-primary-text text-secondary-text text-sm">
            {{ formatedDate(cert.startDate) }} |
            {{ formatedDate(cert.endDate) }}
          </time>
          <h4 class="text-white font-bold text-lg mb-2 uppercase">
            {{ cert.name }} |
            <i class="text-secondary">{{ cert.certifiedBy }} </i>
          </h4>
          <div
            v-if="cert.certId"
            class="
              dark:text-primary-text
              text-secondary-text text-sm
              uppercase
              mb-4
            "
          >
            Id: {{ cert.certId }}
          </div>
          <a :href="cert.certUrls" class="text-primary text-sm">
            {{ cert.certUrl }}
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import Title from '@/components/Title'
import BadgeAward from '@/components/BadgeAward'
import ROUTES from '@/constants/routes'
import { dateFormat } from '@/services/dateFormatting'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import SaveCertsMutation from '@/graphql/mutations/saveCerts'
import { mapActions, mapGetters } from 'vuex'
import { validateDefault, validateUrl } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

const emptyCert = {
  name: null,
  certifiedBy: null,
  startDate: null,
  expires: false,
  endDate: null,
  certId: null,
  certUrl: null
}

export default {
  name: 'CertificacionesyCursosSection',
  props: {
    userCertsAndCourses: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    BadgeAward,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      ROUTES,
      certifications: [],
      edit: true
    }
  },
  mounted() {
    this.initializeData()
    if (this.certifications !== []) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  computed: {
    ...mapGetters(['token']),
    sortCertificaciones() {
      return [...this.certifications].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    confDate: function (e) {
      return dateFormat(e)
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return this.$t('general.no-caduca')
      }
    },
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validar() {
      try {
        this.setLoadingOn()
        const saveCertificaciones = await apolloClient.mutate({
          mutation: SaveCertsMutation,
          variables: {
            certs: this.sortCertificaciones,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveCertificaciones.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.edit = false
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.certifications.push({
        ...emptyCert
      })
    },
    removeRow(index) {
      this.certifications.splice(index, 1)
    },
    editSection() {
      this.edit = true
      if (this.certifications.length <= 0) {
        this.certifications.push({
          ...emptyCert
        })
      }
    },
    cancelEdition() {
      this.certifications = []
      if (this.userCertsAndCourses.length > 0) {
        this.initializeData()
      }
      this.edit = false
    },
    initializeData() {
      if (this.userCertsAndCourses) {
        this.userCertsAndCourses.forEach((val) => {
          this.certifications.unshift({
            name: val.name,
            certifiedBy: val.certifiedBy,
            startDate: val.startDate,
            endDate: val.endDate,
            certId: val.certId,
            certUrl: val.certUrl
          })
        })
      }
    },
    // RULES
    validateDefault,
    validateUrl,
    checkExpires(index) {
      if (this.certifications[index].expires === true) {
        this.certifications[index].endDate = null
      }
    }
  }
}
</script>
