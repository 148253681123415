<template>
  <ModalBienvenida
    v-if="modalBienvenidaShow"
    :userName="fullname"
    @cerrarModal="getCurrentRoute"
  />
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <div class="w-full lg:w-6/12 xl:w-5/12 mx-auto">
        <!-- Card Page Sign Up -->
        <CardPage class="relative">
          <template #default>
            <CodyAnimations :animado="completeFields" class="cody--card" />
            <Form @submit="signUp">
              <!-- Title -->
              <div class="card--page--header">
                <Title>
                  <template #title>
                    <h3>{{ $t('registrarse-page.title') }}</h3>
                  </template>
                </Title>
              </div>
              <!-- End Title -->
              <!-- Body -->
              <div class="card--page--body">
                <p class="dark:text-white text-secondary-text text-lg">
                  {{ $t('registrarse-page.texto-bienvenida') }}
                </p>
                <div class="form--group">
                  <label class="form--label" for="nombre-completo">{{
                    $t('registrarse-page.lbl-nombre-completo')
                  }}</label>
                  <Field
                    name="fullname"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="firstname"
                  />
                  <ErrorMessage name="fullname" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="nombre-completo">{{
                    $t('registrarse-page.lbl-lastname')
                  }}</label>
                  <Field
                    name="lastname"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="lastname"
                  />
                  <ErrorMessage name="fullname" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="nombre-completo">{{
                    $t('registrarse-page.lbl-surname')
                  }}</label>
                  <Field
                    name="surname"
                    type="text"
                    class="form--element w-full"
                    v-model="surname"
                  />
                  <ErrorMessage name="fullname" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="email">{{
                    $t('registrarse-page.lbl-email')
                  }}</label>
                  <Field
                    name="email"
                    type="email"
                    class="form--element w-full"
                    :rules="validateEmail"
                    v-model="email"
                  />
                  <ErrorMessage name="email" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="pais">{{
                    $t('registrarse-page.lbl-pais')
                  }}</label>
                  <Field
                    name="country"
                    as="select"
                    class="form--element form--select w-full"
                    :rules="validateDefault"
                    v-model="country"
                  >
                    <option value="" disabled></option>
                    <option
                      :value="pais"
                      v-for="pais in paisesLista[$i18n.locale]"
                      :key="pais"
                    >
                      {{ pais }}
                    </option>
                  </Field>
                  <ErrorMessage name="country" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="pais">{{
                    $t('sign-up-evento-page.text-especialidad')
                  }}</label>
                  <Field
                    name="especialidad"
                    as="select"
                    class="form--element form--select w-full"
                    :rules="validateDefault"
                    v-model="especialidad"
                  >
                    <option value="" disabled></option>
                    <option
                      :value="especialidad"
                      v-for="especialidad in especialidades
                        .map((val) => val.name)
                        .sort()"
                      :key="especialidad"
                    >
                      {{ especialidad }}
                    </option>
                  </Field>
                  <ErrorMessage name="especialidad" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="nombre-completo">{{
                    $t('registrarse-page.lbl-years')
                  }}</label>
                  <Field
                    name="yearsExperience"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                    v-model="yearsExperience"
                  />
                  <ErrorMessage name="yearsExperience" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="telefono">{{
                    $t('registrarse-page.lbl-telefono')
                  }}</label>
                  <div class="form--phone flex">
                    <Field
                      name="phoneCode"
                      type="text"
                      :placeholder="$t('general.codigo-internacional')"
                      list="phoneCodeNumberList"
                      class="form--element form--select w-1/3 h-12"
                      :rules="validateDefaultPhone"
                      v-model="phoneCodeNumber"
                    />
                    <datalist id="phoneCodeNumberList">
                      <option value="" disabled>
                        {{ $t('general.selecciona') }}
                      </option>
                      <option
                        :value="code.dialCode"
                        v-for="(code, index) in phoneCodes"
                        :key="index"
                      >
                        {{ code.emoji }} {{ code.name }}
                      </option>
                    </datalist>

                    <Field
                      name="phoneNumber"
                      type="text"
                      class="form--element w-2/3 h-12"
                      :rules="validatePhone"
                      v-model="phoneNumber"
                    />
                  </div>
                  <ErrorMessage name="phoneCode" class="form--error" />
                  <ErrorMessage name="phoneNumber" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="contraseña">
                    {{ $t('registrarse-page.lbl-contraseña') }} <br />
                    <small class="text-xs leading-none">
                      {{ $t('form-validation.error-password-valid') }}
                    </small>
                  </label>
                  <div class="form--icon form--icon--right">
                    <button
                      type="button"
                      @click="showPassword = !showPassword"
                      class="icon--wrapper bg-secondary text-white rounded-r-lg"
                    >
                      <img
                        src="@/assets/images/icn-eye.svg"
                        v-if="showPassword"
                      />
                      <img src="@/assets/images/icn-eye-close.svg" v-else />
                    </button>
                    <Field
                      name="password"
                      :type="[showPassword ? 'text' : 'password']"
                      class="form--element"
                      :rules="validatePasswordComplex"
                      v-model="password"
                    />
                  </div>
                  <ErrorMessage name="password" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="confirmar-contraseña">{{
                    $t('registrarse-page.lbl-confirmar-contraseña')
                  }}</label>
                  <div class="form--icon form--icon--right">
                    <button
                      type="button"
                      @click="showRepeatPassword = !showRepeatPassword"
                      class="icon--wrapper bg-secondary text-white rounded-r-lg"
                    >
                      <img
                        src="@/assets/images/icn-eye.svg"
                        v-if="showRepeatPassword"
                      />
                      <img src="@/assets/images/icn-eye-close.svg" v-else />
                    </button>
                    <Field
                      name="repeatPassword"
                      :type="[showRepeatPassword ? 'text' : 'password']"
                      class="form--element w-full"
                      :rules="validateRepeatPassword"
                      v-model="repeatPassword"
                    />
                  </div>
                  <ErrorMessage name="repeatPassword" class="form--error" />
                </div>
                <div class="form--group">
                  <Field
                    v-slot="{ field }"
                    name="accepted"
                    as="label"
                    :rules="validateDefault"
                    class="ml-3 form--checkbox"
                  >
                    <input
                      type="checkbox"
                      name="accepted"
                      v-bind="field"
                      v-model="accepted"
                    />
                    <span>
                      {{ $t('registrarse-page.lbl-politicas-1') }}
                      <router-link
                        :to="{ name: 'Terminos' }"
                        class="text-primary"
                      >
                        {{ $t('footer.terminos-condiciones') }}
                      </router-link>
                      {{ $t('registrarse-page.lbl-politicas-2') }}
                      <router-link
                        :to="{ name: 'Politica' }"
                        class="text-primary"
                      >
                        {{ $t('footer.politica-privacidad') }} </router-link
                      >.
                    </span>
                  </Field>
                  <ErrorMessage name="accepted" class="form--error ml-3" />
                </div>
                <div class="form--group" v-if="error">
                  <p class="text-lg text-center text-fourth font-bold">
                    {{ $t('registrarse-page.error-tienes-cuenta-01') }}
                    <router-link
                      :to="{
                        name: 'SignIn',
                        params: {
                          id: $route.params.id,
                          name: $route.params.name,
                          date: $route.params.date,
                          place: $route.params.place,
                          photo: $route.params.photo,
                          eventLang: $route.params.eventLang,
                          category: $route.params.category,
                          badges: $route.params.badges,
                          liveLink: $route.params.liveLink,
                          calendarLink: $route.params.calendarLink,
                          summit: $route.params.summit
                        }
                      }"
                      class="underline"
                      >{{ $t('registrarse-page.btn-loguearse') }}</router-link
                    >
                    {{ $t('registrarse-page.error-tienes-cuenta-02') }}
                  </p>
                </div>
                <div>
                  <button
                    type="submit"
                    class="btn btn-lg btn-primary btn-rounded-lg mx-auto"
                    role="button"
                  >
                    {{ $t('registrarse-page.btn-crear-cuenta') }}
                  </button>
                </div>
              </div>
              <!-- End Body -->
              <!-- End Options -->
              <!-- <div class="card--page--option">
                <div class="card--page--option--divider">
                  <h4>{{ $t('registrarse-page.texto-ingresa-con') }}</h4>
                </div>
                <div class="card--page--body">
                  <ul class="flex space-x-4 w-full">
                    <li class="w-full">
                      <button
                        type="button"
                        @click="loginWithGithub"
                        class="btn btn-lg btn-full btn-rounded-lg btn-github"
                      >
                        <img
                          src="@/assets/images/social-media/icn-github.svg"
                          alt="Github"
                        />
                      </button>
                    </li>
                    <li class="w-full" v-if="false">
                      <button
                        type="button"
                        class="btn btn-lg btn-full btn-rounded-lg btn-linkedin"
                      >
                        <img
                          src="@/assets/images/social-media/icn-linkedin.svg"
                          alt="LinkedIn"
                        />
                      </button>
                    </li>
                    <li class="w-full">
                      <button
                        type="button"
                        class="btn btn-lg btn-full btn-rounded-lg btn-gmail"
                        @click="loginWithGoogle"
                      >
                        <img
                          src="@/assets/images/social-media/icn-gmail.svg"
                          alt="Gmail"
                        />
                      </button>
                    </li>
                    <li class="w-full">
                      <button
                        type="button"
                        class="btn btn-lg btn-full btn-rounded-lg btn-facebook"
                        @click="loginWithFacebook"
                      >
                        <img
                          src="@/assets/images/social-media/icn-facebook.svg"
                          alt="Facebook"
                        />
                      </button>
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- End Options -->
              <!-- Footer -->
              <div class="card--page--footer">
                <div class="text-center">
                  <p class="dark:text-primary-text text-secondary-text mb-0">
                    {{ $t('registrarse-page.texto-tengo-cuenta') }}
                    <router-link
                      :to="{
                        name: 'SignIn',
                        params: {
                          id: $route.params.id,
                          name: $route.params.name,
                          date: $route.params.date,
                          place: $route.params.place,
                          photo: $route.params.photo,
                          eventLang: $route.params.eventLang,
                          category: $route.params.category,
                          badges: $route.params.badges,
                          liveLink: $route.params.liveLink,
                          calendarLink: $route.params.calendarLink,
                          summit: $route.params.summit
                        }
                      }"
                      class="underline"
                      >{{ $t('registrarse-page.btn-loguearse') }}</router-link
                    >.
                  </p>
                </div>
              </div>
              <!-- End Footer -->
            </Form>
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import paisesLista from '@/constants/countryList.json'
import phoneCodes from '@/constants/phoneCodes.json'
// import especialidadLista from '@/constants/specialities.json'
import { mapActions, mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ROUTES from '@/constants/routes'
import CodyAnimations from '@/components/animation/CodyHangingToogle'
import apolloClient from '@/graphql/apolloClient'
import signUpMutation from '@/graphql/mutations/signUp'
import getPositionsQuery from '@/graphql/queries/positions'
import ModalBienvenida from '@/components/ModalBienvenida'
import firebase, { firebaseInstance, analytics } from '@/services/firebase'
import router from '@/router/index'
import { toastNotification } from '@/services/toastNotification'
import {
  validateDefault,
  validateEmail,
  validatePasswordComplex,
  validatePhone,
  validateDefaultPhone
} from '@/services/validationRules'

export default {
  components: {
    CardPage,
    Title,
    Field,
    Form,
    ErrorMessage,
    CodyAnimations,
    ModalBienvenida
  },
  data() {
    return {
      paisesLista,
      fullname: '',
      firstname: '',
      lastname: '',
      surname: '',
      email: '',
      country: '',
      especialidad: '',
      phoneNumber: '',
      password: '',
      repeatPassword: '',
      yearsExperience: '',
      ROUTES,
      error: false,
      showPassword: false,
      showRepeatPassword: false,
      phoneCodeNumber: '+52',
      modalBienvenidaShow: false,
      phoneCodes,
      accepted: false,
      especialidades: []
    }
  },
  beforeMount() {
    this.getPositions()
  },
  computed: {
    ...mapGetters(['user']),
    completeFields() {
      if (
        this.fullname !== '' &&
        this.email !== '' &&
        this.country !== '' &&
        this.especialidad !== '' &&
        this.phoneNumber !== '' &&
        this.password !== '' &&
        this.repeatPassword !== ''
      ) {
        return true
      } else {
        return false
      }
    },
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phoneNumber
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    getPositions: async function () {
      try {
        const positionQuery = await apolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: getPositionsQuery
        })
        this.especialidades = positionQuery.data.positionTypes
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    loginWithGoogle: async function () {
      const provider = new firebaseInstance.auth.GoogleAuthProvider()
      try {
        this.setLoadingOn()
        const result = await firebase.signInWithPopup(provider)
        if (result.additionalUserInfo.isNewUser) {
          const parts = result.user.displayName.match(
            /^(\S+(?:\s+\S+)*)\s+(\S+)\s+(\S+(?:\s+\S+)*)$/
          )
          const firstname = parts[1]
          const lastname = parts[2]
          const surname = parts[3]
          await apolloClient.mutate({
            mutation: signUpMutation,
            variables: {
              fullname: result.user.displayName,
              email: result.user.email,
              firstname: firstname,
              lastname: lastname,
              surname: surname
            }
          })
          this.fetchUser(this.$router)
          analytics.logEvent('Registro')
          this.setLoadingOff()
        } else {
          this.setLoadingOff()
        }
      } catch (e) {
        toastNotification(e, 'error')
        this.setLoadingOff()
      }
    },
    loginWithFacebook: async function () {
      const provider = new firebaseInstance.auth.FacebookAuthProvider()
      try {
        this.setLoadingOn()
        const result = await firebase.signInWithPopup(provider)
        if (result.additionalUserInfo.isNewUser) {
          const parts = result.user.displayName.match(
            /^(\S+(?:\s+\S+)*)\s+(\S+)\s+(\S+(?:\s+\S+)*)$/
          )
          const firstname = parts[1]
          const lastname = parts[2]
          const surname = parts[3]
          await apolloClient.mutate({
            mutation: signUpMutation,
            variables: {
              fullname: result.user.displayName,
              email: result.user.email,
              firstname: firstname,
              lastname: lastname,
              surname: surname
            }
          })
          analytics.logEvent('Registro')
          this.fetchUser(this.$router)
          this.setLoadingOff()
        } else {
          this.setLoadingOff()
        }
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
        this.setLoadingOff()
      }
    },
    loginWithGithub: async function () {
      const provider = new firebaseInstance.auth.GithubAuthProvider()
      try {
        this.setLoadingOn()
        const result = await firebase.signInWithPopup(provider)
        if (result.additionalUserInfo.isNewUser) {
          const fullname = result.user.displayName || ''
          const parts = fullname.match(
            /^(\S+(?:\s+\S+)*)\s+(\S+)\s+(\S+(?:\s+\S+)*)$/
          )
          const firstname = parts ? parts[1] : ''
          const lastname = parts ? parts[2] : ''
          const surname = parts ? parts[3] : ''
          await apolloClient.mutate({
            mutation: signUpMutation,
            variables: {
              fullname: result.user.displayName ? result.user.displayName : '',
              email: result.user.email,
              firstname: firstname,
              lastname: lastname,
              surname: surname
            }
          })
          analytics.logEvent('Registro')
          this.fetchUser(this.$router)
          this.setLoadingOff()
        } else {
          this.setLoadingOff()
        }
      } catch (e) {
        toastNotification(e, 'error')
        this.setLoadingOff()
      }
    },
    async signUp(values) {
      this.setLoadingOn()
      if (values) {
        try {
          await apolloClient.mutate({
            mutation: signUpMutation,
            variables: {
              firstname: this.firstname,
              lastname: this.lastname,
              surname: this.surname,
              email: this.email,
              country: this.country,
              speciality: this.especialidad,
              fullname: `${this.firstname} ${this.lastname} ${this.surname}`,
              phonenumber: this.phoneNumberComplete
            }
          })
          this.modalBienvenidaShow = true
          const createdUser = await firebase.createUserWithEmailAndPassword(
            this.email,
            this.password
          )
          analytics.logEvent('Registro')
          createdUser.user.updateProfile({
            displayName: this.fullname
          })
          toastNotification(
            this.$t('general.logueado-correctamente'),
            'success'
          )
        } catch (e) {
          this.modalBienvenidaShow = false
          this.setLoadingOff()
          this.error = true
          toastNotification(e, 'error')
        }
      }
    },
    // RULES
    validateDefault,
    validateEmail,
    validatePasswordComplex,
    validatePhone,
    validateDefaultPhone,
    validateRepeatPassword(value) {
      if (!value) {
        return this.$t('form-validation.error-default')
      }
      if (value !== this.password) {
        return this.$t('form-validation.error-password-repeat')
      }
      return true
    },
    async getCurrentRoute() {
      if (router) {
        const currentRoute = router.currentRoute.value.name
        if (currentRoute === 'SignUp') {
          if (router.currentRoute.value.query.redirectTo) {
            await this.closeModalAndWait()
            console.log(
              'Redirecting to: ',
              router.currentRoute.value.query.redirectTo
            )
            router.push({ path: router.currentRoute.value.query.redirectTo })
          } else {
            await this.closeModalAndWait()
            console.log('Redirecting to: Profile')
            router.push({ name: 'Profile' })
          }
        }
      }
    },
    closeModalAndWait() {
      return new Promise((resolve) => {
        this.modalBienvenidaShow = false
        this.$nextTick(() => {
          console.log('Modal closed')
          resolve()
        })
      })
    }
  }
}
</script>
