<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4 class="mt-1">
          {{ $t('profile-page.title-experiencia-profesional') }}
        </h4>
      </template>
      <template #options v-if="!isPublic && edit === false">
        <button
          @click="editSection()"
          type="button"
          role="button"
          aria-label="Edit"
          class="
            w-9
            h-9
            flex
            justify-center
            items-center
            rounded-md
            btn-secondary
            transform
            -translate-y-2
          "
        >
          <img src="@/assets/images/icn-edit.svg" />
        </button>
      </template>
    </Title>
    <!-- If not complete the section -->
    <div
      class="text-center"
      v-if="!isPublic && edit === false && experiences.length <= 0"
    >
      <div class="flex justify-center space-x-6">
        <BadgeAward
          class="inline-block"
          imagen="icn-experiencia.svg"
          :bloqueado="true"
          :titulo="$t('habilidades-page.title-experiencia')"
        ></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-experiencia-profesional-badge') }}
      </p>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-md btn-rounded-lg btn-primary mx-auto"
          @click="editSection()"
        >
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <!-- If Experiences is not Complete and you are editing -->
    <Form @submit="validate" v-else-if="!isPublic && edit === true">
      <p class="dark:text-primary-text text-secondary-text">
        {{ $t('modal-experiencia-profesional.description-1') }}
      </p>
      <!-- Start Experiences Wrapper -->
      <div v-for="(experience, index) in experiences" :key="experience.id">
        <!-- First Row -->
        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 items-end">
          <div class="form--group">
            <label class="form--label" :for="`position-${index}`">{{
              $t('modal-experiencia-profesional.lbl-posicion')
            }}</label>
            <Field
              :name="`position-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="experience.position"
            />
            <ErrorMessage :name="`position-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`company-${index}`">{{
              $t('modal-experiencia-profesional.lbl-empresa')
            }}</label>
            <Field
              :name="`company-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="experience.company"
            />
            <ErrorMessage :name="`company-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`companyLocation-${index}`">{{
              $t('modal-experiencia-profesional.lbl-ubicacion-empresa')
            }}</label>
            <Field
              :name="`companyLocation-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="experience.companyLocation"
            />
            <ErrorMessage
              :name="`companyLocation-${index}`"
              class="form--error"
            />
          </div>
        </div>
        <!-- End First Row -->
        <!-- Second Row -->
        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 items-end">
          <div
            class="form--group"
            :class="!experience.expires ? '' : 'lg:col-span-2'"
          >
            <h4
              class="
                dark:text-primary-text
                text-secondary-text
                font-bold
                mb-2
                lg:col-span-2
              "
            >
              {{ $t('modal-experiencia-profesional.title-fecha-de-inicio') }}
            </h4>
            <Field
              :name="`startDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="experience.startDate"
              class="form--icon form--icon--left block"
            >
              <span class="icon--wrapper bg-secondary text-white rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                :max="new Date().toISOString().split('T')[0]"
              />
            </Field>
            <ErrorMessage :name="`startDate-${index}`" class="form--error" />
          </div>
          <div class="form--group" v-if="!experience.expires">
            <h4
              class="
                dark:text-primary-text
                text-secondary-text
                font-bold
                mb-2
                lg:col-span-2
              "
            >
              {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
            </h4>
            <Field
              :name="`endDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="experience.endDate"
              class="form--icon form--icon--left block"
            >
              <span class="icon--wrapper bg-secondary text-white rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                :min="
                  experience.startDate || new Date().toISOString().split('T')[0]
                "
              />
            </Field>
            <ErrorMessage :name="`endDate-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--checkbox lg:mb-3"
              ><input
                type="checkbox"
                :id="`remember-${index}`"
                value="first_checkbox"
                @change="checkExpires(index)"
                v-model="experience.expires"
              /><span>{{
                $t('modal-experiencia-profesional.lbl-sigo-trabajando')
              }}</span></label
            >
          </div>
        </div>
        <!-- End Second Row -->
        <!-- Third Row -->
        <div class="form--group">
          <label class="form--label" :for="`whatBuilt-${index}`">
            {{ $t('modal-experiencia-profesional.description-2') }}
          </label>
          <Field
            type="div"
            :name="`whatBuilt-${index}`"
            :rules="validateDefault"
            v-slot="{ field }"
            v-model="experience.whatBuilt"
          >
            <textarea
              v-bind="field"
              class="form--element w-full"
              rows="4"
              maxlength="500"
              :placeholder="
                $t('modal-experiencia-profesional.placeholder-ejempo')
              "
            >
            </textarea>
            <div class="text-right font-bold text-secondary-text">
              {{ experience.whatBuilt.length }}/500
            </div>
          </Field>
          <ErrorMessage :name="`whatBuilt-${index}`" class="form--error" />
        </div>
        <!-- End Third Row -->
        <!-- Fourth Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4">
          <div class="form--group">
            <label class="form--label" :for="`technologies-${index}`">{{
              $t('modal-experiencia-profesional.lbl-tecnologias-relevantes')
            }}</label>
            <Field
              :name="`technologies-${index}`"
              as="span"
              :rules="validateDefault"
              v-slot="{ field }"
              v-model="experience.technologies"
            >
              <Multiselect
                :options="tecnologiasLista.map((item) => item.name)"
                :value="experience.technologies"
                mode="tags"
                @update:modelValue="(val) => field.onChange(val)"
                v-bind="field"
                :createTag="true"
                searchable
                :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                :noResultsText="$t('contrata-devs-page.sin-resultados')"
              />
            </Field>
            <ErrorMessage :name="`technologies-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`appOrRepo-${index}`">{{
              $t('modal-experiencia-profesional.lbl-link-app')
            }}</label>
            <Field
              :name="`appOrRepo-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateUrl"
              v-model="experience.appOrRepo"
            />
            <ErrorMessage :name="`appOrRepo-${index}`" class="form--error" />
          </div>
        </div>
        <!-- End Third Row -->
        <!--
              <div class="form--group">
                <label class="form--upload" for="upload">{{
                  $t('modal-experiencia-profesional.lbl-upload')
                }}</label>
                <input
                  type="file"
                  multiple
                  id="upload"
                  name="upload"
                  class="hidden w-full"
                />
              </div>
              -->
        <div class="flex justify-end mb-6">
          <button
            class="
              w-9
              h-9
              flex
              justify-center
              items-center
              rounded-md
              btn-secondary
            "
            @click="removeRow(index)"
          >
            <img src="@/assets/images/icn-remove.svg" />
          </button>
        </div>
        <hr class="line-line border-dashed mb-6" />
      </div>
      <!-- End Experiences Wrapper -->
      <button
        class="btn btn-rounded-lg btn-secondary btn-md mb-8"
        type="button"
        role="button"
        @click="addRow"
        :aria-label="$t('general.agregar-experiencia')"
      >
        {{ $t('general.agregar-experiencia') }}
      </button>
      <div class="flex justify-center space-x-4">
        <button
          type="button"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="cancelEdition"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary mx-auto btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </Form>
    <!-- Else If complete the section -->
    <div v-else>
      <ul class="list--education list--education--small">
        <li v-for="(job, index) in experiences" :key="index" class="pb-8">
          <span class="list--education--bullets"></span>
          <time class="dark:text-primary-text text-secondary-text text-sm"
            >{{ formatedDate(job.startDate) }} |
            <span v-if="job.endDate">{{ formatedDate(job.endDate) }}</span>
            <span v-else>
              {{ $t('general.presente') }}
            </span>
            <span class="dark:text-primary-text text-secondary-text text-sm">
              ( {{ job.companyLocation }} )
            </span>
          </time>
          <h4 class="text-white font-bold text-lg mb-2 uppercase">
            {{ job.position }} | <i class="text-secondary">{{ job.company }}</i>
          </h4>
          <p class="dark:text-primary-text text-secondary-text last">
            {{ job.whatBuilt }}
          </p>
          <div class="list--badges mt-2">
            <template v-for="(badge, key) in job.technologies" :key="key">
              <div class="badge badge-line badge-xs badge-rounded-full">
                #{{ badge }}
              </div>
            </template>
          </div>
          <div class="mt-4 mb-2" v-if="job.appOrRepo">
            <a :href="job.appOrRepo" class="text-primary text-sm">{{
              job.appOrRepo
            }}</a>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import BadgeAward from '@/components/BadgeAward'
import Title from '@/components/Title'
import ROUTES from '@/constants/routes'
import { dateFormat } from '@/services/dateFormatting'
// import tecnologiasLista from '@/components/json/tecnologias.json'
import getTechnologiesQuery from '@/graphql/queries/technologies'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import savePastJobsMutation from '@/graphql/mutations/savePastJobs'
import { mapActions, mapGetters } from 'vuex'
import Multiselect from '@vueform/multiselect'
import { toastNotification } from '@/services/toastNotification'
import {
  validateDefault,
  validateNumber,
  validateUrl
} from '@/services/validationRules'

const emptyExperience = {
  position: null,
  company: null,
  companyLocation: null,
  startDate: null,
  endDate: null,
  whatBuilt: '',
  technologies: null,
  appOrRepo: null,
  expires: false
}

export default {
  name: 'ExperienciaProfesionalSection',
  props: {
    userPastJobs: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    BadgeAward,
    Field,
    Form,
    ErrorMessage,
    Multiselect
  },
  data() {
    return {
      ROUTES,
      tecnologiasLista: [],
      experiences: [],
      edit: false
    }
  },
  beforeMount() {
    this.getTechnologies()
  },
  mounted() {
    this.initializeData()
    // this.edit = this.experiences.length === 0
  },
  computed: {
    ...mapGetters(['token']),
    sortExperiences() {
      return [...this.experiences].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    confDate: function (e) {
      return dateFormat(e)
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return 'Actual'
      }
    },
    getTechnologies: async function () {
      this.setLoadingOn()
      try {
        const technologiesQuery = await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: getTechnologiesQuery
        })
        this.tecnologiasLista = technologiesQuery.data.technologies
        this.setLoadingOff()
      } catch (e) {
        console.log(e, 'error')
        this.setLoadingOff()
      }
    },
    customTechnologies(row) {
      this.tecnologiasLista = [...this.tecnologiasLista, ...row.technologies]
    },
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validate() {
      try {
        this.setLoadingOn()
        const saveExperiencia = await apolloClient.mutate({
          mutation: savePastJobsMutation,
          variables: {
            pastJobs: this.sortExperiences,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveExperiencia.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.edit = false
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.experiences.push({
        ...emptyExperience
      })
    },
    cancelEdition() {
      this.experiences = []
      if (this.userPastJobs.length > 0) {
        this.initializeData()
      }
      this.edit = false
    },
    removeRow(index) {
      this.experiences.splice(index, 1)
    },
    editSection() {
      this.edit = true
      if (this.experiences.length <= 0) {
        this.experiences.push({
          ...emptyExperience
        })
      }
    },
    initializeData() {
      if (this.userPastJobs) {
        this.userPastJobs.forEach((val) => {
          this.experiences.unshift({
            position: val.position,
            company: val.company,
            companyLocation: val.companyLocation,
            startDate: val.startDate,
            endDate: val.endDate,
            whatBuilt: val.whatBuilt,
            technologies: val.technologies,
            appOrRepo: val.appOrRepo
          })
        })
      }
    },
    // RULES
    validateDefault,
    validateNumber,
    validateUrl,
    checkExpires(index) {
      if (this.experiences[index].expires === true) {
        this.experiences[index].endDate = null
      }
    }
  }
}
</script>
