<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('faq-page.title')"
      section="pageHero"
      :hero-image="require('@/assets/images/hero/bg-faq.svg')"
      class="hero--sm"
    />
    <div class="content--wrapper dark:text-primary-text text-secondary-text">
      <CardPage class="p-8">
        <template #default>
          <div v-html="$t('faq-page.description-faq')"></div>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('faq-page.title-para-talento') }}</h4>
            </template>
          </Title>
          <h5 class="text-lg font-bold text-primary mb-3">
            {{ $t('faq-page.title-registro') }}
          </h5>
          <!-- TALENT -->
          <div
            class="
              grid grid-cols-1
              lg:grid-cols-2
              gap-x-6 gap-y-4
              mb-10
              items-start
            "
          >
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-que-es-utopicode')">
                <template #body>
                  {{ $t('faq-page.text-que-es-utopicode') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-como-funciona-el-proceso')">
                <template #body>
                  {{ $t('faq-page.text-como-funciona-el-proceso') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-necesito-pagar-tarifa')">
                <template #body>
                  {{ $t('faq-page.text-necesito-pagar-tarifa') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-informacion-incluida')">
                <template #body>
                  {{ $t('faq-page.text-informacion-incluida') }}
                </template>
              </Collapse>
            </div>
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-como-funcionan-badges')">
                <template #body>
                  {{ $t('faq-page.text-como-funcionan-badges') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-como-gano-mi-remera')">
                <template #body>
                  {{ $t('faq-page.text-como-gano-mi-remera') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-quien-puede-ver-perfil')">
                <template #body>
                  {{ $t('faq-page.text-quien-puede-ver-perfil') }}
                </template>
              </Collapse>
            </div>
          </div>
          <h5 class="text-lg font-bold text-primary mb-3">
            {{ $t('faq-page.title-comunidad') }}
          </h5>
          <div
            class="
              grid grid-cols-1
              lg:grid-cols-2
              gap-x-6 gap-y-4
              mb-10
              items-start
            "
          >
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-tipo-de-eventos')">
                <template #body>
                  {{ $t('faq-page.text-tipo-de-eventos') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-como-me-entero')">
                <template #body>
                  <div v-html="$t('faq-page.text-como-me-entero')"></div>
                </template>
              </Collapse>
            </div>
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-como-puedo-participar')">
                <template #body>
                  {{ $t('faq-page.text-como-puedo-participar') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-puedo-hacer-preguntas')">
                <template #body>
                  {{ $t('faq-page.text-puedo-hacer-preguntas') }}
                </template>
              </Collapse>
            </div>
          </div>
          <h5 class="text-lg font-bold text-primary mb-3">
            {{ $t('faq-page.title-oportunidades') }}
          </h5>
          <div
            class="
              grid grid-cols-1
              lg:grid-cols-2
              gap-x-6 gap-y-4
              mb-14
              items-start
            "
          >
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-buscando-trabajo')">
                <template #body>
                  <ul class="list--number list--number--primary">
                    <li v-for="item in 4" :key="item">
                      {{ $t(`faq-page.lbl-buscando-trabajo-${item}`) }}
                    </li>
                  </ul>
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-sin-entrevista')">
                <template #body>
                  <div v-html="$t('faq-page.text-sin-entrevista')"></div>
                </template>
              </Collapse>
            </div>
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-tipo-de-empresas')">
                <template #body>
                  {{ $t('faq-page.text-tipo-de-empresas') }}
                </template>
              </Collapse>
            </div>
          </div>
          <!-- END TALENT -->
          <!-- COMPANIAS -->
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('faq-page.title-para-companias') }}</h4>
            </template>
          </Title>
          <div
            class="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-4 items-start"
          >
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-que-es-utopicode-empresa')">
                <template #body>
                  {{ $t('faq-page.text-que-es-utopicode-empresa') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-porque-usar-utopicode')">
                <template #body>
                  {{ $t('faq-page.text-porque-usar-utopicode') }}
                </template>
              </Collapse>
              <Collapse
                :title="$t('faq-page.title-como-funciona-el-proceso-empresa')"
              >
                <template #body>
                  {{ $t('faq-page.text-como-funciona-el-proceso-empresa') }}
                </template>
              </Collapse>
            </div>
            <div class="w-full space-y-4">
              <Collapse :title="$t('faq-page.title-necesidades-contratacion')">
                <template #body>
                  {{ $t('faq-page.text-necesidades-contratacion') }}
                </template>
              </Collapse>
              <Collapse :title="$t('faq-page.title-cual-es-el-costo')">
                <template #body>
                  {{ $t('faq-page.text-cual-es-el-costo') }}
                </template>
              </Collapse>
            </div>
          </div>
          <!-- END COMPANIAS -->
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Collapse from '@/components/Collapse'
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'

export default {
  setup() {
    return {}
  },
  components: {
    Hero,
    Collapse,
    CardPage,
    Title
  }
}
</script>
