<template>
  <section>
    <h2
      class="
        text-contrast
        dark:text-white
        title--h2
        font-black
        text-center
        mb-4
      "
    >
      {{ $t('suscription-page.title-01') }}
      <span
        class="
          text-transparent
          bg-clip-text bg-gradient-to-r
          from-sixth
          to-primary
        "
      >
        {{ $t('suscription-page.title-02') }}
      </span>
      {{ $t('suscription-page.title-03') }}
    </h2>
    <p class="text-secondary-text dark:text-primary-text text-lg text-center">
      {{ $t('suscription-page.description') }}
    </p>
    <div class="grid mt-8 gap-6 lg:grid-cols-2 grid-cols-1">
      <!-- First Suscription -->
      <CardPage class="flex flex-col h-full">
        <template #default>
          <!-- Title -->
          <div class="card--page--header flex gap-4 items-center">
            <div
              class="
                bg-line-light
                dark:bg-footer
                rounded-lg
                flex
                justify-center
                items-center
                h-20
                w-20
              "
            >
              <img src="@/assets/images/icon-basic.png" />
            </div>
            <div class="flex flex-col">
              <h4 class="font-black text-2xl dark:text-white text-contrast">
                {{ $t('suscription-page.suscription-title-1') }}
              </h4>
              <h5 class="font-black dark:text-white text-contrast text-lg">
                <span class="text-5xl dark:text-primary text-secondary"
                  >$65/</span
                >{{ $t('suscription-page.anual') }}
              </h5>
            </div>
          </div>
          <!-- End Title -->
          <!-- Body -->
          <div class="card--page--body flex-grow flex flex-col">
            <ul class="w-full list--icon text-sm mb-8 space-y-2 flex-grow">
              <li v-for="(item, index) in 3" :key="index">
                <span class="icon"
                  ><img src="@/assets/images/icn-check-list.svg"
                /></span>
                {{ $t(`suscription-page.suscription-basic-${index + 1}`) }}
              </li>
            </ul>
            <div class="flex justify-center">
              <router-link
                class="
                  btn btn-lg btn-secondary
                  dark:rounded-full
                  rounded-lg
                  shadow-lg
                "
                :to="{ name: 'Trabajos' }"
                :title="$t('general.contratar-ahora')"
                >{{ $t('general.contratar-ahora') }}</router-link
              >
            </div>
          </div>
        </template>
      </CardPage>
      <!-- End First Suscription -->
      <!-- Second Suscription -->
      <CardPage class="flex flex-col h-full">
        <template #default>
          <!-- Title -->
          <div class="card--page--header flex gap-4 items-center">
            <div
              class="
                bg-line-light
                dark:bg-footer
                rounded-lg
                flex
                justify-center
                items-center
                h-20
                w-20
              "
            >
              <img src="@/assets/images/icon-pro.png" />
            </div>
            <div class="flex flex-col">
              <h4 class="font-black text-2xl dark:text-white text-contrast">
                {{ $t('suscription-page.suscription-title-2') }}
              </h4>
              <h5 class="font-black dark:text-white text-contrast text-lg">
                <span class="text-5xl dark:text-primary text-secondary"
                  >$300/</span
                >{{ $t('suscription-page.anual') }}
              </h5>
            </div>
          </div>
          <!-- End Title -->
          <!-- Body -->
          <div class="card--page--body flex-grow flex flex-col">
            <ul class="w-full list--icon text-sm mb-8 space-y-2 flex-grow">
              <li v-for="(item, index) in 5" :key="index">
                <span class="icon"
                  ><img src="@/assets/images/icn-check-list.svg"
                /></span>
                {{ $t(`suscription-page.suscription-pro-${index + 1}`) }}
              </li>
            </ul>
            <div class="flex justify-center">
              <router-link
                class="
                  btn btn-lg btn-secondary
                  dark:rounded-full
                  rounded-lg
                  shadow-lg
                "
                :to="{ name: 'Trabajos' }"
                :title="$t('general.contratar-ahora')"
                >{{ $t('general.contratar-ahora') }}</router-link
              >
            </div>
          </div>
        </template>
      </CardPage>
      <!-- End Second Suscription -->
    </div>
  </section>
</template>

<script>
import CardPage from '@/components/CardPage'

export default {
  name: 'SuscriptionSection',
  components: {
    CardPage
  }
}
</script>
