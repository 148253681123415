<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Cody"
    x="0"
    y="0"
    version="1.1"
    xml:space="preserve"
    viewBox="0 0 174.8 182.8"
  >
    <defs />
    <path
      id="Body"
      d="M44 77.5C41.9 81 33.4 88.8 31.3 90.7c-4.6 1.5-9.4 2.8-14.1 3.8-.9.3-1.9.3-2.8.1-.7-.2-1.3-.6-1.8-1.1L9.8 91c-.9-.8-2.4-.4-2.6.6L7 92.8c-.2.7-.9 1.1-1.7 1-.7-.1-1.4-.1-2.1-.1-1.1 0-1.8.9-1.3 1.7l1.8 3.2c.1.2.2.4.1.6-.1.2-.4.3-.6.4l-2.3.8c-.6.2-1 .8-.8 1.4.1.4.4.7.8.8 1.2.5 2.5.9 3.8 1.3.9.3 1.3 1.1.9 1.8s-.8 1.3-1.2 1.9c-.6 1 .7 2 2 1.6 2.8-.9 5.4-2.3 7.8-4.1.9-.8 1.9-1.4 3-1.9 1-.3 2-.4 3.1-.3H31v45.9c-4.5 1.5-9.1 2.7-13.7 3.7-.9.3-1.9.3-2.8.1-.7-.2-1.3-.6-1.8-1.2L9.9 149c-.9-.8-2.4-.4-2.6.6l-.3 1.3c-.2.7-.9 1.1-1.7 1-.7-.1-1.4-.1-2.1-.1-1.1 0-1.8.9-1.3 1.7l1.8 3.2c.1.2.2.4.1.6-.1.2-.4.3-.6.4l-2.2.9c-.6.1-1 .7-.8 1.3.1.4.4.7.8.8 1.2.5 2.5.9 3.8 1.3.9.3 1.3 1.1.9 1.8s-.8 1.3-1.2 1.9c-.6 1 .7 2 2 1.6 2.8-.9 5.4-2.3 7.8-4.1.9-.8 1.9-1.4 3-1.8 1-.3 2-.4 3.1-.3h10.9v19.5c37.3-10 49.4-55.5 49.4-55.5S46.4 73.6 44 77.5z"
      class="st0"
    />
    <transition name="fade">
      <g id="headTrue" v-if="animado">
        <path
          d="M116.4 141.5c.1 2.5.1 6 1.8 7.9 1.3 1.4 2.9 2.6 3.6 4.3 1 2.7-.5 6 1.3 8.2 1.2 1.5 3.5 1.9 4.9 3.3 2.6 2.9 0 7.8 2.5 10.8.6.6 1.2 1.2 1.7 1.8.9 1.5.4 3.4-1.1 4.3-1.5.8-3.3.9-4.9.3-5.5-1.6-9.3-6.9-11.1-12.3-.2-.9-.6-1.7-1.2-2.5-1-1.2-2.6-1.5-4-2.1-2.4-1-4.6-3.1-4.9-5.6-.2-1.7.5-3.4.5-5.1-.2-1.8-.9-3.6-2.1-5.1-1.1-1.5-2-3.1-2.7-4.9-.6-1.8-.4-3.8.9-4.9"
          class="st1"
        />
        <path
          d="M120.7 134c2.8-.2 5.7-.1 8.5.3 1.4.1 2.8.7 3.8 1.7 1.3 1.6.9 4 2 5.8.9 1.2 2.2 2.1 3.7 2.4 1.4.4 2.8.4 4.2.8 2.2.6 4.2 1.8 5.7 3.5.5.6.9 1.2 1.1 2v2.2c.1 1.8 1.7 3.3 3.4 4.1s3.4 1.1 5.1 2 3.2 2.5 3.1 4.3c-.2 1.5-1.3 2.8-2.8 3-1.5.2-3.1.1-4.6-.4-1.5-.5-3-.6-4.6-.6-1.6.2-3 1.1-4.6 1.2-1.7.1-3.4-.5-4.7-1.6-1.9-1.7-2.9-4.4-5.1-5.6s-4.9-.2-7.2-1.3c-1.6-.9-2.9-2.4-3.6-4.1-.7-1.7-1.5-3.4-2.4-5-1.7-2.6-4.5-4.4-6.9-6.5s-4.4-5.3-3.6-8.1"
          class="st1"
        />
        <path
          d="M125.6 114c2.4-.8 5.2-1.5 8.1.4 2.1 1.4 3.8 4.1 6.1 4.9 2.6 1 4.4-1 7-.3 2.4.7 4.4 3.4 6.7 4.8 2.6 1.6 5.2 1.3 7.7 1.6s5.9 1.8 7.1 4.7c.7 1.4.4 3.1-.6 4.3-1 .9-2.3 1.4-3.7 1.5-1.8.3-3.8.4-4.4 2.1-.2.5.2-.5 0 0-.6 1.7-3.7 2-5.3 2.6-1.9.7-4.3-.4-6-1.5-4.4-2.8-6.5-1.2-8.9-.7-3.3.7-7.6-2.6-11.2-5.3-4.8-3.6-7.1-2.6-12.1-5.2M58.6 50.8c-1.9-1.6-4.5-3.1-5.1-5.7-.4-1.8-.4-3.9-1.4-5.4-1.5-2.4-5.1-3.1-5.8-6-.5-1.9.6-3.9.2-5.9-.7-3.8-6.2-4.6-7.1-8.4-.1-.8-.2-1.6-.4-2.4-.7-1.6-2.5-2.3-4.1-1.7-1.5.8-2.6 2.1-3.1 3.7-2 5.3.1 11.6 3.3 16.2.6.7 1 1.5 1.3 2.4.4 1.5-.3 3-.7 4.5-.6 2.5-.3 5.5 1.6 7.2 1.3 1.2 3 1.6 4.4 2.7 1.3 1.3 2.3 2.9 2.8 4.7.5 1.8 1.3 3.5 2.3 5.1 1.1 1.5 2.8 2.6 4.5 2.2M71.6 53.4c1.6-2.4 2.9-4.9 3.8-7.6.6-1.3.7-2.8.3-4.1-.8-1.9-3-2.7-4.1-4.6-.7-1.4-.8-2.9-.3-4.3.4-1.4 1-2.7 1.4-4.1.6-2.2.4-4.5-.3-6.7-.2-.7-.6-1.4-1.2-1.9-.6-.4-1.3-.7-1.9-1-1.5-1-2.1-3.1-1.9-5s.7-3.6.7-5.4-.7-4-2.2-4.8c-1.4-.6-3-.2-4 1-.9 1.3-1.6 2.7-1.9 4.2-.3 1.5-.9 3-1.7 4.3-1 1.3-2.4 2.1-3.3 3.4-.9 1.4-1.2 3.2-.8 4.9.6 2.5 2.5 4.7 2.4 7.1s-2.2 4.4-2.4 6.9c0 1.9.6 3.7 1.8 5.2 1.2 1.4 2.3 3 3.2 4.6 1.5 2.8 1.6 6.1 2.3 9.2s2.5 6.4 5.3 7.1M92.8 55.6c1.9-1.7 3.9-3.8 3.6-7.2-.2-2.6-1.7-5.3-1.4-7.7.4-2.7 3-3.4 3.6-6 .5-2.4-.8-5.4-.9-8.1-.1-3 1.4-5.1 2.4-7.5s1.3-6-.6-8.5c-.9-1.3-2.5-1.9-4-1.6-1.3.5-2.4 1.4-3.1 2.5-1.1 1.4-2.2 3.1-4 2.8-.5-.1.5.1 0 0-1.8-.3-3.5 2.2-4.9 3.4-1.5 1.3-1.7 3.9-1.6 6 .3 5.2-2.1 6.3-3.7 8.1-2.2 2.6-1.4 7.9-.8 12.4.8 6-1.1 7.5-1.4 13.1"
          class="st1"
        />
        <path
          d="M40.8 25.7c.5 1.3.8 2.6 1.1 3.9 1.4 6.4 4.9 12 8.5 17.2 1.2 1.8 2.5 3.4 4 5 2.8-.9 5.7-1.6 8.6-2-4.2-12.3-3.8-25.8-2.9-34 .2-2 2.7-1.8 3.1.2l8 33.3c3.6.1 7.3.5 10.8 1.3.2-1.4.5-3 1.1-5.8 1.2-6.8 3.5-13.4 6.7-19.6.8-1.4 1.7-2.8 2.5-2.3.3.2.4.5.5.8.3 1.3-.1 3.3-.4 5.1-1.2 6.6-1.4 17.4-1.4 24.7 10.4 4.5 19.4 11.8 26 21 0 0 13.9 18.9 10.6 42.6 6 3.4 17.6 9.8 25 12.3 1.7.4 3.3 1.1 4.7 2.1.2.2.4.5.4.8 0 .9-1.6 1-3.2 1-6.9-.2-13.8-1.4-20.4-3.7-3-.9-5.9-2-8.7-3.2-.8 2.4-1.8 4.8-3 7.1l26.3 24.3c1.5 1.4.4 3.5-1.3 2.7-7.9-3.3-20.5-9.8-29-20.3-1.3 1.9-2.7 3.7-4.2 5.4.1 2.5.6 4.9 1.2 7.2 1.6 5.9 3.5 12 7.3 17 .8 1 1.6 2.1 2.2 3.2.4.6.2 1.3-.3 1.7-.5.3-1.1.3-1.5-.1-4.7-3.5-8.5-8-11.4-13.1-1.8-3.2-3.4-6.6-4.7-10.1-22.3 10.8-57.2-30.4-66.8-63-4.4-14.8-3.6-27.9 5.9-33.5-2.1-3.6-3.8-7.4-5.2-11.3-2-5.5-2.9-11.6-2.5-17.6 0-.7.6-1.2 1.3-1.2.5 0 1 .4 1.1.9z"
          class="st0"
        />
        <path
          d="M95.7 136c.6-6.8-3-13.4-9.2-16.3-1.1-.5-2.1 1.2-1 1.7 5.6 2.6 8.9 8.5 8.3 14.7-.2 1.1 1.7 1.1 1.9-.1zM63.6 80.4c.6-6.8-3-13.4-9.2-16.3-1.1-.5-2.1 1.2-1 1.7 5.6 2.6 8.9 8.5 8.3 14.6-.1 1.3 1.8 1.3 1.9 0z"
          class="st2"
        />
        <path
          fill="#1c1c1c"
          d="M72 110.8c-2.3-.8-4.4-2.2-6.1-3.9-1.8-2.1-3-4.5-3.7-7.2-.2-.6-.8-1-1.4-.8-.6.2-1 .8-.8 1.4.7 3.1 2.2 5.9 4.3 8.2 2 2 4.5 3.6 7.2 4.6.6.2 1.3-.2 1.4-.8 0-.7-.3-1.3-.9-1.5z"
        />
        <ellipse
          cx="82.2"
          cy="131.4"
          class="st4"
          rx="4.6"
          ry="7.7"
          transform="rotate(-27.541 82.194 131.374)"
        />
        <ellipse
          cx="50.1"
          cy="74.8"
          class="st4"
          rx="4.6"
          ry="7.7"
          transform="rotate(-27.541 50.054 74.75)"
        />
      </g>
    </transition>
    <transition name="fade">
      <g id="headFalse" v-if="!animado">
        <path
          d="M125.1 116.5c1 2.3 2.2 5.5 4.5 6.7 1.7.9 3.6 1.4 4.9 2.7 2 2.1 1.7 5.7 4.2 7.2 1.7 1 3.9.4 5.7 1.3 3.5 1.7 2.8 7.3 6.2 9.1.8.3 1.5.6 2.2 1 1.3 1.1 1.6 3 .5 4.4-1.1 1.2-2.7 2-4.4 2-5.7.5-11.2-3.1-14.8-7.4-.5-.8-1.2-1.4-2-1.9-1.3-.7-3-.5-4.5-.5-2.6 0-5.4-1.2-6.6-3.4-.8-1.6-.7-3.4-1.4-4.9-.9-1.6-2.2-3-3.8-4-1.6-1-3-2.2-4.3-3.5-1.1-1.4-1.8-3.4-.9-4.9"
          class="st1"
        />
        <path
          d="M126.4 107.9c2.5-1.3 5.2-2.2 8-2.8 1.4-.4 2.8-.3 4.1.2 1.8 1 2.3 3.4 4 4.6 1.3.8 2.8 1.2 4.3.9 1.4-.2 2.8-.7 4.2-.8 2.3-.3 4.6.2 6.6 1.2.7.3 1.3.8 1.7 1.4.3.6.6 1.3.8 2 .8 1.6 2.8 2.4 4.6 2.6s3.6-.2 5.4 0 3.9 1.2 4.4 2.9c.4 1.5-.2 3.1-1.6 3.8-1.4.7-2.9 1.2-4.4 1.3-1.5.1-3.1.5-4.5 1.1-1.4.8-2.4 2.1-3.9 2.8-1.6.7-3.3.8-4.9.2-2.4-.9-4.3-3.1-6.8-3.3s-4.6 1.6-7.2 1.4c-1.9-.3-3.6-1.1-4.9-2.5-1.3-1.4-2.6-2.6-4.1-3.8-2.6-1.9-5.8-2.4-8.8-3.6s-6-3.3-6.3-6.2"
          class="st1"
        />
        <path
          d="M123.7 87.5c1.9-1.6 4.3-3.3 7.7-2.6 2.5.6 5.1 2.4 7.4 2.4 2.8 0 3.8-2.6 6.4-2.8 2.4-.2 5.3 1.6 8 2 3 .5 5.3-.7 7.8-1.4s6.2-.4 8.3 1.8c1.1 1.1 1.5 2.7 1 4.2-.6 1.2-1.7 2.2-2.9 2.7-1.6.9-3.4 1.8-3.3 3.6 0 .5 0-.5 0 0 0 1.8-2.7 3.2-4 4.4-1.5 1.4-4.1 1.2-6.1.8-5.1-1-6.5 1.2-8.5 2.6-2.9 1.9-8 .4-12.4-.9-5.8-1.6-7.5.1-13.2-.4M38.3 53c-2.4-.8-5.4-1.3-6.8-3.5-1-1.6-1.8-3.5-3.2-4.6-2.3-1.7-5.9-1.1-7.6-3.4-1.2-1.6-.9-3.9-1.9-5.5-2.1-3.3-7.5-2-9.7-5.2-.4-.7-.8-1.4-1.3-2.1-1.2-1.2-3.2-1.3-4.4-.1-1.1 1.2-1.7 2.9-1.5 4.6.1 5.7 4.3 10.8 9 13.9.8.5 1.5 1.1 2.1 1.8.9 1.3.8 2.9 1 4.4.3 2.6 1.8 5.2 4.1 6.2 1.6.6 3.4.4 5 .9 1.7.7 3.2 1.8 4.3 3.3 1.2 1.5 2.5 2.8 4 3.9 1.5 1 3.6 1.4 5 .4M51.4 50.7c.6-2.8.9-5.6.8-8.4.1-1.4-.3-2.9-1.2-4-1.4-1.5-3.8-1.4-5.4-2.8-1.1-1-1.8-2.4-1.9-3.9-.2-1.4 0-2.9-.2-4.3-.3-2.3-1.2-4.4-2.8-6.1-.5-.6-1.1-1.1-1.8-1.3-.7-.1-1.4-.2-2.2-.3-1.8-.4-3-2.2-3.6-3.9s-.5-3.7-1.1-5.5-2.1-3.5-3.8-3.6c-1.5-.1-2.9.9-3.4 2.4-.4 1.5-.5 3.1-.2 4.6.2 1.5.2 3.1-.1 4.7-.4 1.6-1.5 2.8-1.8 4.4-.3 1.7 0 3.4 1 4.8 1.4 2.1 4 3.5 4.8 5.8s-.4 4.9.3 7.3c.7 1.7 2 3.2 3.6 4.2 1.6.9 3.2 1.9 4.7 3.1 2.4 2.1 3.7 5.1 5.5 7.7s4.7 5.1 7.6 4.7M71.9 45.1c1.1-2.2 2.2-5 .7-8.1-1.1-2.3-3.5-4.3-4.1-6.7-.6-2.7 1.6-4.3 1.2-6.9-.3-2.4-2.7-4.7-3.7-7.2-1.2-2.8-.6-5.3-.5-7.9s-1-6.1-3.7-7.7c-1.3-.9-3-.9-4.3 0-1 .9-1.7 2.1-2 3.5-.5 1.7-1 3.7-2.7 4-.5.1.5-.1 0 0-1.8.4-2.5 3.3-3.3 4.9-1 1.8-.2 4.3.7 6.2 2.2 4.7.3 6.6-.5 8.9-1.1 3.2 1.5 7.9 3.8 11.9 2.9 5.2 1.7 7.4 3.5 12.7"
          class="st1"
        />
        <path
          d="M12.6 36.1c.9 1 1.7 2.1 2.4 3.2 3.6 5.4 8.9 9.4 14.2 12.9 1.7 1.2 3.6 2.3 5.5 3.2 2.3-1.9 4.7-3.5 7.3-5-8.4-9.9-12.9-22.6-15-30.6-.5-1.9 1.8-2.7 3-1l19.6 28.1c3.4-1.3 7-2.2 10.6-2.7-.3-1.4-.6-3-1.1-5.8-1.4-6.8-1.7-13.8-.9-20.7.2-1.6.6-3.2 1.5-3 .3.1.5.3.7.6.8 1.5 1.3 3.2 1.5 4.9 1.3 6.6 5 16.8 7.7 23.5 11.3.4 22.4 3.9 31.9 10.1 0 0 19.9 12.6 25.4 35.8 6.8 1 20 2.7 27.7 2.4 1.9-.1 3.8-.2 5.1.3.3.1.6.3.7.6.4.9-1.1 1.6-2.6 2.1-6.5 2.4-13.4 3.7-20.3 4-3.1.3-6.2.3-9.3.2.1 2.6.1 5.1-.2 7.7l33.4 13c1.9.7 1.7 3.1-.3 3-8.5-.2-22.7-1.7-34.4-8.4-.5 2.2-1.2 4.4-1.9 6.6 1 2.2 2.3 4.3 3.8 6.3 3.6 4.9 7.6 9.9 13 13.2 1.1.6 2.2 1.4 3.2 2.2.6.3.7 1.1.4 1.6-.3.5-1 .7-1.5.5-5.6-1.6-10.8-4.3-15.4-8-2.9-2.3-5.5-4.9-8-7.7C95.6 155.7 6.4 89.4 28.4 61.5c-3.2-2.6-6.3-5.5-9-8.6-4-4.5-7-9.8-8.9-15.6-.3-.6-.1-1.4.5-1.7s1.3-.1 1.6.5z"
          class="st0"
        />
        <circle cx="102.1" cy="114" r="8.7" class="st5" />
        <circle cx="99.3" cy="112.6" r="5.6" class="st2" />
        <path
          d="M96 109.8h3.1v3.1H96z"
          class="st5"
          transform="rotate(-9.508 97.54 111.347)"
        />
        <circle cx="47.7" cy="70.2" r="8.7" class="st5" />
        <circle cx="44.9" cy="68.8" r="5.6" class="st2" />
        <path
          d="M41.6 66h3.1v3.1h-3.1z"
          class="st5"
          transform="rotate(-9.508 43.133 67.533)"
        />
        <path
          fill="#1b1d2b"
          d="M75.7 103.1l-3.7-1.6c-3.2-1.4-6-3.6-8.1-6.5l-2.4-3.3c-1.1-1.5-1.8-4-3.9-.6-2.3 3.8-.9 7.6.5 10.2.2.4.4.8.7 1.2l.1.2c1.6-.4 7.6-1.6 7.2 5.5.3.1.6.2 1 .3 2.9.8 6.7 1 10.1-1.8 3.1-2.5.2-2.8-1.5-3.6z"
        />
        <path
          fill="#fcbec9"
          d="M59 102.8c1.6 2.3 3.8 4.1 6.4 5.2l.8.3c.4-7.2-5.6-6-7.2-5.5z"
        />
      </g>
    </transition>
  </svg>
</template>

<script>
export default {
  name: 'CodyHangingAnimation',
  props: {
    animado: Boolean
  }
}
</script>

<style scoped>
.st0 {
  fill: #d2d2f9;
}
.st1 {
  fill: #4d47ff;
}
.st2 {
  fill: #050006;
}
.st4 {
  opacity: 0.34;
  enable-background: new;
}
.st4,
.st5 {
  fill: #fff;
}
</style>
