<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 144.4 26.3"
    class="fill-current"
  >
    <path
      d="M0 7.2v19.1h15.6c4 0 7.2-3.2 7.2-7.2V0H7.2C3.2 0 0 3.2 0 7.2zm18.6 11.9c0 1.7-1.4 3.1-3.1 3.1H4.1v-15c0-1.7 1.4-3.1 3.1-3.1h11.5v15zM44.3 4c-4.2 0-6.8 2-7.2 5.6v.3h3.1v-.2c.2-2 1.6-3.1 4-3.1 2.6 0 4 1.3 4.1 3.8v3.2c0 2.8-1 6.1-5.1 6.1-2.2 0-3.4-1-3.4-2.9s1.3-2.9 3.9-2.9h2.8v-2.5h-3.1c-4.3 0-6.7 2-6.7 5.4 0 3.4 2.4 5.6 6.2 5.6 2.4 0 4.2-1 5.4-2.9v2.6h3.1v-11c0-1.3-.1-2.7-.7-3.9-.9-2.1-3.2-3.2-6.4-3.2zm24.1 2.7c2.5 0 4.3 1.3 4.8 3.6v.2h3.2l-.1-.3C75.7 6.4 72.6 4 68.4 4c-5.3 0-8.7 3.6-8.7 9.2s3.4 9.2 8.7 9.2c4.3 0 7.3-2.3 8.1-6.3l.1-.3h-3.2v.2c-.5 2.3-2.3 3.6-4.8 3.6-3.5 0-5.5-2.4-5.5-6.4-.3-4.1 1.8-6.5 5.3-6.5zM91.7 4c-4.2 0-6.8 2-7.2 5.6v.3h3.1v-.2c.2-2 1.6-3.1 4-3.1 2.6 0 4 1.3 4.1 3.8v3.2c0 2.8-1 6.1-5.1 6.1-2.2 0-3.4-1-3.4-2.9s1.3-2.9 3.9-2.9h2.8v-2.5h-3.1c-4.3 0-6.7 2-6.7 5.4 0 3.4 2.4 5.6 6.2 5.6 2.4 0 4.2-1 5.4-2.9v2.6h3.1v-11c0-1.3-.1-2.7-.7-3.9-.9-2.1-3.2-3.2-6.4-3.2zm25.2 7.7-2.8-.7c-2.3-.5-3.3-.9-3.3-2.2 0-1.7 1.8-2.3 3.4-2.3 1.4 0 3.8.4 4.1 3.1v.2h3.1v-.3c-.3-3.5-3-5.6-7.2-5.6-4 0-6.6 2-6.6 5.1 0 3.6 3.4 4.4 5 4.8l3.4.8c1.3.3 2.7.7 2.7 2.4 0 2.4-2.5 2.8-3.9 2.8-2.9 0-4.4-1.2-4.7-3.8v-.2H107v.3c.2 4 3.1 6.3 7.8 6.3 4.3 0 7.1-2.2 7.1-5.6 0-2.7-1.6-4.3-5-5.1zm26.9-4.5c-1-2.1-3.3-3.3-6.5-3.3-4.2 0-6.8 2-7.2 5.6v.3h3.1v-.2c.2-2 1.6-3.1 4-3.1 2.6 0 4 1.3 4.1 3.8v3.2c0 2.8-1 6.1-5.1 6.1-2.2 0-3.4-1-3.4-2.9s1.3-2.9 3.9-2.9h2.8v-2.5h-3.1c-4.3 0-6.7 2-6.7 5.4 0 3.4 2.4 5.6 6.2 5.6 2.4 0 4.2-1 5.4-2.9V22h3.1V11c0-1.1 0-2.6-.6-3.8z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoAcasa'
}
</script>
