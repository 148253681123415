import gql from 'graphql-tag'

const saveCertsMutation = gql`
  mutation savePastJobsMutation($certs: [UserProfileCertsInputType], $id: Int) {
    updateCerts(certs: $certs, id: $id) {
      name
      startDate
      endDate
      certifiedBy
      certId
      certUrl
    }
  }
`

export default saveCertsMutation
