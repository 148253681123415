<template v-if="currentAssesment">
  <main class="main--wrapper">
    <Hero
      :hero-title="currentAssesment.name"
      section="pageHero"
      :hero-image="
        require(`@/assets/images/hero/bg-habilidades-${assessment}.svg`)
      "
      class="hero--md mb-8 lg:mb-0"
    />
    <div class="content--wrapper transform">
      <CardPage class="mx-auto lg:w-8/12">
        <template #default>
          <form>
            <!-- Head -->
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h2>
                    {{ $t('general.pregunta') }}
                    {{ questionCount }}/{{ currentAssesment.testLength }}
                  </h2>
                </template>
                <template #options v-if="false">
                  <div
                    class="
                      bg-main-dark
                      px-4
                      py-2
                      rounded-lg
                      text-white text-lg
                      font-bold
                      flex
                      items-baseline
                      align-baseline
                      transform
                      -translate-y-3
                    "
                  >
                    <img
                      src="@/assets/images/icn-clock.svg"
                      class="mr-2 transform translate-y-1"
                      alt=""
                    />10<span class="uppercase text-xs mx-1">min</span>30<span
                      class="uppercase text-xs ml-1"
                      >sec</span
                    >
                  </div>
                </template>
              </Title>
            </div>
            <!-- End Head -->
            <!-- Body -->
            <div class="card--page--body overflow-hidden relative">
              <p
                class="
                  text-lg
                  dark:text-primary-text
                  text-secondary-text
                  font-bold
                "
              >
                {{ currentQuestion.text }}
              </p>
              <template
                v-for="(respuesta, index) in currentQuestion.options"
                :key="index + 1"
              >
                <div
                  :class="
                    index === currentQuestion.options.length - 1
                      ? 'mb-6'
                      : 'mb-2'
                  "
                >
                  <label class="form--label" :for="index">
                    <input
                      type="radio"
                      :id="index"
                      name="group"
                      :value="index + 1"
                      class="mr-2"
                      v-model="currentAnswer"
                    />
                    {{ respuesta }}
                  </label>
                </div>
              </template>
              <div class="text-primary" v-if="error">
                {{ $t('form-validation.error-select') }}
              </div>
            </div>
            <img
              src="@/assets/images/habilidades/img-librerias-cody.svg"
              class="
                w-64
                mx-auto
                shadow-svg-xl
                lg:absolute
                pt-6
                right-0
                bottom-0
                transform
              "
              alt="Utopicode Cody"
            />
            <!-- End Body -->
            <!-- Footer -->
            <div class="card--page--footer">
              <button
                type="button"
                @click="saveAnswer"
                class="btn btn-lg btn-rounded-lg btn-primary mx-auto"
              >
                {{ $t('general.continuar') }}
              </button>
            </div>
            <!-- End Footer -->
          </form>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardPage from '@/components/CardPage'

import { mapGetters, mapActions } from 'vuex'
import ApolloClient from '../graphql/apolloClient'
import SaveAnswerMutation from '../graphql/mutations/saveAnswer.js'
import assesmentInfoQuery from '../graphql/queries/assesmentInfo'
import { toastNotification } from '@/services/toastNotification'

export default {
  data: function () {
    return {
      assessment: 'soft-skill',
      currentAnswer: null,
      error: false,
      currentAssesment: '',
      currentQuestion: '',
      questionCount: 1
    }
  },
  components: {
    Hero,
    Title,
    CardPage
  },
  created() {
    this.getAssesmentInfo()
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    getAssesmentInfo: async function () {
      try {
        const assesmentQuery = await ApolloClient.query({
          variables: {
            assesmentId: parseInt(this.$route.params.id)
          },
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          query: assesmentInfoQuery
        })
        this.setLoadingOff()
        this.currentAssesment = assesmentQuery.data.assesment
        this.currentQuestion = this.currentAssesment.firstQuestion
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    changeCurrentQuestion: function (question) {
      this.currentQuestion = question
      this.questionCount += 1
    },
    saveAnswer: async function () {
      try {
        if (this.currentAnswer === null) {
          this.error = true
          return
        } else {
          this.error = false
        }
        this.setLoadingOn()
        const nextQuestion = await ApolloClient.mutate({
          mutation: SaveAnswerMutation,
          variables: {
            questionId: this.currentQuestion.id,
            answer: this.currentAnswer,
            attemptId: parseInt(this.$route.params.attemptId)
          },
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        this.setLoadingOff()
        this.currentAnswer = null
        if (nextQuestion.data.saveQuestionAnswer) {
          this.changeCurrentQuestion(nextQuestion.data.saveQuestionAnswer)
        } else {
          await this.fetchUser()
          this.$router.push({
            name: 'ResultadoAssesments',
            params: { id: this.currentAssesment.id }
          })
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
