<template>
  <!--  Modal Newsletter -->
  <teleport to="#modals">
    <transition name="fade">
      <Modal size="xs" v-if="modalNewsletter" @click="modalNewsletter = false">
        <template #default>
          <div class="modal--header">
            <Title>
              <template #title>
                <h4 v-if="suscripto">
                  {{ $t('modal-newsletter.title-enviado') }}
                </h4>
                <h4 v-else>
                  {{ $t('modal-newsletter.title-no-enviado') }}
                </h4>
              </template>
            </Title>
          </div>
          <div class="modal--body">
            <div class="flex flex-wrap lg:flex-nowrap lg:space-x-6">
              <div class="w-full lg:w-7/12">
                <p class="text-primary-text last" v-if="suscripto">
                  {{ $t('modal-newsletter.description-enviado') }}
                </p>
                <p class="text-primary-text last" v-else>
                  {{ $t('modal-newsletter.description-no-enviado') }}
                </p>
              </div>
              <div class="w-full lg:w-5/12">
                <img
                  src="@/assets/images/img-cody-newsletter.svg"
                  alt="Newslletter Utopicode"
                />
              </div>
            </div>
          </div>
          <div class="modal--footer">
            <button
              type="submit"
              @click="modalNewsletter = false"
              class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
              role="button"
              aria-pressed="false"
            >
              {{ $t('general.cerrar') }}
            </button>
          </div>
        </template>
      </Modal>
    </transition>
  </teleport>
  <!-- End Modal Newsletter -->
  <div class="flex flex-col">
    <h4 class="text-white font-bold text-2xl mb-2">
      {{ $t('newsletter-footer.title') }}
    </h4>
    <p class="text-primary-text mb-4">
      {{ $t('newsletter-footer.description') }}
    </p>
    <Form @submit="validar">
      <div class="flex mb-0">
        <Field
          name="email"
          type="email"
          :rules="validateEmail"
          v-slot="{ field }"
        >
          <input
            v-bind="field"
            class="
              bg-white
              rounded-lg
              px-4
              py-3
              focus:outline-none
              outline-none
              placeholder-gray-600
              text-back
              placeholder-main
              transition-all
              duration-300
              mr-2
              w-full
            "
            type="email"
            :placeholder="$t('newsletter-footer.lbl-email')"
          />
        </Field>
        <button type="submit" class="btn btn-primary btn-lg rounded-lg">
          {{ $t('newsletter-footer.cta') }}
        </button>
      </div>
      <ErrorMessage name="email" class="form--error" />
    </Form>
  </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'
import Modal from '@/components/Modal'
import Title from '@/components/Title'
import apolloClient from '@/graphql/apolloClient'
import addToNewsletter from '@/graphql/mutations/addToNewsletter'
import { validateEmail } from '@/services/validationRules'

export default {
  name: 'Newsletter',
  components: {
    Form,
    Field,
    ErrorMessage,
    Modal,
    Title
  },
  data() {
    return {
      modalNewsletter: false,
      suscripto: false
    }
  },
  methods: {
    async validar({ email }) {
      if (email) {
        await apolloClient.mutate({
          mutation: addToNewsletter,
          variables: {
            email
          }
        })
        this.modalNewsletter = true
        this.suscripto = true
      } else {
        this.suscripto = false
      }
    },
    // RULES
    validateEmail
  }
}
</script>
