<template v-if="user">
  <main class="main--wrapper pt-24 lg:pt-40">
    <div class="content--wrapper">
      <!-- Card Page -->
      <CardPage>
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <!-- Title -->
          <div class="card--page--header">
            <Title class="mb-4">
              <template #title>
                <h3>{{ $t('badges-detail-page.title') }}</h3>
              </template>
              <template #info>
                <div class="tooltip">
                  <button
                    type="button"
                    role="button area"
                    aria-label="Info"
                    @mouseenter="tooltipShow = true"
                    @mouseleave="tooltipShow = false"
                  >
                    <img src="@/assets/images/icn-info.svg" class="w-5" />
                  </button>
                  <transition name="fade">
                    <div
                      class="tooltip--globe tooltip--sm"
                      v-show="tooltipShow == true"
                    >
                      <div class="title title--wrapper mb-6">
                        <h5>{{ $t('badges-detail-page.title-globe') }}</h5>
                        <span class="title--line"></span>
                      </div>
                      <div class="grid grid-cols-2 gap-8">
                        <p class="dark:text-primary-text text-secondary-text">
                          {{ $t('badges-detail-page.description-globe') }}
                        </p>
                        <img
                          src="@/assets/images/utopicode-t-shirt.jpg"
                          alt="Utopicode T-Shirt"
                        />
                      </div>
                      <p
                        class="
                          dark:text-primary-text
                          text-secondary-text text-sm
                          last
                        "
                      >
                        *{{ $t('badges-detail-page.description-stock') }}
                      </p>
                    </div>
                  </transition>
                </div>
              </template>
            </Title>
          </div>
          <!-- End Title -->
          <!-- Body -->
          <div class="card--page--body">
            <div
              class="
                grid grid-cols-1
                sm:grid-cols-2
                lg:grid-cols-4
                gap-x-8 gap-y-10
              "
            >
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-soft-skill.svg"
                  :title="$t('habilidades-page.title-soft-skill')"
                  :description="
                    $t('habilidades-page.descripcion-corta-soft-skill')
                  "
                  :locked="softSkill ? false : true"
                />
                <router-link
                  v-if="softSkill"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{ name: 'Habilidades', hash: '#SoftSkills' }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-comportamiento-emocional.svg"
                  :title="$t('habilidades-page.title-comportamiento-emocional')"
                  :description="
                    $t(
                      'habilidades-page.descripcion-corta-comportamiento-emocional'
                    )
                  "
                  :locked="comportamientoEmocional ? false : true"
                />
                <router-link
                  v-if="comportamientoEmocional"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'Habilidades',
                    hash: '#ComportamientoEmocional'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-fit-cultural.svg"
                  :title="$t('habilidades-page.title-fit-cultural')"
                  :description="
                    $t('habilidades-page.descripcion-corta-fit-cultural')
                  "
                  :locked="fitCultural ? false : true"
                />
                <router-link
                  v-if="fitCultural"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'Habilidades',
                    hash: '#FitCultural'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-hard-skill.svg"
                  :title="$t('habilidades-page.title-hard-skill')"
                  :description="
                    $t('habilidades-page.descripcion-corta-hard-skill')
                  "
                  :locked="hardSkill ? false : true"
                />
                <router-link
                  v-if="hardSkill"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'Habilidades',
                    hash: '#HardSkills'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-tech-talk.svg"
                  :title="$t('habilidades-page.title-tech-talk')"
                  :description="
                    $t('habilidades-page.descripcion-corta-tech-talk')
                  "
                  :locked="user && !user.registeredEvents"
                />
                <router-link
                  v-if="user && !user.registeredEvents"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'Eventos'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-experiencia.svg"
                  :title="$t('habilidades-page.title-experiencia')"
                  :description="
                    $t('habilidades-page.descripcion-corta-experiencia')
                  "
                  :locked="user && user.pastJobs !== null ? false : true"
                />
                <router-link
                  v-if="user && user.pastJobs === null"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'EditExperienciaProfesional'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-aprendizaje.svg"
                  :title="$t('habilidades-page.title-aprendizaje')"
                  :description="
                    $t('habilidades-page.descripcion-corta-aprendizaje')
                  "
                  :locked="user && user.certsAndCourses !== null ? false : true"
                />
                <router-link
                  v-if="user && user.certsAndCourses === null"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'EditCertificacionesCursos'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
              <div class="flex flex-col">
                <CardSkill
                  class="no-card mb-4 flex-grow"
                  image="icn-tecnologias.svg"
                  :title="$t('habilidades-page.title-tecnologias')"
                  :description="
                    $t('habilidades-page.descripcion-corta-tecnologias')
                  "
                  :locked="user && user.technologies.length > 0 ? false : true"
                />
                <router-link
                  v-if="user && user.technologies.length <= 0"
                  class="btn btn-secondary btn-rounded-lg btn-md mr-auto"
                  :to="{
                    name: 'EditTecnologias'
                  }"
                  >{{ $t('general.obtener-badge') }}</router-link
                >
              </div>
            </div>
          </div>
          <!-- End Body -->
        </template>
      </CardPage>
      <!-- End Card Page -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import CardSkill from '@/components/CardSkill'
import userAssesmentsQuery from '@/graphql/queries/userAssesments'
import ApolloClient from '@/graphql/apolloClient'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'BadgeDetailPage',
  components: {
    CardPage,
    Title,
    CardSkill
  },
  data() {
    return {
      user: null,
      tooltipShow: false,
      softSkill: null,
      hardSkill: null,
      comportamientoEmocional: null,
      fitCultural: null
    }
  },
  mounted() {
    this.setLoadingOn()
    this.getUserAssesments()
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    getUserAssesments: async function () {
      try {
        const assesmentData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userAssesmentsQuery
        })
        this.user = assesmentData.data.userProfile
        this.setTakenAssesments()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    setTakenAssesments: function () {
      this.softSkill = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-soft-skill.svg'
      )
      this.hardSkill = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-hard-skill.svg'
      )
      this.comportamientoEmocional = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-hard-skill.svg'
      )
      this.fitCultural = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-fit-cultural.svg'
      )
    }
  }
}
</script>
