<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <CardPage class="w-full lg:w-7/12 xl:w-7/12 mx-auto">
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <Form @submit="updateProfileAvatar">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>{{ $t('edit-profile-avatar-page.title') }}</h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('edit-profile-avatar-page.description') }}
              </p>
              <div class="grid grid-cols-3 lg:grid-cols-5 gap-6">
                <Field
                  v-for="(avatarItem, index) in 15"
                  :key="avatarItem"
                  v-slot="{ field }"
                  name="avatar"
                  class="form--avatar"
                  as="div"
                  :rules="validateDefault"
                >
                  <input
                    type="radio"
                    name="avatar"
                    v-bind="field"
                    :value="index + 1"
                    v-model="avatar"
                    :id="`avatar-${index + 1}`"
                  />
                  <label :for="`avatar-${index + 1}`">
                    <img
                      class="rounded-full"
                      :src="
                        require(`@/assets/images/avatars/avatar-${
                          index + 1
                        }.svg`)
                      "
                      alt="Cody Utopicode"
                    />
                  </label>
                </Field>
              </div>
              <ErrorMessage name="avatar" class="form--error" />
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="
                  btn btn-outline btn-outline-primary btn-rounded-lg btn-lg
                "
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.volver') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </Form>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { Field, Form, ErrorMessage } from 'vee-validate'

import ApolloClient from '@/graphql/apolloClient'
import updateAvatarMutation from '@/graphql/mutations/updateAvatar'
import { mapActions, mapGetters } from 'vuex'
import { validateDefault } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'EditProfileImage',
  components: {
    Title,
    CardPage,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      avatar: 1,
      error: null
    }
  },
  computed: {
    ...mapGetters(['token', 'user'])
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    updateProfileAvatar: async function () {
      try {
        this.setLoadingOn()
        const updateAvatar = await ApolloClient.mutate({
          mutation: updateAvatarMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            avatar: this.avatar.toString()
          }
        })
        if (!updateAvatar.errors) {
          this.setLoadingOff()
          await this.fetchUser()
          this.$router.push({ name: 'Profile' })
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        this.error = e
        toastNotification(e, 'error')
      }
    },
    // RULES
    validateDefault
  }
}
</script>
