<template>
  <footer class="footer--wrapper">
    <section class="max-w-screen-xl mx-auto px-4">
      <!-- GO TO TOP -->
      <button
        type="button"
        @click="scrollToTop"
        class="footer--top focus:outline-none"
        :title="$t('footer.ir-arriba')"
      >
        <svg width="21" height="13" class="fill-current">
          <path
            d="M11.2734 0.722656C10.8516 0.300781 10.1484 0.300781 9.72656 0.722656L0.585938 9.81641C0.164062 10.2852 0.164062 10.9883 0.585938 11.4102L1.66406 12.4883C2.08594 12.9102 2.78906 12.9102 3.25781 12.4883L10.5234 5.26953L17.7422 12.4883C18.2109 12.9102 18.9141 12.9102 19.3359 12.4883L20.4141 11.4102C20.8359 10.9883 20.8359 10.2852 20.4141 9.81641L11.2734 0.722656Z"
          />
        </svg>
      </button>
      <!-- ENG GO TO TOP -->
      <!-- Discord Section -->
      <DiscordSection />
      <!-- end Discord Section -->
      <hr class="mb-8 lg:mb-16 line-line" />
      <!-- Newsletter and Social Networks -->
      <section class="flex flex-wrap justify-between">
        <div class="w-full lg:w-5/12 mb-10 lg:mb-24">
          <NewsletterFooter />
        </div>
        <div class="w-full lg:w-6/12 mb-10 lg:mb-24">
          <SocialNetworks />
        </div>
      </section>
      <!-- End Newsletter and Social Networks -->
    </section>
    <div class="footer--container">
      <div class="footer--column">
        <router-link class="footer--logo" :to="{ name: 'Home' }">
          <img src="@/assets/images/new_logo_white.svg" alt="Utopicode" />
        </router-link>
        <p class="footer--slogan">{{ $t('footer.slogan') }}</p>
        <p class="footer--copyright">{{ $t('footer.copyright') }}</p>
      </div>
      <nav class="footer--column">
        <ul class="footer--nav">
          <li>
            <router-link :to="{ name: 'Comunidad' }">{{
              $t('header.comunidad')
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Eventos' }">{{
              $t('header.eventos')
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Trabajos Tech' }">{{
              $t('header.trabajos-tech')
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Contrata' }">{{
              $t('footer.contrata-dev')
            }}</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Nosotros' }">{{
              $t('footer.sobre-nosotros')
            }}</router-link>
          </li>
          <li>
            <a href="https://blog.utopicode.io" target="_blank">Blog</a>
          </li>
        </ul>
      </nav>
      <nav class="footer--column">
        <ul class="footer--nav">
          <li>
            <h4>{{ $t('footer.informacion') }}</h4>
          </li>
          <li>
            <router-link :to="{ name: 'Faq' }" title="$t('footer.faq')"
              >FAQ</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Terminos' }"
              title="$t('footer.terminos-condiciones')"
              >{{ $t('footer.terminos-condiciones') }}</router-link
            >
          </li>
          <li>
            <router-link
              :to="{ name: 'Politica' }"
              title="{{ $t('footer.politica-privacidad') }}"
              >{{ $t('footer.politica-privacidad') }}</router-link
            >
          </li>
        </ul>
      </nav>
      <div class="footer--column">
        <ul class="footer--nav">
          <li>
            <h4>{{ $t('footer.sugerencias') }}</h4>
          </li>
          <li>
            <p>
              {{ $t('footer.sugerencias-texto-uno') }}
              <button
                type="button"
                @click="$emit('evento')"
                class="underline focus:outline-none"
                title="$t('footer.click-aqui')"
              >
                {{ $t('footer.click-aqui') }}</button
              >.<br />
              {{ $t('footer.sugerencias-texto-dos') }}
              <a
                href="mailto:contact@utopicode.com"
                class="underline"
                title="Utopicode Email"
                >contact@utopicode.com</a
              >
            </p>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import NewsletterFooter from '@/components/NewsletterFooter'
import DiscordSection from '@/components/DiscordSection'
import SocialNetworks from '@/components/SocialNetworks'

export default {
  name: 'Footer',
  components: {
    NewsletterFooter,
    DiscordSection,
    SocialNetworks
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
}
</script>
