import gql from 'graphql-tag'

const eventsQuery = gql`
  query eventsQUery($limit: Int) {
    events(limit: $limit) {
      id
      date
      name
      place
      eventLang
      photo
      category
      subCategories
    }
    pastEvents(limit: $limit) {
      id
      date
      name
      place
      eventLang
      photo
      category
      videoLink
      subCategories
    }
  }
`

export default eventsQuery
