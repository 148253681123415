import gql from 'graphql-tag'

const userAssesmentsQuery = gql`
  query userAssesmentsQuery {
    user {
      fullname
    }
    userProfile {
      technologies {
        techName
        level
        expYears
      }
      takenAssesments {
        id
        assesment {
          id
          name
          testLength
          photo
        }
        totalScore
        subAssesmentsResult {
          id
          assesment {
            id
            name
            testLength
          }
          totalScore
          description
        }
        keyPercentil
      }
      certsAndCourses {
        name
        startDate
        endDate
        certifiedBy
        certId
        certUrl
      }
      registeredEvents
    }
  }
`

export default userAssesmentsQuery
