<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 333 98"
    class="fill-current"
  >
    <path d="M37.6 60.5H19.3v18.3h18.3v18.3h18.3V42.2H37.6v18.3z" />
    <path
      d="M19.3 42.2h18.3V23.9H19.3V5.6H1v54.9h18.3V42.2zm101.3 10c0-8.3-5.5-13.7-12.6-13.7-6.9 0-12.7 5.1-12.7 13.7 0 7.4 5 13.7 12.6 13.7 7 .1 12.7-5.4 12.7-13.7zm-25 21.3v5.7H81V6h14.6v25.3h1.1c2.5-3.1 6.9-6.7 15-6.7 13.6 0 23.8 12.1 23.8 27.5 0 15.7-11 27.6-24.5 27.6-7.5 0-11.7-2.9-14.2-6.2h-1.2zM158.5 25v8.4h.9c2.3-4.5 7.5-8.4 13.5-8.4h4.2v14.4h-5.4c-8 0-13.2 5-13.2 14.7v25H144V25h14.5zm33.2-16.1c0-4.4 3.5-7.9 8.2-7.9 4.6 0 8.1 3.5 8.1 7.9 0 4.5-3.5 8-8.1 8-4.7-.1-8.2-3.5-8.2-8zm-7 29.1V24h23.2v54.2h-14.1V38h-9.1zM257 51.5c0-7.4-5-13.6-12.5-13.6-7.1 0-12.7 5.5-12.7 13.6 0 8.3 5.5 13.8 12.5 13.8S257 60.2 257 51.5zm-.2 21.9-1.1-1c-2.5 3.2-6.8 6.7-15 6.7C227.2 79 217 67 217 51.6c0-15.7 11.1-27.6 24.6-27.6 7.5 0 11.4 2.9 14.2 6.2h1.1V24h14.4v73.2h-14.5V72.4v1zm25.2-33c0-1.3 1-2.4 2.5-2.4s2.5 1.1 2.5 2.4-1 2.4-2.5 2.4c-1.5.1-2.5-1-2.5-2.4m9-14h4.5v1.5h.3c.6-.9 1.8-1.9 3.7-1.9 2.1 0 3.5 1 4.3 2.3h.3c.8-1.3 2.3-2.3 4.5-2.3 3.4 0 5.8 2.5 5.8 5.6v10.9h-4.5v-9.3c0-1.9-1-2.8-2.4-2.8s-2.5 1-2.5 2.8v9.3h-4.5v-9.7c0-1.5-1.1-2.4-2.5-2.4s-2.5 1.1-2.5 2.8v9.4H291V26.4m30.2 7.2-5-7.6h5.2l2.6 4.6h.4L327 26h5.2l-4.8 7.9 5 8.6h-5.3l-2.8-5.6h-.3l-2.8 5.6H316"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoBriq'
}
</script>
