<template>
  <blockquote class="blockquote--wrapper">
    <p class="blockquote--cite">{{ cite }}</p>
    <div class="blockquote--quote">
      <svg
        width="128"
        height="112"
        viewBox="0 0 128 112"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M116 56H96V40C96 31.25 103 24 112 24H114C117.25 24 120 21.5 120 18V6C120 2.75 117.25 0 114 0H112C89.75 0 72 18 72 40V100C72 106.75 77.25 112 84 112H116C122.5 112 128 106.75 128 100V68C128 61.5 122.5 56 116 56ZM44 56H24V40C24 31.25 31 24 40 24H42C45.25 24 48 21.5 48 18V6C48 2.75 45.25 0 42 0H40C17.75 0 0 18 0 40V100C0 106.75 5.25 112 12 112H44C50.5 112 56 106.75 56 100V68C56 61.5 50.5 56 44 56Z"
        />
      </svg>
    </div>
    <footer class="blockquote--footer">
      <div class="blockquote--meta">
        <img
          v-if="authorImage"
          :src="require(`@/assets/images/${authorImage}`)"
          :alt="author"
          class="blockquote--author--image"
        />
        <div class="blockquote--data">
          <div class="blockquote--author">{{ author }}</div>
          <div class="blockquote--position" v-if="position">{{ position }}</div>
        </div>
      </div>
      <slot></slot>
    </footer>
  </blockquote>
</template>

<script>
export default {
  name: 'Blockquote',
  props: {
    cite: String,
    author: String,
    position: String,
    authorImage: String
  }
}
</script>
