<template>
  <main class="main--wrapper overflow-hidden">
    <Hero
      :hero-title="$t('habilidades-page.title')"
      :hero-description="$t('habilidades-page.description')"
      section="pageHero"
      :hero-image="require('@/assets/images/hero/bg-habilidades.svg')"
      class="hero--md"
    />
    <div class="content--wrapper">
      <section class="relative">
        <div
          class="
            absolute
            top-3/4
            lg:top-2/4
            left-2/4
            lg:w-5/12
            transform
            -translate-x-2/4
            lg:-translate-x-1/4 lg:-translate-y-2/4
          "
        >
          <img
            src="@/assets/images/shape-03.svg"
            class="w-auto ml-auto lg:h-full h-64"
            alt="Utopicode"
          />
        </div>
        <div class="flex flex-wrap mx-auto lg:w-10/12 items-center mb-32">
          <CardPage class="p-8 w-full lg:w-6/12">
            <Title class="mb-6">
              <template #title>
                <h3>{{ $t('habilidades-page.title-librerias-test') }}</h3>
              </template>
            </Title>
            <div
              class="dark:text-primary-text text-secondary-text"
              v-html="$t('habilidades-page.descripcion-librerias-test')"
            ></div>
          </CardPage>
          <div class="w-full lg:w-6/12 relative">
            <img
              src="@/assets/images/habilidades/img-librerias-cody.svg"
              class="w-8/12 mx-auto shadow-svg-xl mb-6"
              alt="Utopicode Cody"
            />
            <img
              src="@/assets/images/img-tests.svg"
              class="
                w-32
                lg:w-48
                mx-auto
                shadow-svg-xl
                lg:absolute
                lg:left-0
                lg:top-3/4
                lg:transform
                lg:-translate-x-1/4
              "
              alt="Utopicode Cody"
            />
          </div>
        </div>
      </section>
      <!-- Soft Skill -->
      <div class="relative">
        <div
          class="
            absolute
            bottom-0
            right-0
            w-6/12
            transform
            translate-x-2/3 translate-y-1/3
          "
        >
          <img
            src="@/assets/images/shape-02.svg"
            class="w-auto ml-auto lg:h-full"
            alt="Utopicode"
          />
        </div>

        <section
          class="mb-8 lg:mb-16 relative z-5"
          v-for="assesCat in assesmentCategories"
          v-bind:key="assesCat.id"
        >
          <Title class="mb-6">
            <template #title>
              <h3>
                {{ assesCat.name }}
              </h3>
            </template>
          </Title>
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div
              v-for="assesmentCard in assesCat.assesments"
              :key="assesmentCard.id"
              :id="assesmentCard.name.replace(/ /g, '')"
            >
              <CardSkill
                class="h-full"
                :link="
                  user && checkAssesment(assesmentCard)
                    ? {
                        name: 'ResultadoAssesments',
                        params: { id: assesmentCard.id }
                      }
                    : {
                        name: 'InstruccionesAssesments',
                        params: { id: assesmentCard.id }
                      }
                "
                :image="assesmentCard.photo"
                :title="assesmentCard.name"
                :description="
                  user && checkAssesment(assesmentCard)
                    ? $t('general.text-volver-a-tomarlo')
                    : assesmentCard.instructions[0]
                "
                footer
                :locked="user && checkAssesment(assesmentCard) ? false : true"
                :cta="
                  user && checkAssesment(assesmentCard)
                    ? $t('general.ver-resultados')
                    : $t('general.realizar-test')
                "
              />
            </div>
          </div>
        </section>
      </div>
      <!-- End Soft Skill -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardPage from '@/components/CardPage'
import CardSkill from '@/components/CardSkill'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userAssesmentsQuery from '@/graphql/queries/userAssesments'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'HabilidadesPage',
  data: function () {
    return {
      user: null,
      assesmentCategories: []
    }
  },
  setup() {
    return {}
  },
  components: {
    Hero,
    Title,
    CardPage,
    CardSkill
  },
  mounted: function () {
    this.getAssesmentCategories()
    // this.getUserAssesments()
  },
  updated() {
    this.getAssesmentCategories()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
        this.setLoadingOff()
        if (this.user) {
          this.getUserAssesments()
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserAssesments: async function () {
      try {
        const userAssesmentsData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userAssesmentsQuery
        })
        this.user = userAssesmentsData.data.userProfile
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    checkAssesment(assesment) {
      return this.user.takenAssesments.some((el) => {
        return el.assesment.id === assesment.id
      })
    }
  },
  computed: {
    ...mapGetters(['token', 'isAuthenticated'])
  }
}
</script>
