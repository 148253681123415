import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import createPlugin from 'logrocket-vuex'
import LogRocket from 'logrocket'
import { getUser } from '../services/authentication'
import router from '../router/index'
import apolloClient from '@/graphql/apolloClient'
import firebase, { analytics, firebaseInstance } from '@/services/firebase'
import createUserMutation from '@/graphql/mutations/signUp'
import { toastNotification } from '@/services/toastNotification'

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init('acp4rz/utopicode')
}
const logrocketPlugin = createPlugin(LogRocket)

export default new Vuex.Store({
  state: {
    isLoggedIn: null,
    user: null,
    lang: 'es',
    loading: false,
    themeColor: 'dark'
  },
  mutations: {
    setLang(state, vuei18n) {
      const toggled = state.lang === 'es' ? 'en' : 'es'
      vuei18n.locale = toggled
      state.lang = toggled
    },
    setLoggedIn(state, user) {
      console.log('user', user)
      state.isLoggedIn = user !== null
      if (user) {
        state.user = user
      } else {
        state.user = null
      }
      console.log('state', state)
    },
    setLoadingOn(state) {
      state.loading = true
    },
    setLoadingOff(state) {
      state.loading = false
    },
    setUser(state, user) {
      if (user === null) {
        state.user = null
        return
      }
      console.log('user', user)
      state.user = {
        ...user.user,
        ...user.userData,
        token: user.token
      }
      console.log('state.user', state.user)
    },
    setUserSocialNetworks(state, socialNetworks) {
      state.user.socialNetworks = socialNetworks
    },
    setUserAbout(state, about) {
      state.user.about = about
    },
    clearUser(state) {
      state.user = null
    },
    setThemeColor(state, theme) {
      state.themeColor = theme
    }
  },
  actions: {
    toggleLang({ commit }, vuei18n) {
      commit('setLang', vuei18n)
    },
    setLoadingOn({ commit }) {
      commit('setLoadingOn')
    },
    setLoadingOff({ commit }) {
      commit('setLoadingOff')
    },
    async socialLogin({ dispatch }, type) {
      console.log('started social login')
      const provider = (type) => {
        console.log('provider type', type)
        if (type === 'github')
          return new firebaseInstance.auth.GithubAuthProvider()
        if (type === 'facebook')
          return new firebaseInstance.auth.FacebookAuthProvider()
        if (type === 'gmail')
          return new firebaseInstance.auth.GoogleAuthProvider()
      }
      try {
        console.log('setting loading on')
        dispatch('setLoadingOn')
        console.log('signing in with popup')
        const result = await firebase.signInWithPopup(provider(type))
        console.log('result', result)
        if (result.additionalUserInfo.isNewUser) {
          console.log('creating user')
          await apolloClient.mutate({
            mutation: createUserMutation,
            variables: {
              fullname: result.user.displayName,
              email: result.user.email
            }
          })
          console.log('fetching user')
          dispatch('fetchUser', router)
          console.log('setting loading off')
          dispatch('setLoadingOff')
          toastNotification(
            this.$t('general.logueado-correctamente'),
            'success'
          )
        } else {
          console.log('setting loading off')
          dispatch('setLoadingOff')
        }
      } catch (e) {
        console.log('error', e)
        toastNotification(e, 'error')
        console.log('setting loading off')
        dispatch('setLoadingOff')
      }
    },
    async fetchUser({ commit, getters }, router) {
      const token = await getters.token
      if (token) {
        const userData = await getUser(token)
        commit('setLoggedIn', {
          ...userData.user,
          ...userData.userProfile
        })
        if (router) {
          const currentRoute = router.currentRoute._value.name
          if (currentRoute === 'SignIn' || currentRoute === 'SignUp') {
            if (router.currentRoute._value.query.redirectTo) {
              router.push({ path: router.currentRoute._value.query.redirectTo })
            } else {
              if (userData.user.role === 'ADMIN') {
                router.push({ path: '/admin' })
              } else if (router.currentRoute._value.params.eventLang) {
                router.push({
                  name: 'EventoDetail',
                  params: router.currentRoute._value.params
                })
              } else if (router.currentRoute._value.params.summit) {
                router.push({
                  name: 'LandingSummit'
                })
              } else {
                router.push({ name: 'Profile' })
              }
            }
          }
        }
      } else {
        commit('setLoggedIn', null)
      }
    },
    async signOut() {
      await router.push({ name: 'Home' })
      await this.commit('setUser', null)
      await firebase.signOut()
    },
    setThemeColor({ commit }, theme) {
      analytics.logEvent('ThemeChange!', { theme })
      commit('setThemeColor', theme)
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    isAuthenticated(state) {
      return state.isLoggedIn
    },
    lang(state) {
      return state.lang
    },
    loading(state) {
      return state.loading
    },
    async token(state) {
      if (firebase.currentUser) {
        return await firebase.currentUser.getIdToken(true)
      } else {
        return null
      }
    },
    themeColor(state) {
      return state.themeColor
    }
  },
  plugins: [createPersistedState(), logrocketPlugin]
})
