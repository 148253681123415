<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 181 41.5"
    class="fill-current"
  >
    <path
      d="M56.1 33.9v-9.2c-.1-1.6.4-3.1 1.3-4.4.8-1 2-1.5 3.3-1.5 1.2-.2 2.4.4 3 1.4.6 1.4.8 2.9.8 4.3v9.3h3.4v-9.7c0-3.6 1.5-5.4 4.5-5.4.7 0 1.4.1 2 .4.5.3.9.7 1.2 1.2.2.5.4 1.1.5 1.6.1.7.2 1.3.1 2v9.9h3.4V23.5c0-5.3-2.3-7.9-6.8-7.9-1.1 0-2.3.3-3.2.9-1 .6-1.9 1.4-2.4 2.4-1-2.1-3.2-3.4-5.6-3.3-1.3 0-2.6.4-3.8 1.1-.9.5-1.5 1.2-2 2.1h-.1V16h-3.2v17.8l3.6.1zm33.3-17.8v-5H86v5h-3.9V19H86v11.1c.1.5.2 1.1.3 1.6.1.5.4 1 .8 1.4.5.4 1 .7 1.6.9.8.3 1.7.4 2.6.4 1 .1 2.1-.1 3-.6v-3.1c-.7.4-1.6.6-2.4.5-.5 0-1-.1-1.5-.3-.4-.2-.7-.6-.8-1l-.3-1.2c0-.5-.1-1-.1-1.5V19H94v-2.9h-4.6zM102 33.9V25c0-3.8 1.7-5.7 5.1-5.7.6 0 1.1.1 1.7.3l.2-3.6c-.5-.2-1-.2-1.5-.2-1.1 0-2.2.3-3.2.9-.9.5-1.7 1.3-2.1 2.3h-.2v-2.7h-3.4v17.8h3.4v-.2zm14-14.6c.5-.3.9-.5 1.4-.6.7-.3 1.5-.4 2.3-.4 1.2-.1 2.3.3 3.1 1.1.7.7 1.1 1.6 1.1 2.6v.8h-2.5c-2.5-.1-4.9.4-7.1 1.5-1.7.9-2.8 2.7-2.7 4.7-.1 1.5.6 3 1.8 3.9 1.2 1 2.8 1.5 4.4 1.4 2.5.1 4.9-1.1 6.2-3.2h.1c0 .9 0 1.8.1 2.7h3c-.1-1.1-.2-2.2-.2-3.2v-9.1c0-1.6-.7-3.2-1.9-4.2-1.2-1.1-3.1-1.7-5.6-1.7-2.6-.1-5.2.9-7.1 2.6l2 2.1c.9-.4 1.3-.8 1.6-1zm8 7.1c0 1.3-.4 2.6-1.2 3.6-1 1.1-2.4 1.7-3.8 1.5-2.6 0-3.8-1-3.8-2.9 0-2.3 2.4-3.4 7.1-3.4h1.8l-.1 1.2zm22.5-20.9v13.2h-.1c-.6-1-1.5-1.7-2.6-2.2-1.1-.5-2.4-.8-3.6-.8-2.5-.1-4.9.9-6.6 2.6-3.3 3.8-3.3 9.6 0 13.4 1.7 1.8 4.1 2.8 6.6 2.7 1.2 0 2.4-.3 3.5-.8s2-1.2 2.7-2.2h.1V34h3.4V5.5h-3.4zM134.8 25c-.1-1.6.5-3.2 1.6-4.5 2.4-2.3 6.2-2.3 8.5 0 2.1 2.6 2.1 6.3 0 8.9-2.4 2.3-6.2 2.3-8.5 0-1.1-1.2-1.7-2.8-1.6-4.4zm37 0c.1-2.4-.7-4.7-2.2-6.6-1.4-1.8-3.6-2.8-6.5-2.8-2.5-.1-4.9.9-6.6 2.7-1.7 1.8-2.7 4.2-2.6 6.7-.1 2.5.8 4.9 2.5 6.7 1.7 1.8 4.2 2.8 6.7 2.6 3.1.1 6-1.2 7.9-3.6l-2.6-2c-1.1 1.6-2.9 2.6-4.9 2.7-1.5 0-3-.5-4.2-1.5-1.1-.9-1.8-2.3-1.8-3.7h14.3V25zm-12.7-5c1-1.1 2.5-1.7 3.9-1.6 1.4-.1 2.8.4 3.8 1.4.9 1 1.4 2.4 1.4 3.8h-10.7c.1-1.4.7-2.7 1.6-3.6zM181 31.6c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5 1.1 2.5 2.5 2.5c.7 0 1.3-.3 1.8-.7.5-.5.7-1.1.7-1.8zM18.3.3l17.9 36.1-18 4.7L.1 36.5 18.3.3z"
    />
    <path d="m27.6 5.1 17.5 31.3-17.6 5.1-16.4-3.8L27.6 5.1z" />
    <path d="M18.1 0 0 36.3l18.2 4.9L18.1 0m9.4.1 17.8 36.2-17.8 4.9V.1" />
    <path d="m27.5 32.9-9.4-2 .2-17.3L27.5 9v23.9z" />
    <path
      d="M27.5 41.2 18.2 39v-9.9l9.4-3.9v16zm-9.3-19.4 9.4-4.6V.1l-9.4 16.8v4.9z"
    />
    <path d="M18.2.2v17.1L23 8.4 18.2.2z" />
  </svg>
</template>

<script>
export default {
  name: 'LogoMtrade'
}
</script>
