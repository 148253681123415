<template>
  <div class="progress">
    <div class="progress--meta">
      <h5 v-if="title">{{ title }}</h5>
      <h6 v-if="level">
        {{ $t(`modal-tecnologias.opcion-nivel-experiencia-${level}`) }}
      </h6>
    </div>
    <div class="progress--bar">
      <div class="progress--data" :style="`width: ${porcentaje}%`">
        {{ porcentaje }}%
      </div>
      <div class="progress--progress" :style="`width: ${porcentaje}%`"></div>
    </div>
    <h6
      class="dark:text-primary-text text-secondary-text text-xs mt-1"
      v-if="years"
    >
      {{ years }} {{ $t('general.años') }}
    </h6>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    porcentaje: Number,
    title: String,
    years: String,
    level: String
  }
}
</script>
