import i18n from '@/plugins/i18n'
const { t } = i18n.global

// RULES

// Default Validation
const validateDefault = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  return true
}

// Number Validation
const validateNumber = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  if (!/\[0-9]/g.test(value)) {
    return t('form-validation.error-number')
  }
  return true
}

// Url Validation
const validateUrl = (value) => {
  /* if (!value) {
    return t('form-validation.error-default')
  } */
  if (value === '' || value === null || value === undefined) {
    return true
  } else {
    if (!/^(ftp|http|https):\/\/[^ "]+$/i.test(value)) {
      return t('form-validation.error-url')
    }
  }
  return true
}

// Url Validation Complete
const validateUrlComplete = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  if (value === '' || value === null || value === undefined) {
    return true
  } else {
    if (!/^(ftp|http|https):\/\/[^ "]+$/i.test(value)) {
      return t('form-validation.error-url')
    }
  }
  return true
}

const validateUrlNoRegex = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  /*
      if (!/^[^ "]+$/i.test(value)) {
        return t('form-validation.error-url')
      } */
  return true
}

// Email Validation
const validateEmail = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return t('form-validation.error-email-valid')
  }
  return true
}

// Phone Number Validation
const validatePhone = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  if (!/^\+?[0-9]{3}-?[0-9]{6,12}$/i.test(value)) {
    return t('form-validation.error-phone')
  }
  return true
}

// Phone Code validation
const validateDefaultPhone = (value) => {
  if (!value) {
    return t('form-validation.error-select')
  }
  return true
}

// Password validation
const validatePassword = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  return true
}

// Password Validation Complex
const validatePasswordComplex = (value) => {
  if (!value) {
    return t('form-validation.error-default')
  }
  if (!/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i.test(value)) {
    return t('form-validation.error-password-valid')
  }
  return true
}

export {
  validateDefault,
  validateNumber,
  validateUrl,
  validateUrlNoRegex,
  validateEmail,
  validatePhone,
  validateDefaultPhone,
  validatePassword,
  validatePasswordComplex,
  validateUrlComplete
}
