<template>
  <main class="main--wrapper py-24 lg:py-32">
    <div class="content--wrapper">
      <!-- Sección Eventos Registrados -->
      <section class="content--comunidad mb-10 lg:mb-20">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('profile-events.title') }}</h2>
          </template>
        </Title>
        <Tabs class="tabs-horizontal" :tabList="tabList">
          <template v-slot:tabPanel-1>
            <splide
              v-if="userEvents.length > 0"
              class="splide--default mb-10 lg:mb-32"
              :options="options"
            >
              <splide-slide
                class="w-full max-w-xs pr-3 mr-3"
                v-for="(conference, index) in userEvents"
                :key="index"
              >
                <CardComunidad class="h-full" :conferenceData="conference" />
              </splide-slide>
            </splide>
            <div v-else lass="w-16 lg:w-5/12 mb-12 lg:mb-0">
              <h5 class="text-white font-bold text-lg text-center mb-6 mt-24">
                {{ $t('profile-events.no-registered-events') }}
              </h5>
              <p class="flex justify-center">
                <router-link
                  :to="{ name: 'Eventos' }"
                  class="btn btn-rounded-lg btn-lg btn-secondary text-center"
                  >{{ $t('profile-events.no-events-button') }}</router-link
                >
              </p>
            </div>
          </template>
          <template v-slot:tabPanel-2>
            <splide
              v-if="pastUserEvents.length > 0"
              class="splide--default mb-10 lg:mb-32"
              :options="options"
            >
              <splide-slide
                class="w-full max-w-xs pr-3 mr-3"
                v-for="(conference, index) in pastUserEvents"
                :key="index"
              >
                <CardComunidad class="h-full" :conferenceData="conference" />
              </splide-slide>
            </splide>
            <div v-else lass="w-16 lg:w-5/12 mb-12 lg:mb-0">
              <h5 class="text-white font-bold text-lg text-center mt-24">
                {{ $t('profile-events.no-past-registered-events') }}
              </h5>
              <p class="flex justify-center">
                <router-link
                  :to="{ name: 'Eventos' }"
                  class="btn btn-rounded-lg btn-lg btn-secondary text-center"
                  >{{ $t('profile-events.no-events-button') }}</router-link
                >
              </p>
            </div>
          </template>
          <template v-slot:tabPanel-3>
            <splide
              v-if="recommendedEvents.length > 0"
              class="splide--default mb-10 lg:mb-32"
              :options="options"
            >
              <splide-slide
                class="w-full max-w-xs pr-3 mr-3"
                v-for="(conference, index) in recommendedEvents"
                :key="index"
              >
                <CardComunidad class="h-full" :conferenceData="conference" />
              </splide-slide>
            </splide>
            <h5 v-else class="text-white font-bold text-lg text-center mt-24">
              {{ $t('profile-events.no-recommended-registered-events') }}
            </h5>
          </template>
        </Tabs>
      </section>
      <!-- End Sección Eventos Registrados -->
    </div>
  </main>
</template>
<script>
import Title from '@/components/Title'
import CardComunidad from '@/components/CardComunidad'
import { Splide, SplideSlide } from '@splidejs/vue-splide'

import EventsQuery from '../graphql/queries/events'
import userEventsQuery from '../graphql/queries/userEvents'
import ApolloClient from '@/graphql/apolloClient'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import Tabs from '@/components/Tabs.vue'

export default {
  name: 'ProfileEvents',
  data() {
    return {
      userEvents: [],
      pastUserEvents: [],
      recommendedEvents: [],
      tabList: [
        'Eventos Registrados',
        'Eventos Registrados Pasados',
        'Recomendados'
      ],
      options: {
        rewind: false,
        perPage: 4,
        pagination: true,
        autoplay: true,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '1rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      }
    }
  },
  components: {
    Title,
    CardComunidad,
    Splide,
    SplideSlide,
    Tabs
  },
  computed: {
    ...mapGetters(['token'])
  },
  mounted() {
    this.setLoadingOn()
    this.getEvents()
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    getEvents: async function () {
      let userEventsList = null
      const today = new Date()
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        const userEventsData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userEventsQuery
        })

        userEventsList = userEventsData.data.userProfile.events
        this.queryEvents = eventsQuery.data.events

        userEventsList.forEach((element) => {
          element.event.date > today
            ? this.userEvents.push(element.event)
            : this.pastUserEvents.push(element.event)
        })
        this.queryEvents.forEach((element) => {
          if (
            !this.userEvents.includes(element) &&
            !this.pastUserEvents.includes(element)
          ) {
            this.recommendedEvents.push(element)
          }
        })
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
