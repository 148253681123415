<template>
  <svg
    width="180"
    height="30"
    viewBox="0 0 180 30"
    class="fill-current dark:text-white text-contrast"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.7523 11.9044L20.0819 3.57275H15.6417L19.4613 12.0093C19.8517 12.8731 19.8407 13.8565 19.4282 14.7092L15.4392 22.9782H8.64532L4.65631 14.7092C4.24562 13.8565 4.23273 12.8712 4.62316 12.0093L8.44274 3.57275H4.00253L0.332123 11.9044C-0.120922 12.932 -0.109872 14.1051 0.36159 15.1236L2.79993 20.3944C3.88466 22.7572 4.73734 23.0205 6.04307 23.0205C6.1462 23.0205 6.25302 23.0187 6.36352 23.0169C6.50532 23.0132 6.6545 23.0113 6.8092 23.0113H8.47036L9.19597 24.8475C9.44275 25.4699 10.0339 25.8714 10.7024 25.8714H13.3876C14.0561 25.8714 14.6472 25.4699 14.894 24.8475L15.6196 23.0113H17.2753C17.43 23.0113 17.5791 23.015 17.721 23.0169C17.8315 23.0187 17.9383 23.0205 18.0414 23.0205C19.3471 23.0205 20.1998 22.7553 21.2845 20.3944L23.7229 15.1236C24.1943 14.1051 24.2054 12.932 23.7523 11.9044Z"
    />
    <path
      d="M176.578 7.44775C178.167 7.44775 179.444 8.73138 179.444 10.3612C179.444 11.9985 178.167 13.2747 176.578 13.2747C175.003 13.2747 173.744 11.9985 173.744 10.3612C173.744 8.73138 175.003 7.44775 176.578 7.44775ZM176.578 12.6946C177.854 12.6946 178.858 11.6762 178.858 10.3612C178.858 9.05367 177.856 8.02787 176.578 8.02787C175.316 8.02787 174.329 9.05367 174.329 10.3612C174.331 11.6762 175.316 12.6946 176.578 12.6946ZM176.108 11.9193H175.504V8.78663H176.6C177.25 8.78663 177.72 9.19364 177.72 9.7977C177.72 10.2047 177.477 10.5178 177.101 10.6522L177.758 11.9211H177.116L176.552 10.7461H176.106V11.9193H176.108ZM176.108 9.32624V10.2581H176.515C176.891 10.2581 177.095 10.1163 177.095 9.79585C177.095 9.4662 176.891 9.32624 176.515 9.32624H176.108Z"
    />
    <path
      d="M70.4089 8.29466C69.2007 7.60404 67.8361 7.25781 66.3167 7.25781C64.7771 7.25781 63.4032 7.60404 62.1951 8.29466C60.987 8.98712 60.033 9.93557 59.3314 11.1437C58.6297 12.3518 58.2798 13.7257 58.2798 15.2653C58.2798 16.8049 58.6297 18.1788 59.3314 19.3869C60.033 20.595 60.987 21.5453 62.1951 22.2359C63.4032 22.9284 64.7771 23.2728 66.3167 23.2728C67.8361 23.2728 69.2007 22.9265 70.4089 22.2359C71.617 21.5453 72.5673 20.595 73.2579 19.3869C73.9485 18.1788 74.2947 16.8049 74.2947 15.2653C74.2947 13.7257 73.9485 12.3518 73.2579 11.1437C72.5673 9.93742 71.617 8.98712 70.4089 8.29466ZM70.1013 17.7221C69.7219 18.4421 69.2044 19.0186 68.5525 19.4458C67.8987 19.8749 67.1547 20.0886 66.3167 20.0886C65.4788 20.0886 64.7237 19.8749 64.0515 19.4458C63.3793 19.0167 62.8581 18.4421 62.4879 17.7221C62.1178 17.002 61.9336 16.1824 61.9336 15.2671C61.9336 14.3518 62.1178 13.5286 62.4879 12.7975C62.8581 12.0664 63.3793 11.4918 64.0515 11.0737C64.7237 10.6557 65.4788 10.4457 66.3167 10.4457C67.1547 10.4457 67.8987 10.6557 68.5525 11.0737C69.2044 11.4936 69.7219 12.0627 70.1013 12.7828C70.4807 13.5047 70.6612 14.3316 70.6428 15.2671C70.6612 16.1824 70.4825 17.002 70.1013 17.7221Z"
    />
    <path
      d="M89.0131 8.3389C87.9505 7.65749 86.7387 7.31494 85.374 7.31494C84.5158 7.31494 83.6926 7.48069 82.9044 7.81219C82.1161 8.14368 81.4476 8.57647 80.9025 9.11239C80.7496 9.26156 80.617 9.41443 80.4973 9.56913L80.4642 7.57646H76.9282V29.4092H80.5526V21.1439C80.6134 21.2102 80.676 21.2765 80.7423 21.3409C81.3169 21.9063 82.0185 22.3649 82.8473 22.7148C83.6742 23.0647 84.5379 23.2415 85.433 23.2415C86.7571 23.2415 87.9505 22.8953 89.0131 22.2047C90.0757 21.514 90.9137 20.5674 91.527 19.3685C92.1402 18.1696 92.4478 16.8013 92.4478 15.2617C92.4478 13.7036 92.1402 12.3297 91.527 11.14C90.9118 9.95771 90.0739 9.02215 89.0131 8.3389ZM88.3998 17.7516C88.0389 18.4716 87.538 19.037 86.8952 19.4459C86.2525 19.8547 85.5213 20.0591 84.7037 20.0591C83.8454 20.0591 83.1014 19.8547 82.4679 19.4459C81.8344 19.037 81.3316 18.4716 80.9633 17.7516C80.5931 17.0315 80.4071 16.2027 80.4071 15.2672C80.4071 14.3316 80.5913 13.5047 80.9633 12.7828C81.3334 12.0627 81.8344 11.501 82.4679 11.1014C83.1014 10.7017 83.8454 10.5028 84.7037 10.5028C85.5416 10.5028 86.2819 10.7073 86.9247 11.1161C87.5674 11.5249 88.0647 12.0848 88.4146 12.7975C88.7645 13.5084 88.9413 14.3316 88.9413 15.2672C88.9394 16.2027 88.759 17.0296 88.3998 17.7516Z"
    />
    <path
      d="M109.86 11.072C110.532 10.6539 111.297 10.444 112.155 10.444C112.661 10.444 113.168 10.5416 113.674 10.7368C114.181 10.932 114.658 11.1954 115.107 11.525C115.555 11.8565 115.925 12.2267 116.218 12.6355L118.205 10.2395C117.58 9.30399 116.704 8.57287 115.575 8.048C114.444 7.52128 113.188 7.25977 111.805 7.25977C110.363 7.25977 109.067 7.60968 107.917 8.31135C106.768 9.01302 105.862 9.96699 105.199 11.1751C104.536 12.3832 104.205 13.7479 104.205 15.2673C104.205 16.7866 104.536 18.1513 105.199 19.3594C105.862 20.5675 106.768 21.5233 107.917 22.2231C109.067 22.9248 110.363 23.2747 111.805 23.2747C113.091 23.2747 114.313 23.0059 115.474 22.4718C116.632 21.9359 117.544 21.2397 118.207 20.3815L116.219 17.9561C115.849 18.4238 115.45 18.8143 115.021 19.1255C114.591 19.4367 114.138 19.6762 113.661 19.8419C113.184 20.0076 112.693 20.0905 112.186 20.0905C111.348 20.0905 110.588 19.8769 109.906 19.4478C109.225 19.0187 108.683 18.4441 108.284 17.724C107.884 17.0039 107.685 16.1844 107.685 15.2691C107.685 14.3538 107.881 13.5306 108.269 12.7994C108.658 12.0665 109.188 11.4919 109.86 11.072Z"
    />
    <path
      d="M132.165 8.29466C130.957 7.60404 129.592 7.25781 128.073 7.25781C126.533 7.25781 125.159 7.60404 123.951 8.29466C122.743 8.98712 121.789 9.93557 121.087 11.1437C120.386 12.3518 120.036 13.7257 120.036 15.2653C120.036 16.8049 120.386 18.1788 121.087 19.3869C121.789 20.595 122.743 21.5453 123.951 22.2359C125.159 22.9284 126.533 23.2728 128.073 23.2728C129.592 23.2728 130.957 22.9265 132.165 22.2359C133.373 21.5453 134.323 20.595 135.014 19.3869C135.704 18.1788 136.052 16.8049 136.052 15.2653C136.052 13.7257 135.706 12.3518 135.014 11.1437C134.323 9.93742 133.373 8.98712 132.165 8.29466ZM131.859 17.7221C131.48 18.4421 130.962 19.0186 130.31 19.4458C129.656 19.8749 128.912 20.0886 128.074 20.0886C127.236 20.0886 126.481 19.8749 125.809 19.4458C125.137 19.0167 124.616 18.4421 124.246 17.7221C123.875 17.002 123.689 16.1824 123.689 15.2671C123.689 14.3518 123.874 13.5286 124.246 12.7975C124.616 12.0664 125.137 11.4918 125.809 11.0737C126.481 10.6557 127.236 10.4457 128.074 10.4457C128.912 10.4457 129.656 10.6557 130.31 11.0737C130.962 11.4936 131.48 12.0627 131.859 12.7828C132.238 13.5047 132.419 14.3316 132.4 15.2671C132.419 16.1824 132.238 17.002 131.859 17.7221Z"
    />
    <path
      d="M150.392 9.34995C150.139 9.07002 149.852 8.80481 149.515 8.55803C148.97 8.1584 148.351 7.84164 147.659 7.60775C146.966 7.37386 146.252 7.25783 145.511 7.25783C144.147 7.25783 142.924 7.60406 141.843 8.29468C140.762 8.98714 139.909 9.93559 139.285 11.1437C138.66 12.3518 138.349 13.7257 138.349 15.2653C138.349 16.8252 138.666 18.2027 139.299 19.4017C139.933 20.6006 140.789 21.5453 141.872 22.2378C142.953 22.9303 144.176 23.2746 145.541 23.2746C146.242 23.2746 146.929 23.1623 147.602 22.9376C148.274 22.7148 148.863 22.4164 149.37 22.0463C149.802 21.7295 150.163 21.3833 150.462 21.0094L150.51 22.9818H153.988V1.35352H150.393V9.34995H150.392ZM149.968 17.8381C149.607 18.5784 149.106 19.1641 148.463 19.5913C147.821 20.0205 147.08 20.2341 146.242 20.2341C145.404 20.2341 144.664 20.0205 144.021 19.5913C143.379 19.1622 142.876 18.5784 142.517 17.8381C142.156 17.0978 141.975 16.2414 141.975 15.2653C141.975 14.3113 142.156 13.4587 142.517 12.7073C142.878 11.9577 143.379 11.3721 144.021 10.954C144.664 10.536 145.404 10.326 146.242 10.326C147.08 10.326 147.821 10.536 148.463 10.954C149.106 11.3739 149.607 11.9577 149.968 12.7073C150.329 13.4568 150.51 14.3095 150.51 15.2653C150.51 16.2414 150.329 17.0978 149.968 17.8381Z"
    />
    <path
      d="M171.436 14.8291C171.454 13.7389 171.294 12.7296 170.953 11.8033C170.613 10.8788 170.121 10.0795 169.478 9.4073C168.835 8.7351 168.075 8.2047 167.198 7.81427C166.321 7.42568 165.375 7.23047 164.362 7.23047C163.213 7.23047 162.156 7.43121 161.191 7.829C160.226 8.22864 159.397 8.79403 158.706 9.52332C158.014 10.2545 157.474 11.1163 157.084 12.1108C156.693 13.1053 156.5 14.2048 156.5 15.4129C156.5 16.9323 156.846 18.2822 157.537 19.4608C158.227 20.6395 159.192 21.5695 160.43 22.2528C161.668 22.936 163.095 23.2767 164.712 23.2767C165.432 23.2767 166.163 23.1791 166.903 22.9839C167.644 22.7887 168.351 22.5217 169.023 22.181C169.695 21.8403 170.294 21.4259 170.821 20.9397L169.038 18.4553C168.336 19.0594 167.684 19.4793 167.08 19.7131C166.476 19.947 165.813 20.0631 165.093 20.0631C164.06 20.0631 163.159 19.8642 162.39 19.4645C161.62 19.0649 161.025 18.4958 160.607 17.7555C160.325 17.2582 160.141 16.7021 160.049 16.0888H171.408L171.436 14.8291ZM161.951 10.9709C162.603 10.6007 163.408 10.4147 164.362 10.4147C164.986 10.4147 165.555 10.5473 166.071 10.8088C166.587 11.0721 167.01 11.4331 167.342 11.8898C167.673 12.3484 167.868 12.8788 167.926 13.4829V13.6283H160.121C160.204 13.2379 160.32 12.8806 160.472 12.562C160.806 11.8714 161.297 11.341 161.951 10.9709Z"
    />
    <path
      d="M42.0915 15.4089C42.0915 16.582 41.7913 17.5158 41.2002 18.1843C40.6182 18.8417 39.8005 19.1769 38.771 19.1769C37.7416 19.1769 36.922 18.8454 36.3345 18.1898C35.7397 17.5268 35.4377 16.5912 35.4377 15.4089V7.13623H31.8667V15.8288C31.8667 17.8638 32.5039 19.5232 33.7618 20.7571C35.0178 21.991 36.7047 22.6153 38.7729 22.6153C40.841 22.6153 42.5243 21.9891 43.7766 20.7571C45.029 19.5232 45.6643 17.8638 45.6643 15.8288V7.13623H42.0934V15.4089H42.0915Z"
    />
    <path
      d="M99.0501 0.806641C97.9212 0.806641 97.0059 1.72193 97.0059 2.85086C97.0059 3.97979 97.9212 4.89509 99.0501 4.89509C100.179 4.89509 101.094 3.97979 101.094 2.85086C101.092 1.72009 100.179 0.806641 99.0501 0.806641Z"
    />
    <path
      d="M94.6909 7.65039V10.748H97.3097V22.9932H100.789V10.748V7.65039H97.3097H94.6909Z"
    />
    <path
      d="M52.8929 19.0685V10.1053H55.7659V6.92841H52.8929V3.64844H49.1912V6.92841H49.1875V10.1053H49.1912V19.0704C49.1912 21.2619 50.228 23.3688 51.9665 24.7058L54.2226 26.3173L56.3718 23.3043L54.1986 21.7537C53.3809 21.1109 52.8929 20.1109 52.8929 19.0685Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
