<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('politica-page.title')"
      :hero-image="require('@/assets/images/hero/bg-privacidad.svg')"
      section="pageHero"
      class="hero--sm"
    />
    <div class="content--wrapper dark:text-primary-text text-secondary-text">
      <CardPage class="p-8 lg:w-9/12 mx-auto">
        <template #default>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-datos-contacto') }}</h4>
            </template>
          </Title>
          <p class="mb-10">
            <b>{{ $t('politica-page.lbl-nombre') }}:</b> UTOPICODE LTD<br />
            <b>{{ $t('politica-page.lbl-direccion') }}:</b> 85E Warrington
            Crescent, Warrington Crescent, London, W9 1EH<br />
            <b>{{ $t('politica-page.lbl-correo') }}:</b>
            <a
              href="mailto:contact@utopicode.com"
              title="Utopicode Email"
              class="text-secondary"
              >contact@utopicode.com</a
            >
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-quienes-somos') }}</h4>
            </template>
          </Title>
          <div
            class="mb-10"
            v-html="$t('politica-page.text-quienes-somos')"
          ></div>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-info-sobre-ti') }}</h4>
            </template>
          </Title>
          <div v-html="$t('politica-page.text-info-sobre-ti')"></div>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="item in 12" :key="item">
              {{ $t(`politica-page.list-info-sobre-ti-${item}`) }}
            </li>
          </ul>
          <p>{{ $t('politica-page.text-recopilacion-sobre-ti') }}</p>
          <ul class="mb-10 list--bullet list--bullet--secondary mb-8">
            <li v-for="item in 10" :key="item">
              {{ $t(`politica-page.list-recopilacion-sobre-ti-${item}`) }}
            </li>
          </ul>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-politica-cookies') }}</h4>
            </template>
          </Title>
          <p>{{ $t('politica-page.text-politica-cookies-1') }}</p>
          <table class="table--default table-fixed mb-8">
            <thead>
              <tr>
                <th class="w-4/12 text-center">
                  {{ $t('politica-page.table-title-nombre-cookie') }}
                </th>
                <th class="w-8/12 text-center">
                  {{ $t('politica-page.table-title-proposito') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>UTOPICODE Login</td>
                <td>
                  {{ $t('politica-page.table-description-utopicode-login') }}
                </td>
              </tr>
              <tr>
                <td>Google Analytics</td>
                <td>
                  {{ $t('politica-page.table-description-google-analytics') }}
                </td>
              </tr>
              <tr>
                <td>Facebook</td>
                <td>{{ $t('politica-page.table-description-facebook') }}</td>
              </tr>
              <tr>
                <td>LinkedIn</td>
                <td>{{ $t('politica-page.table-description-linkedin') }}</td>
              </tr>
            </tbody>
          </table>
          <p>{{ $t('politica-page.text-politica-cookies-2') }}</p>
          <p>
            <b>Chome:</b>
            <a
              target="_blank"
              href="https://support.google.com/chrome/answer/95647"
              class="text-secondary"
              >https://support.google.com/chrome/answer/95647</a
            ><br />
            <b>Safari:</b>
            <a
              target="_blank"
              href="https://support.apple.com/kb/ph21411"
              class="text-secondary"
              >https://support.apple.com/kb/ph21411</a
            ><br />
            <b>Firefox:</b>
            <a
              target="_blank"
              href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
              class="text-secondary"
              >https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a
            >
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-como-usamos-tus-datos') }}</h4>
            </template>
          </Title>
          <div v-html="$t('politica-page.text-como-usamos-tus-datos')"></div>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="item in 8" :key="item">
              {{ $t(`politica-page.list-como-usamos-tus-datos-${item}`) }}
            </li>
          </ul>
          <p class="mb-10">
            {{ $t('politica-page.text-como-usamos-tus-datos-final') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-pruebas-de-evaluacion') }}</h4>
            </template>
          </Title>
          <ul class="mb-10 list--bullet list--bullet--secondary mb-8">
            <li v-for="item in 4" :key="item">
              {{ $t(`politica-page.list-pruebas-de-evaluacion-${item}`) }}
            </li>
          </ul>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-tus-derechos') }}</h4>
            </template>
          </Title>
          <p>{{ $t('politica-page.text-tus-derechos-1') }}</p>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="item in 6" :key="item">
              {{ $t(`politica-page.list-tus-derechos-${item}`) }}
            </li>
          </ul>
          <div
            class="mb-10"
            v-html="$t('politica-page.text-tus-derechos-2')"
          ></div>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-compartir-divulgar') }}</h4>
            </template>
          </Title>
          <p class="mb-10">{{ $t('politica-page.text-compartir-divulgar') }}</p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-medidas-salvaguardia') }}</h4>
            </template>
          </Title>
          <div v-html="$t('politica-page.text-medidas-salvaguardia')"></div>
          <ul class="mb-10 list--bullet list--bullet--secondary mb-8">
            <li v-for="item in 6" :key="item">
              {{ $t(`politica-page.list-medidas-salvaguardia-${item}`) }}
            </li>
          </ul>
          <Title class="mb-6">
            <template #title>
              <h4>
                {{ $t('politica-page.title-donde-almacenamos-tus-datos') }}
              </h4>
            </template>
          </Title>
          <p class="mb-10">
            {{ $t('politica-page.text-donde-almacenamos-tus-datos') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-como-presentar-una-queja') }}</h4>
            </template>
          </Title>
          <div v-html="$t('politica-page.text-como-presentar-una-queja')"></div>
          <p class="mb-10">
            Information Commissioner’s Office<br />
            Wycliffe House<br />
            Water Lane<br />
            Wilmslow<br />
            SK9 5AF<br />
            0303 123 1113
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('politica-page.title-cambios-avisos-privacidad') }}</h4>
            </template>
          </Title>
          <p>{{ $t('politica-page.text-cambios-avisos-privacidad') }}</p>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'

export default {
  setup() {
    return {}
  },
  components: {
    Hero,
    CardPage,
    Title
  }
}
</script>
