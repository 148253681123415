<template>
  <div class="cards p-6">
    <Title class="mb-8">
      <template #title>
        <h6>{{ data.role }}</h6>
      </template>
      <template #options>
        <button>
          <img src="@/assets/images/icn-ellipsis.svg" />
        </button>
        <!-- <router-link
          :to="{ name: 'Eventos' }"
          class="
            btn btn-sm btn-rounded-lg btn-secondary
            transform
            -translate-y-3
          "
          >{{ $t('candidates-pool.lbl-position') }}</router-link -->
      </template>
    </Title>
    <div class="flex space-x-4">
      <div
        class="
          bg-primary
          rounded-lg
          w-32
          h-32
          text-white
          font-bold
          text-center
          p-4
        "
      >
        <span class="text-5xl">{{ data.candidates }}</span>
        <p>Candidates</p>
      </div>
      <div class="w-7/12">
        <div class="">
          <p class="mb-1">
            {{ $t('candidates-pool.lbl-position') }}:
            <span class="font-bold">{{ data.position }}</span>
          </p>
          <p class="mb-1">
            {{ $t('candidates-pool.lbl-english') }}:
            <span class="font-bold">{{ data.english }}</span>
          </p>
          <p class="mb-1">
            {{ $t('candidates-pool.lbl-salary') }}:
            <span class="font-bold"
              >{{ data.salary.currency }} {{ data.salary.min }} -
              {{ data.salary.max }}</span
            >
          </p>
          <p class="mb-1 flex space-x-1">
            {{ $t('candidates-pool.lbl-tech-stack') }}:
            <span
              class="
                badge
                dark:bg-main-dark
                bg-line-light
                dark:text-white
                badge-xs badge-rounded-full
              "
              v-for="stack in data.techStack"
              :key="stack"
              >{{ stack }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="p-2 dark:text-white">
      <div
        class="
          content--wrapper
          flex
          lg:justify-between
          flex-wrap
          relative
          items-center
          lg:flex-row-reverse
        "
      >
        <div class="w-full lg:w-8/12 mb-12 lg:mb-0">
          <h2 class="flex text-contrast dark:text-white font-bold lg:text-xl">
            {{ data.role }}
          </h2>
          <p class="text-secondary">{{ data.position }}</p>
        </div>
        <p class="text-secondary-text text-sm">{{ data.english }}</p>
      </div>
    </div> -->
  </div>
</template>
<script>
import Title from '@/components/Title'

export default {
  name: 'CardPool',
  props: {
    data: Array
  },
  components: {
    Title
  },
  data() {
    return {}
  }
}
</script>
