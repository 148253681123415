import gql from 'graphql-tag'

const assesmentsQuery = gql`
  query assesments {
    assesments {
      id
      name
      instructions
      photo
    }
  }
`

export default assesmentsQuery
