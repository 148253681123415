<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4 class="mt-1">{{ $t('profile-page.title-tecnologias') }}</h4>
      </template>
      <template #options v-if="!isPublic && edit === false">
        <button
          @click="editSection()"
          type="button"
          role="button"
          aria-label="Edit"
          class="
            w-9
            h-9
            flex
            justify-center
            items-center
            rounded-md
            btn-secondary
            transform
            -translate-y-2
          "
        >
          <img src="@/assets/images/icn-edit.svg" />
        </button>
      </template>
    </Title>
    <!-- If Technology is empty and is not Public -->
    <div
      class="text-center"
      v-if="techList.length <= 0 && !isPublic && edit === false"
    >
      <BadgeAward
        class="mx-auto inline-block"
        imagen="icn-tecnologias.svg"
        :bloqueado="true"
        :titulo="$t('habilidades-page.title-tech-talk')"
      ></BadgeAward>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-tecnologias-badge') }}
      </p>
      <div class="flex justify-center">
        <button
          @click="editSection()"
          type="button"
          role="button"
          :aria-label="$t('general.completar')"
          class="btn btn-md btn-rounded-lg btn-primary mx-auto"
        >
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <!-- If Technologies is not Empty and is Public -->
    <form @submit.prevent="validar" v-if="!isPublic && edit === true">
      <p class="dark:text-primary-text text-secondary-text">
        {{ $t(`modal-tecnologias.description-01`) }}
      </p>
      <table class="table--tecnologias table-fixed mb-5 w-full">
        <thead>
          <tr>
            <th class="w-full">
              <h5
                class="
                  dark:text-primary-text
                  text-secondary-text text-sm
                  font-normal
                "
              >
                {{ $t('modal-tecnologias.lbl-tecnologias') }}
              </h5>
            </th>
            <th class="w-full">
              <h5
                class="
                  dark:text-primary-text
                  text-secondary-text text-sm
                  font-normal
                  flex
                "
              >
                {{ $t('modal-tecnologias.lbl-nivel') }}
                <div classs="tooltip">
                  <span
                    class="ml-2"
                    type="button"
                    role="button area"
                    aria-label="Info"
                    @mouseenter="tooltipShow = true"
                    @mouseleave="tooltipShow = false"
                  >
                    <img src="@/assets/images/icn-info.svg" class="w-4" />
                  </span>
                  <transition name="fade">
                    <div
                      class="tooltip--globe tooltip--sm rounded-lg"
                      v-show="tooltipShow == true"
                    >
                      <p
                        class="dark:text-primary-text text-secondary-text"
                        v-for="(level, index) in 4"
                        :key="index + 1"
                        :class="index + 1 === 4 ? 'last' : ''"
                      >
                        <b>
                          {{
                            $t(`tooltip-nivel-experiencia.title-${index + 1}`)
                          }}:
                        </b>
                        {{
                          $t(
                            `tooltip-nivel-experiencia.description-${index + 1}`
                          )
                        }}
                      </p>
                    </div>
                  </transition>
                </div>
              </h5>
            </th>
            <th class="w-full">
              <h5
                class="
                  dark:text-primary-text
                  text-secondary-text text-sm
                  font-normal
                "
              >
                {{ $t('modal-tecnologias.lbl-experiencia') }}
              </h5>
            </th>
            <th class="w-12"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(lista, index) in techList" :key="index">
            <td>
              <input
                list="technologiesList"
                name="techList"
                class="form--element form--select w-full cursor-default"
                v-model.lazy="techList[index].techName"
              />
              <datalist id="technologiesList">
                <option
                  :value="tecnologia.name"
                  v-for="(tecnologia, index) in tecnologiasLista"
                  :key="index"
                />
              </datalist>
            </td>
            <td>
              <select
                id="nivel"
                name="nivel"
                class="form--element form--select w-full"
                v-model="techList[index].level"
              >
                <option value=""></option>
                <option
                  :value="`${nivel}`"
                  v-for="(nivel, index) in 4"
                  :key="nivel"
                >
                  {{
                    $t(
                      `modal-tecnologias.opcion-nivel-experiencia-${index + 1}`
                    )
                  }}
                </option>
              </select>
            </td>
            <td>
              <select
                id="experiencia"
                name="experiencia"
                class="form--element form--select w-full"
                v-model="techList[index].expYears"
              >
                <option value=""></option>
                <option
                  v-for="year in 15"
                  :key="year"
                  :value="year !== 10 ? `${year}` : `${year}+`"
                >
                  {{ year !== 15 ? year : `${year}+` }}
                </option>
              </select>
            </td>
            <td>
              <span
                class="
                  w-9
                  h-9
                  flex
                  justify-center
                  items-center
                  rounded-md
                  btn-secondary
                "
                @click="removeRow(index)"
              >
                <img src="@/assets/images/icn-remove.svg" />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex">
        <span
          class="btn btn-rounded-lg btn-secondary btn-md"
          type="button"
          role="button"
          :aria-label="$t('general.agregar')"
          @click="addRow"
        >
          {{ $t('general.agregar') }}
        </span>
      </div>
      <div class="mt-8 flex justify-center space-x-4">
        <button
          type="button"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="cancelEdition"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </form>
    <div v-else>
      <div class="grid gap-x-6 gap-y-4 grid-cols-1 lg:grid-cols-3">
        <template v-for="technology in techList" :key="technology.techName">
          <ProgressBar
            class="w-full"
            :title="technology.techName"
            :years="technology.expYears"
            :level="technology.level"
            :porcentaje="calculateTechLevel(technology.level)"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script>
import Title from '@/components/Title'
import ProgressBar from '@/components/ProgressBar'
import BadgeAward from '@/components/BadgeAward'
import ROUTES from '@/constants/routes'

// import tecnologiasLista from '@/components/json/tecnologias.json'
import getTechnologiesQuery from '@/graphql/queries/technologies'
import apolloClient from '@/graphql/apolloClient'
import saveTechnologiesMutation from '@/graphql/mutations/saveTechnologies'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

const emptyTech = {
  techName: null,
  level: null,
  expYears: null
}

export default {
  name: 'TecnologiaSection',
  props: {
    userTechnologies: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    ProgressBar,
    BadgeAward
  },
  data() {
    return {
      ROUTES,
      tecnologiasLista: [],
      techList: [],
      tooltipShow: false,
      value: null,
      edit: true
    }
  },
  mounted() {
    this.initializeData()
    if (
      (this.techName !== null &&
        this.level !== null &&
        this.expYears !== null) ||
      this.isPublic
    ) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  beforeMount() {
    this.getTechnologies()
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validar() {
      try {
        this.setLoadingOn()
        const saveTechnologies = await apolloClient.mutate({
          mutation: saveTechnologiesMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            technologies: this.techList.filter((val) => {
              return JSON.stringify(val) !== JSON.stringify({ ...emptyTech })
            }),
            id: this.id
          }
        })
        if (!saveTechnologies.errors) {
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.edit = false
        }
      } catch (e) {
        toastNotification(e.message, 'error')
      }
    },
    addRow() {
      this.techList.push({ ...emptyTech })
    },
    removeRow(index) {
      this.techList.splice(index, 1)
    },
    editSection() {
      this.edit = true
      if (this.techList.length <= 0) {
        this.techList.push({ ...emptyTech })
      }
    },
    cancelEdition() {
      this.techList = []
      if (this.userTechnologies.length > 0) {
        this.initializeData()
      }
      this.edit = false
    },
    initializeData() {
      if (this.userTechnologies) {
        this.userTechnologies.forEach((val) => {
          this.techList.unshift({
            techName: val.techName,
            level: val.level,
            expYears: val.expYears
          })
        })
      }
    },
    getTechnologies: async function () {
      this.setLoadingOn()
      try {
        const technologiesQuery = await apolloClient.query({
          fetchPolicy: 'no-cache',
          query: getTechnologiesQuery
        })
        this.tecnologiasLista = technologiesQuery.data.technologies
        this.setLoadingOff()
      } catch (e) {
        console.log(e, 'error')
        this.setLoadingOff()
      }
    },
    calculateTechLevel: (level) => {
      switch (level) {
        case '1':
          return 25
        case '2':
          return 50
        case '3':
          return 75
        case '4':
          return 100
        default:
          return 0
      }
    }
  }
}
</script>
