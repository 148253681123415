<template>
  <div class="tabs">
    <div class="flex tabs-header">
      <div
        v-for="(tab, index) in tabList"
        :key="index"
        class="tabs-item"
        :class="{
          'tabs-item-active': index + 1 == activeTab
        }"
      >
        <label :for="`tabs-${index}`" v-text="tab" />
        <input
          :id="`tabs-${index}`"
          type="radio"
          :name="`${index}-tab`"
          :value="index + 1"
          v-model="activeTab"
          class="hidden"
        />
      </div>
      <slot class="justify-end" name="options"></slot>
    </div>
    <template v-for="(tab, index) in tabList">
      <div :key="index" v-if="index + 1 === activeTab">
        <slot :name="`tabPanel-${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeTab: 1
    }
  }
}
</script>
