<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    alt="Utopicode"
    class="
      fill-current
      bg-gradient-to-br
      from-tertiary
      to-linkedin
      text-main-light
      opacity-30
      dark:from-gradient-primary
      dark:to-gradient-secondary
      dark:opacity-100
      dark:text-main-dark
    "
    viewBox="0 0 672 711"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M672 0H0v711h180.18c-25.176-.028-65.947-8.681-117.2502-48.924-73.0435-57.296-55.06358-116.84-11.2375-143.803 9.6021-5.907 20.6066-12.192 31.9619-18.678 40.4728-23.114 85.3998-48.773 87.1548-68.952 2.248-25.839-21.351-46.061-66.301-51.679-5.4822-.685-11.1816-1.136-16.9639-1.594C45.9166 374.075 0 370.441 0 276.729 0 170.001 185.418 48.667 229.244 29.5682c43.826-19.0988 146.087-59.5434 207.893 7.8642C498.943 104.84 672 120.568 672 120.568V0Zm0 587.928s-77.314 29.199-156.201 38.197c-12.148 1.386-42.965.712-62.985.274-6.923-.151-12.554-.274-15.677-.274-58.183 0-76.898 9.106-107.918 24.2-6.725 3.272-14.028 6.825-22.436 10.628-18.513 8.372-35.989 16.745-51.342 24.101-23.789 11.397-42.482 20.353-52.043 23.084-4.646 1.327-12.428 2.85-22.969 2.862H672V587.928Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Shape01'
}
</script>
