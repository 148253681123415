import gql from 'graphql-tag'

const sendHiringInterest = gql`
  mutation sendHiringInteres(
    $name: String!
    $email: String!
    $companyName: String!
    $webpage: String!
    $country: String!
    $phone: String!
    $needs: String!
    $roles: String!
  ) {
    saveHiringInterest(
      name: $name
      email: $email
      companyName: $companyName
      webpage: $webpage
      country: $country
      phone: $phone
      needs: $needs
      roles: $roles
    )
  }
`

export default sendHiringInterest
