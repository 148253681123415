const routes = {
  home: '',
  comunidad: 'comunidad',
  upskilling: 'upskilling-team',
  upskillingb2c: 'upskilling',
  summit: 'summit',
  tickets: 'tickets',
  eventos: 'comunidad/evento',
  cursos: 'cursos',
  mentorias: 'mentorias',
  habilidades: 'habilidades',
  detallesHabilidad: 'instrucciones',
  test: 'test',
  habilidadesTestIncompletos: 'test-incompleto',
  contrata: 'contrata',
  trabajos: 'trabajos',
  nosotros: 'nosotros',
  faq: 'faq',
  recuperarContraseña: 'recuperar-password',
  terminos: 'terminos-y-condiciones',
  politica: 'politica-de-privacidad',
  signup: 'sign-up',
  signin: 'sign-in',
  dashboardProfile: 'welcome',
  profile: '',
  profileEvents: 'eventos',
  profileBadges: 'badges',
  profileTests: 'tests',
  cliente: 'cliente',
  clientes: 'clientes',
  candidato: 'roles',
  candidateProfile: 'candidate',
  dashboardKanban: 'track',
  dashboardGeneral: 'dashboardGeneral',
  dashboardPremium: 'dashboard',
  landing: '',
  detalleBusqueda: 'detalle',
  paywall: 'paywall',
  signUpEmpresas: 'sign-up',
  signInEmpresas: 'sign-in',
  clientsProfile: 'perfil',
  resultadoAssesments: 'resultado/:id',
  editProfile: 'edit',
  editTecnologias: 'tecnologias',
  editExperienciaProfesional: 'experiencia-profesional',
  editEducacionFormal: 'educacion-formal',
  editCertificacionesCursos: 'certificaciones-cursos',
  publicProfile: 'public-profile/:id',
  editProfileAvatar: 'avatar',
  unboxingEs: '/es/unboxing',
  unboxingEn: '/en/unboxing'
}

export default routes
