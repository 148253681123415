import gql from 'graphql-tag'

const saveJobExperience = gql`
  mutation saveJobExperience(
    $yearsOfExp: Int
    $personsInCharge: Int
    $englishLevel: String
    $id: Int
  ) {
    updateExperience(
      yearsOfExp: $yearsOfExp
      personsInCharge: $personsInCharge
      englishLevel: $englishLevel
      id: $id
    ) {
      id
      yearsOfExp
      personsInCharge
      englishLevel
    }
  }
`

export default saveJobExperience
