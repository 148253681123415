<template>
  <div class="md:flex flex-col md:flex-row md:min-h-screen w-full relative">
    <!-- SideBar -->
    <div :class="[isShowingMenu ? '' : 'collapsed']" class="sidebar">
      <div class="sidebar--header">
        <router-link class="header--logo mx-auto" :to="{ name: 'Home' }">
          <Logo v-if="isShowingMenu" />
          <LogoSmall v-else />
        </router-link>
        <button
          @click="isShowingMenu = !isShowingMenu"
          class="sidebar--toggle"
          :class="{ collapsed: !isShowingMenu }"
        >
          <span class="icon-wrapper">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </span>
        </button>
      </div>
      <nav v-if="isShowingMenu" class="sidebar--menu">
        <router-link
          class="sidebar--nav--link"
          :to="{ name: 'DashboardPremium' }"
        >
          <icnHome class="image" />
          <span class="mx-4">{{ $t('sidebar-section.dashboard') }}</span>
        </router-link>
        <router-link
          class="sidebar--nav--link"
          :to="{ name: 'DetalleBusqueda' }"
        >
          <icnCogsSolid class="image" />
          <span class="mx-4">{{ $t('sidebar-section.segundo-link') }}</span>
        </router-link>
        <router-link
          class="sidebar--nav--link"
          :to="{ name: 'CandidateProfile' }"
        >
          <icnAddressBook class="image" />
          <span class="mx-4">{{ $t('sidebar-section.tercer-link') }}</span>
        </router-link>
        <router-link
          class="sidebar--nav--link"
          :to="{ name: 'DashboardKanban' }"
        >
          <icnEnvelopeOpen class="image" />
          <span class="mx-4">{{ $t('sidebar-section.cuarto-link') }}</span>
        </router-link>
      </nav>
      <nav v-else class="sidebar--menu">
        <router-link
          class="sidebar--nav--link"
          :to="{ name: 'DashboardPremium' }"
        >
          <icnHome class="image" />
          <span class="text-xs">{{ $t('sidebar-section.dashboard') }}</span>
        </router-link>
        <router-link
          class="sidebar--nav--link"
          :to="{ name: 'DetalleBusqueda' }"
        >
          <icnCogsSolid class="image" />
          <span class="text-xs">{{ $t('sidebar-section.segundo-link') }}</span>
        </router-link>
        <router-link class="sidebar--nav--link" :to="{ name: 'Home' }">
          <icnAddressBook class="image" />
          <span class="text-xs">{{ $t('sidebar-section.tercer-link') }}</span>
        </router-link>
        <router-link class="sidebar--nav--link" :to="{ name: 'Home' }">
          <icnEnvelopeOpen class="image" />
          <span class="text-xs">{{ $t('sidebar-section.cuarto-link') }}</span>
        </router-link>
      </nav>
      <transition name="fade">
        <div v-if="dimmer && isShowingMenu" @click="toggle()" />
      </transition>
    </div>
    <!-- End Sidebar -->
    <!-- Content -->
    <div class="flex flex-col w-full">
      <HeaderClients />
      <div class="flex-grow">
        <router-view></router-view>
      </div>
      <FooterClients />
    </div>
    <!-- End Content -->
  </div>
</template>
<script>
import Logo from '@/components/svg/logo'
import LogoSmall from '@/components/svg/logoSmall'
import HeaderClients from '@/components/empresas/HeaderClients'
import FooterClients from '@/components/empresas/FooterClients'

// Icons
import icnHome from '@/components/svg/icon/icnHome.vue'
import icnCogsSolid from '@/components/svg/icon/icnCogsSolid.vue'
import icnAddressBook from '@/components/svg/icon/icnAddressBook.vue'
import icnEnvelopeOpen from '@/components/svg/icon/icnEnvelopeOpen.vue'

export default {
  name: 'SidebarComponent',
  components: {
    Logo,
    LogoSmall,
    HeaderClients,
    FooterClients,
    icnHome,
    icnCogsSolid,
    icnAddressBook,
    icnEnvelopeOpen
  },
  data() {
    return {
      isAuthenticated: true,
      isShowingMenu: true,
      dimmer: true
    }
  },
  methods: {
    handleCloseMenu() {
      this.isShowingMenu = false
    },
    toggleLang(lang) {
      this.$root.$i18n.locale = lang
      const to = this.$router.resolve({ params: { locale: lang } })
      this.$router.push(to.fullPath)
    }
  }
}
</script>
