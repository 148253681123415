<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 69.6 22.8"
    class="fill-current"
  >
    <path
      d="M1.9.3 0 5.8l4.7 6.8-1.4 7.3L8 22.7c.3.2.7 0 .7-.3l2.2-10.6L19 10c.4-.1.6-.6.2-.9l-4-3.1-7 1.6L2.8.2c-.2-.3-.7-.2-.9.1zm27.7 10.9 1.7-4.1h2.5l-3.1 6.1v3.4h-2.3v-3.4l-3.1-6.1h2.5l1.8 4.1zm10.2 3.6h-3.1l-.5 1.8h-2.4l3.5-9.5h2.2l3.5 9.5h-2.5l-.7-1.8zM37.2 13h2l-1-3.3-1 3.3zm9.8-1.8 1.7-4.1h2.5l-3.1 6.1v3.4h-2.3v-3.4l-3.1-6.1h2.5l1.8 4.1zm8.4 3.6h4.5v1.8h-7.4v-1.2L57 8.8h-4.6V7.1h7.4v1.2l-4.4 6.5zm10-3.6 1.7-4.1h2.5l-3.1 6.1v3.4h-2.3v-3.4l-3.1-6.1h2.5l1.8 4.1z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoYayzy'
}
</script>
