import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { createApolloProvider } from '@vue/apollo-option'
import firebase from '@/services/firebase'
import { createGtm } from '@gtm-support/vue-gtm'
import LogRocket from 'logrocket'
import VueToast from 'vue-toast-notification'

import apolloClient from '@/graphql/apolloClient'

firebase.onAuthStateChanged((user) => {
  if (user) {
    LogRocket.identify(user.email)
  }
  store.dispatch('fetchUser', router)
})

export const app = createApp(App)

const apolloProvider = createApolloProvider({
  defaultClient: apolloClient
})

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(
    createGtm({
      id: 'GTM-NQ2J2BM',
      vueRouter: router
    })
  )
  .use(apolloProvider)
  .use(VueToast)
  .mount('#app')
