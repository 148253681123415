<template>
  <div class="collapse--simple--wrapper">
    <div class="collapse--header" @click="collapsed = !collapsed">
      <div class="collapse--icon" v-if="icon">
        <span>
          <img :src="require(`@/assets/images/${icon}`)" alt="Icon" />
        </span>
      </div>
      <div class="collapse--title">
        {{ title }}
      </div>
      <div
        class="collapse--simple--arrow"
        :class="{ active: collapsed == false }"
      >
        <img src="@/assets/images/icn-arrow.svg" alt="Arrow" />
      </div>
    </div>
    <transition name="fade" v-show="collapsed == false">
      <div class="collapse--body">
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String
  },
  name: 'Collapse',
  setup() {
    return {}
  },
  data() {
    return {
      collapsed: true
    }
  }
}
</script>
