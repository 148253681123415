<template>
  <teleport to="#modals">
    <!-- Modal Loading -->
    <transition name="fade">
      <Loading
        v-if="loading"
        class="fixed w-full h-full z-20 left-0 top-0 loading--dark"
      ></Loading>
    </transition>
    <!-- End Modal Loading -->
    <!-- Modal Sugerencias -->
    <transition name="fade">
      <ModalSugerencias
        size="xs"
        v-if="sugerenciasShow"
        @cerrarModal="sugerenciasShow = false"
      >
        <template #default>
          <Form @submit="sendSuggestion">
            <div class="modal--header">
              <!-- Modal Title -->
              <Title>
                <template #title>
                  <h3 v-if="enviado">
                    {{ $t('modal-sugerencias.title-enviado') }}
                  </h3>
                  <h3 v-else>{{ $t('modal-sugerencias.title') }}</h3>
                </template>
              </Title>
              <!-- End Modal Title -->
            </div>
            <div class="modal--body">
              <!-- Si fue enviado -->
              <div
                v-if="enviado"
                class="dark:text-primary-text text-secondary-text"
                v-html="$t('modal-sugerencias.texto-sugerencias-enviado')"
              ></div>
              <!-- Si NO fue enviado -->
              <div v-else>
                <div
                  class="dark:text-primary-text text-secondary-text"
                  v-html="$t('modal-sugerencias.texto-sugerencias')"
                ></div>
                <Field
                  type="text"
                  name="experienciaProfesionalDescription"
                  :rules="validateDefault"
                  v-slot="{ field }"
                  v-model="sugerenciaText"
                >
                  <textarea
                    class="form--element form--textarea w-full"
                    v-bind="field"
                    rows="5"
                    :placeholder="$t('modal-sugerencias.comentarios')"
                  ></textarea>
                </Field>
                <ErrorMessage
                  name="experienciaProfesionalDescription"
                  class="form--error"
                />
              </div>
            </div>
            <div class="modal--footer">
              <div class="flex space-x-4 justify-center">
                <!-- Si fue enviado -->
                <button
                  v-if="enviado"
                  type="button"
                  @click="
                    ;(sugerenciasShow = false),
                      (enviado = false),
                      (sugerenciaText = '')
                  "
                  class="btn btn-primary btn-rounded-lg btn-lg"
                  role="button"
                  aria-pressed="false"
                >
                  {{ $t('modal-sugerencias.btn-cerrar') }}
                </button>
                <!-- Si No fue enviado -->
                <button
                  v-else
                  type="submit"
                  class="btn btn-primary btn-rounded-lg btn-lg"
                  role="button"
                  aria-pressed="false"
                >
                  {{ $t('modal-sugerencias.btn-sugerencias') }}
                </button>
              </div>
            </div>
          </Form>
        </template>
      </ModalSugerencias>
    </transition>
    <!-- End Modal Sugerencias -->
    <!-- Modal Registro Evento -->
    <ModalRegistroEvento
      title="Title del Evento"
      image="img-cody-calidad.svg"
      place="Live Streaming"
      language="Español"
      ticket="Gratis"
      date="26 oct 20201"
      time="10:00 PM (GTM -3)"
      :badges="['javascript', 'vue.js']"
      v-if="modalRegistroEventoShow"
      @cerrarModal="modalRegistroEventoShow = false"
    />
  </teleport>
  <!-- End Modal Registro Evento -->
  <!-- Header -->
  <Header />
  <!-- End Header -->
  <slot></slot>
  <!-- Footer -->
  <Footer @evento="sugerenciasShow = true" />
  <!-- End Footer -->
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ModalSugerencias from '@/components/Modal'
import ModalRegistroEvento from '@/components/ModalRegistroEvento'
import Title from '@/components/Title'
import Loading from '@/components/Loading'

import ApolloClient from '@/graphql/apolloClient'
import SendSuggestion from '@/graphql/mutations/sendSuggestion'
import { mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'

import { validateDefault } from '@/services/validationRules'

export default {
  name: 'DefaultLayout',
  components: {
    Header,
    Footer,
    ModalSugerencias,
    Title,
    ModalRegistroEvento,
    Loading,
    Form,
    Field,
    ErrorMessage
  },
  data: function () {
    return {
      sugerenciasShow: false,
      enviado: false,
      modalRegistroEventoShow: false,
      sugerenciaText: ''
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  methods: {
    sendSuggestion: async function () {
      if (this.$data.sugerenciaText !== '') {
        const result = await ApolloClient.mutate({
          mutation: SendSuggestion,
          variables: {
            suggestion: this.$data.sugerenciaText
          }
        })
        if (result.data.saveSuggestion) {
          this.$data.enviado = true
        }
      }
    },
    // RULES
    validateDefault
  }
}
</script>
