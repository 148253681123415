<template>
  <!-- <div class="grid grid-cols-3 flex" v-for="pool in poolData" :key="pool.role">
    <CardPool :data="pool" />
  </div> -->
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
    <template v-for="pool in poolData" :key="pool.role">
      <CardPool :data="pool" />
    </template>
  </div>
</template>
<script>
import CardPool from '@/components/empresas/CardPool.vue'
export default {
  name: 'Pool',
  components: {
    CardPool
  },
  data() {
    return {
      poolData: [
        {
          role: 'React Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 8
        },
        {
          role: 'Frontend Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 8
        },
        {
          role: 'Backend Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 11
        },
        {
          role: 'Frontend Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 10
        },
        {
          role: 'Mobile Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 6
        },
        {
          role: 'Devops Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 4
        },
        {
          role: 'Angular Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 5
        },
        {
          role: 'Android Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 3
        },
        {
          role: 'Node Senior',
          position: 'Remoto',
          english: 'Nativo',
          salary: { min: '3000', max: '6000', currency: 'USD' },
          techStack: ['React', 'CSS'],
          candidates: 7
        }
      ]
    }
  }
}
</script>
