<template>
  <main class="main--wrapper pt-32 pb-8 min-h-screen flex items-center">
    <div class="content--wrapper flex justify-center">
      <CardPage class="w-full lg:w-6/12 xl:w-6/12" v-if="users">
        <template #default>
          <!-- Title -->
          <div class="card--page--header">
            <Title>
              <template #title>
                <h4>
                  {{ $t('admin-dashboard-welcome.title') }}
                </h4>
              </template>
            </Title>
          </div>
          <!-- End Title -->
          <!-- Body -->
          <div class="card--page--body">
            <table
              class="
                table--default table--hover table-fixed
                w-full
                dark:text-primary-text
                text-secondary-text
              "
            >
              <tbody>
                <tr>
                  <td class="dark:bg-main-dark bg-main-light font-bold">
                    {{ $t('admin-dashboard-welcome.table-title-1') }}
                  </td>
                  <td>{{ users.totalCandidates }}</td>
                </tr>
                <tr>
                  <td class="dark:bg-main-dark bg-main-light font-bold">
                    <span class="flex items-center">
                      <span
                        class="
                          w-3
                          h-3
                          rounded-full
                          bg-secondary
                          inline-block
                          mr-2
                        "
                      ></span>
                      {{ $t('admin-dashboard-welcome.table-title-2') }}
                    </span>
                  </td>
                  <td>{{ users.prevettedCandidates }}</td>
                </tr>
                <tr>
                  <td class="dark:bg-main-dark bg-main-light font-bold">
                    <span class="flex items-center">
                      <span
                        class="w-3 h-3 rounded-full bg-badges inline-block mr-2"
                      ></span>
                      {{ $t('admin-dashboard-welcome.table-title-3') }}
                    </span>
                  </td>
                  <td>
                    {{ users.totalCandidates - users.prevettedCandidates }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- End Body -->
          <!-- Footer -->
          <div class="card--page--footer flex justify-center">
            <router-link
              class="
                btn btn-lg btn-primary
                dark:rounded-lg
                rounded-full
                shadow-lg
              "
              :to="{ name: 'AdminCandidates' }"
            >
              {{ $t('admin-dashboard-welcome.btn') }}
            </router-link>
          </div>
          <!-- End Footer-->
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import ApolloClient from '@/graphql/apolloClient'
import GetUsers from '@/graphql/queries/welcomeUsers'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'AdminWelcome',
  components: {
    CardPage,
    Title
  },
  data() {
    return {
      users: null
    }
  },
  async mounted() {
    await this.fetchUsers()
  },
  computed: {
    ...mapGetters(['token']),
    numberUsersPreVetted() {
      if (this.users !== null) {
        return this.users.filter((user) => user.status === 'pre-vetted')
      }
      return false
    },
    numberUsersNotVetted() {
      if (this.users !== null) {
        return this.users.filter((user) => user.status !== 'pre-vetted')
      }
      return false
    }
  },
  methods: {
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    fetchUsers: async function () {
      this.setLoadingOn()
      try {
        const getUsers = await ApolloClient.query({
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: GetUsers
        })
        this.users = getUsers.data.dashboard
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e.message, 'error')
      }
    }
  }
}
</script>
