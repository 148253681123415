<template>
  <main class="main--wrapper">
    <!-- Section Hero -->
    <section class="relative pt-32 pb-14 lg:py-40">
      <div class="absolute bottom-0 lg:top-0 right-0 w-9/12 lg:w-6/12">
        <img
          src="@/assets/images/shape-01.svg"
          class="w-auto lg:h-full ml-auto"
          alt="Utopicode"
        />
      </div>
      <div
        class="
          content--wrapper
          flex
          lg:justify-between
          flex-wrap
          relative
          items-center
        "
      >
        <div class="w-full lg:w-6/12 mb-12 lg:mb-0">
          <h2 class="dark:text-white font-bold mb-2 lg:mb-3 title--h2">
            {{ $t('trabajos-page.title') }}
          </h2>
          <p class="text-main text-xl mb-8">
            {{ $t('trabajos-page.description') }}
          </p>
          <div
            class="
              flex flex-wrap
              lg:flex-nowrap
              space-y-6
              lg:space-x-6 lg:space-y-0
            "
          >
            <router-link
              class="btn btn-xl btn-primary btn-rounded-lg w-full lg:w-auto"
              :to="{ name: 'SignUp' }"
              :title="$t('contrata-devs-page.hero-btn-trabajo')"
              >{{ $t('trabajos-tech-page.btn-comienza-aqui') }}</router-link
            >
            <router-link
              :to="{ name: 'Contrata' }"
              :title="$t('contrata-devs-page.hero-btn-contrata')"
              class="
                btn btn-xl
                border-2
                btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg
                w-full
                lg:w-auto
              "
              >{{ $t('contrata-devs-page.hero-btn-contrata') }}</router-link
            >
          </div>
        </div>
        <div class="w-full lg:w-6/12">
          <img
            src="@/assets/images/img-devs.svg"
            class="shadow-svg-xl mx-auto"
            alt="Utopicode"
          />
        </div>
      </div>
    </section>
    <!-- End Section Hero -->
    <!-- Section Empresas -->
    <section class="mb-24 lg:mb-40 mt-24">
      <div class="content--wrapper">
        <LogosGrid :title="$t('trabajos-tech-page.text-empresas-trabajar')" />
      </div>
    </section>
    <!-- End Section Empresas -->
    <section class="relative py-24 mb-6">
      <div class="absolute bottom-0 lg:top-0 left-0 w-9/12 lg:w-6/12">
        <img
          src="@/assets/images/shape-02.svg"
          class="w-auto mr-auto lg:h-full"
          alt="Utopicode"
        />
      </div>
      <div
        class="
          content--wrapper
          flex
          lg:justify-between
          flex-wrap
          relative
          items-center
          lg:flex-row-reverse
        "
      >
        <div class="w-full lg:w-5/12 mb-12 lg:mb-0">
          <h2
            class="dark:text-white font-bold mb-4 lg:mb-8 title--h3"
            v-html="$t('trabajos-tech-page.section-completa-aplicacion-title')"
          ></h2>
          <ol class="list--education list--education--primary mb-8">
            <li
              v-for="(number, index) in 4"
              :key="index"
              :class="index + 1 === 4 ? 'last' : ''"
            >
              <span class="list--education--bullets">0{{ index + 1 }}</span>
              <h4 class="dark:text-white font-bold title--h6 mb-2">
                {{ $t(`trabajos-tech-page.list-title-${index + 1}`) }}
              </h4>
              <p class="text-main text-lg">
                {{ $t(`trabajos-tech-page.list-description-${index + 1}`) }}
              </p>
            </li>
          </ol>
          <p class="text-center">
            <router-link
              :to="{ name: 'SignUp' }"
              class="
                text-primary
                font-bold
                text-2xl
                btn btn-icon btn-icon-primary
              "
            >
              <span class="underline">
                {{ $t('trabajos-tech-page.btn-comienza-aqui') }}
              </span>
              <svg
                width="52"
                height="16"
                viewBox="0 0 52 16"
                fill="fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.7071 8.70711C52.0976 8.31658 52.0976 7.68342 51.7071 7.29289L45.3431 0.928932C44.9526 0.538408 44.3195 0.538408 43.9289 0.928932C43.5384 1.31946 43.5384 1.95262 43.9289 2.34315L49.5858 8L43.9289 13.6569C43.5384 14.0474 43.5384 14.6805 43.9289 15.0711C44.3195 15.4616 44.9526 15.4616 45.3431 15.0711L51.7071 8.70711ZM0 9H51V7H0V9Z"
                />
              </svg>
            </router-link>
          </p>
        </div>
        <div class="w-full lg:w-5/12">
          <img
            src="@/assets/images/img-cody-connection.svg"
            class="shadow-svg-xl mx-auto"
            alt="Utopicode"
          />
        </div>
      </div>
    </section>
    <!-- Section Blockquote -->
    <section class="relative pt-24 lg:pb-24">
      <div class="content--wrapper">
        <div class="w-full lg:w-8/12 mx-auto">
          <splide class="splide--default" :options="options">
            <splide-slide class="lg:px-40">
              <Blockquote
                class="lg:mt-14 mt-9"
                :cite="$t('trabajos-tech-page.testimonial-1')"
                author="Antonio Lozano"
                companyLogo="clients/logo-siclo.svg"
              />
            </splide-slide>
            <splide-slide class="lg:px-40">
              <Blockquote
                class="lg:mt-14 mt-9"
                :cite="$t('trabajos-tech-page.testimonial-2')"
                author="Emmanuel Reyes"
                companyLogo="clients/logo-perea-clinic.svg"
              />
            </splide-slide>
            <splide-slide class="lg:px-40">
              <Blockquote
                class="lg:mt-14 mt-9"
                :cite="$t('trabajos-tech-page.testimonial-3')"
                author="Ivan Yael Garcia Siclo"
                companyLogo="clients/logo-siclo.svg"
              />
            </splide-slide>
          </splide>
        </div>
      </div>
    </section>
    <!-- End Section Blockquote -->
    <!-- Section Pool de Talento -->
    <section class="relative py-24 mb-6">
      <div class="absolute bottom-0 lg:top-0 right-0 w-9/12 lg:w-6/12">
        <img
          src="@/assets/images/shape-02.svg"
          class="w-auto ml-auto lg:h-full flip-horizontal"
          alt="Utopicode"
        />
      </div>
      <div
        class="
          content--wrapper
          flex
          lg:justify-between
          flex-wrap
          relative
          items-center
        "
      >
        <div class="w-full lg:w-5/12 mb-12 lg:mb-0">
          <h2
            class="dark:text-white font-bold mb-2 lg:mb-3 title--h3"
            v-html="
              $t('trabajos-tech-page.section-oportunidades-laborales-title')
            "
          ></h2>
          <p class="text-main text-lg mb-8">
            {{ $t('trabajos-tech-page.text-oportunidades-laborales') }}
          </p>
          <ul
            class="list--bullet list--bullet--secondary mb-8 text-main text-lg"
          >
            <li v-for="(oportunidades, index) in 7" :key="index">
              {{
                $t(
                  `trabajos-tech-page.list-oportunidades-laborales-${index + 1}`
                )
              }}
            </li>
          </ul>
        </div>
        <div class="w-full lg:w-6/12">
          <img
            src="@/assets/images/img-cody-oportunidades.svg"
            class="shadow-svg-xl mx-auto"
            alt="Utopicode"
          />
        </div>
      </div>
    </section>
    <!-- End Section Pool de Talento -->
    <!-- Section Oportunidades a tu Alcance -->
    <section class="relative pt-24">
      <div class="content--wrapper">
        <h2
          class="dark:text-white font-bold mb-2 lg:mb-3 title--h3 text-center"
          v-html="$t('trabajos-tech-page.section-oportunidades-alcance-title')"
        ></h2>
        <div class="grid grid-cols-1 gap-6">
          <CardTrabajo
            v-for="job in jobs"
            :key="job.id"
            v-bind:jobData="job"
            horizontal
          />
        </div>
        <p class="text-center">
          <router-link
            :to="{ name: 'SignUp' }"
            class="
              text-primary
              font-bold
              text-2xl
              btn btn-icon btn-icon-primary
            "
          >
            <span class="underline">
              {{ $t('trabajos-tech-page.btn-comienza-aqui') }}
            </span>
            <svg
              width="52"
              height="16"
              viewBox="0 0 52 16"
              fill="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M51.7071 8.70711C52.0976 8.31658 52.0976 7.68342 51.7071 7.29289L45.3431 0.928932C44.9526 0.538408 44.3195 0.538408 43.9289 0.928932C43.5384 1.31946 43.5384 1.95262 43.9289 2.34315L49.5858 8L43.9289 13.6569C43.5384 14.0474 43.5384 14.6805 43.9289 15.0711C44.3195 15.4616 44.9526 15.4616 45.3431 15.0711L51.7071 8.70711ZM0 9H51V7H0V9Z"
              />
            </svg>
          </router-link>
        </p>
      </div>
    </section>
    <!-- End Section Oportunidades a tu Alcance -->
  </main>
</template>

<script>
import CardTrabajo from '@/components/CardTrabajo'
import ApolloClient from '../graphql/apolloClient'
import JobsQuery from '../graphql/queries/jobs'
import Blockquote from '@/components/Blockquote'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import ROUTES from '../constants/routes'
import { toastNotification } from '@/services/toastNotification'
import LogosGrid from '@/components/LogosGrid'
export default {
  components: {
    CardTrabajo,
    Blockquote,
    Splide,
    SplideSlide,
    LogosGrid
  },
  data() {
    return {
      jobs: [],
      options: {
        rewind: true,
        perPage: 1,
        type: 'loop',
        pagination: false,
        autoplay: true,
        pauseOnHover: false,
        breakpoints: {
          1024: {
            pagination: true,
            arrows: false
          }
        }
      },
      ROUTES
    }
  },
  mounted() {
    // this.getJobs()
  },
  methods: {
    getJobs: async function () {
      try {
        const recentJobs = await ApolloClient.query({
          variables: {
            limit: 50
          },
          query: JobsQuery
        })
        this.jobs = recentJobs.data.jobs
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
