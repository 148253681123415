<template>
  <!-- <ModalProfileTutorial
    v-if="modalBienvenidaShow === 1"
    @cerrarModal="modalBienvenidaShow = 0"
    @handleFooterButton="modalBienvenidaShow = 0"
  /> -->
  <ModalBienvenida
    v-if="modalBienvenidaShow"
    :userName="userProfile.fullname"
    @cerrarModal="modalBienvenidaShow = 0"
    @handleFooterButton="modalBienvenidaShow = 0"
  />
  <main class="main--wrapper py-24 lg:py-32" v-if="userProfile">
    <div class="content--wrapper mb-4">
      <div
        class="
          w-full
          lg:w-8/12
          flex flex-wrap
          lg:space-x-8
          space-y-4
          lg:flex-nowrap lg:space-y-0 lg:pr-6
          items-center
        "
      >
        <div class="w-full lg:w-9/12 flex items-center flex-wrap md:space-x-4">
          <h5 class="dark:text-primary-text text-secondary-text font-xl">
            {{ $t('profile-page.title-complete-profile') }}
          </h5>
          <ProgressBar
            class="w-full md:w-7/12"
            :porcentaje="completionPercent"
          />
        </div>
        <!--<div class="w-full lg:w-3/12">
          <button
            type="button"
            role="button"
            aria-label=""
            class="btn btn-md btn-primary btn-rounded-lg w-full"
          >
            {{ $t('profile-page.btn-importar-linkedIn') }}
          </button>
        </div>-->
      </div>
    </div>
    <div
      class="
        content--wrapper
        flex flex-wrap
        lg:space-x-8
        space-y-8
        lg:flex-nowrap lg:space-y-0
      "
    >
      <div class="w-full lg:w-8/12">
        <!-- Card Page Sign Up -->
        <CardPage>
          <template #default>
            <!-- Body -->
            <div class="card--page--body pt-8">
              <!-- Section Basic Information -->
              <BasicInformationSection :user="user" />
              <!-- End Section Basic Information -->
              <!-- Section Social Media -->
              <SocialMediaSection
                :socialNetworks="userProfile.socialNetworks"
              />
              <!-- End Section Social Media -->
              <!-- Section Mis Badges -->
              <MisBadgesSection :userBadges="userProfile" />
              <!-- End Section Mis Badges -->
              <!-- Section Un poco sobre Mi -->
              <AboutMeSection :userAbout="userProfile.about" />
              <!-- End Section Un poco sobre Mi -->
              <!-- Section Tecnologias -->
              <TecnologiasSection
                :userTechnologies="userProfile.technologies"
              />
              <!-- End Section Tecnologias -->
              <!-- Section Experiencia Profesional -->
              <ExperienciaProfesionalSection
                :userPastJobs="userProfile.pastJobs"
              />
              <!-- End Section Experiencia Profesional -->
              <!-- Section Educación Formal -->
              <EducacionFormalSection
                :userFormalEducation="userProfile.formalEducation"
              />
              <!-- Section Educación Formal -->
              <!-- Section Certificaciones y Cursos -->
              <CertificacionesCursosSection
                :userCertsAndCourses="userProfile.certsAndCourses"
              />
              <!-- Section Certificaciones y Cursos -->
              <!-- Section Soft Skill -->
              <SoftSkillSection :userSoftSkill="userProfile.takenAssesments" />
              <!-- End Section Soft Skill -->
              <!-- Section Hard Skill -->
              <HardSkillSection :userHardSkill="userProfile.takenAssesments" />
              <!-- End Section Hard Skill -->
            </div>
            <!-- End Body -->
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
      <!-- SIDEBAR -->
      <aside class="w-full lg:w-4/12 space-y-8">
        <!-- Estado de Empleo -->
        <BoxExperiencia :user="userProfile" />
        <BoxEstadoDeEmpleo :user="userProfile" />
        <BoxPreferenciasPersonales
          :industries="userProfile.industries"
          :idealCompany="userProfile.idealCompany"
        />
        <!-- End Estado de Empleo -->
      </aside>
      <!-- END SIDEBAR -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import BoxEstadoDeEmpleo from '@/components/profile/BoxEstadoDeEmpleo'
import BoxExperiencia from '@/components/profile/BoxExperiencia'
import BoxPreferenciasPersonales from '@/components/profile/BoxPreferenciasPersonales'
import ProgressBar from '@/components/ProgressBar'
import BasicInformationSection from '@/components/profile/BasicInformationSection'
import SocialMediaSection from '@/components/profile/SocialMediaSection'
import MisBadgesSection from '@/components/profile/MisBadgesSection'
import AboutMeSection from '@/components/profile/AboutMeSection'
import TecnologiasSection from '@/components/profile/TecnologiasSection'
import SoftSkillSection from '@/components/profile/SoftSkillSection'
import HardSkillSection from '@/components/profile/HardSkillSection'
import ExperienciaProfesionalSection from '@/components/profile/ExperienciaProfesionalSection'
import EducacionFormalSection from '@/components/profile/EducacionFormalSection'
import CertificacionesCursosSection from '@/components/profile/CertificacionesCursosSection'
// import ModalProfileTutorial from '@/components/ModalProfileTutorial'
import ModalBienvenida from '@/components/ModalBienvenida'

import ApolloClient from '@/graphql/apolloClient'
import assesmentCategoriesQuery from '@/graphql/queries/assesmentCategories'
import userProfileQuery from '@/graphql/queries/userProfile'
import { mapGetters, mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'Profile',
  data() {
    return {
      userProfile: null,
      modalBienvenidaShow: false,
      assesmentCategories: []
    }
  },
  components: {
    CardPage,
    BoxEstadoDeEmpleo,
    BoxExperiencia,
    BoxPreferenciasPersonales,
    ProgressBar,
    BasicInformationSection,
    SocialMediaSection,
    MisBadgesSection,
    AboutMeSection,
    TecnologiasSection,
    SoftSkillSection,
    HardSkillSection,
    ExperienciaProfesionalSection,
    EducacionFormalSection,
    CertificacionesCursosSection,
    ModalBienvenida
  },
  updated() {
    this.getAssesmentCategories()
  },
  mounted() {
    this.setLoadingOn()
    this.getUserProfile()
    this.getAssesmentCategories()
  },
  watch: {
    modalBienvenidaShow(newName) {
      localStorage.modalBienvenidaShow = newName
    }
  },
  computed: {
    ...mapGetters(['token', 'user']),
    completionPercent: function () {
      const userObject = this.userProfile
      const value = Object.keys(this.userProfile).reduce((acc, val) => {
        const realValue = userObject[val]
        if (realValue !== null && realValue !== '') {
          if (val === 'takenAssesments') {
            if (realValue.length === 4) {
              return acc + 1
            } else return acc
          }
          if (Array.isArray(realValue)) {
            return realValue.length > 0 ? acc + 1 : acc
          }
          return acc + 1
        } else {
          return acc
        }
      }, 0)
      return Math.round((value * 100) / Object.values(this.userProfile).length)
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff']),
    showTutorialModal: function () {
      // Comprobar si ya se ha mostrado el modal antes y almacenarlo en localStorage
      if (localStorage.getItem('modalBienvenidaShown')) {
        // Si el modal ya se mostró anteriormente, asegurar que no se muestre de nuevo
        this.modalBienvenidaShow = false
      } else {
        // Verificar si el perfil del usuario tiene datos que indicarían que no es su primera visita
        if (
          this.userProfile.englishLevel === null &&
          this.userProfile.idealCompany.length === 0 &&
          this.userProfile.idealJobType === null &&
          this.userProfile.industries.length === 0 &&
          this.userProfile.jobStatus === null &&
          this.userProfile.minSalary === null &&
          this.userProfile.personsInCharge === null
        ) {
          // Mostrar el modal solo si es evidente que el usuario es nuevo y no ha configurado su perfil
          this.modalBienvenidaShow = true
          // Almacenar en localStorage que el modal ya se ha mostrado para no repetir en futuras sesiones
          localStorage.setItem('modalBienvenidaShown', 'true')
        } else {
          // No mostrar el modal si el perfil del usuario ya está parcialmente o totalmente lleno
          this.modalBienvenidaShow = false
        }
      }
    },

    getAssesmentCategories: async function () {
      try {
        const habilitiesQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentCategoriesQuery
        })
        this.assesmentCategories = habilitiesQuery.data.assesmentCategories
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserProfile: async function () {
      try {
        const userProfileData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userProfileQuery
        })
        this.userProfile = userProfileData.data.userProfile
        this.showTutorialModal()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    onModalClose() {
      this.modalBienvenidaShow = false
    }
  }
}
</script>
