<template>
  <!-- Abstract Images -->
  <div
    class="
      fixed
      transform
      -translate-x-1/4 -translate-y-1/4
      left-0
      top-0
      rotate-180
    "
  >
    <img
      class="flip-horizontal"
      src="@/assets/images/shape-02.svg"
      alt="Not Found"
    />
  </div>
  <div class="fixed transform translate-x-1/4 translate-y-1/4 right-0 bottom-0">
    <img
      class="flip-horizontal"
      src="@/assets/images/shape-02.svg"
      alt="Not Found"
    />
  </div>
  <main class="py-10 min-h-screen justify-center items-center flex">
    <div
      class="
        max-w-screen-xl
        mx-auto
        px-4
        w-full
        flex flex-row
        py-0
        relative
        z-10
      "
    >
      <div
        class="
          grid grid-cols-1
          lg:grid-cols-2 lg:gap-x-6
          gap-y-4
          lg:gap-y-0
          items-center
        "
      >
        <div class="mb-6 lg:mb-0">
          <div class="flex">
            <span class="badge badge-rounded-full badge-sm badge-secondary mb-3"
              >Error 404</span
            >
          </div>
          <h2
            class="
              dark:text-white
              text-contrast text-4xl
              lg:text-7xl
              font-bold
              mb-2
            "
          >
            {{ $t('404-page.title') }}
          </h2>
          <p
            class="
              dark:text-primary-text
              text-secondary-text
              lg:text-lg
              text-base
            "
          >
            {{ $t('404-page.description') }}
          </p>
          <button
            @click="$router.back()"
            class="btn btn-xl btn-primary btn-rounded-lg"
          >
            {{ $t('general.volver') }}
          </button>
        </div>
        <div>
          <img src="@/assets/images/icn-cody-working.svg" alt="Cody Working" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'PageNotFound'
}
</script>
