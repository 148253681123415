import gql from 'graphql-tag'

const savePersonalPreferences = gql`
  mutation savePersonalPreferences(
    $industries: [String]
    $idealCompany: [String]
    $id: Int
  ) {
    updatePersonalPreferences(
      industries: $industries
      idealCompany: $idealCompany
      id: $id
    ) {
      id
      idealCompany
      industries
    }
  }
`

export default savePersonalPreferences
