<template>
  <div class="content--wrapper m-6">
    <CardPage class="mb-6">
      <template #default>
        <div>
          <div class="card--page--header">
            <Title>
              <template #title>
                <h3>
                  {{ $t('profile-candidate.lbl-overview') }}
                </h3>
              </template>
            </Title>
          </div>
          <div class="card--page--body">
            <!-- Filters -->
            <!-- <form
                class="
                  mt-4
                  mb-8
                  grid
                  gap-x-6 gap-y-4
                  lg:grid-cols-5
                  md:grid-cols-3
                  grid-cols-1
                  items-start
                "
              >
                <input
                  class="form--filter"
                  :placeholder="
                    $t('admin-dashboard-candidatos.form-buscar-email')
                  "
                  v-model="filter.email"
                  id="email"
                />
                <input
                  class="form--filter"
                  id="nombre"
                  v-model="filter.nombre"
                  :placeholder="
                    $t('admin-dashboard-candidatos.form-buscar-nombre')
                  "
                /> -->
            <!-- Especialidad -->
            <!-- <Multiselect
                  class="multiselect-filter"
                  :options="especialidadLista"
                  v-model.lazy="filter.especialidad"
                  mode="multiple"
                  :placeholder="
                    $t('admin-dashboard-candidatos.form-buscar-especialidad')
                  "
                  searchable
                  :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                  :noResultsText="$t('contrata-devs-page.sin-resultados')"
                /> -->
            <!-- End Especialidad -->
            <!-- Tecnologias -->
            <!-- <Multiselect
                  class="multiselect-filter"
                  :options="tecnologiasLista"
                  v-model.lazy="filter.tecnologias"
                  mode="multiple"
                  :placeholder="
                    $t('admin-dashboard-candidatos.form-buscar-tecnologias')
                  "
                  searchable
                  :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                  :noResultsText="$t('contrata-devs-page.sin-resultados')"
                /> -->
            <!-- End Tecnologias -->
            <!-- <select
                  class="form--filter form--select"
                  v-model="filter.status"
                >
                  <option value="" selected>
                    {{ $t('admin-dashboard-candidatos.form-buscar-status') }}
                  </option>
                  <option
                    :value="level"
                    v-for="(level, index) in status"
                    :key="index"
                  >
                    {{ level }}
                  </option>
                </select>
                <select class="form--filter form--select" v-model="filter.pais">
                  <option value="" selected>
                    {{ $t('admin-dashboard-candidatos.form-buscar-pais') }}
                  </option>
                  <option
                    :value="pais"
                    v-for="pais in paisesLista[$i18n.locale]"
                    :key="pais"
                  >
                    {{ pais }}
                  </option>
                </select>
                <input
                  class="form--filter"
                  v-model="filter.experiencia"
                  :placeholder="
                    $t('admin-dashboard-candidatos.form-buscar-experiencia')
                  "
                  type="number"
                  min="0"
                />
                <div class="form--filter lg:col-span-2 flex items-center">
                  <span class="mr-2 whitespace-nowrap">{{
                    $t('admin-dashboard-candidatos.form-buscar-salarial')
                  }}</span>
                  <input
                    v-model="filter.minSalarial"
                    :max="filter.maxSalarial"
                    class="-mr-1 w-1/2"
                    type="range"
                    step="100"
                  />
                  <input
                    v-model="filter.maxSalarial"
                    :min="filter.minSalarial"
                    max="50000"
                    class="mr-2 w-1/2"
                    type="range"
                    step="100"
                  />
                  <span class="text-xs whitespace-nowrap"
                    >{{ filter.minSalarial }} USD a {{ filter.maxSalarial }} USD
                    {{
                      $t('admin-dashboard-candidatos.lbl-netos-mensuales')
                    }}</span
                  >
                </div>
                <button
                  type="button"
                  @click="resetFilter()"
                  class="btn btn-rounded-lg btn-lg btn-secondary"
                >
                  {{ $t('general.resetear') }}
                </button>
              </form> -->
            <!-- End Filters -->
            <!-- Table -->
            <SearchTable />
            <!-- End Table -->
            <!-- <Pagination
                class="justify-end"
                :totalPages="totalPages"
                :currentPage="currentPage"
              /> -->
          </div>
        </div>
      </template>
    </CardPage>
    <CardPage class="mb-6">
      <template #default>
        <div>
          <div class="card--page--header">
            <Title>
              <template #title>
                <h3>
                  {{ $t('profile-candidate.lbl-overview') }}
                </h3>
              </template>
            </Title>
          </div>
          <div class="card--page--body flex gap-4">
            <CardKanban class="w-1/4 h-96" v-for="step in steps" :key="step">
              <div class="card--kanban overflow-auto dark:text-white">
                <div
                  class="
                    card--kanban--header
                    sticky
                    top-0
                    dark:bg-main-dark
                    bg-main-light
                    flex flex-auto
                    justify-center
                  "
                >
                  <h4 class="font-bold">{{ step.name }}</h4>
                  <button class="justify-end">
                    <img src="@/assets/images/icn-ellipsis.svg" />
                  </button>
                </div>
                <div class="card--kanban--body">
                  <draggable
                    class=""
                    :list="step.candidates"
                    group="people"
                    @change="log"
                    itemKey="name"
                  >
                    <template #item="{ element }">
                      <CardCandidate :candidate="element" />
                    </template>
                  </draggable>
                </div>
              </div>
            </CardKanban>
          </div>
        </div>
      </template>
    </CardPage>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import SearchTable from '@/components/empresas/SearchTable'
import CardKanban from '@/components/empresas/CardKanban'
import CardCandidate from '@/components/empresas/CardCandidate'
// import Pagination from '@/components/Pagination'

export default {
  name: 'DashboardKanban',
  components: {
    draggable,
    CardPage,
    Title,
    CardKanban,
    CardCandidate,
    SearchTable
  },
  data() {
    return {
      list1: [
        { name: 'John', id: 1 },
        { name: 'Joao', id: 2 },
        { name: 'Jean', id: 3 },
        { name: 'Gerard', id: 4 }
      ],
      list2: [
        { name: 'Juan', id: 5 },
        { name: 'Edgard', id: 6 },
        { name: 'Johnson', id: 7 }
      ],
      steps: [
        {
          name: 'Pre-Selection',
          candidates: [
            {
              name: 'Jaimito Hernandez',
              speciality: 'Frontend',
              salary: { min: '3000', max: '6000', currency: 'USD' },
              techStack: ['React', 'CSS', 'Vue.js'],
              experience: 5
            },
            {
              name: 'Ezequiel Cordera',
              speciality: 'Frontend',
              salary: { min: '3000', max: '6000', currency: 'USD' },
              techStack: ['React', 'CSS', 'Vue.js'],
              experience: 5
            },
            {
              name: 'Felipe Luis',
              speciality: 'Frontend',
              salary: { min: '3000', max: '6000', currency: 'USD' },
              techStack: ['React', 'CSS', 'Vue.js'],
              experience: 5
            },
            {
              name: 'Juanita Perez',
              speciality: 'Frontend',
              salary: { min: '3000', max: '6000', currency: 'USD' },
              techStack: ['React', 'CSS', 'Vue.js'],
              experience: 5
            }
          ]
        },
        { name: 'Selection', candidates: [] },
        { name: 'Entrevista', candidates: [] },
        { name: 'Challenge', candidates: [] }
      ],
      totalPages: 2,
      currentPage: 0
    }
  },
  methods: {
    add: function () {
      this.list.push({ name: 'Juan' })
    },
    replace: function () {
      this.list = [{ name: 'Edgard' }]
    },
    clone: function (el) {
      return {
        name: el.name + ' cloned'
      }
    },
    log: function (evt) {
      window.console.log(evt)
    }
  }
}
</script>
