<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('cursos-page.title')"
      :hero-description="$t('cursos-page.description')"
      section="pageHeroCta"
      :hero-image="require('@/assets/images/hero/bg-comunidad.svg')"
      class="hero--lg"
    >
      <template v-slot:pageHeroCta>
        <div class="flex">
          <router-link
            :to="{ name: 'CursosAll' }"
            class="btn btn-lg btn-primary btn-rounded-lg"
          >
            {{ $t('welcome-profile-page.cta-courses-you') }}
          </router-link>
        </div>
      </template>
    </Hero>
    <div class="content--wrapper">
      <!-- Filter -->
      <section
        class="
          mt-4
          mb-16
          grid
          gap-x-6 gap-y-4
          lg:grid-cols-4
          md:grid-cols-3
          grid-cols-1
        "
      >
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <button type="button" class="btn btn-rounded-lg btn-lg btn-secondary">
          {{ $t('general.buscar') }}
        </button>
      </section>
      <!-- End Filter -->
      <!-- Sección Comunidad -->
      <section class="content--comunidad mb-10">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('cursos-page.title-junior') }}</h2>
          </template>
        </Title>
      </section>
    </div>
    <splide class="splide--default mb-10 lg:mb-24" :options="optionsV1">
      <splide-slide
        class="w-full mx-3"
        v-for="conference in courses"
        v-bind:key="conference.id"
        v-bind:conferenceData="conference"
      >
        <CardComunidad v-if="courses.length > 0" :conferenceData="conference" />
      </splide-slide>
    </splide>
    <div class="content--wrapper">
      <!-- End Sección Comunidad -->
      <!-- Sección Evalúa tus Habilidades -->
      <section class="content--habilidades mb-10">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('cursos-page.title-mid') }}</h2>
          </template>
        </Title>
      </section>
    </div>
    <splide class="splide--default mb-10" :options="optionsV2">
      <splide-slide
        class="w-full mx-3"
        v-for="conference in courses"
        v-bind:key="conference.id"
        v-bind:conferenceData="conference"
      >
        <CardComunidad v-if="courses.length > 0" :conferenceData="conference" />
      </splide-slide>
    </splide>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardComunidad from '@/components/CardComunidad'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import ApolloClient from '../graphql/apolloClient'
import EventsQuery from '../graphql/queries/events'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  components: {
    Hero,
    Title,
    CardComunidad,
    Splide,
    SplideSlide
  },
  data: function () {
    return {
      conferences: [],
      pastConferences: [],
      optionsV1: {
        rewind: true,
        perPage: 4,
        type: 'loop',
        pagination: true,
        autoplay: true,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      },
      optionsV2: {
        rewind: true,
        perPage: 4,
        type: 'loop',
        pagination: true,
        autoplay: true,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      },
      courses: [
        {
          id: 1,
          category: 'Javascript',
          name: 'New Emotional Intelligence and How to Develop It',
          url: 'http://www.google.com.ar',
          photo:
            'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
          subCategories: ['react', 'vue'],
          duration: '2 Weeks',
          description:
            'Elementum pulvinar rutrum amet est consectetur libero ut nisi. Ultrices in elit eu odio. Nisi rutrum vulputate et vestibulum ornare cras ut.'
        },
        {
          id: 2,
          category: 'Python',
          name: 'What’s Happening with AI Today?',
          url: 'http://www.google.com.ar',
          photo:
            'https://www.sciencenewsforstudents.org/wp-content/uploads/2021/08/LL_AI_feat-1030x580.jpg',
          subCategories: ['react', 'vue'],
          duration: '5 Weeks',
          description:
            'Elementum pulvinar rutrum amet est consectetur libero ut nisi. Ultrices in elit eu odio. Nisi rutrum vulputate et vestibulum ornare cras ut.'
        }
      ]
    }
  },
  mounted() {
    this.getEvents()
  },
  updated() {
    this.getEvents()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getEvents: async function () {
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.pastConferences = eventsQuery.data.pastEvents
        this.conferences = eventsQuery.data.events
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
