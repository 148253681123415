<template>
  <svg
    width="662"
    height="590"
    viewBox="0 0 662 590"
    alt="Utopicode"
    class="
      fill-current
      bg-gradient-to-br
      from-tertiary
      to-linkedin
      text-main-light
      opacity-30
      dark:from-gradient-primary
      dark:to-gradient-secondary
      dark:opacity-100
      dark:text-main-dark
    "
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M629.254 305.277C633.055 324.501 638.857 343.224 646.559 361.246C657.184 386.559 661.89 409.108 662 428.714V430.126C661.629 495.572 609.671 527.677 556.537 519.541C484.219 508.628 370.291 548.077 370.291 548.077C125.031 651.104 57.1146 541.468 70.2178 462.571C83.321 383.674 72.3183 361.246 58.715 337.817C55.2461 331.91 50.2404 325.875 44.5944 319.069C25.1685 295.652 -1.83685 263.098 0.100643 195.141C2.60125 108.734 70.2178 74.492 179.044 83.3028C259.204 89.7928 339.364 61.7878 418.045 34.2994C446.183 24.4692 474.132 14.705 501.823 6.60817C516.718 2.25307 530.91 0.145079 544.193 -8.28877e-08H547.134C625.803 0.864993 671.388 71.2573 639.757 150.286C617.251 206.755 622.052 266.729 629.254 305.277ZM547.134 -8.28877e-08H662V428.714C662.003 429.186 662.003 429.657 662 430.126V590H0V-8.28877e-08H544.193C545.178 -0.0107614 546.159 -0.0107227 547.134 -8.28877e-08Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Shape03'
}
</script>
