<template>
  <div class="content--wrapper p-6">
    <div class="w-full lg:w-8/12 mx-auto">
      <CardPage>
        <template #default>
          <div v-if="enviado">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h3>
                    {{ $t('form-validation.validation-hire-devs-1') }}
                  </h3>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('form-validation.validation-hire-devs-2') }}
              </p>
              <div
                class="dark:text-primary-text text-secondary-text"
                v-html="$t('form-validation.validation-upskilling-devs')"
              ></div>
            </div>
            <div class="card--page--footer">
              <button
                type="button"
                class="btn btn-primary btn-lg btn-rounded-lg mx-auto"
                @click="resetForm"
              >
                {{ $t('general.volver') }}
              </button>
            </div>
          </div>
          <Form @submit="downloadSyllabus" v-else>
            <!-- Title -->
            <div class="card--page--header">
              <h3 class="text-center font-bold dark:text-white title--h4">
                {{ $t('candidates-pool.form-title') }}
              </h3>
              <p class="text-primary text-center text-lg">
                {{ $t('candidates-pool.form-subtitle') }}
              </p>
            </div>
            <!-- End Title -->
            <!-- Body -->
            <div class="card--page--body">
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <div class="form--group">
                  <label class="form--label" for="name">{{
                    $t('candidates-pool.form-pool-name')
                  }}</label>
                  <Field
                    name="name"
                    type="text"
                    class="form--element w-full"
                    :rules="validateDefault"
                  />
                  <ErrorMessage name="name" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="tipo-de-rol">{{
                    $t('upskilling-landing.lbl-speciality')
                  }}</label>
                  <Field
                    name="speciality"
                    as="div"
                    :rules="validateDefault"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      mode="tags"
                      v-bind="field"
                      v-model="speciality"
                      :options="especialidadLista"
                      searchable
                      :max="5"
                      :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                      :noResultsText="$t('contrata-devs-page.sin-resultados')"
                    />
                  </Field>
                  <ErrorMessage name="speciality" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="tipo-de-rol">{{
                    $t('candidates-pool.form-position-type')
                  }}</label>
                  <Field
                    name="english"
                    as="Select"
                    class="form--element form--select w-full"
                    :rules="validateDefault"
                  >
                    <option
                      v-for="item in englishLevels"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </Field>
                  <ErrorMessage name="english" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="tipo-de-rol">{{
                    $t('candidates-pool.form-salary-range')
                  }}</label>
                  <Field
                    name="english"
                    as="Select"
                    class="form--element form--select w-full"
                    :rules="validateDefault"
                  >
                    <option
                      v-for="item in englishLevels"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </Field>
                  <ErrorMessage name="english" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="tipo-de-rol">{{
                    $t('candidates-pool.form-tech-stack')
                  }}</label>
                  <Field
                    name="speciality"
                    as="div"
                    :rules="validateDefault"
                    v-slot="{ field }"
                  >
                    <Multiselect
                      mode="tags"
                      v-bind="field"
                      v-model="speciality"
                      :options="especialidadLista"
                      searchable
                      :max="5"
                      :noOptionsText="$t('contrata-devs-page.lista-vacia')"
                      :noResultsText="$t('contrata-devs-page.sin-resultados')"
                    />
                  </Field>
                  <ErrorMessage name="speciality" class="form--error" />
                </div>
                <div class="form--group">
                  <label class="form--label" for="tipo-de-rol">{{
                    $t('upskilling-landing.lbl-englishLevel')
                  }}</label>
                  <Field
                    name="english"
                    as="Select"
                    class="form--element form--select w-full"
                    :rules="validateDefault"
                  >
                    <option
                      v-for="item in englishLevels"
                      :key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </Field>
                  <ErrorMessage name="english" class="form--error" />
                </div>
              </div>
              <div class="dark:bg-main-dark bg-line-light rounded-lg p-4">
                <ul
                  class="
                    list--bullet list--bullet--secondary
                    dark:text-primary-text
                    text-secondary-text text-sm
                  "
                >
                  <li
                    class="mb-4"
                    v-for="(disclaimer, index) in 2"
                    :key="index"
                  >
                    {{ $t(`candidates-pool.form-disclaimer-${index + 1}`) }}
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Body -->
            <!-- Footer -->
            <div class="flex justify-center space-x-4 pb-6">
              <router-link
                :to="{ name: 'DetalleBusqueda' }"
                class="
                  btn btn-outline btn-outline-primary btn-rounded-lg btn-md
                "
              >
                {{ $t('candidates-pool.btn-cancel') }}
              </router-link>
              <button
                class="btn btn-primary btn-rounded-lg btn-md"
                role="button"
                aria-pressed="false"
              >
                {{ $t('candidates-pool.btn-save-pool') }}
              </button>
            </div>
            <!-- End Footer -->
          </Form>
        </template>
      </CardPage>
    </div>
  </div>
</template>
<script>
import CardPage from '@/components/CardPage'
import { Field, Form, ErrorMessage } from 'vee-validate'
import especialidadLista from '@/components/json/especialidad.json'

import {
  validateDefault,
  validateEmail,
  validatePhone,
  validateUrlNoRegex,
  validateDefaultPhone
} from '@/services/validationRules'
export default {
  name: 'CreatePool',
  components: {
    CardPage,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      especialidadLista,
      enviado: false,
      phonenumber: '',
      phoneCodeNumber: '',
      courses: [],
      englishLevels: {
        opt1: 'No sé nada de Inglés',
        opt2: 'Básico',
        opt3: 'Conversacional',
        opt4: 'Profesional',
        opt5: 'Lengua Materna'
      },
      seniorityLevels: {
        opt1: 'Student',
        opt2: 'Junior',
        opt3: 'Mid-senior',
        opt4: 'Senior',
        opt5: 'Expert'
      },
      motivations: {
        opt1: 'Me gusta aprender',
        opt2: 'Me gusta mejorar',
        opt3: 'No se'
      }
    }
  },
  methods: {
    // RULES
    validateDefault,
    validateEmail,
    validatePhone,
    validateUrlNoRegex,
    validateDefaultPhone
  }
}
</script>
