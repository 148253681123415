<template>
  <div class="circle-wrapper">
    <svg width="100%" height="100%" viewBox="0 0 40 40" class="circle">
      <circle
        class="circle-ring"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3"
      ></circle>
      <circle
        v-for="(respuesta, index) in respuestas.subAssesmentsResult"
        :key="respuesta"
        class="circle-segment"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3"
        :stroke-dasharray="`${
          (((respuesta.totalScore * 100) / respuesta.assesment.testLength) *
            100) /
          totalCirclePercentage
        } 100`"
        :stroke-dashoffset="sumPathDonuts(index, respuesta)"
      ></circle>
    </svg>
    <div class="circle-data">
      <ul>
        <li
          v-for="(item, index) in respuestas.subAssesmentsResult"
          :key="index"
        >
          <CircleChartItem :key="item.name" :info="item" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import CircleChartItem from '@/components/CircleChartItem'
export default {
  name: 'CircleChart',
  props: {
    respuestas: Object
  },
  components: {
    CircleChartItem
  },
  data() {
    return {
      total: 0
    }
  },
  methods: {
    sumPathDonuts(index, respuesta) {
      if (index === 0) {
        return 0
      }
      const calcValue = this.respuestas.subAssesmentsResult.reduce(
        (acc, val, currIdx) => {
          if (currIdx < index) {
            return (acc +=
              ((val.totalScore * 100) / val.assesment.testLength) * 100)
          } else return acc
        },
        0
      )
      return -calcValue / this.totalCirclePercentage
    }
  },
  computed: {
    totalCirclePercentage() {
      let sum = 0
      for (let i = 0; i < this.respuestas.subAssesmentsResult.length; i++) {
        sum +=
          (parseFloat(this.respuestas.subAssesmentsResult[i].totalScore) *
            100) /
          parseFloat(
            this.respuestas.subAssesmentsResult[i].assesment.testLength
          )
      }
      return sum
    }
  }
}
</script>
