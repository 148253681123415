<template>
  <svg
    width="269"
    height="30"
    viewBox="0 0 269 30"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.8617 11.359V22.8041H45.6833V12.3487C45.6833 10.0252 44.1474 8.73441 42.041 8.73441C39.9784 8.73441 38.3547 10.1973 38.3108 12.0475V22.8041H33.0447V4.64688H37.9598V6.32492C39.2324 5.03412 41.1633 4.17358 43.5769 4.17358C48.0092 4.17358 50.8617 7.0994 50.8617 11.359Z"
    />
    <path
      d="M53.5386 13.7255C53.5386 8.26106 57.7076 4.21655 63.588 4.21655C69.4246 4.21655 73.6375 8.30409 73.6375 13.7255C73.6375 19.1468 69.4685 23.2344 63.588 23.2344C57.6637 23.2774 53.5386 19.1468 53.5386 13.7255ZM68.3714 13.7255C68.3714 10.8427 66.4844 8.77738 63.588 8.77738C60.6917 8.77738 58.7608 10.7996 58.7608 13.7685C58.7608 16.6513 60.6917 18.7596 63.588 18.7596C66.3966 18.7166 68.3714 16.6513 68.3714 13.7255Z"
    />
    <path
      d="M93.517 4.64679L86.715 22.8041H80.6589L73.8569 4.60376H79.2547L83.6869 17.813L88.1631 4.60376H93.517V4.64679Z"
    />
    <path
      d="M93.7803 13.7255C93.7803 8.26106 97.9493 4.21655 103.83 4.21655C109.666 4.21655 113.879 8.30409 113.879 13.7255C113.879 19.1468 109.71 23.2344 103.83 23.2344C97.9493 23.2774 93.7803 19.1468 93.7803 13.7255ZM108.657 13.7255C108.657 10.8427 106.77 8.77738 103.874 8.77738C100.977 8.77738 99.0464 10.7996 99.0464 13.7685C99.0464 16.6513 100.977 18.7596 103.874 18.7596C106.682 18.7166 108.657 16.6513 108.657 13.7255Z"
    />
    <path
      d="M136.128 13.7256C136.128 19.147 132.267 23.1915 127.001 23.1915C123.841 23.1915 121.296 21.6856 119.804 19.2761V29.0001H117.478V4.64701H119.672V8.51941C121.12 5.89478 123.753 4.25977 127.044 4.25977C132.354 4.25977 136.128 8.30428 136.128 13.7256ZM133.803 13.7256C133.803 9.42297 130.818 6.28202 126.781 6.28202C122.656 6.28202 119.76 9.33692 119.76 13.7256C119.76 18.1144 122.612 21.1693 126.781 21.1693C130.775 21.1693 133.803 17.9853 133.803 13.7256Z"
    />
    <path
      d="M157.675 4.64689V22.8042H155.481V18.8887C154.077 21.5564 151.488 23.1914 148.153 23.1914C142.887 23.1914 139.069 19.1469 139.069 13.6825C139.069 8.30416 142.93 4.3457 148.153 4.3457C151.444 4.3457 154.077 5.93769 155.481 8.56232V4.60386H157.675V4.64689ZM155.394 13.7255C155.394 9.37983 152.541 6.2819 148.372 6.2819C144.379 6.2819 141.351 9.50891 141.351 13.7255C141.351 18.0282 144.291 21.1691 148.372 21.1691C152.497 21.1691 155.394 18.0712 155.394 13.7255Z"
    />
    <path
      d="M178.871 4.64697L168.427 29.0431H166.101L168.822 22.8473H168.295L160.703 4.64697H163.117L169.656 20.825L176.414 4.64697H178.871Z"
    />
    <path
      d="M211.082 11.6173V22.8043H208.757V12.0046C208.757 8.60546 206.65 6.28202 203.271 6.32505C199.936 6.32505 197.698 8.90665 197.654 11.144V22.8043H195.284V12.0046C195.284 8.60546 193.222 6.28202 189.842 6.32505C186.507 6.32505 184.269 8.86362 184.225 11.144V22.8043H181.899V4.64701H184.138V7.61585C185.191 5.80873 187.429 4.25977 190.457 4.25977C193.792 4.25977 196.03 5.7657 197.039 8.3473C197.917 6.28202 200.287 4.25977 203.798 4.25977C208.362 4.25977 211.082 7.31466 211.082 11.6173Z"
    />
    <path
      d="M224.291 21.2123C227.627 21.2123 229.733 19.8785 230.786 17.2538L232.586 18.3295C231.269 21.3844 228.592 23.2345 224.291 23.2345C218.85 23.2345 214.988 19.19 214.988 13.7687C214.988 8.43336 218.982 4.25977 224.16 4.25977C229.47 4.25977 233.024 8.30428 233.024 13.3384C233.024 13.7256 233.024 14.1129 232.981 14.371H217.358C217.577 18.4586 220.386 21.2123 224.291 21.2123ZM230.742 12.65C230.479 8.73454 227.978 6.15294 224.204 6.15294C220.43 6.15294 217.753 8.73454 217.358 12.65H230.742Z"
    />
    <path
      d="M253.562 11.7894V22.8042H251.192V12.1766C251.192 8.4763 249.042 6.28194 245.4 6.32497C241.889 6.32497 239.475 8.82052 239.432 11.4451V22.8042H237.106V4.64693H239.344V7.70182C240.485 5.85168 242.811 4.30271 245.97 4.30271C250.754 4.25969 253.562 7.1855 253.562 11.7894Z"
    />
    <path
      d="M259.487 16.9095V6.62611H256.02V4.60386H258.082C259.179 4.60386 259.662 4.08754 259.662 3.01187V0H261.9V4.64688H267.693V6.66914H261.856V16.7374C261.856 19.319 263.217 20.8249 265.806 20.8249H268.044V22.8042H265.455C261.549 22.8042 259.487 20.5668 259.487 16.9095Z"
    />
    <path
      d="M24.1362 12.6929H0.482725C0.21942 12.6929 0 12.908 0 13.2522V17.3828C0 17.684 0.21942 17.9421 0.482725 17.9421H24.1362C24.3995 17.9421 24.619 17.684 24.619 17.3828V13.2522C24.619 12.908 24.3995 12.6929 24.1362 12.6929Z"
    />
    <path
      d="M0.482725 11.0578H24.1362C24.3995 11.0578 24.619 10.7997 24.619 10.4985V7.09939C24.619 6.7982 24.3995 6.54004 24.1362 6.54004H0.482725C0.21942 6.54004 0 6.7982 0 7.09939V10.4985C0 10.7997 0.21942 11.0578 0.482725 11.0578Z"
    />
    <path
      d="M22.6881 25.5579H1.88701C0.833797 25.5579 0 24.7404 0 23.7078V20.0505C0 19.7493 0.21942 19.5342 0.526609 19.5342H24.0924C24.3995 19.5342 24.619 19.7493 24.619 20.0505V23.7078C24.619 24.6974 23.7413 25.5579 22.6881 25.5579Z"
    />
    <path
      d="M24.0924 4.90503H0.526609C0.21942 4.90503 0 4.68989 0 4.38871V3.31304C0 2.2804 0.833797 1.46289 1.88701 1.46289H22.6881C23.7413 1.46289 24.5751 2.2804 24.5751 3.31304V4.38871C24.619 4.68989 24.3557 4.90503 24.0924 4.90503Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoLatitud'
}
</script>
