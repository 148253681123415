<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 110.7 44.9"
    class="fill-current"
  >
    <path
      d="M12 44.3h7V25.1h20.2v-5.5H19V5.9h22.6V.5H12zm42.8-5.6c-.6-.5-1-1.2-1-2 0-.5-.1-1-.1-1.5V.5h-7v35.9c0 .8 0 1.5.1 2.1.1 2.2.8 3.6 1.9 4.4 1.3.9 2.9 1.3 4.5 1.2.8 0 1.6 0 2.3-.1.8-.1 1.5-.1 2.2-.2V39c-.9.2-1.9.1-2.9-.3zm34.2-4V20.4c0-.9-.1-1.6-.2-2.3-.1-1.3-.4-2.6-1-3.9-.5-1.2-1.3-2.2-2.3-3-1.1-.9-2.4-1.5-3.8-1.9-1.8-.5-3.7-.7-5.6-.7-1.8 0-3.6.2-5.3.8-1.5.5-3 1.2-4.2 2.2-1.2 1-2.2 2.2-2.9 3.6-.7 1.5-1.1 3.2-1.2 4.9h7c.1-1.8.8-3.5 1.9-4.8 1.3-1.1 2.9-1.7 4.6-1.5 1.6-.1 3.2.3 4.4 1.3.9.9 1.4 2.1 1.5 3.3.1.6.1 1.2.1 1.8V23h-2.2c-6.4 0-11.2 1-14.2 3-4.1 2.8-5.6 8.1-3.7 12.7.6 1.2 1.4 2.2 2.3 3 1 .8 2.2 1.5 3.5 1.9 1.4.4 2.8.6 4.2.6 1.5 0 3-.2 4.5-.5 1.4-.3 2.7-.9 3.8-1.7l1.7-3.6.6 5.8h6.9c-.3-1.9-.4-4.4-.5-5.7.1-1.3.1-2.5.1-3.8zm-7-4c0 2.8-.7 4.8-2.1 6.3-1.4 1.4-3.4 2.2-5.4 2.2-1.6.1-3.1-.4-4.4-1.3-1.1-1-1.7-2.4-1.6-3.9-.1-2.4 1.4-4.6 3.7-5.4 2.4-1 5.7-1.3 9.8-1v3.1zm22.1-17.3h6.6V8.5h-6.6v-8h-7v8.1h-4.8v4.9h4.8v22.4c0 .7 0 1.4.1 2 0 1.8.7 3.6 2.1 4.9 1.2 1 3.2 1.5 5.8 1.5 1.7 0 3.4-.1 5.1-.4v-4.8c-2.3.2-3.8.1-4.7-.4s-1.3-1.5-1.4-3.2c0-.5-.1-.9-.1-1.3l.1-20.8zM.3 11.4h7v21.9h-7z"
      class="st0"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoFlat'
}
</script>
