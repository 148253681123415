import gql from 'graphql-tag'

const saveTechnologiesMutation = gql`
  mutation saveTechnologiesMutation(
    $technologies: [UserProfileTechnologiesInputType]
    $id: Int
  ) {
    updateTechnologies(technologies: $technologies, id: $id) {
      techName
      expYears
      level
    }
  }
`

export default saveTechnologiesMutation
