<template>
  <svg
    :viewBox="`0 0 ${svgOptions.width} ${svgOptions.height}`"
    version="1.1"
    class="w-full h-full graph-activity"
  >
    <defs>
      <!-- Filters -->
      <filter x="-5%" y="-20%" width="110%" height="140%" id="filter-primary">
        <feFlood flood-color="#ff67ad" result="bg" />
        <feMerge>
          <feMergeNode in="bg" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter x="-5%" y="-20%" width="110%" height="140%" id="filter-secondary">
        <feFlood flood-color="#7b72f5" result="bg" />
        <feMerge>
          <feMergeNode in="bg" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <filter x="-5%" y="-20%" width="110%" height="140%" id="filter-tertiary">
        <feFlood flood-color="#28f5a1" result="bg" />
        <feMerge>
          <feMergeNode in="bg" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
      <linearGradient id="gradientShapeBg" gradientTransform="rotate(90)">
        <stop offset="0%" stop-color="rgba(255, 35, 246, 0.49)" />
        <stop offset="65%" stop-color="rgba(218, 59, 54, 0.46)" />
        <stop offset="100" stop-color="rgba(60, 43, 51, 0.35)" />
      </linearGradient>
      <!-- End Filters -->
    </defs>

    <line
      x1="0"
      :x2="svgOptions.width"
      v-for="(item, index) in 8"
      :y1="(svgOptions.height / 8) * index"
      :y2="(svgOptions.height / 8) * index"
      :key="index"
      class="graph-activity-axis-x"
    />
    <polygon
      :points="`0 ${svgOptions.height}, ${returnValues
        .map((item) => [item.x, item.y])
        .flat()}, ${returnValues[returnValues.length - 1].x} ${
        svgOptions.height
      }`"
      stroke="green"
      fill="url(#gradientShapeBg)"
      stroke-width="0"
    />
    <path
      class="graph-activity-connected-line"
      :d="lineGenerator(returnValues)"
    />
    <!-- Points Text -->
    <text
      :filter="
        index % 3 === 0
          ? 'url(#filter-tertiary)'
          : index % 2 === 0
          ? 'url(#filter-primary)'
          : 'url(#filter-secondary)'
      "
      class="graph-activity-event"
      :class="
        index % 3 === 0
          ? 'graph-activity-event-tertiary'
          : index % 2 === 0
          ? 'graph-activity-event-primary'
          : 'graph-activity-event-secondary'
      "
      v-for="(item, index) in returnValues"
      :key="index"
      :x="item.x"
      :y="item.y - 7"
    >
      {{ item.event }}
    </text>
    <!-- End Points Text -->
    <!-- Bottom Text -->
    <text
      class="graph-activity-text-bottom"
      v-for="(item, index) in graphInfo.date"
      :key="index"
      :x="(svgOptions.width / graphInfo.date.length) * index"
      y="100%"
    >
      {{ item }}
    </text>
    <!-- End Bottom Text -->
    <!-- top Text -->
    <text
      class="graph-activity-text-top"
      v-for="(item, index) in graphInfo.time"
      :key="index"
      :x="(svgOptions.width / graphInfo.time.length) * index"
      y="3"
    >
      {{ item }}
    </text>
    <!-- End top Text -->
    <!-- Points -->
    <circle
      class="graph-activity-point"
      v-for="(item, index) in returnValues"
      :key="index"
      :r="svgOptions.radius"
      :cx="item.x"
      :cy="item.y"
    />
    <!-- end Points -->
  </svg>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: 'ActivityChart',
  data() {
    return {
      svgOptions: {
        radius: '1.7',
        width: 365,
        height: 31
      },
      graphInfo: {
        date: [
          'Nov 2020',
          'Jan 2021',
          'Mar 2021',
          'May 2021',
          'Jul 2021',
          'Sep 2021'
        ],
        time: ['7D', '1M', '3M', '1Y', '5Y', 'MAX']
      },
      points: [
        {
          event: 'Joined Utopicode',
          // Day in a Year
          x: 10,
          // Day in a month
          y: 2
        },
        {
          event: 'Attended Angular webinar',
          x: 40,
          y: 5
        },
        {
          event: 'Profile Completed',
          x: 80,
          y: 5
        },
        {
          event: 'Completed English Course',
          x: 120,
          y: 10
        },
        {
          event: 'Hosted an Event',
          x: 150,
          y: 12
        },
        {
          event: 'Applied to job at Google',
          x: 200,
          y: 20
        },
        {
          event: '',
          x: 360,
          y: 25
        }
      ]
    }
  },
  computed: {
    returnValues() {
      return this.points.map((point, index) => ({
        ...point,
        y: this.svgOptions.height - point.y
      }))
    },
    lineGenerator() {
      return d3
        .line()
        .x((item) => item.x)
        .y((item) => item.y)
        .curve(d3.curveLinear)
    }
  }
}
</script>
