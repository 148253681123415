<template>
  <teleport to="#modals">
    <transition name="fade">
      <Modal size="base">
        <template #default>
          <div class="modal--header">
            <Title>
              <template #title>
                <h4>{{ userName }} {{ $t('modal-bienvenida.title') }}</h4>
              </template>
            </Title>
          </div>
          <div class="modal--body">
            <img
              src="@/assets/images/icn-cody-welcome.svg"
              class="w-8/12 mx-auto mb-10"
              alt="Bienvenida/Welcome"
            />
            <p class="dark:text-primary-text text-secondary-text">
              {{ $t('modal-bienvenida.description-bienvenida-1') }}
            </p>
            <p
              class="dark:text-primary-text text-secondary-text"
              v-html="$t('modal-bienvenida.description-bienvenida-2')"
            ></p>
            <p
              class="dark:text-primary-text text-secondary-text"
              v-html="$t('modal-bienvenida.description-bienvenida-3')"
            ></p>
            <p
              class="dark:text-primary-text text-secondary-text"
              v-html="$t('modal-bienvenida.description-bienvenida-4')"
            ></p>
            <p
              class="dark:text-primary-text text-secondary-text"
              v-html="$t('modal-bienvenida.description-bienvenida-5')"
            ></p>
            <p class="dark:text-primary-text text-secondary-text">
              {{ $t('modal-bienvenida.description-bienvenida-6') }}
            </p>
            <p class="dark:text-primary-text text-secondary-text">
              {{ $t('modal-bienvenida.description-bienvenida-7') }}
            </p>
            <div class="modal--footer">
              <button
                type="button"
                @click="$emit('cerrarModal')"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.cerrar') }}
              </button>
            </div>
          </div>
        </template>
      </Modal>
    </transition>
  </teleport>
</template>

<script>
import Modal from '@/components/Modal'
import Title from '@/components/Title'

export default {
  name: 'ModalBienvenida',
  props: {
    userName: String
  },
  emits: ['cerrarModal'],
  components: {
    Title,
    Modal
  }
}
</script>
