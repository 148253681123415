<template>
  <teleport to="#modals">
    <transition name="fade">
      <Modal size="xs" @cerrarModal="$emit('cerrarModal')">
        <template #default>
          <form @submit.prevent="validar">
            <div class="modal--header">
              <Title>
                <template #title>
                  <h4>
                    {{ $t('modal-registro-evento.title') }}
                  </h4>
                </template>
              </Title>
            </div>
            <div class="modal--body">
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-registro-evento.description-registro-evento-1') }}
                <b>{{ title }}</b>
              </p>
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-registro-evento.description-registro-evento-2') }}
              </p>
              <div class="flex mb-8">
                <div class="w-2/6">
                  <img
                    :src="
                      image !== 'null'
                        ? image
                        : require('@/assets/images/img-about-us-codys.svg')
                    "
                    class="w-full"
                    :alt="title"
                  />
                </div>
                <div class="w-4/6 pl-6">
                  <div class="cards--comunidad--badges">
                    <span
                      v-if="category"
                      class="badge badge-line badge-xs badge-rounded-full"
                      >{{ category }}</span
                    >
                    <template v-if="badges.length > 0">
                      <span
                        v-for="badge in badges"
                        :key="badge"
                        class="badge badge-line badge-xs badge-rounded-full"
                        >#{{ badge }}</span
                      >
                    </template>
                  </div>
                  <ul class="cards--comunidad--date">
                    <li>
                      <time>{{ time }}</time>
                    </li>
                    <li>|</li>
                    <li>{{ date }}</li>
                  </ul>
                  <h3 class="dark:text-white font-bold mb-3">
                    {{ title }}
                  </h3>
                  <ul class="cards--comunidad--meta">
                    <li>
                      <span><img src="@/assets/images/icn-lugar.svg" /></span
                      >{{ place }}
                    </li>
                    <li>
                      <span><img src="@/assets/images/icn-idioma.svg" /></span
                      >{{ language }}
                    </li>
                    <li>
                      <span><img src="@/assets/images/icn-ticket.svg" /></span
                      >{{ ticket }}
                    </li>
                  </ul>
                </div>
              </div>
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-registro-evento.description-registro-evento-3') }}
              </p>
              <div class="mb-8 flex flex-wrap justify-center gap-y-4 gap-x-4">
                <a
                  class="btn btn-primary btn-lg btn-rounded-lg"
                  :href="calendarLink"
                  :title="$t('general.agregar-al-calendario')"
                  target="_blank"
                  >{{ $t('general.agregar-al-calendario') }}</a
                >
                <a
                  class="btn btn-secondary btn-lg btn-rounded-lg"
                  :href="liveLink"
                  :title="$t('general.link-al-evento')"
                  target="_blank"
                  >{{ $t('general.link-al-evento') }}</a
                >
                <router-link
                  class="btn btn-secondary btn-lg btn-rounded-lg"
                  :to="{ name: 'ProfileEvents' }"
                >
                  <span>
                    {{ $t('modal-registro-evento.button-mis-eventos') }}
                  </span>
                </router-link>
              </div>
              <div class="mb-2">
                <label class="form--checkbox"
                  ><input
                    type="checkbox"
                    id="recibir-newsletter"
                    :value="
                      $t('modal-registro-evento.lbl-registro-evento-1')
                    " /><span
                    v-html="$t('modal-registro-evento.lbl-registro-evento-1')"
                  ></span
                ></label>
              </div>
            </div>
            <div class="modal--footer">
              <button
                type="submit"
                @click="$emit('cerrarModal')"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.cerrar') }}
              </button>
            </div>
          </form>
        </template>
      </Modal>
    </transition>
  </teleport>
</template>

<script>
import Modal from '@/components/Modal'
import Title from '@/components/Title'

export default {
  name: 'ModalRegistroDeEvento',
  props: {
    title: String,
    date: String,
    category: String,
    badges: Array,
    place: String,
    language: String,
    ticket: String,
    image: String,
    time: String,
    liveLink: String,
    calendarLink: String
  },
  emits: ['cerrarModal'],
  components: {
    Title,
    Modal
  }
}
</script>
