<template>
  <main class="main--wrapper pt-24 lg:pt-40" v-if="assesment">
    <div class="content--wrapper">
      <div class="w-full lg:w-8/12 mx-auto">
        <!-- Card Page Sign Up -->
        <CardPage class="relative">
          <template #default>
            <button
              type="button"
              class="card--close"
              role="button"
              @click="$router.back()"
            >
              <img
                src="@/assets/images/icn-close.svg"
                :alt="$t('general.cerrar')"
              />
            </button>
            <CodyAnimations :animado="false" class="cody--card" />
            <Form @submit="attemptAssesment">
              <!-- Title -->
              <div class="card--page--header">
                <Title>
                  <template #title>
                    <h4>{{ assesment.name }}</h4>
                  </template>
                </Title>
              </div>
              <!-- End Title -->
              <!-- Body -->
              <div class="card--page--body">
                <p class="dark:text-white">{{ assesment.instructions[0] }}</p>
                <p class="dark:text-white">
                  {{
                    $t(
                      'instrucciones-assessment-page.text-habilidades-evaluadas'
                    )
                  }}
                </p>
                <ul class="list--icon dark:text-white text-contrast mb-10">
                  <li
                    v-for="skill in assesment.skills"
                    :key="skill"
                    class="mb-6"
                  >
                    <span class="box">
                      <img src="@/assets/images/icn-money.svg" alt="" />
                    </span>
                    <p class="last">
                      {{ skill }}
                    </p>
                  </li>
                </ul>
                <ul
                  class="
                    list--icon
                    text-sm
                    dark:text-primary-text
                    text-secondary-text
                    mb-10
                    space-y-4
                  "
                >
                  <li>
                    <span>
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 16 19"
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0C4.18359 0 0.28125 3.9375 0.28125 8.71875C0.28125 13.5352 4.18359 17.4375 9 17.4375C13.7812 17.4375 17.7188 13.5352 17.7188 8.71875C17.7188 3.9375 13.7812 0 9 0ZM9 3.86719C9.80859 3.86719 10.4766 4.53516 10.4766 5.34375C10.4766 6.1875 9.80859 6.82031 9 6.82031C8.15625 6.82031 7.52344 6.1875 7.52344 5.34375C7.52344 4.53516 8.15625 3.86719 9 3.86719ZM10.9688 12.7969C10.9688 13.043 10.7578 13.2188 10.5469 13.2188H7.45312C7.20703 13.2188 7.03125 13.043 7.03125 12.7969V11.9531C7.03125 11.7422 7.20703 11.5312 7.45312 11.5312H7.875V9.28125H7.45312C7.20703 9.28125 7.03125 9.10547 7.03125 8.85938V8.01562C7.03125 7.80469 7.20703 7.59375 7.45312 7.59375H9.70312C9.91406 7.59375 10.125 7.80469 10.125 8.01562V11.5312H10.5469C10.7578 11.5312 10.9688 11.7422 10.9688 11.9531V12.7969Z"
                        />
                      </svg>
                    </span>
                    {{ $t('instrucciones-assessment-page.text-intentos') }}
                  </li>
                  <li>
                    <span>
                      <svg
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.8125 2.25H9C9 1.01953 7.98047 0 6.75 0C5.48438 0 4.5 1.01953 4.5 2.25H1.6875C0.738281 2.25 0 3.02344 0 3.9375V16.3125C0 17.2617 0.738281 18 1.6875 18H11.8125C12.7266 18 13.5 17.2617 13.5 16.3125V3.9375C13.5 3.02344 12.7266 2.25 11.8125 2.25ZM6.75 1.40625C7.20703 1.40625 7.59375 1.79297 7.59375 2.25C7.59375 2.74219 7.20703 3.09375 6.75 3.09375C6.25781 3.09375 5.90625 2.74219 5.90625 2.25C5.90625 1.79297 6.25781 1.40625 6.75 1.40625ZM11.0039 9.5625L5.97656 14.5547C5.80078 14.7305 5.51953 14.7305 5.37891 14.5547L2.46094 11.6367C2.28516 11.4609 2.32031 11.1797 2.46094 11.0391L3.48047 10.0195C3.62109 9.87891 3.90234 9.87891 4.07812 10.0547L5.69531 11.6719L9.42188 7.98047C9.5625 7.80469 9.84375 7.80469 10.0195 7.98047L11.0039 8.96484C11.1797 9.14062 11.1445 9.42188 11.0039 9.5625Z"
                        />
                      </svg>
                    </span>
                    {{ $t('instrucciones-assessment-page.text-respuestas') }}
                  </li>
                  <li>
                    <span>
                      <svg
                        width="24"
                        height="19"
                        viewBox="0 0 24 19"
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.543 10.1932L21.6562 2.72835C21.4102 1.74398 20.7949 0.923665 19.8926 0.431477C19.0312 -0.0196947 17.9648 -0.142742 17.0215 0.185383L16.4062 0.390462C16.0371 0.513508 15.873 0.882649 15.9961 1.21077L16.2012 1.86702C16.3242 2.19515 16.6934 2.35921 17.0215 2.27718L17.5547 2.0721C18.0059 1.94906 18.498 1.94906 18.9082 2.15413C19.3594 2.35921 19.6465 2.76937 19.7695 3.22054L21.3281 9.53695C20.4258 9.24984 19.2773 9.00374 18.0059 9.00374C16.5703 9.00374 14.9707 9.29085 13.2891 10.1112H10.2949C8.61328 9.29085 7.01367 9.00374 5.57812 9.00374C4.30664 9.00374 3.1582 9.24984 2.25586 9.53695L3.81445 3.22054C3.9375 2.76937 4.22461 2.35921 4.67578 2.15413C5.08594 1.94906 5.57812 1.94906 6.0293 2.0721L6.5625 2.27718C6.89062 2.35921 7.25977 2.19515 7.38281 1.86702L7.58789 1.21077C7.71094 0.882649 7.54688 0.513508 7.17773 0.390462L6.5625 0.185383C5.61914 -0.142742 4.55273 -0.0196947 3.69141 0.431477C2.78906 0.923665 2.17383 1.74398 1.92773 2.72835L0.0410156 10.1932C0 10.3573 0 10.6444 0 10.8084V13.7205C0 16.3045 2.0918 18.3553 4.7168 18.3553H6.23438C8.69531 18.3553 10.7461 16.5096 10.9512 14.0487L11.0742 12.4491H12.5098L12.6328 14.0487C12.8379 16.5096 14.8887 18.3553 17.3496 18.3553H18.8672C21.4922 18.3553 23.625 16.3045 23.625 13.7205V10.8084C23.625 10.6034 23.584 10.3983 23.543 10.1932ZM8.32617 13.8436C8.24414 14.91 7.30078 15.7303 6.23438 15.7303H4.7168C3.52734 15.7303 2.625 14.828 2.625 13.7205V12.1619C3.36328 11.9159 4.38867 11.6287 5.57812 11.6287C6.5625 11.6287 7.50586 11.7928 8.44922 12.1619L8.32617 13.8436ZM21 13.7205C21 14.828 20.0566 15.7303 18.8672 15.7303H17.3496C16.2832 15.7303 15.3398 14.91 15.2578 13.8436L15.1348 12.1619C16.0781 11.7928 17.0215 11.6287 18.0059 11.6287C19.1953 11.6287 20.2207 11.9159 20.959 12.1619V13.7205H21Z"
                        />
                      </svg>
                    </span>
                    {{
                      $t('instrucciones-assessment-page.text-toma-tu-tiempo')
                    }}
                  </li>
                  <li>
                    <span>
                      <svg
                        width="16"
                        height="19"
                        viewBox="0 0 16 19"
                        class="fill-current"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.1875 10.9375C15.1875 9.49609 14.7305 8.125 13.9922 6.96484H14.0273L15.0469 5.91016C15.2227 5.76953 15.2227 5.48828 15.0469 5.3125L14.0625 4.32812C13.8867 4.15234 13.6055 4.15234 13.4648 4.32812L12.4805 5.3125C11.4961 4.50391 10.3008 3.94141 9 3.73047V2.5H9.98438C10.1953 2.5 10.4062 2.32422 10.4062 2.07812V0.671875C10.4062 0.460938 10.1953 0.25 9.98438 0.25H5.76562C5.51953 0.25 5.34375 0.460938 5.34375 0.671875V2.07812C5.34375 2.32422 5.51953 2.5 5.76562 2.5H6.75V3.73047C3.23438 4.25781 0.5625 7.28125 0.5625 10.9375C0.5625 14.9805 3.83203 18.25 7.875 18.25C11.8828 18.25 15.1875 14.9805 15.1875 10.9375ZM9 12.2031C9 12.4492 8.78906 12.625 8.57812 12.625H7.17188C6.92578 12.625 6.75 12.4492 6.75 12.2031V6.89453C6.75 6.64844 6.92578 6.47266 7.17188 6.47266H8.57812C8.78906 6.47266 9 6.64844 9 6.89453V12.2031Z"
                        />
                      </svg>
                    </span>
                    {{
                      $t('instrucciones-assessment-page.text-duracion-del-test')
                    }}
                  </li>
                </ul>
                <label class="ml-3 form--checkbox">
                  <Field
                    v-slot="{ field }"
                    name="prometo"
                    as="checkbox"
                    :rules="validateDefault"
                  >
                    <input
                      type="checkbox"
                      name="prometo"
                      v-bind="field"
                      v-model="accepted"
                    />
                    {{
                      $t('instrucciones-assessment-page.lbl-prometo-responder')
                    }}
                  </Field>
                </label>
                <ErrorMessage name="prometo" class="form--error ml-3" />
              </div>
              <!-- End Body -->
              <!-- Footer -->
              <div class="card--page--footer flex justify-center space-x-4">
                <button
                  type="button"
                  class="
                    btn btn-outline btn-outline-primary btn-rounded-lg btn-lg
                  "
                  role="button"
                  aria-pressed="false"
                  @click="$router.back()"
                >
                  {{ $t('general.volver') }}
                </button>
                <button
                  type="submit"
                  class="btn btn-lg btn-primary btn-rounded-lg mx-auto"
                >
                  {{ $t('general.empezar') }}
                </button>
              </div>
              <!-- End Footer -->
            </Form>
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { mapActions, mapGetters } from 'vuex'
import ApolloClient from '../graphql/apolloClient'
import assesmentInfoQuery from '../graphql/queries/assesmentInfo'
import CodyAnimations from '@/components/animation/CodyHangingToogle'
import { Field, Form, ErrorMessage } from 'vee-validate'
import AttemptAssesmentMutation from '@/graphql/mutations/attemptAssesment'
import { validateDefault } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

export default {
  data: function () {
    return {
      assesment: null,
      accepted: false
    }
  },
  components: {
    CardPage,
    Title,
    CodyAnimations,
    Form,
    Field,
    ErrorMessage
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    attemptAssesment: async function () {
      try {
        const canAttempt = await ApolloClient.mutate({
          variables: {
            assesmentId: parseInt(this.$route.params.id)
          },
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          mutation: AttemptAssesmentMutation
        })
        if (canAttempt.data.attemptAssesment !== 0) {
          // this.startAssesment({
          //   assesment: this.assesment,
          //   attemptId: canAttempt.data.attemptAssesment
          // })
          this.$router.push({
            name: 'HabilidadesTest',
            params: {
              id: this.assesment.id,
              attemptId: canAttempt.data.attemptAssesment
            }
          })
        } else {
          this.$router.push({
            name: 'HabilidadesTestIncompletos',
            params: { id: this.assesment.id }
          })
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getAssesmentInfo: async function () {
      try {
        const assesmentQuery = await ApolloClient.query({
          variables: {
            assesmentId: parseInt(this.$route.params.id)
          },
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          query: assesmentInfoQuery
        })
        this.setLoadingOff()
        this.assesment = assesmentQuery.data.assesment
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    // RULES
    validateDefault
  },
  mounted: async function () {
    this.getAssesmentInfo()
  },
  updated() {
    this.getAssesmentInfo()
  }
}
</script>
