<template>
  <div class="infinite-slider">
    <div
      class="infinite-slider-wrapper"
      :style="{
        'animation-duration': duration,
        'animation-direction': direction
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InfiniteSlider',
  props: {
    duration: {
      type: String,
      default: '12s'
    },
    direction: {
      type: String,
      default: 'normal'
    }
  }
}
</script>

<style lang="scss" scoped>
.infinite-slider {
  transform: translate3d(0, 0, 0);
  @apply mx-auto relative overflow-hidden;
  &-wrapper {
    transform: translate3d(0, 0, 0);
    animation-name: moveSlideshow;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: flex;
    width: max-content;
    @apply absolute left-0 top-0 h-full;
  }
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}
</style>
