<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('eventos-page.title')"
      :hero-description="$t('eventos-page.description')"
      section="pageHeroCta"
      :hero-image="require('@/assets/images/hero/bg-comunidad.svg')"
      class="hero--md"
    >
    </Hero>
    <div class="content--wrapper">
      <!-- Filter -->
      <!-- <section
        class="
          mt-4
          mb-16
          grid
          gap-x-6 gap-y-4
          lg:grid-cols-4
          md:grid-cols-3
          grid-cols-1
        "
      >
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <button type="button" class="btn btn-rounded-lg btn-lg btn-secondary">
          {{ $t('general.resetear') }}
        </button>
      </section> -->
      <!-- End Filter -->
      <!-- Cards Events -->
      <div class="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-8">
        <CardComunidad
          v-for="conference in allConferences"
          v-bind:key="conference.id"
          v-bind:conferenceData="conference"
        />
      </div>
      <Pagination
        class="justify-center"
        :totalPages="totalPages"
        :currentPage="currentPage"
      />
      <!-- End Cards Events -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import CardComunidad from '@/components/CardComunidad'
import ApolloClient from '@/graphql/apolloClient'
import EventsQuery from '@/graphql/queries/events'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
// import Pagination from '@/components/Pagination'

export default {
  name: 'EventosAll',
  components: {
    Hero,
    CardComunidad
  },
  data: function () {
    return {
      allConferences: [],
      pastConferences: [],
      totalPages: '',
      currentPage: 1,
      currentConferences: []
    }
  },
  mounted() {
    this.getEvents()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getEvents: async function () {
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.conferences = eventsQuery.data.events
        this.pastConferences = eventsQuery.data.pastEvents
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
