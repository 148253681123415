<template v-if="user">
  <main class="main--wrapper py-24 lg:py-32" v-if="score">
    <div class="content--wrapper">
      <!-- Card Page -->
      <CardPage class="mb-12">
        <template #default>
          <!-- Title -->
          <div class="card--page--header">
            <Title>
              <template #title>
                <h3>
                  {{
                    $t(`resultados-assessment-page.lvl-${score.keyPercentil}`)
                  }}
                </h3>
              </template>
            </Title>
          </div>
          <!-- End Title -->
          <!-- Body -->
          <div class="card--page--body">
            <div
              class="
                flex flex-wrap
                mb-10
                lg:flex-nowrap
                items-center
                space-x-8 space-y-6
                lg:space-y-0
              "
            >
              <div class="w-full lg:w-7/12">
                <p class="dark:text-white text-lg lg:text-right">
                  {{
                    $t(
                      'resultados-assessment-page.text-resultados-assessment-01'
                    )
                  }}
                  <span class="text-2xl font-bold">
                    {{
                      $t(
                        'resultados-assessment-page.text-resultados-assessment-04'
                      )
                    }}
                    {{
                      $t(`resultados-assessment-page.lvl-${score.keyPercentil}`)
                    }}
                    {{
                      $t(
                        'resultados-assessment-page.text-resultados-assessment-02'
                      )
                    }}
                    {{ score.assesment.name }}
                  </span>
                  <br />
                  {{
                    $t(
                      'resultados-assessment-page.text-resultados-assessment-03'
                    )
                  }}
                </p>
                <div class="flex mb-10 lg:mb-0 justify-center lg:justify-end">
                  <router-link
                    class="btn btn-primary btn-rounded-lg btn-md"
                    :to="{ name: 'Profile' }"
                    >{{ $t('general.ver-en-perfil') }}</router-link
                  >
                </div>
              </div>
              <div class="w-full lg:w-5/12 text-center">
                <PerceptileChart :nivel="score.keyPercentil"></PerceptileChart>
              </div>
            </div>
            <Title class="mb-6">
              <template #title>
                <h4>
                  {{
                    $t('resultados-assessment-page.title-resultado-generales')
                  }}
                </h4>
              </template>
            </Title>
            <div
              class="
                flex flex-wrap
                mb-10
                lg:flex-nowrap lg:space-x-8
                items-center
                space-y-6
                lg:space-y-0
              "
            >
              <div class="w-full lg:w-4/12">
                <CircleChart :respuestas="score"></CircleChart>
              </div>
              <div class="w-full lg:w-8/12">
                <h4 class="dark:text-white text-lg font-bold mb-3">
                  {{
                    $t('resultados-assessment-page.text-resultado-general-1')
                  }}
                  {{ score.subAssesmentsResult[0].assesment.name }}
                  {{
                    $t('resultados-assessment-page.text-resultado-general-2')
                  }}
                  {{
                    score.subAssesmentsResult[
                      score.subAssesmentsResult.length - 1
                    ].assesment.name
                  }}
                </h4>
                <p
                  class="
                    dark:text-primary-text
                    text-secondary-text
                    leading-relaxed
                  "
                >
                  {{ score.description }}
                </p>
              </div>
            </div>
            <Title class="mb-6">
              <template #title>
                <h4>
                  {{
                    $t('resultados-assessment-page.title-resultado-detallados')
                  }}
                </h4>
              </template>
            </Title>
            <Collapse
              class="mb-4"
              v-for="subAssesment in score.subAssesmentsResult"
              icon="icn-money.svg"
              :key="subAssesment.id"
              :title="subAssesment.assesment.name"
            >
              <template #body>
                <div
                  class="
                    flex flex-wrap
                    lg:flex-nowrap lg:space-x-8
                    space-y-6
                    lg:space-y-0
                    items-center
                  "
                >
                  <div class="w-full lg:w-3/12">
                    <DonutChart
                      icon="icn-money.svg"
                      :title="subAssesment.assesment.name"
                      :respuestas-correctas="subAssesment.totalScore"
                      :respuestas-totales="subAssesment.assesment.testLength"
                    ></DonutChart>
                  </div>
                  <div class="w-full lg:w-9/12">
                    <p
                      class="
                        dark:text-primary-text
                        text-secondary-text
                        leading-relaxed
                      "
                    >
                      {{ subAssesment.assesment.instructions[0] }}
                    </p>
                    <h4 class="dark:text-white text-lg font-bold mb-3">
                      {{ $t('resultados-assessment-page.title-tu-resultado') }}
                    </h4>
                    <p
                      class="
                        dark:text-primary-text
                        text-secondary-text
                        leading-relaxed
                        last
                      "
                    >
                      {{ subAssesment.description }}
                    </p>
                  </div>
                </div>
              </template>
            </Collapse>
          </div>
          <!-- End Body -->
        </template>
      </CardPage>
      <!-- End Card Page -->
      <!-- Sección Comunidad -->
      <section class="content--comunidad">
        <Title class="mb-6">
          <template #title>
            <h3>{{ $t('general.otros-badges') }}</h3>
          </template>
        </Title>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          <CardSkill
            v-for="(item, key) in otherAssesments"
            :key="key"
            :link="{ name: 'InstruccionesAssesments', params: { id: item.id } }"
            :image="item.photo"
            :title="item.name"
            :description="item.instructions[0]"
            locked
            footer
            :cta="$t('general.realizar-test')"
          />
        </div>
      </section>
      <!-- End Sección Comunidad -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import Collapse from '@/components/Collapse'
import DonutChart from '@/components/DonutChart'
import PerceptileChart from '@/components/PerceptileChart'
import CircleChart from '@/components/CircleChart'
import CardSkill from '@/components/CardSkill'
import apolloClient from '@/graphql/apolloClient'
import AssesmentResultQuery from '@/graphql/queries/assesmentResult'
import userAssesmentsQuery from '@/graphql/queries/userAssesments'
import assesmentsQuery from '@/graphql/queries/assesments'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  components: {
    CardPage,
    Title,
    Collapse,
    DonutChart,
    CircleChart,
    PerceptileChart,
    CardSkill
  },
  data() {
    return {
      user: null,
      score: null,
      otherAssesments: []
    }
  },
  computed: {
    ...mapGetters(['token']),
    getAssesmentFromCategories() {
      return this.user.takenAssesments.filter((item) => item.assesment.filter())
    }
  },
  mounted: async function () {
    this.setLoadingOn()
    this.getUserAssesments()
    await this.getAssesmentResult()
    await this.getAssesmentCategories()
  },
  methods: {
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    getAssesmentResult: async function () {
      try {
        const assesmentResult = await apolloClient.query({
          query: AssesmentResultQuery,
          variables: {
            assesmentId: parseInt(this.$route.params.id)
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        this.setLoadingOff()
        this.score = assesmentResult.data.assesmentResult
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getAssesmentCategories: async function () {
      try {
        const fetchAssesmentsQuery = await apolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: assesmentsQuery
        })
        this.otherAssesments = fetchAssesmentsQuery.data.assesments.filter(
          (el) => {
            return el.id !== parseInt(this.$route.params.id)
          }
        )
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    getUserAssesments: async function () {
      try {
        const userAssesmentsData = await apolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userAssesmentsQuery
        })
        this.user = userAssesmentsData.data.userProfile
        // this.getAssesmentFromCategories()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
    // getAssesmentFromCategories: function () {
    //   const newAssesments = this.user.takenAssesments.filter((item) =>
    //     item.assesment.filter()
    //   )
    //   return newAssesments
    // }
  }
}
</script>
