import gql from 'graphql-tag'

const savePersonalInfoMutation = gql`
  mutation savePersonalInfoMutation(
    $fullname: String
    $country: String
    $phonenumber: String
    $speciality: String
    $id: Int
  ) {
    updatePersonalInfo(
      fullname: $fullname
      country: $country
      phonenumber: $phonenumber
      speciality: $speciality
      id: $id
    ) {
      id
      speciality
    }
  }
`

export default savePersonalInfoMutation
