<template>
  <div class="title title--wrapper">
    <slot name="title"></slot>
    <slot name="info"></slot>
    <span class="title--line"></span>
    <slot name="options"></slot>
  </div>
</template>

<script>
export default {
  name: 'Title'
}
</script>
