import gql from 'graphql-tag'

const signUpEvent = gql`
  mutation signUpEvent(
    $email: String!
    $speciality: String!
    $fullname: String!
    $eventId: Int!
  ) {
    inscribeToEvent(
      email: $email
      speciality: $speciality
      eventId: $eventId
      fullname: $fullname
    )
  }
`

export default signUpEvent
