import { DateTime } from 'luxon'

const hourFormat = (date) => {
  return DateTime.fromMillis(parseInt(date))
    .setLocale('es-mx')
    .toFormat('t ZZZZ')
}

const dateFormat = (date) => {
  return DateTime.fromMillis(parseInt(date)).setLocale('es-mx').toFormat('DD')
}

const dayFormat = (date) => {
  return DateTime.fromMillis(parseInt(date)).getDay()
}

const monthFormat = (date) => {
  return DateTime.fromMillis(parseInt(date)).getMonth()
}

const dateHourFormat = (date) => {
  return (
    DateTime.fromMillis(parseInt(date))
      .setLocale('es-mx')
      .toFormat('yyyy-MM-dd HH:mm') || ''
  )
}

export { hourFormat, dateFormat, monthFormat, dayFormat, dateHourFormat }
