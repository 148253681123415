<template>
  <section class="mb-12">
    <Title class="mb-8">
      <template #title>
        <h3>{{ $t('profile-page.title-perfil') }}</h3>
      </template>
      <template #options v-if="!isPublic && edit === false">
        <button
          @click="edit = true"
          type="button"
          role="button"
          aria-label="Edit"
          class="
            w-9
            h-9
            flex
            justify-center
            items-center
            rounded-md
            btn-secondary
            transform
            -translate-y-2
          "
        >
          <img src="@/assets/images/icn-edit.svg" />
        </button>
      </template>
    </Title>
    <!-- Start Basic Info -->
    <div class="flex flex-wrap lg:flex-nowrap items-center">
      <div
        class="
          relative
          w-24
          h-24
          lg:w-28 lg:h-28
          flex
          justify-center
          items-center
        "
      >
        <router-link
          :to="{ name: 'EditProfileAvatar', params: { locale: 'es' } }"
          v-if="!isPublic"
          class="
            bg-secondary
            flex
            justify-center
            items-center
            w-8
            h-8
            absolute
            bottom-0
            left-0
            rounded-md
            transform
            -translate-y-1/4
          "
        >
          <img src="@/assets/images/icn-edit.svg" class="w-4" />
        </router-link>
        <img
          :src="
            require(`@/assets/images/avatars/avatar-${
              user.avatar ? user.avatar : 1
            }.svg`)
          "
          class="rounded-full"
          alt="Cody Avatar"
        />
      </div>
      <div class="flex-grow lg:mr-3 lg:ml-6" v-if="edit === false">
        <div
          v-if="isPublic"
          class="dark:text-white text-contrast font-bold text-lg"
        >
          ID: {{ user.id }}
        </div>
        <div
          v-if="!isPublic"
          class="dark:text-white text-contrast font-bold text-lg"
        >
          {{ user.name || user.fullname }}
        </div>
        <div
          class="dark:text-primary-text text-secondary-text uppercase"
          v-if="user.speciality"
        >
          {{ user.speciality }}
        </div>
        <button
          class="
            dark:text-primary-text
            text-secondary-text text-sm
            flex
            justify-center
            items-center
          "
          v-if="!user.speciality && !isPublic"
          type="button"
          @click="edit = true"
        >
          {{ $t('sign-up-evento-page.text-speciality') }}
          <span
            class="
              ml-3
              w-7
              h-7
              flex
              justify-center
              items-center
              rounded-md
              btn-secondary
            "
          >
            <img src="@/assets/images/icn-edit.svg" class="w-4" />
          </span>
        </button>
      </div>
      <ul
        class="
          w-full
          lg:w-auto
          mt-6
          lg:mt-0 lg:ml-3
          list--icon
          text-white text-sm
          space-y-2
          flex-grow
        "
        v-if="edit === false"
      >
        <li v-if="!isPublic">
          <span class="icon transform translate-y-1"
            ><img src="@/assets/images/icn-mail.svg" /></span
          >{{ user.email }}
        </li>
        <li>
          <span class="icon transform translate-y-1"
            ><img src="@/assets/images/icn-idioma.svg" /></span
          >{{ $t('profile-page.lbl-locacion') }}: &nbsp;<b>{{
            user.country
          }}</b>
        </li>
        <li v-if="!isPublic">
          <span class="icon transform translate-y-1"
            ><img src="@/assets/images/icn-phone.svg" /></span
          >{{ $t('profile-page.lbl-telefono') }}: &nbsp;<b>{{
            user.phonenumber
          }}</b>
        </li>
      </ul>
      <Form v-else @submit="updateUserProfile" class="lg:ml-6">
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 mb-3">
          <div class="mb-3">
            <Field
              name="name"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="name"
              :placeholder="$t('modal-profile.lbl-nombre')"
            />
            <ErrorMessage name="name" class="form--error" />
          </div>
          <div class="mb-3">
            <Field
              name="country"
              as="select"
              class="form--element form--select w-full"
              :rules="validateDefault"
              v-model="country"
            >
              <option value="" disabled>
                {{ $t('registrarse-page.lbl-countryItem') }}
              </option>
              <option
                :value="countryItem"
                v-for="countryItem in countriesList[
                  $i18n.locale ? $i18n.locale : 'es'
                ]"
                :key="countryItem"
              >
                {{ countryItem }}
              </option>
            </Field>
            <ErrorMessage name="country" class="form--error" />
          </div>
          <div class="mb-3">
            <Field
              name="speciality"
              as="select"
              class="form--element form--select w-full"
              :rules="validateDefault"
              v-model="speciality"
            >
              <option value="" disabled>
                {{ $t('modal-profile.lbl-especialidad') }}
              </option>
              <option
                :value="speciality"
                v-for="speciality in especialidadLista.map((val) => val.name)"
                :key="speciality"
              >
                {{ speciality }}
              </option>
            </Field>
            <ErrorMessage name="speciality" class="form--error" />
          </div>
          <!-- If Other Selected -->
          <div class="mb-4" v-if="speciality === 'Other/Otro'">
            <label class="form--label" for="otro">
              {{ $t('contrata-devs-page.option-otro') }}
              {{ $t('modal-profile.lbl-especialidad') }}
            </label>
            <Field
              name="otro"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="otherSpeciality"
            />
            <ErrorMessage name="otro" class="form--error" />
          </div>
          <!-- End Other Selected -->
          <div class="mb-4">
            <div class="form--phone">
              <Field
                name="phoneCode"
                type="text"
                :placeholder="$t('general.codigo-internacional')"
                list="phoneCodeNumberList"
                class="form--element form--select w-24"
                :rules="validateDefaultPhone"
                v-model.lazy="phoneCodeNumber"
              />
              <Field
                name="phone"
                type="text"
                class="form--element w-full"
                :rules="validatePhone"
                v-model="phonenumber"
              />
              <datalist id="phoneCodeNumberList">
                <option value="" disabled></option>
                <option
                  :value="code.dialCode"
                  v-for="(code, index) in phoneCodes"
                  :key="index"
                >
                  {{ code.emoji }} {{ code.name }}
                </option>
              </datalist>
            </div>
            <ErrorMessage name="phone" class="form--error" />
            <ErrorMessage name="phoneCode" class="form--error" />
            <div class="form--error" v-if="error">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="flex justify-center space-x-4">
          <button
            type="button"
            class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
            @click="edit = false"
          >
            {{ $t('general.cancelar') }}
          </button>
          <button
            type="submit"
            class="btn btn-primary btn-rounded-lg btn-md"
            role="button"
            aria-pressed="false"
          >
            {{ $t('general.guardar') }}
          </button>
        </div>
      </Form>
    </div>
    <!-- End Basic Info -->
  </section>
</template>

<script>
import Title from '@/components/Title'
import ROUTES from '@/constants/routes'
import countriesList from '@/components/json/countries.json'
// import especialidadLista from '@/components/json/especialidad.json'
import phoneCodes from '@/components/json/phoneCodes.json'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ApolloClient from '@/graphql/apolloClient'
import savePersonalInfoMutation from '@/graphql/mutations/savePersonalInfo'
import getPositionsQuery from '@/graphql/queries/positions'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import {
  validateDefault,
  validateDefaultPhone,
  validatePhone
} from '@/services/validationRules'
export default {
  name: 'BasicInformationSection',
  props: ['isPublic', 'user'],
  components: {
    Title,
    Field,
    ErrorMessage,
    Form
  },
  data() {
    return {
      ROUTES,
      edit: null,
      countriesList,
      especialidadLista: [],
      phoneCodes,
      name: this.user.name || this.user.fullname || null,
      speciality: this.user.speciality || null,
      otherSpeciality: null,
      country: this.user.country || null,
      phonenumber: this.user.phonenumber || null,
      phoneCodeNumber: '',
      error: null
    }
  },
  mounted() {
    if (
      (this.name !== null &&
        this.speciality !== null &&
        this.country !== null &&
        this.phonenumber !== null) ||
      this.isPublic
    ) {
      this.edit = false
    } else {
      this.edit = true
    }
    // this.splitPhoneNumber()
  },
  beforeMount() {
    this.splitPhoneNumber()
    this.getPositions()
  },
  computed: {
    ...mapGetters(['token']),
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phonenumber
    }
  },
  methods: {
    splitPhoneNumber() {
      const checkNumber = this.phonenumber
      if (checkNumber !== null) {
        this.phoneCodes.forEach((item) => {
          if (checkNumber.includes(item.dialCode)) {
            this.phonenumber = checkNumber.split(item.dialCode)[1]
            this.phoneCodeNumber = item.dialCode
          }
        })
      }
    },
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    getPositions: async function () {
      try {
        const positionQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: getPositionsQuery
        })
        this.especialidadLista = positionQuery.data.positionTypes
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    updateUserProfile: async function () {
      try {
        this.setLoadingOn()
        const personalInfo = await ApolloClient.mutate({
          mutation: savePersonalInfoMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            fullname: this.name,
            name: this.name,
            phonenumber: this.phoneNumberComplete,
            country: this.country,
            id: this.user.userId,
            speciality:
              this.speciality === 'Other/Otro'
                ? this.otherSpeciality
                : this.speciality
          }
        })
        if (!personalInfo.errors) {
          this.setLoadingOff()
          await this.fetchUser()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.edit = false
        }
      } catch (e) {
        this.error = e
        toastNotification(e, 'error')
      }
    },
    // RULES
    validateDefault,
    validateDefaultPhone,
    validatePhone
  }
}
</script>
