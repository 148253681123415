<template>
  <div
    class="cards cards--trabajo"
    :class="{ 'cards--trabajo--horizontal': horizontal }"
  >
    <!-- Header -->
    <div class="cards--trabajo--header">
      <!-- Image -->
      <div class="cards--trabajo--image">
        <img
          :src="
            jobData.image ? jobData.image : require(`@/assets/images/logo.png`)
          "
          :alt="title"
          class="max-h-20 mx-auto"
        />
      </div>
      <!-- End Image -->
      <!-- Info -->
      <div class="cards--trabajo--info">
        <h3>
          {{ jobData.jobTitle }}
        </h3>
        <h4>{{ jobData.company.name }}</h4>
        <p>{{ jobData.company.about }}</p>
      </div>
      <!-- End Info -->
    </div>
    <!-- End Header -->
    <!-- Body -->
    <div
      class="cards--trabajo--body"
      v-if="
        jobData.jobType ||
        jobData.salaryRange ||
        jobData.jobTime ||
        (jobData.techStack && jobData.techStack.length > 0) ||
        jobData.expRequired ||
        jobData.company.industry
      "
    >
      <!-- Meta -->
      <ul class="cards--trabajo--meta">
        <li v-if="jobData.jobType">
          <span><img src="@/assets/images/icn-modalidad.svg" /></span
          >{{ jobData.jobType }}
        </li>
        <!-- <li v-if="jobData.salaryRange">
          <span><img src="@/assets/images/icn-money.svg" /></span
          >{{ jobData.salaryRange }}
        </li> -->
        <li v-if="jobData.jobTime">
          <span><img src="@/assets/images/icn-type-position.svg" /></span
          >{{ jobData.jobTime }}
        </li>
        <li v-if="jobData.techStack && jobData.techStack.length > 0">
          <span><img src="@/assets/images/icn-technology.svg" /></span
          ><template v-for="stack in jobData.techStack" v-bind:key="stack">{{
            `${stack} `
          }}</template>
        </li>
        <li v-if="jobData.expRequired">
          <span><img src="@/assets/images/icn-level.svg" /></span
          >{{ jobData.expRequired }}
        </li>
        <li v-if="jobData.company.industry">
          <span><img src="@/assets/images/icn-industry.svg" /></span
          >{{ jobData.company.industry }}
        </li>
      </ul>
      <!-- End Meta -->
    </div>
    <!-- End Body -->
    <!-- Footer -->
    <div class="cards--trabajo--footer" v-if="showCta || showOptions">
      <router-link
        v-if="showCta"
        class="btn btn-primary btn-rounded-lg btn-md mx-auto"
        :to="{
          name: 'TrabajosTechDetail',
          params: { id: jobData.id }
        }"
        >{{ $t('general.conocer-mas') }}</router-link
      >
      <div v-else-if="showOptions" class="grid grid-cols-2 gap-4 w-full">
        <router-link
          class="btn btn-primary btn-rounded-lg btn-md col-span-full"
          :to="{
            name: 'TrabajosTechDetail',
            params: { id: jobData.id }
          }"
          >{{ $t('general.conocer-mas') }}
        </router-link>
        <button class="btn btn-gray btn-rounded-lg btn-md" type="button">
          {{ $t('general.guardar') }}
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            class="ml-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.4375 1.9687C13.7188 0.499951 11.1875 0.781201 9.59376 2.4062L9.00001 3.0312L8.37501 2.4062C6.81251 0.781201 4.25001 0.499951 2.53126 1.9687C0.562511 3.6562 0.468761 6.6562 2.21876 8.4687L8.28126 14.7187C8.65626 15.125 9.31251 15.125 9.68751 14.7187L15.75 8.4687C17.5 6.6562 17.4063 3.6562 15.4375 1.9687Z"
              fill="transparent"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.23676 2.05657C10.9874 0.271614 13.8155 -0.074554 15.7623 1.58906C17.9528 3.46664 18.0566 6.80004 16.1097 8.81647L16.1089 8.81731L10.0502 15.0633C9.47808 15.6774 8.49063 15.6774 7.91849 15.0633L1.85984 8.81731L1.85903 8.81647C-0.0878685 6.80004 0.0153329 3.46712 2.20584 1.58955C4.14938 -0.0713 7.00906 0.26829 8.73191 2.0565L8.9908 2.31539L9.23676 2.05657ZM9.95343 2.754L9.00916 3.74797L8.0145 2.7533C6.61207 1.29478 4.34904 1.0738 2.85639 2.34901C1.10977 3.84635 1.02542 6.51294 2.57804 8.12147C2.57817 8.12161 2.57791 8.12134 2.57804 8.12147L8.64875 14.3799C8.82577 14.5717 9.14306 14.5718 9.32008 14.38L9.32848 14.3709L15.3903 8.12188C15.3904 8.12175 15.3902 8.12201 15.3903 8.12188C16.9429 6.51337 16.859 3.84642 15.1124 2.34907C13.6226 1.07624 11.3903 1.29155 9.95343 2.754Z"
              fill="white"
            />
          </svg>
        </button>
        <button class="btn btn-gray btn-rounded-lg btn-md" type="button">
          {{ $t('general.aplicar') }}
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            class="ml-2"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16ZM8.5 17C13.1944 17 17 13.1944 17 8.5C17 3.80558 13.1944 0 8.5 0C3.80558 0 0 3.80558 0 8.5C0 13.1944 3.80558 17 8.5 17Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.7284 5.18995C12.4751 4.93668 12.0678 4.93668 11.8145 5.18995L8.24916 8.75532L6.66493 7.18512L6.66449 7.18468C6.41119 6.93185 6.00421 6.932 5.75109 7.18512L5.18995 7.74626C4.93668 7.99953 4.93668 8.42242 5.18995 8.67569L7.77744 11.2632C8.0307 11.5164 8.4536 11.5164 8.70686 11.2632L13.2895 6.68052C13.5428 6.42725 13.5428 6.00436 13.2895 5.75109L12.7284 5.18995Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
    <!-- End Footer -->
  </div>
</template>

<script>
export default {
  name: 'CardTrabajo',
  props: {
    jobData: Object,
    link: Object,
    image: String,
    title: String,
    company: String,
    description: String,
    modalidad: String,
    typePosition: String,
    horizontal: Boolean,
    showCta: Boolean,
    showOptions: Boolean
  },
  setup() {
    return {}
  }
}
</script>
