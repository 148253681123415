<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 129.3 45.5"
    class="fill-current"
  >
    <path
      d="M19.1 24.6h1v-.3c0-4.6-1.8-6.3-5.9-6.3-1.4 0-4.3.4-4.3 2.1v1.7H2.5v-3.4c0-6 8.4-6.6 11.8-6.6 10.7 0 13.9 5.6 13.9 12.8v12c0 .9.5 1.4 1.4 1.4H32v6.7h-7.2c-3 0-4.1-1.6-4.1-3.5 0-.8.1-1.4.1-1.4h-.1s-2.5 5.7-9.7 5.7c-5.7 0-11-3.6-11-10 0-10.1 13.9-10.9 19.1-10.9zm-6.2 14.7c4.3 0 7.3-4.5 7.3-8.4v-.8h-1.4c-4.2 0-10.8.6-10.8 5 .1 2.1 1.6 4.2 4.9 4.2zm22.6-18.5c0-.9-.5-1.4-1.4-1.4h-2.5v-6.8h7.6c3 0 4.2 1.3 4.2 4.3v19.7c0 .9.5 1.4 1.4 1.4h2.5v6.7h-7.6c-3 0-4.2-1.3-4.2-4.3V20.8zM35.9 0h6.9v7.4h-6.9V0zm20.2 34.6s3.5 3.9 8.9 3.9c3.6 0 6.2-2.2 6.2-5.4s-3-5.3-6.8-5.3h-2.5l-1.3-3.3 6.1-8.2c1.2-1.8 2.4-2.8 2.4-2.8v-.1s-1.1.2-3.2.2h-4.5c-.7 0-1.1.4-1.1 1.1v2.2h-6v-5.5c0-2.4.7-3.5 3.2-3.5h19.7V12l-8.3 10.4c4.6.8 9.1 4.5 9.1 10.6 0 6-4.7 11.7-12.9 11.7-7.9 0-12.3-5.1-12.3-5.1l3.3-5z"
    />
    <path
      d="M77.4 27.9c0-9.6 5.5-20.6 16.7-20.6 4.6 0 7.4 1.5 7.4 1.5l-1.9 5.7s-2.3-1.1-5.1-1.1c-5.7 0-8.7 4.7-9.6 9.8h.1c1.2-1.6 4.1-2.7 6.7-2.7 7.1 0 11.6 5.6 11.6 12 0 6.7-4.7 12.1-11.9 12.1-7.5.1-14-6-14-16.7zm14 10.8c3.4 0 5.5-2.7 5.5-6.1 0-3.6-2.3-6.6-6.6-6.6-3.2 0-5.9 1.7-5.9 3.9-.1 4.5 3.2 8.8 7 8.8z"
    />
    <path
      d="M103.1 26c0-10.4 3.2-18.7 13.1-18.7 9.9 0 13.1 8.3 13.1 18.7 0 10.4-3.2 18.8-13.1 18.8-9.9-.1-13.1-8.4-13.1-18.8zm19.4 0c0-7-1.4-12.6-6.4-12.6-4.9 0-6.4 5.6-6.4 12.6 0 7.1 1.4 12.7 6.4 12.7s6.4-5.6 6.4-12.7z"
    />
  </svg>
</template>

<script>
export default {
  name: 'LogoAI360'
}
</script>
