import gql from 'graphql-tag'

const homepageQuery = gql`
  query homepageQUery($eventLimit: Int) {
    events(limit: $eventLimit) {
      id
      date
      name
      place
      eventLang
      category
      subCategories
      photo
    }
    assesments(limit: 2) {
      id
      name
      instructions
      photo
    }
  }
`

export default homepageQuery
