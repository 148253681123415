<template>
  <div class="lg:ml-4">
    <h4 class="text-white font-bold text-2xl mb-2">
      {{ $t('social-networks.title') }}
    </h4>
    <p class="text-primary-text mb-4">
      {{ $t('social-networks.description') }}
    </p>
    <ul class="footer--social flex-wrap">
      <li>
        <a
          href="https://discord.gg/SwctK6By"
          title="Utopicode Discord"
          target="_blank"
        >
          <svg viewBox="0 0 26 21" class="fill-current w-6 h-6">
            <path
              d="M22.0101 2.16817C20.3529 1.4078 18.5759 0.847591 16.7179 0.526737C16.6841 0.520544 16.6503 0.536019 16.6328 0.56697C16.4043 0.973453 16.1511 1.50374 15.9739 1.92055C13.9754 1.62137 11.9873 1.62137 10.0298 1.92055C9.85254 1.49448 9.59019 0.973453 9.36062 0.56697C9.34319 0.537052 9.30939 0.521577 9.27555 0.526737C7.41857 0.846566 5.64152 1.40677 3.98335 2.16817C3.96899 2.17436 3.95669 2.18469 3.94852 2.19809C0.577841 7.23381 -0.345529 12.1458 0.107445 16.9968C0.109495 17.0205 0.122817 17.0432 0.141265 17.0577C2.36514 18.6908 4.51935 19.6823 6.63355 20.3395C6.66739 20.3498 6.70324 20.3375 6.72477 20.3096C7.22489 19.6266 7.6707 18.9065 8.05293 18.1492C8.07549 18.1049 8.05396 18.0522 8.00785 18.0347C7.30073 17.7665 6.6274 17.4394 5.97971 17.068C5.92848 17.0381 5.92437 16.9648 5.9715 16.9297C6.1078 16.8276 6.24414 16.7213 6.37428 16.614C6.39783 16.5944 6.43064 16.5903 6.45833 16.6027C10.7134 18.5454 15.32 18.5454 19.5249 16.6027C19.5525 16.5893 19.5854 16.5934 19.6099 16.613C19.7401 16.7203 19.8764 16.8276 20.0137 16.9297C20.0609 16.9648 20.0578 17.0381 20.0066 17.068C19.3589 17.4466 18.6855 17.7665 17.9774 18.0337C17.9313 18.0512 17.9108 18.1049 17.9333 18.1492C18.3238 18.9054 18.7696 19.6256 19.2605 20.3086C19.281 20.3375 19.3179 20.3498 19.3517 20.3395C21.4761 19.6823 23.6303 18.6908 25.8542 17.0577C25.8737 17.0432 25.886 17.0216 25.8881 16.9978C26.4302 11.3895 24.98 6.51783 22.0439 2.19912C22.0367 2.18469 22.0245 2.17436 22.0101 2.16817ZM8.68836 14.043C7.40729 14.043 6.35173 12.8669 6.35173 11.4225C6.35173 9.97813 7.38682 8.80202 8.68836 8.80202C10.0001 8.80202 11.0455 9.98846 11.025 11.4225C11.025 12.8669 9.98986 14.043 8.68836 14.043ZM17.3276 14.043C16.0466 14.043 14.991 12.8669 14.991 11.4225C14.991 9.97813 16.0261 8.80202 17.3276 8.80202C18.6394 8.80202 19.6847 9.98846 19.6643 11.4225C19.6643 12.8669 18.6394 14.043 17.3276 14.043Z"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.youtube.com/channel/UCkSTX4rcQ_KJO6e_OZKF7vA"
          title="YouTube"
          target="_blank"
        >
          <svg viewBox="0 0 27 19" class="fill-current w-6 h-6">
            <path
              d="M25.6279 3.46484C25.3467 2.33984 24.4561 1.44922 23.3779 1.16797C21.3623 0.605469 13.3936 0.605469 13.3936 0.605469C13.3936 0.605469 5.37793 0.605469 3.3623 1.16797C2.28418 1.44922 1.39355 2.33984 1.1123 3.46484C0.549805 5.43359 0.549805 9.65234 0.549805 9.65234C0.549805 9.65234 0.549805 13.8242 1.1123 15.8398C1.39355 16.9648 2.28418 17.8086 3.3623 18.0898C5.37793 18.6055 13.3936 18.6055 13.3936 18.6055C13.3936 18.6055 21.3623 18.6055 23.3779 18.0898C24.4561 17.8086 25.3467 16.9648 25.6279 15.8398C26.1904 13.8242 26.1904 9.65234 26.1904 9.65234C26.1904 9.65234 26.1904 5.43359 25.6279 3.46484ZM10.7686 13.4492V5.85547L17.4248 9.65234L10.7686 13.4492Z"
            />
          </svg>
        </a>
      </li>
      <!--
      <li>
        <a
          href="https://utopicode.medium.com/"
          title="Utopicode Medium"
          target="_blank"
        >
          <svg viewBox="0 0 28 17" class="fill-current w-6 h-6">
            <path
              d="M15.784 8.098c0 4.392-3.5 7.96-7.892 7.96S0 12.49 0 8.098C0 3.705 3.569.136 7.892.136c4.324 0 7.892 3.568 7.892 7.96ZM24.431 8.098c0 4.117-1.784 7.48-3.98 7.48-2.196 0-3.98-3.363-3.98-7.48 0-4.118 1.784-7.48 3.98-7.48 2.265 0 3.98 3.362 3.98 7.48ZM28 8.099c0 3.705-.618 6.725-1.373 6.725s-1.372-3.02-1.372-6.725c0-3.706.617-6.726 1.372-6.726.755 0 1.373 3.02 1.373 6.726Z"
            />
          </svg>
        </a>
      </li>
		-->
      <li>
        <a
          href="https://www.instagram.com/utopicode_dev_community"
          title="Instagram"
          target="_blank"
        >
          <svg viewBox="0 0 22 22" class="fill-current w-6 h-6">
            <path
              d="M10.7139 5.69141C7.71387 5.69141 5.32324 8.12891 5.32324 11.082C5.32324 14.082 7.71387 16.4727 10.7139 16.4727C13.667 16.4727 16.1045 14.082 16.1045 11.082C16.1045 8.12891 13.667 5.69141 10.7139 5.69141ZM10.7139 14.5977C8.79199 14.5977 7.19824 13.0508 7.19824 11.082C7.19824 9.16016 8.74512 7.61328 10.7139 7.61328C12.6357 7.61328 14.1826 9.16016 14.1826 11.082C14.1826 13.0508 12.6357 14.5977 10.7139 14.5977ZM17.5576 5.50391C17.5576 4.80078 16.9951 4.23828 16.292 4.23828C15.5889 4.23828 15.0264 4.80078 15.0264 5.50391C15.0264 6.20703 15.5889 6.76953 16.292 6.76953C16.9951 6.76953 17.5576 6.20703 17.5576 5.50391ZM21.1201 6.76953C21.0264 5.08203 20.6514 3.58203 19.4326 2.36328C18.2139 1.14453 16.7139 0.769531 15.0264 0.675781C13.292 0.582031 8.08887 0.582031 6.35449 0.675781C4.66699 0.769531 3.21387 1.14453 1.94824 2.36328C0.729492 3.58203 0.354492 5.08203 0.260742 6.76953C0.166992 8.50391 0.166992 13.707 0.260742 15.4414C0.354492 17.1289 0.729492 18.582 1.94824 19.8477C3.21387 21.0664 4.66699 21.4414 6.35449 21.5352C8.08887 21.6289 13.292 21.6289 15.0264 21.5352C16.7139 21.4414 18.2139 21.0664 19.4326 19.8477C20.6514 18.582 21.0264 17.1289 21.1201 15.4414C21.2139 13.707 21.2139 8.50391 21.1201 6.76953ZM18.8701 17.2695C18.542 18.207 17.792 18.9102 16.9014 19.2852C15.4951 19.8477 12.2139 19.707 10.7139 19.707C9.16699 19.707 5.88574 19.8477 4.52637 19.2852C3.58887 18.9102 2.88574 18.207 2.51074 17.2695C1.94824 15.9102 2.08887 12.6289 2.08887 11.082C2.08887 9.58203 1.94824 6.30078 2.51074 4.89453C2.88574 4.00391 3.58887 3.30078 4.52637 2.92578C5.88574 2.36328 9.16699 2.50391 10.7139 2.50391C12.2139 2.50391 15.4951 2.36328 16.9014 2.92578C17.792 3.25391 18.4951 4.00391 18.8701 4.89453C19.4326 6.30078 19.292 9.58203 19.292 11.082C19.292 12.6289 19.4326 15.9102 18.8701 17.2695Z"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/utopicoders/"
          title="Facebook"
          target="_blank"
        >
          <svg viewBox="0 0 12 24" class="fill-current w-6 h-5">
            <path
              d="M3 8H0V12H3V24H8V12H11.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z"
            />
          </svg>
        </a>
      </li>
      <li>
        <a
          href="https://twitter.com/utopicode1"
          title="Twitter"
          target="_blank"
        >
          <svg viewBox="0 0 26 21" class="fill-current w-6 h-6">
            <path
              d="M26 2.48611C25.0434 2.90817 24.0153 3.19242 22.9363 3.32055C24.0381 2.66484 24.8842 1.62582 25.2818 0.387613C24.2515 0.994873 23.1097 1.43632 21.8942 1.67427C20.9224 0.643868 19.5347 0 18.0007 0C14.5567 0 12.0261 3.1935 12.8039 6.50866C8.372 6.28794 4.44167 4.1776 1.81025 0.970109C0.41275 3.35285 1.0855 6.4699 3.46017 8.04835C2.587 8.02035 1.76367 7.7824 1.04542 7.3851C0.986917 9.84106 2.75817 12.1387 5.3235 12.6502C4.57275 12.8526 3.7505 12.9 2.91417 12.7406C3.59233 14.8466 5.56183 16.3788 7.8975 16.4219C5.655 18.1693 2.82967 18.95 0 18.6183C2.36058 20.1225 5.16533 21 8.177 21C18.0808 21 23.6763 12.6868 23.3382 5.23062C24.3804 4.48231 25.285 3.54881 26 2.48611Z"
            />
          </svg>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialNetworks'
}
</script>
