<template>
  <div class="cards p-6">
    <h4 class="title-lg dark:text-white text-contrast font-bold mb-6">
      {{ title }}
    </h4>
  </div>
</template>
<script>
export default {
  name: 'CardWidget',
  props: {
    title: String,
    data: Object
  },
  data() {
    return {}
  },
  mounted() {},
  setup() {
    const testData = {
      labels: ['Paris', 'Nîmes', 'Toulon', 'Perpignan', 'Autre'],
      datasets: [
        {
          data: [30, 40, 60, 70, 5],
          backgroundColor: [
            '#77CEFF',
            '#0079AF',
            '#123E6B',
            '#97B0C4',
            '#A5C8ED'
          ]
        }
      ]
    }

    return { testData }
  }
}
</script>
