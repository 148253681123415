<template v-if="user">
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <!-- Soft Skill -->
      <section class="mb-8 lg:mb-16">
        <Title class="mb-6">
          <template #title>
            <h3>
              {{ $t('habilidades-page.title-librerias-de') }}
              {{ $t('habilidades-page.title-soft-skill') }}
            </h3>
          </template>
        </Title>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
          v-if="userAssesments.length > 0"
        >
          <CardSkill
            v-if="softSkill.length > 0"
            :link="{
              name: 'ResultadoAssesments',
              params: { id: softSkill[0].assesment.id }
            }"
            image="icn-soft-skill.svg"
            :title="$t('habilidades-page.title-soft-skill')"
            :description="$t('habilidades-page.descripcion-soft-skill')"
            footer
            :cta="$t('general.ver-resultados')"
          />
          <CardSkill
            v-if="comportamientoEmocional.length > 0"
            :link="{
              name: 'ResultadoAssesments',
              params: { id: comportamientoEmocional[0].assesment.id }
            }"
            image="icn-comportamiento-emocional.svg"
            :title="$t('habilidades-page.title-comportamiento-emocional')"
            :description="
              $t('habilidades-page.descripcion-comportamiento-emocional')
            "
            footer
            :cta="$t('general.ver-resultados')"
          />
          <CardSkill
            v-if="fitCultural.length > 0"
            :link="{
              name: 'ResultadoAssesments',
              params: { id: fitCultural[0].assesment.id }
            }"
            image="icn-fit-cultural.svg"
            :title="$t('habilidades-page.title-fit-cultural')"
            :description="$t('habilidades-page.descripcion-fit-cultural')"
            footer
            :cta="$t('general.ver-resultados')"
          />
        </div>
        <div v-else class="flex flex-col justify-center align-center">
          <h4
            class="
              dark:text-primary-text
              text-secondary-text text-center text-lg
              font-bold
              my-8
            "
          >
            {{ $t('mis-test-page.take-assesments') }}
          </h4>
          <router-link
            :to="{ name: 'Habilidades' }"
            class="btn btn-md btn-rounded-lg btn-primary mx-auto"
            role="button"
            :aria-label="$t('general.completar')"
          >
            {{ $t('general.completar') }}
          </router-link>
        </div>
      </section>
      <!-- End Soft Skill -->
      <!-- Hard Skill -->
      <section>
        <Title class="mb-6">
          <template #title>
            <h3>
              {{ $t('habilidades-page.title-librerias-de') }}
              {{ $t('habilidades-page.title-hard-skill') }}
            </h3>
          </template>
        </Title>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
          v-if="userAssesments.length > 0"
        >
          <CardSkill
            v-if="hardSkill.length > 0"
            :link="{
              name: 'ResultadoAssesments',
              params: { id: hardSkill[0].assesment.id }
            }"
            image="icn-hard-skill.svg"
            :title="$t('habilidades-page.title-hard-skill')"
            :description="$t('habilidades-page.descripcion-hard-skill')"
            footer
            :cta="$t('general.ver-resultados')"
          />
        </div>
        <div v-else class="flex flex-col justify-center align-center">
          <h4
            class="
              dark:text-primary-text
              text-secondary-text text-center text-lg
              font-bold
              my-8
            "
          >
            {{ $t('mis-test-page.take-assesments') }}
          </h4>
          <router-link
            :to="{ name: 'Habilidades' }"
            class="btn btn-md btn-rounded-lg btn-primary mx-auto"
            role="button"
            :aria-label="$t('general.completar')"
          >
            {{ $t('general.completar') }}
          </router-link>
        </div>
      </section>
      <!-- End Hard Skill -->
    </div>
  </main>
</template>

<script>
import Title from '@/components/Title'
import CardSkill from '@/components/CardSkill'
import { mapGetters, mapActions } from 'vuex'
import ROUTES from '@/constants/routes'
import userAssesmentsQuery from '@/graphql/queries/userAssesments'
import ApolloClient from '@/graphql/apolloClient'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'MisTestsPage',
  components: {
    Title,
    CardSkill
  },
  data() {
    return {
      user: null,
      softSkill: null,
      hardSkill: null,
      comportamientoEmocional: null,
      fitCultural: null,
      userAssesments: 0,
      ROUTES
    }
  },
  created() {
    this.setLoadingOn()
    this.getUserAssesments()
  },
  computed: {
    ...mapGetters(['token'])
  },
  methods: {
    ...mapActions(['setLoadingOff', 'setLoadingOn']),
    getUserAssesments: async function () {
      try {
        const assesmentData = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              authorization: `Bearer ${await this.token}`
            }
          },
          fetchPolicy: 'network-only',
          query: userAssesmentsQuery
        })
        this.user = assesmentData.data.userProfile
        this.userAssesments = assesmentData.data.userProfile.takenAssesments
        this.setTakenAssesments()
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    setTakenAssesments: function () {
      this.softSkill = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-soft-skill.svg'
      )
      this.hardSkill = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-hard-skill.svg'
      )
      this.comportamientoEmocional = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-comportamiento-emocional.svg'
      )
      this.fitCultural = this.user.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-fit-cultural.svg'
      )
    }
  }
}
</script>
