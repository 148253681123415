import gql from 'graphql-tag'

const savePastJobsMutation = gql`
  mutation savePastJobsMutation(
    $pastJobs: [UserProfilePastJobsInputType]
    $id: Int
  ) {
    updatePastJobs(pastJobs: $pastJobs, id: $id) {
      position
      company
      companyLocation
      startDate
      endDate
      whatBuilt
      technologies
      appOrRepo
    }
  }
`

export default savePastJobsMutation
