import { app } from '@/main.js'

const toastNotification = (toastMessage, toastType) => {
  app.config.globalProperties.$toast.open({
    message: toastMessage,
    type: toastType,
    position: 'top-right'
  })
}

export { toastNotification }
