<template>
  <CardPage>
    <template #default>
      <!-- Title -->
      <div class="card--page--header">
        <Title>
          <template #title>
            <h5 class="mt-2">
              {{ $t('box-experiencia.title-experiencia') }}
            </h5>
          </template>
          <template #options v-if="!isPublic">
            <button
              @click="edit = true"
              v-if="edit === false"
              type="button"
              role="button"
              aria-label="Edit"
              class="
                w-9
                h-9
                flex
                justify-center
                items-center
                rounded-md
                btn-secondary
                transform
                -translate-y-2
              "
            >
              <img src="@/assets/images/icn-edit.svg" />
            </button>
          </template>
        </Title>
      </div>
      <!-- End Title -->
      <!-- Body -->
      <div class="card--page--body">
        <ul class="list--box" v-if="edit === false">
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-experiencia.svg" /></span
            ><b>{{ $t('box-experiencia.text-años-experiencia') }}: </b>
            <span v-if="yearsOfExperience"
              >{{ yearsOfExperience }}
              {{ $t('box-experiencia.text-años') }}</span
            >
          </li>
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-empleados.svg" /></span
            ><b>{{ $t('box-experiencia.text-personas-a-cargo') }}: </b>
            <span v-if="peopleInCharge">{{ peopleInCharge }} </span>
          </li>
          <li>
            <span class="icon"
              ><img src="@/assets/images/icn-lenguajes.svg" /></span
            ><b>{{ $t('box-experiencia.text-nivel-de-ingles') }}: </b>
            <span v-if="userEnglishLevel">{{
              $t(`nivel-ingles.opt-${userEnglishLevel}`)
            }}</span>
          </li>
        </ul>
        <form @submit.prevent="onSubmit" v-else>
          <div class="form--group">
            <label class="form--label" for="experiencia-laboral">{{
              $t('box-experiencia.lbl-experiencia-laboral')
            }}</label>
            <input
              id="experiencia-laboral"
              name="experiencia-laboral"
              type="number"
              min="0"
              class="form--element form--number w-full"
              v-model="yearsOfExperience"
            />
          </div>
          <div class="form--group">
            <label class="form--label" for="personas-a-cargo">{{
              $t('box-experiencia.lbl-personas-a-cargo')
            }}</label>
            <input
              id="personas-a-cargo"
              name="personas-a-cargo"
              class="form--element form--number w-full"
              v-model="peopleInCharge"
              type="number"
              min="0"
            />
          </div>
          <div class="form--group">
            <label class="form--label" for="nivel-de-ingles"
              >{{ $t('box-experiencia.lbl-nivel-de-ingles') }}
              <div class="tooltip inline">
                <button
                  type="button"
                  role="button area"
                  aria-label="Info"
                  @mouseenter="tooltipShow = true"
                  @mouseleave="tooltipShow = false"
                >
                  <img src="@/assets/images/icn-info.svg" class="w-4" />
                </button>
                <transition name="fade">
                  <div
                    class="tooltip--globe tooltip--md"
                    v-show="tooltipShow == true"
                  >
                    <p
                      class="dark:text-primary-text text-secondary-text text-xs"
                    >
                      <b>{{ $t('tooltip-nivel-ingles.title-basico') }}:</b>
                      {{ $t('tooltip-nivel-ingles.description-basico') }}
                    </p>
                    <p
                      class="dark:text-primary-text text-secondary-text text-xs"
                    >
                      <b
                        >{{
                          $t('tooltip-nivel-ingles.title-conversacional')
                        }}:</b
                      >
                      {{
                        $t('tooltip-nivel-ingles.description-conversacional')
                      }}
                    </p>
                    <p
                      class="dark:text-primary-text text-secondary-text text-xs"
                    >
                      <b>{{ $t('tooltip-nivel-ingles.title-profesional') }}:</b>
                      {{ $t('tooltip-nivel-ingles.description-profesional') }}
                    </p>
                    <p
                      class="
                        dark:text-primary-text
                        text-secondary-text text-xs
                        last
                      "
                    >
                      <b
                        >{{
                          $t('tooltip-nivel-ingles.title-lengua-materna')
                        }}:</b
                      >
                      {{
                        $t('tooltip-nivel-ingles.description-lengua-materna')
                      }}
                    </p>
                  </div>
                </transition>
              </div>
            </label>
            <select
              id="nivel-de-ingles"
              name="nivel-de-ingles"
              class="form--element form--select w-full"
              v-model="userEnglishLevel"
            >
              <option v-for="item in englishLevels" :key="item" :value="item">
                {{ $t(`nivel-ingles.opt-${item}`) }}
              </option>
            </select>
          </div>
          <div class="flex justify-center space-x-4">
            <button
              type="button"
              @click="edit = false"
              class="btn btn-md btn-outline btn-outline-primary btn-rounded-lg"
              role="button"
            >
              {{ $t('general.cancelar') }}
            </button>
            <button
              type="submit"
              @click="submitForm"
              class="btn btn-md btn-primary btn-rounded-lg"
              role="button"
            >
              {{ $t('general.guardar') }}
            </button>
          </div>
        </form>
      </div>
      <!-- End Body -->
    </template>
  </CardPage>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { mapActions, mapGetters } from 'vuex'
import apolloClient from '@/graphql/apolloClient'
import UpdateExperienceMutation from '@/graphql/mutations/saveExperience'
import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'ExperienciaBox',
  components: {
    CardPage,
    Title
  },
  props: {
    user: Object,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  data() {
    return {
      yearsOfExperience: this.user.yearsOfExp,
      peopleInCharge: this.user.personsInCharge,
      userEnglishLevel: this.user.englishLevel,
      edit: true,
      englishLevels: 5,
      tooltipShow: false
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  mounted() {
    if (
      (this.yearsOfExperience !== null &&
        this.peopleInCharge !== null &&
        this.userEnglishLevel !== null) ||
      this.isPublic
    ) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  methods: {
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    async submitForm() {
      try {
        this.setLoadingOn()
        const updateExperience = await apolloClient.mutate({
          mutation: UpdateExperienceMutation,
          variables: {
            yearsOfExp: parseInt(this.yearsOfExperience),
            personsInCharge: parseInt(this.peopleInCharge),
            englishLevel: this.userEnglishLevel.toString(),
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!updateExperience.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.edit = false
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
