<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <!-- Card Page Sign Up -->
      <CardPage class="w-full lg:w-6/12 xl:w-5/12 mx-auto relative">
        <template #default>
          <CodyAnimations :animado="shyCody" class="cody--card" />
          <Form @submit="login">
            <!-- Title -->
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h3>{{ $t('loguearse-page.title') }}</h3>
                </template>
              </Title>
            </div>
            <!-- End Title -->
            <!-- Body -->
            <div class="card--page--body">
              <p class="dark:text-white text-secondary-text text-lg">
                {{ $t('loguearse-page.texto-bienvenida') }}
              </p>
              <div class="form--group">
                <label class="form--label" for="email">{{
                  $t('loguearse-page.lbl-email')
                }}</label>
                <Field
                  name="email"
                  type="email"
                  class="form--element w-full"
                  :rules="validationRules.email"
                />
                <ErrorMessage name="email" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="contraseña">{{
                  $t('loguearse-page.lbl-contraseña')
                }}</label>
                <div class="form--icon form--icon--right">
                  <button
                    type="button"
                    @click="showPassword = !showPassword"
                    class="icon--wrapper bg-secondary text-white rounded-r-lg"
                  >
                    <img
                      src="@/assets/images/icn-eye.svg"
                      v-if="showPassword"
                    />
                    <img src="@/assets/images/icn-eye-close.svg" v-else />
                  </button>
                  <Field
                    name="password"
                    :type="[showPassword ? 'text' : 'password']"
                    class="form--element w-full"
                    @focusin="
                      () => {
                        if (!showPassword) shyCody = true
                      }
                    "
                    @focusout="() => (shyCody = false)"
                    :rules="validationRules.password"
                  />
                </div>
                <ErrorMessage name="contraseña" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--checkbox"
                  ><input
                    type="checkbox"
                    id="remember"
                    value="first_checkbox" /><span
                    v-html="$t('loguearse-page.lbl-recuerdame')"
                  ></span
                ></label>
              </div>
              <div class="flex justify-end items-center space-x-6">
                <router-link
                  :to="{ name: 'RecuperarContraseña' }"
                  class="dark:text-primary-text text-secondary-text underline"
                  >{{
                    $t('loguearse-page.lbl-olvidaste-contraseña')
                  }}</router-link
                >
                <button
                  type="submit"
                  class="btn btn-lg btn-primary btn-rounded-lg"
                  role="button"
                >
                  {{ $t('loguearse-page.btn-loguearse') }}
                </button>
              </div>
            </div>
            <!-- End Body -->
            <!-- End Options -->
            <!-- TODO: MAKE COMPONENT-->
            <div class="card--page--option">
              <div class="card--page--option--divider">
                <h4>{{ $t('loguearse-page.texto-ingresa-con') }}</h4>
              </div>
              <div class="card--page--body">
                <ul class="flex space-x-4 w-full">
                  <li class="w-full">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-github"
                      @click="socialLogin('github')"
                    >
                      <img
                        src="@/assets/images/social-media/icn-github.svg"
                        alt="Github"
                      />
                    </button>
                  </li>
                  <li class="w-full">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-gmail"
                      @click="socialLogin('gmail')"
                    >
                      <img
                        src="@/assets/images/social-media/icn-gmail.svg"
                        alt="Gmail"
                      />
                    </button>
                  </li>
                  <li class="w-full">
                    <button
                      type="button"
                      class="btn btn-lg btn-full btn-rounded-lg btn-facebook"
                      @click="socialLogin('facebook')"
                    >
                      <img
                        src="@/assets/images/social-media/icn-facebook.svg"
                        alt="Facebook"
                      />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <!-- End Options -->
            <!-- Footer -->
            <div class="card--page--footer">
              <div class="flex items-baseline justify-end space-x-6">
                <p class="dark:text-primary-text text-secondary-text mb-0">
                  {{ $t('loguearse-page.texto-no-tengo-cuenta') }}
                </p>
                <router-link
                  :to="{
                    path: ROUTES.signup,
                    params: {
                      id: $route.params.id,
                      name: $route.params.name,
                      date: $route.params.date,
                      place: $route.params.place,
                      photo: $route.params.photo,
                      eventLang: $route.params.eventLang,
                      category: $route.params.category,
                      badges: $route.params.badges,
                      liveLink: $route.params.liveLink,
                      calendarLink: $route.params.calendarLink,
                      summit: $route.params.summit
                    },
                    query: isQuery ? { redirectTo: isQuery } : null
                  }"
                  class="btn btn-lg btn-primary btn-rounded-lg"
                  >{{ $t('loguearse-page.btn-crear-cuenta') }}</router-link
                >
              </div>
            </div>
            <!-- End Footer -->
          </Form>
        </template>
      </CardPage>
      <!-- End Card Page Sign Up -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { mapActions, mapGetters } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ROUTES from '../constants/routes'
import CodyAnimations from '@/components/animation/CodyHangingToogle'
import firebase from '@/services/firebase'
import { toastNotification } from '@/services/toastNotification'
import * as yup from 'yup'

export default {
  components: {
    CardPage,
    Title,
    Field,
    Form,
    ErrorMessage,
    CodyAnimations
  },
  data: function () {
    return {
      validationRules: {
        email: yup
          .string()
          .required(this.$i18n.t('form-general-messages.required'))
          .email(this.$i18n.t('form-general-messages.email')),
        password: yup
          .string()
          .required(this.$i18n.t('form-general-messages.required'))
          .min(8)
      },
      ROUTES,
      showPassword: false,
      shyCody: false
    }
  },
  computed: {
    ...mapGetters(['lang']),
    isQuery() {
      return this.$route.query.redirectTo || null
    }
  },
  methods: {
    ...mapActions([
      'setLoadingOn',
      'setLoadingOff',
      'fetchUser',
      'socialLogin'
    ]),
    login: async function ({ email, password }) {
      this.setLoadingOn()
      console.log('About to call firebase.signInWithEmailAndPassword')
      try {
        await firebase.signInWithEmailAndPassword(email, password)
      } catch (e) {
        this.setLoadingOff()
        console.log('Caught error: ' + e.message)
        if (
          e.message ===
          'The password is invalid or the user does not have a password.'
        ) {
          toastNotification(
            this.$i18n.t('loguearse-page.error-password'),
            'error'
          )
        } else if (
          e.message ===
          'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
        ) {
          toastNotification(
            this.$i18n.t('loguearse-page.error-password-blocking'),
            'error'
          )
        } else if (
          e.message ===
          'There is no user record corresponding to this identifier. The user may have been deleted.'
        ) {
          toastNotification(
            this.$i18n.t('loguearse-page.error-user-no-existe'),
            'error'
          )
        } else {
          toastNotification(e.message, 'error')
        }
      }
    }
  }
}
</script>
