<template>
  <Header />
</template>
<script>
import Header from '@/components/company/Header'
export default {
  name: 'SignUpClients',
  components: {
    Header
  }
}
</script>
