<template>
  <div class="card">
    <slot name="default"></slot>
  </div>
</template>
<script>
export default {
  name: 'CardKanban'
}
</script>
