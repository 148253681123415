<template>
  <teleport to="#modals">
    <transition name="fade">
      <Modal size="sm" @cerrarModal="$emit('cerrarModal')">
        <template #default>
          <div class="modal--header">
            <Title>
              <template #title>
                <h4>{{ $t('modal-profile-tutorial.title') }}</h4>
              </template>
            </Title>
          </div>
          <div class="modal--body">
            <p
              class="dark:text-primary-text text-secondary-text font-bold mb-12"
            >
              {{ $t('modal-profile-tutorial.description') }}
            </p>
            <div
              class="
                grid grid-cols-1
                lg:grid-cols-4
                gap-y-14
                lg:gap-x-10 lg:gap-y-0
                mb-12
                lg:mb-24
              "
            >
              <div class="flex flex-col relative">
                <img
                  src="@/assets/images/icn-arrow-right.svg"
                  alt="Arrow"
                  class="
                    w-8
                    absolute
                    bottom-0
                    lg:bottom-auto
                    left-1/2
                    lg:left-auto lg:top-1/4 lg:right-0
                    transform
                    translate-y-8
                    -translate-x-1/2
                    lg:translate-y-0 lg:translate-x-8
                    rotate-90
                    lg:rotate-0
                  "
                />
                <img
                  src="@/assets/images/step-1.svg"
                  class="mb-6"
                  alt="Step 1"
                />
                <p
                  class="
                    text-white
                    font-bold
                    text-center
                    flex-grow flex
                    justify-center
                    items-center
                    last
                  "
                >
                  {{ $t('modal-profile-tutorial.step-1') }}
                </p>
              </div>

              <div class="flex flex-col relative">
                <img
                  src="@/assets/images/icn-arrow-right.svg"
                  alt="Arrow"
                  class="
                    w-8
                    absolute
                    bottom-0
                    lg:bottom-auto
                    left-1/2
                    lg:left-auto lg:top-1/4 lg:right-0
                    transform
                    translate-y-8
                    -translate-x-1/2
                    lg:translate-y-0 lg:translate-x-8
                    rotate-90
                    lg:rotate-0
                  "
                />
                <img
                  src="@/assets/images/step-2.svg"
                  class="mb-6"
                  alt="Step 2"
                />
                <p
                  class="
                    text-white
                    font-bold
                    text-center
                    flex-grow flex
                    justify-center
                    items-center
                    last
                  "
                >
                  {{ $t('modal-profile-tutorial.step-2') }}
                </p>
              </div>

              <div class="flex flex-col relative">
                <img
                  src="@/assets/images/icn-arrow-right.svg"
                  alt="Arrow"
                  class="
                    w-8
                    absolute
                    bottom-0
                    lg:bottom-auto
                    left-1/2
                    lg:left-auto lg:top-1/4 lg:right-0
                    transform
                    translate-y-8
                    -translate-x-1/2
                    lg:translate-y-0 lg:translate-x-8
                    rotate-90
                    lg:rotate-0
                  "
                />
                <img
                  src="@/assets/images/step-3.svg"
                  class="mb-6"
                  alt="Step 3"
                />
                <p
                  class="
                    text-white
                    font-bold
                    text-center
                    flex-grow flex
                    justify-center
                    items-center
                    last
                  "
                >
                  {{ $t('modal-profile-tutorial.step-3') }}
                </p>
              </div>

              <div class="flex flex-col">
                <img
                  src="@/assets/images/step-4.svg"
                  class="mb-6"
                  alt="Step 4"
                />
                <p
                  class="
                    text-white
                    font-bold
                    text-center
                    flex-grow flex
                    justify-center
                    items-center
                    last
                  "
                >
                  {{ $t('modal-profile-tutorial.step-4') }}
                </p>
              </div>
            </div>
            <p
              class="
                dark:text-primary-text
                text-secondary-text text-center
                font-bold
                last
              "
            >
              {{ $t('modal-profile-tutorial.description-final') }}
            </p>
          </div>
          <div class="modal--footer">
            <button
              type="button"
              @click="$emit('handleFooterButton')"
              class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
              role="button"
              aria-pressed="false"
            >
              {{ $t('general.comenzar') }}
            </button>
          </div>
        </template>
      </Modal>
    </transition>
  </teleport>
</template>

<script>
import Modal from '@/components/Modal'
import Title from '@/components/Title'

export default {
  name: 'ModalProfileTutorial',
  components: {
    Title,
    Modal
  },
  emits: ['cerrarModal', 'handleFooterButton']
}
</script>
