<template>
  <svg
    alt="Utopicode"
    width="17"
    height="23"
    viewBox="0 0 17 23"
    class="fill-current dark:text-white text-contrast"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.1835 11.3301L14.2135 15.8636C14.1935 16.8791 14.0087 17.8317 13.6606 18.7183C13.3111 19.605 12.8241 20.3799 12.1968 21.0416C11.5694 21.7034 10.8217 22.2277 9.95507 22.6115C9.08848 22.994 8.13738 23.1874 7.10033 23.1874C6.04466 23.1874 5.0821 22.9983 4.21551 22.6201C3.34891 22.242 2.60694 21.7206 1.98815 21.0588C1.36936 20.3956 0.886646 19.6193 0.538576 18.7269C0.189075 17.8345 0.0100267 16.8806 0 15.865L0.0300801 11.3315H2.65994L2.7201 15.865C2.73012 16.5124 2.83182 17.1355 3.02663 17.7328C3.22143 18.3301 3.49931 18.8587 3.86314 19.3171C4.22697 19.7754 4.6796 20.1435 5.22247 20.4229C5.76535 20.7022 6.38987 20.8411 7.09746 20.8411C7.80506 20.8411 8.42815 20.7036 8.96529 20.43C9.50387 20.1564 9.95364 19.7869 10.3175 19.3242C10.6813 18.8616 10.9577 18.3301 11.1468 17.7328C11.3359 17.1355 11.4405 16.5124 11.4605 15.865L11.5049 11.3315H14.1835V11.3301Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>
