<template>
  <div class="h-screen w-screen bg-unboxing overflow-hidden">
    <video
      class="h-screen w-screen object-cover object-center absolute lg:hidden"
      autoplay
      loop
      muted
      playsinline
    >
      <source
        src="https://webdynamiccontentutopicode.s3.us-west-2.amazonaws.com/unboxing/utopicode-unboxing-mobile.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <video
      class="
        h-screen
        w-screen
        object-contain object-center
        absolute
        hidden
        lg:block
      "
      autoplay
      loop
      muted
      playsinline
    >
      <source
        src="https://webdynamiccontentutopicode.s3.us-west-2.amazonaws.com/unboxing/utopicode.mp4"
        type="video/mp4"
      />
      Your browser does not support the video tag.
    </video>
    <Share
      button-styles="px-8 py-4 text-white flex items-center bg-tertiary rounded-lg w-64 lg:w-auto absolute z-10 left-1/2 bottom-0 mb-8 lg:mb-20 transform -translate-x-2/4"
      link="es/sign-up"
      :title="$t('unboxing-page.title')"
    >
      <template #button>
        {{ $t('general.compartir-experiencia') }}
        <svg
          class="ml-2 w-5 text-white fill-current"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5 8.75C9.87109 8.75 9.29688 8.96875 8.85938 9.32422L6.04297 7.57422C6.07031 7.4375 6.125 7.16406 6.125 7C6.125 6.86328 6.07031 6.58984 6.04297 6.45312L8.85938 4.70312C9.29688 5.05859 9.87109 5.25 10.5 5.25C11.9492 5.25 13.125 4.10156 13.125 2.625C13.125 1.17578 11.9492 0 10.5 0C9.02344 0 7.875 1.17578 7.875 2.625C7.875 2.84375 7.875 3.03516 7.92969 3.19922L5.11328 4.94922C4.67578 4.59375 4.10156 4.375 3.5 4.375C2.02344 4.375 0.875 5.55078 0.875 7C0.875 8.47656 2.02344 9.625 3.5 9.625C4.10156 9.625 4.67578 9.43359 5.11328 9.07812L7.92969 10.8281C7.90234 10.9648 7.875 11.2383 7.875 11.375C7.875 12.8516 9.02344 14 10.5 14C11.9492 14 13.125 12.8516 13.125 11.375C13.125 9.92578 11.9492 8.75 10.5 8.75Z"
          />
        </svg>
      </template>
    </Share>
  </div>
</template>

<script>
import Share from '@/components/Share'
export default {
  name: 'Unboxing',
  components: {
    Share
  }
}
</script>
