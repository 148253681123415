<template>
  <header class="header--wrapper" :class="{ open: isShowingMenu }">
    <div class="header--container">
      <!-- Logo -->
      <router-link class="header--logo" :to="{ name: 'AdminWelcome' }">
        <Logo />
      </router-link>
      <!-- End Logo -->
      <!-- Menu -->
      <transition name="fade">
        <div class="header--nav" :class="{ open: isShowingMenu }">
          <div class="header--nav--left">
            <router-link
              :to="{ name: 'AdminWelcome' }"
              :title="$t('admin-header.inicio')"
              class="header--nav--link"
              ><span @click="handleCloseMenu" class="menu-span">
                {{ $t('admin-header.inicio') }}
              </span>
            </router-link>
            <router-link
              :to="{ name: 'AdminCandidates' }"
              :title="$t('admin-header.candidatos')"
              class="header--nav--link"
              ><span @click="handleCloseMenu" class="menu-span">
                {{ $t('admin-header.candidatos') }}
              </span>
            </router-link>
            <router-link
              :to="{ name: 'AdminEvents' }"
              :title="$t('admin-header.eventos')"
              class="header--nav--link"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('admin-header.eventos') }}
              </span>
            </router-link>
          </div>
          <div class="header--nav--right">
            <button
              type="button"
              class="header--nav--link--active"
              @click="signOut"
            >
              Sign Out
            </button>
            <SwitchThemeColor />
            <!-- End If User is Login -->
          </div>
        </div>
      </transition>
      <!-- End Menu -->
      <!-- Mobile Button -->
      <button
        @click="isShowingMenu = !isShowingMenu"
        class="header--toggle"
        :class="{ collapsed: !isShowingMenu }"
      >
        <span class="icon-wrapper">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </span>
      </button>
      <!-- End Mobile Button -->
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ROUTES from '@/constants/routes'
import Logo from '@/components/svg/logo'
import SwitchThemeColor from '@/components/SwitchThemeColor'

export default {
  name: 'Header',
  components: {
    Logo,
    SwitchThemeColor
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['signOut']),
    handleCloseMenu() {
      this.isShowingMenu = false
      this.isShowingMenuProfile = false
    }
  },
  data: function () {
    return {
      ROUTES,
      isShowingMenu: false,
      isShowingMenuProfile: false
    }
  }
}
</script>
