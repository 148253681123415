<template>
  <slot></slot>
</template>

<script>
export default {
  name: 'NoHeaderFooterLayout'
}
</script>

<style lang="scss" scoped></style>
