<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('home-page.title')"
      :hero-description="$t('home-page.description')"
      :hero-image="'hero-home.jpg'"
      class="lg:min-h-screen variation lg:mb-10"
    >
      <template v-slot:default>
        <div
          class="
            lg:w-10/12
            mx-auto
            flex flex-col
            items-center
            justify-center
            mb-8
            lg:mb-10
          "
        >
          <h2
            class="
              text-center text-white
              font-bold
              text-3xl
              lg:text-6xl
              mx-auto
              mb-6
              lg:leading-tight lg:w-10/12
            "
          >
            {{ $t('home-page.title') }}
          </h2>
          <p class="lg:text-2xl text-xl text-white text-center">
            {{ $t('home-page.description') }}
          </p>
        </div>
        <div class="grid lg:grid-cols-3 gap-6 mx-auto lg:w-12/12 my-8">
          <!-- Card Events -->
          <router-link :to="{ name: 'Eventos' }">
            <div
              class="
                dark:bg-contrast
                bg-white
                h-full
                w-full
                p-5
                rounded-xl
                shadow-lg
                flex flex-col
              "
            >
              <div class="flex-grow">
                <div class="flex gap-4 items-center mb-4">
                  <div
                    class="
                      rounded-full
                      text-white
                      bg-gradient-to-br
                      from-red-500
                      to-eighth
                      w-16
                      h-16
                      flex flex-none
                      justify-center
                      items-center
                    "
                  >
                    <svg
                      viewBox="0 0 31 35"
                      xmlns="http://www.w3.org/2000/svg"
                      class="fill-current w-full h-8"
                    >
                      <path
                        d="M27.1667 34.1673H3.83333C1.99238 34.1673 0.5 32.6749 0.5 30.834V7.50065C0.5 5.6597 1.99238 4.16732 3.83333 4.16732H7.16667V0.833984H10.5V4.16732H20.5V0.833984H23.8333V4.16732H27.1667C29.0076 4.16732 30.5 5.6597 30.5 7.50065V30.834C30.5 32.6749 29.0076 34.1673 27.1667 34.1673ZM3.83333 14.1673V30.834H27.1667V14.1673H3.83333ZM3.83333 7.50065V10.834H27.1667V7.50065H3.83333ZM23.8333 27.5007H20.5V24.1673H23.8333V27.5007ZM17.1667 27.5007H13.8333V24.1673H17.1667V27.5007ZM10.5 27.5007H7.16667V24.1673H10.5V27.5007ZM23.8333 20.834H20.5V17.5007H23.8333V20.834ZM17.1667 20.834H13.8333V17.5007H17.1667V20.834ZM10.5 20.834H7.16667V17.5007H10.5V20.834Z"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                      text-contrast
                      dark:text-white
                      font-bold
                      text-lg
                      uppercase
                    "
                  >
                    {{ $t('home-page.card-home-title-01') }}
                  </h3>
                </div>
                <p class="text-contrast dark:text-white text-base">
                  {{ $t('home-page.card-home-description-01') }}
                </p>
              </div>
              <p
                class="
                  dark:text-primary-text
                  text-secondary-text
                  last
                  uppercase
                  ml-auto
                "
              >
                {{ $t('home-page.card-home-cta-01') }}
              </p>
            </div>
          </router-link>
          <!-- End Card Events -->
          <!-- Card Community -->
          <router-link :to="{ name: 'Comunidad' }">
            <div
              class="
                bg-white
                dark:bg-contrast
                p-5
                h-full
                w-full
                rounded-xl
                shadow-lg
                flex flex-col
              "
            >
              <div class="flex-grow">
                <div class="flex gap-4 items-center mb-4">
                  <div
                    class="
                      rounded-full
                      text-white
                      bg-gradient-to-br
                      from-tertiary
                      to-green-600
                      w-16
                      h-16
                      flex flex-none
                      justify-center
                      items-center
                    "
                  >
                    <svg
                      viewBox="0 0 26 21"
                      class="fill-current w-full h-8"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.0101 2.16817C20.3529 1.4078 18.5759 0.847591 16.7179 0.526737C16.6841 0.520544 16.6503 0.536019 16.6328 0.56697C16.4043 0.973453 16.1511 1.50374 15.9739 1.92055C13.9754 1.62137 11.9873 1.62137 10.0298 1.92055C9.85254 1.49448 9.59019 0.973453 9.36062 0.56697C9.34319 0.537052 9.30939 0.521577 9.27555 0.526737C7.41857 0.846566 5.64152 1.40677 3.98335 2.16817C3.96899 2.17436 3.95669 2.18469 3.94852 2.19809C0.577841 7.23381 -0.345529 12.1458 0.107445 16.9968C0.109495 17.0205 0.122817 17.0432 0.141265 17.0577C2.36514 18.6908 4.51935 19.6823 6.63355 20.3395C6.66739 20.3498 6.70324 20.3375 6.72477 20.3096C7.22489 19.6266 7.6707 18.9065 8.05293 18.1492C8.07549 18.1049 8.05396 18.0522 8.00785 18.0347C7.30073 17.7665 6.6274 17.4394 5.97971 17.068C5.92848 17.0381 5.92437 16.9648 5.9715 16.9297C6.1078 16.8276 6.24414 16.7213 6.37428 16.614C6.39783 16.5944 6.43064 16.5903 6.45833 16.6027C10.7134 18.5454 15.32 18.5454 19.5249 16.6027C19.5525 16.5893 19.5854 16.5934 19.6099 16.613C19.7401 16.7203 19.8764 16.8276 20.0137 16.9297C20.0609 16.9648 20.0578 17.0381 20.0066 17.068C19.3589 17.4466 18.6855 17.7665 17.9774 18.0337C17.9313 18.0512 17.9108 18.1049 17.9333 18.1492C18.3238 18.9054 18.7696 19.6256 19.2605 20.3086C19.281 20.3375 19.3179 20.3498 19.3517 20.3395C21.4761 19.6823 23.6303 18.6908 25.8542 17.0577C25.8737 17.0432 25.886 17.0216 25.8881 16.9978C26.4302 11.3895 24.98 6.51783 22.0439 2.19912C22.0367 2.18469 22.0245 2.17436 22.0101 2.16817ZM8.68836 14.043C7.40729 14.043 6.35173 12.8669 6.35173 11.4225C6.35173 9.97813 7.38682 8.80202 8.68836 8.80202C10.0001 8.80202 11.0455 9.98846 11.025 11.4225C11.025 12.8669 9.98986 14.043 8.68836 14.043ZM17.3276 14.043C16.0466 14.043 14.991 12.8669 14.991 11.4225C14.991 9.97813 16.0261 8.80202 17.3276 8.80202C18.6394 8.80202 19.6847 9.98846 19.6643 11.4225C19.6643 12.8669 18.6394 14.043 17.3276 14.043Z"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                      text-contrast
                      dark:text-white
                      font-bold
                      text-lg
                      uppercase
                    "
                  >
                    {{ $t('home-page.card-home-title-03') }}
                  </h3>
                </div>
                <p class="text-contrast dark:text-white text-base">
                  {{ $t('home-page.card-home-description-03') }}
                </p>
              </div>
              <p
                class="
                  dark:text-primary-text
                  text-secondary-text
                  uppercase
                  last
                  ml-auto
                "
              >
                {{ $t('home-page.card-home-cta-03') }}
              </p>
            </div>
          </router-link>
          <!-- End Card Community -->
          <!-- Card Developers -->
          <router-link :to="{ name: 'Contrata' }">
            <div
              class="
                bg-white
                dark:bg-contrast
                p-5
                h-full
                w-full
                rounded-xl
                shadow-lg
                flex flex-col
              "
            >
              <div class="flex-grow">
                <div class="flex gap-4 items-center mb-4">
                  <div
                    class="
                      rounded-full
                      text-white
                      bg-gradient-to-br
                      from-seventh
                      to-yellow-600
                      w-16
                      h-16
                      flex flex-none
                      justify-center
                      items-center
                    "
                  >
                    <svg
                      viewBox="0 0 27 33"
                      class="fill-current w-full h-8"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.16699 8.83333C5.16699 4.23096 8.89795 0.5 13.5003 0.5C18.1027 0.5 21.8337 4.23096 21.8337 8.83333C21.8337 13.4357 18.1027 17.1667 13.5003 17.1667C8.89795 17.1667 5.16699 13.4357 5.16699 8.83333ZM13.5003 13.8333C16.2617 13.8333 18.5003 11.5948 18.5003 8.83333C18.5003 6.07191 16.2617 3.83333 13.5003 3.83333C10.7389 3.83333 8.50032 6.07191 8.50032 8.83333C8.50032 11.5948 10.7389 13.8333 13.5003 13.8333Z"
                      />
                      <path
                        d="M4.07223 22.7386C1.57175 25.2391 0.166992 28.6304 0.166992 32.1667H3.50033C3.50033 29.5145 4.55389 26.971 6.42926 25.0956C8.30462 23.2202 10.8482 22.1667 13.5003 22.1667C16.1525 22.1667 18.696 23.2202 20.5714 25.0956C22.4468 26.971 23.5003 29.5145 23.5003 32.1667H26.8337C26.8337 28.6304 25.4289 25.2391 22.9284 22.7386C20.4279 20.2381 17.0365 18.8333 13.5003 18.8333C9.9641 18.8333 6.57272 20.2381 4.07223 22.7386Z"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                      text-contrast
                      dark:text-white
                      font-bold
                      text-lg
                      uppercase
                    "
                  >
                    {{ $t('home-page.card-home-title-04') }}
                  </h3>
                </div>
                <p class="text-contrast dark:text-white text-base">
                  {{ $t('home-page.card-home-description-04') }}
                </p>
              </div>
              <p
                class="
                  dark:text-primary-text
                  text-secondary-text
                  uppercase
                  last
                  ml-auto
                "
              >
                {{ $t('home-page.card-home-cta-04') }}
              </p>
            </div>
          </router-link>
        </div>
        <!-- End Card Developers -->
        <!-- Card Upskilling -->
        <div class="grid lg:grid-cols-2 grid-rows-2 gap-6 mx-auto lg:w-8/12">
          <router-link :to="{ name: 'UpskillingB2C' }">
            <div
              class="
                dark:bg-contrast
                bg-white
                p-5
                h-full
                w-full
                rounded-xl
                shadow-lg
                flex flex-col
              "
            >
              <div class="flex-grow">
                <div class="flex gap-4 items-center mb-4">
                  <div
                    class="
                      rounded-full
                      text-white
                      bg-gradient-to-br
                      from-blue-400
                      to-secondary
                      w-16
                      h-16
                      flex flex-none
                      justify-center
                      items-center
                    "
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 16.3H30.5V11.5C30.5 9.7 29.8667 8.16667 28.6 6.9C27.3333 5.63333 25.8 5 24 5C22.2 5 20.6667 5.63333 19.4 6.9C18.1333 8.16667 17.5 9.7 17.5 11.5H14.5C14.5 8.86667 15.425 6.625 17.275 4.775C19.125 2.925 21.3667 2 24 2C26.6333 2 28.875 2.925 30.725 4.775C32.575 6.625 33.5 8.86667 33.5 11.5V16.3H37C37.8333 16.3 38.5417 16.5917 39.125 17.175C39.7083 17.7583 40 18.4667 40 19.3V41C40 41.8333 39.7083 42.5417 39.125 43.125C38.5417 43.7083 37.8333 44 37 44H11C10.1667 44 9.45833 43.7083 8.875 43.125C8.29167 42.5417 8 41.8333 8 41V19.3C8 18.4667 8.29167 17.7583 8.875 17.175C9.45833 16.5917 10.1667 16.3 11 16.3ZM11 41H37V19.3H11V41ZM24 34C25.0667 34 25.975 33.6333 26.725 32.9C27.475 32.1667 27.85 31.2833 27.85 30.25C27.85 29.25 27.475 28.3417 26.725 27.525C25.975 26.7083 25.0667 26.3 24 26.3C22.9333 26.3 22.025 26.7083 21.275 27.525C20.525 28.3417 20.15 29.25 20.15 30.25C20.15 31.2833 20.525 32.1667 21.275 32.9C22.025 33.6333 22.9333 34 24 34ZM11 41V19.3V41Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                      text-contrast
                      dark:text-white
                      font-bold
                      text-lg
                      uppercase
                    "
                  >
                    {{ $t('home-page.card-home-title-02') }}
                  </h3>
                </div>
                <p class="text-contrast dark:text-white text-base">
                  {{ $t('home-page.card-home-description-02') }}
                </p>
              </div>
              <p
                class="
                  dark:text-primary-text
                  text-secondary-text
                  ml-auto
                  last
                  uppercase
                "
              >
                {{ $t('home-page.card-home-cta-02') }}
              </p>
            </div>
          </router-link>
          <!-- End Card Upskilling -->
          <!-- Card Unlock -->
          <router-link :to="{ name: 'Upskilling' }">
            <div
              class="
                dark:bg-contrast
                bg-white
                p-5
                h-full
                w-full
                rounded-xl
                shadow-lg
                flex flex-col
              "
            >
              <div class="flex-grow">
                <div class="flex gap-4 items-center mb-4">
                  <div
                    class="
                      rounded-full
                      text-white
                      bg-gradient-to-br
                      from-blue-400
                      to-secondary
                      w-16
                      h-16
                      flex flex-none
                      justify-center
                      items-center
                    "
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.45 42V35.65H14.9C14.0667 35.65 13.3583 35.3583 12.775 34.775C12.1917 34.1917 11.9 33.4833 11.9 32.65V27.1H9.90001C9.23334 27.1 8.73334 26.7833 8.40001 26.15C8.06667 25.5167 8.08334 24.8667 8.45001 24.2L11.9 18C12.7 14.5667 14.3917 11.7083 16.975 9.425C19.5583 7.14167 22.5667 6 26 6C29.9 6 33.2083 7.35833 35.925 10.075C38.6417 12.7917 40 16.1 40 20C40 22.8 39.175 25.3083 37.525 27.525C35.875 29.7417 33.7833 31.4667 31.25 32.7V42H18.45ZM24.45 27.3H27.45L27.6 25.1C28 25.0333 28.375 24.8917 28.725 24.675C29.075 24.4583 29.3833 24.2167 29.65 23.95L31.75 24.65L33.15 22.25L31.65 21.05C31.8167 20.5833 31.9 20.1 31.9 19.6C31.9 19.1 31.8167 18.6167 31.65 18.15L33.15 16.95L31.75 14.55L29.65 15.25C29.3833 14.9833 29.0667 14.75 28.7 14.55C28.3333 14.35 27.9667 14.2 27.6 14.1L27.45 11.9H24.45L24.3 14.1C23.9333 14.2 23.5667 14.35 23.2 14.55C22.8333 14.75 22.5167 14.9833 22.25 15.25L20.15 14.55L18.75 16.95L20.25 18.15C20.0833 18.6167 20 19.1 20 19.6C20 20.1 20.0833 20.5833 20.25 21.05L18.75 22.25L20.15 24.65L22.25 23.95C22.5167 24.2167 22.825 24.4583 23.175 24.675C23.525 24.8917 23.9 25.0333 24.3 25.1L24.45 27.3ZM25.95 23.1C24.9833 23.1 24.1583 22.7583 23.475 22.075C22.7917 21.3917 22.45 20.5667 22.45 19.6C22.45 18.6333 22.7917 17.8083 23.475 17.125C24.1583 16.4417 24.9833 16.1 25.95 16.1C26.9167 16.1 27.7417 16.4417 28.425 17.125C29.1083 17.8083 29.45 18.6333 29.45 19.6C29.45 20.5667 29.1083 21.3917 28.425 22.075C27.7417 22.7583 26.9167 23.1 25.95 23.1ZM21.45 39H28.25V30.75L29.65 30.1C31.75 29.1333 33.5 27.775 34.9 26.025C36.3 24.275 37 22.2667 37 20C37 16.9333 35.9333 14.3333 33.8 12.2C31.6667 10.0667 29.0667 9 26 9C23.1333 9 20.6333 9.9 18.5 11.7C16.3667 13.5 15.2167 15.8 15.05 18.6L12 24.1H14.9V32.65H21.45V39Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <h3
                    class="
                      text-contrast
                      dark:text-white
                      font-bold
                      text-lg
                      uppercase
                    "
                  >
                    {{ $t('home-page.card-home-title-05') }}
                  </h3>
                </div>
                <p class="text-contrast dark:text-white text-base">
                  {{ $t('home-page.card-home-description-05') }}
                </p>
              </div>
              <p
                class="
                  dark:text-primary-text
                  text-secondary-text
                  ml-auto
                  last
                  uppercase
                "
              >
                {{ $t('home-page.card-home-cta-05') }}
              </p>
            </div>
          </router-link>
          <!-- End Card Unlock-->
        </div>
      </template>
    </Hero>
    <div class="content--wrapper">
      <!-- Sección Comunidad -->
      <section class="content--comunidad">
        <h2
          class="
            title--h3
            font-bold
            text-center text-contrast
            dark:text-white
            font-bold
            mb-2
            lg:mb-3
            text-2xl
            lg:text-4xl
          "
        >
          {{ $t('home-page.title-comunidad') }}
        </h2>
      </section>
    </div>
    <splide
      v-if="conferences.length > 0"
      class="splide--default mb-12"
      :options="optionsV1"
    >
      <splide-slide
        class="w-full max-w-xs pr-3 mr-3"
        v-for="(conference, index) in conferences"
        :key="index"
      >
        <CardComunidad class="h-full" :conferenceData="conference" />
      </splide-slide>
    </splide>
    <div class="flex justify-center mb-10 lg:mb-32">
      <router-link
        :to="{ name: 'Eventos' }"
        class="btn btn-md btn-rounded-lg btn-secondary"
        >{{ $t('home-page.cta-comunidad') }}</router-link
      >
    </div>
    <!-- <splide class="splide--default mb-10 lg:mb-32" :options="optionsV1">
      <splide-slide
        class="w-full max-w-xs mr-3 pr-3"
        v-for="(conference, index) in conferences"
        :key="index"
      >
        <CardComunidad
          v-if="conferences.length > 0"
          :conferenceData="conference"
        />
      </splide-slide>
    </splide> -->
    <!-- End Sección Comunidad -->
    <!-- Sección Evalúa tus Habilidades -->
    <!-- <div class="content--wrapper">
      <div class="content--habilidades mb-10">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('home-page.title-habilidades') }}</h2>
          </template>
          <template #options>
            <router-link
              :to="{ name: 'Habilidades' }"
              class="
                btn btn-sm btn-rounded-lg btn-secondary
                transform
                -translate-y-3
              "
              >{{ $t('home-page.cta-habilidades') }}</router-link
            >
          </template>
        </Title>
      </div>
    </div> -->
    <!-- <Tabs class="tabs-horizontal" :tabList="tabList">
      <template v-slot:tabPanel-1>
        <splide class="splide--default mb-10 lg:mb-32" :options="optionsV2">
          <splide-slide
            class="w-full max-w-xs pr-3 mr-3"
            v-for="(card, index) in CardCourses"
            :key="index"
          >
            <CardCourse :cardData="card" />
          </splide-slide>
        </splide>
      </template>
      <template v-slot:tabPanel-2>
        <splide class="splide--default mb-10 lg:mb-32" :options="optionsV2">
          <splide-slide
            class="w-full max-w-xs pr-3 mr-3"
            v-for="(card, index) in CardCourses"
            :key="index"
          >
            <CardCourse :cardData="card" />
          </splide-slide>
        </splide>
      </template>
      <template v-slot:tabPanel-3>
        <splide class="splide--default mb-10 lg:mb-32" :options="optionsV2">
          <splide-slide
            class="w-full max-w-xs pr-3 mr-3"
            v-for="(card, index) in CardCourses"
            :key="index"
          >
            <CardCourse :cardData="card" />
          </splide-slide>
        </splide>
      </template>
      <template v-slot:tabPanel-4>
        <splide class="splide--default mb-10 lg:mb-32" :options="optionsV2">
          <splide-slide
            class="w-full max-w-xs pr-3 mr-3"
            v-for="(card, index) in CardCourses"
            :key="index"
          >
            <CardCourse :cardData="card" />
          </splide-slide>
        </splide>
      </template>
    </Tabs> -->

    <!-- End Sección Evalúa tus Habilidades -->
    <!-- Sección Trabajos Tech -->
    <div class="content--wrapper">
      <section class="content--trabajos mb-10 lg:mb-20">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('home-page.title-trabajos') }}</h2>
          </template>
          <template #options>
            <router-link
              :to="{ name: 'Trabajos Tech' }"
              class="
                btn btn-sm btn-rounded-lg btn-secondary
                transform
                -translate-y-3
              "
              >{{ $t('home-page.cta-trabajos') }}</router-link
            >
          </template>
        </Title>
      </section>
    </div>
    <Splide
      v-if="jobsExamples.length > 0"
      class="splide--default mb-10 lg:mb-32"
      :options="optionsV3"
    >
      <SplideSlide
        class="h-auto mx-6 flex flex-col gap-8 pb-6"
        v-for="(job, index) in jobsExamples"
        :key="job.id"
      >
        <CardTrabajo
          v-if="(index + 1) % 2 !== 0"
          :key="jobsExamples[index].id"
          v-bind:jobData="jobsExamples[index]"
          horizontal
          class="h-auto shadow-lg logo-invert"
        />
        <CardTrabajo
          v-if="jobsExamples.length > index + 1 && (index + 1) % 2 !== 0"
          :key="jobsExamples[index + 1].id"
          v-bind:jobData="jobsExamples[index + 1]"
          horizontal
          class="h-auto shadow-lg transform lg:translate-x-10 logo-invert"
        />
      </SplideSlide>
    </Splide>
    <section class="content--contratar">
      <p class="text-center text-submain font-bold text-lg">
        {{ $t('home-page.texto-contrata')
        }}<router-link class="underline ml-2" :to="{ name: 'Contrata' }">{{
          $t('home-page.link-contrata')
        }}</router-link>
      </p>
    </section>
    <!-- <Infinite class="h-32 mb-8" duration="40s">
      <div class="flex gap-6">
        <CardTrabajo
          v-for="job in jobsExamples"
          :key="job.id"
          v-bind:jobData="job"
          :image="job.image"
          horizontal
          class="h-auto shadow-lg"
        />
      </div>
    </Infinite>
    <Infinite class="h-32 mb-32" duration="40s">
      <div class="flex gap-6 pl-32">
        <CardTrabajo
          v-for="job in jobsExamples"
          :key="job.id"
          v-bind:jobData="job"
          :image="job.image"
          horizontal
          class="h-auto shadow-lg"
        />
      </div>
    </Infinite> -->
    <!-- End Sección Trabajos Tech -->
    <!-- Sección Join Community -->
    <div class="content--wrapper">
      <section class="relative pt-24">
        <div
          class="
            content--wrapper
            flex
            lg:justify-between
            flex-wrap
            relative
            items-center
            lg:flex-row-reverse
          "
        >
          <div class="w-full lg:w-6/12">
            <img
              src="@/assets/images/img-join-community-utopicode.png"
              class="mx-auto w-full"
              alt="Utopicode"
            />
          </div>
          <div class="w-full lg:w-5/12 mb-12 lg:mb-0">
            <h2
              class="
                text-contrast
                dark:text-white
                font-bold
                mb-2
                lg:mb-3
                text-2xl
                lg:text-4xl
              "
            >
              {{ $t('home-page.title-join-community') }}
            </h2>
            <p
              class="
                dark:dark:text-primary-text
                text-secondary-text text-secondary-text text-lg
                mb-8
              "
            >
              {{ $t('home-page.txt-join-community') }}
            </p>
            <p class="flex">
              <router-link
                :to="{ name: 'SignUp' }"
                class="btn btn-lg btn-rounded-lg btn-primary"
                >{{ $t('home-page.cta-join-community') }}</router-link
              >
            </p>
          </div>
        </div>
      </section>
      <!-- End Sección Join Community -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/FullHero'
import Title from '@/components/Title'
import CardComunidad from '@/components/CardComunidad'
// import CardCourse from '@/components/CardCourse'
import CardTrabajo from '@/components/CardTrabajo'
import ApolloClient from '../graphql/apolloClient'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import HomepageQuery from '../graphql/queries/homepage'
import ROUTES from '../constants/routes'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
// import Tabs from '@/components/Tabs.vue'
// import Infinite from '@/components/InfiniteSlider.vue'
export default {
  name: 'Home',
  components: {
    Hero,
    Title,
    CardComunidad,
    Splide,
    SplideSlide,
    CardTrabajo
  },
  data() {
    return {
      ROUTES,
      jobs: [],
      conferences: [],
      assesments: [],
      optionsV1: {
        rewind: true,
        perPage: 4,
        pagination: true,
        autoplay: true,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      },
      optionsV2: {
        rewind: true,
        perPage: 6,
        type: 'loop',
        pagination: true,
        autoplay: true,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      },
      optionsV3: {
        rewind: true,
        type: 'loop',
        perPage: 2,
        pagination: true,
        autoplay: true,
        pauseOnHover: true,
        arrows: true,
        autoWidth: true,
        padding: '1rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      },
      CardCourses: [
        {
          title: 'New Emotional Intelligence and How to Develop It',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
          new: false
        },
        {
          title: 'Maintaining Professionalism in Times of Conflict',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1502945015378-0e284ca1a5be?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
          new: true
        },
        {
          title: 'Perfecting the handoff (English course)',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1172&q=80',
          new: true
        },
        {
          title: 'Maintaining Professionalism in Times of Conflict',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1534353436294-0dbd4bdac845?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=687&q=80',
          new: false
        },
        {
          title: 'Level Up From Junior to Midweight Software Developers',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1605&q=80',
          new: false
        },
        {
          title: 'Maintaining Professionalism in Times of Conflict',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1502945015378-0e284ca1a5be?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
          new: true
        },
        {
          title: 'Maintaining Professionalism in Times of Conflict',
          url: 'http://www.google.com.ar',
          img: 'https://images.unsplash.com/photo-1502945015378-0e284ca1a5be?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80',
          new: false
        }
      ],
      tabList: ['Featured', 'Most Popular', 'Just Added', 'Trending'],
      jobsExamples: [
        {
          id: 1,
          jobTitle: 'Frontend Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          image: require('@/assets/images/clients/logo-konfio.svg'),
          company: {
            id: 1,
            name: 'Konfio',
            about:
              'Fintech dedicada a apoyar a las Pymes a resolver sus problemas financieros.'
          }
        },
        {
          id: 2,
          jobTitle: 'Backend Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          image: require('@/assets/images/clients/logo-flat.svg'),
          company: {
            id: 1,
            name: 'Flat',
            about:
              'Proptech que simplifica el proceso de compra o venta de un inmueble.'
          }
        },
        {
          id: 3,
          jobTitle: 'Mobile Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          image: require('@/assets/images/clients/logo-siclo.svg'),
          company: {
            id: 1,
            name: 'Siclo',
            about:
              'Startup de fitness que revoluciona la forma de ejercitarse. '
          }
        },
        {
          id: 4,
          jobTitle: 'Data Engineer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          image: require('@/assets/images/clients/logo-zebrands.svg'),
          company: {
            id: 1,
            name: 'Zbrands',
            about:
              'Grupo de marcas de retail para mejorar la vida diaria de las personas. '
          }
        },
        {
          id: 5,
          jobTitle: 'Frontend Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          image: require('@/assets/images/clients/logo-cumplo.svg'),
          company: {
            id: 1,
            name: 'Cumplo',
            about:
              'Plataforma de financiamiento colaborativo para desintermediar el sistema financiero.'
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  mounted() {
    this.getJobs()
  },
  updated() {
    this.getJobs()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getJobs: async function () {
      try {
        const homeQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale,
              variables: {
                eventLimit: 8
              }
            }
          },
          fetchPolicy: 'network-only',
          query: HomepageQuery
        })
        this.conferences = homeQuery.data.events
        this.assesments = homeQuery.data.assesments
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
