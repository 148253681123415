<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    viewBox="0 0 32.9 6.9"
    class="fill-current"
  >
    <path
      d="M1.8 6.9C.7 6.1 0 4.9 0 3.4 0 2 .7.8 1.8 0l.2.2C1.2.9.8 2.1.8 3.5c0 1.3.5 2.6 1.2 3.2l-.2.2zM8 4.1c0 .3-.2 1.1-.3 1.4h-4v-.2c1-1.4 2-2.9 2.9-4.4H5.4c-.6.1-.7.1-.9.4-.1.1-.2.3-.3.6h-.3c0-.4.1-1.1.1-1.5h.2c.1.2.2.2.4.2h3l.1.1c-1 1.4-1.9 2.9-2.9 4.4h1c.9 0 1.1 0 1.3-.2.2-.2.4-.5.5-.8H8zm2.9.7c-.5.6-1 .8-1.3.8-1 0-1.6-.8-1.6-1.7 0-.5.2-1 .5-1.3.3-.3.7-.5 1.1-.5.7 0 1.2.6 1.2 1.2 0 .1 0 .2-.1.3-.1 0-1.2.1-1.9.1 0 .9.5 1.3 1 1.3.3 0 .6-.1.9-.4l.2.2zM9.6 2.4c-.4 0-.6.3-.7.9h1c.1 0 .2 0 .2-.1 0-.4-.2-.8-.5-.8zm2.7-.2c.2-.1.3-.1.5-.1.9 0 1.5.7 1.5 1.6 0 1-.9 1.9-1.9 1.9-.4 0-.8-.1-1.3-.3V1.2c0-.4 0-.5-.5-.5V.4c.4 0 .9-.1 1.3-.3v2.2l.4-.1zm-.4 2.4v.3c.1.1.4.4.7.4.5 0 .8-.5.8-1.4 0-.9-.5-1.3-1-1.3-.2 0-.3 0-.4.1v1.9z"
    />
    <path
      d="M15.9 5.5H14v-.3c.4 0 .5-.1.5-.5V3.1c0-.4 0-.4-.4-.5v-.2c.4-.1.8-.1 1.2-.3v.8c.3-.5.6-.8.9-.8.3 0 .5.2.5.4 0 .3-.2.5-.3.6h-.2c-.1-.1-.2-.2-.3-.2-.1 0-.3.1-.5.5v1.4c0 .5 0 .5.6.5v.2zm3.1.1c-.2 0-.3-.1-.4-.2-.1-.1-.2-.2-.2-.3-.3.2-.7.4-.8.4-.6 0-1-.5-1-.9s.2-.6.6-.7c.4-.2 1-.3 1.2-.5v-.3c0-.4-.2-.7-.5-.7-.1 0-.2.1-.3.2-.1.1-.1.3-.2.5 0 .2-.1.2-.3.2-.2 0-.4-.2-.4-.4 0-.1.1-.2.3-.3.3-.2.7-.4 1.2-.5.3 0 .5.1.7.2.3.2.4.5.4.9v1.3c0 .4.1.4.2.4s.2 0 .2-.1l.1.2-.8.6zm-.6-1.9c-.1.1-.4.2-.5.3-.3.1-.4.3-.4.5 0 .4.3.6.5.6.1 0 .3 0 .4-.2V3.7z"
    />
    <path
      d="M21.8 5.5v-.3c.4 0 .4-.1.4-.6V3.4c0-.5-.2-.8-.6-.8-.2 0-.4.1-.7.3v1.8c0 .5 0 .5.4.5v.2h-1.7v-.2c.4-.1.5-.1.5-.6V3.1c0-.5-.1-.5-.4-.6v-.2c.4-.1.9-.2 1.3-.3v.6c.1-.1.3-.2.5-.3.2-.1.4-.2.6-.2.6 0 1 .4 1 1.2v1.4c0 .5 0 .5.4.6v.2h-1.7z"
    />
    <path
      d="M26.9 5.3c-.2 0-.8.1-1.4.3v-.5c-.1.1-.3.2-.5.3l-.4.2c-.6 0-1.5-.6-1.5-1.7 0-1 .9-1.8 2-1.8h.4v-.9c0-.4 0-.5-.5-.5V.4c.5-.1 1-.2 1.4-.3v4.4c0 .4 0 .4.3.5h.2v.3zm-1.4-2.5c-.1-.2-.4-.4-.6-.4-.3 0-.8.2-.8 1.3 0 1 .6 1.4.9 1.4.2 0 .4-.1.5-.2V2.8zm3.3.3c-.2-.5-.4-.8-.8-.8-.2 0-.4.2-.4.4 0 .3.2.4.6.6.6.3 1 .5 1 1.1 0 .7-.7 1.1-1.3 1.1-.4 0-.8-.1-.9-.2 0-.2-.1-.7-.1-.9h.2c.1.5.4.9.9.9.3 0 .5-.2.5-.4 0-.3-.1-.4-.6-.7-.5-.2-1-.5-1-1.1 0-.6.5-1 1.2-1 .3 0 .6.1.8.1 0 .2.1.7.1.9h-.2zM31.1 0c1.1.8 1.8 2 1.8 3.5 0 1.4-.7 2.6-1.8 3.4l-.2-.2c.7-.7 1.2-1.9 1.2-3.2 0-1.3-.5-2.6-1.2-3.2l.2-.3z"
    />
    <circle cx="9.5" cy="1" r=".5" />
  </svg>
</template>

<script>
export default {
  name: 'LogoZebrands'
}
</script>
