<template>
  <div :class="themeColor">
    <!-- Teleport -->
    <div id="modals"></div>
    <!-- End Teleport -->
    <div class="bg-main-light dark:bg-main-dark">
      <!-- View -->
      <component :is="this.$route.meta.layout || 'div'">
        <router-view />
      </component>
      <!-- End View -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['themeColor'])
  }
}
</script>

<style lang="scss">
// Styles are in @/assets/scss/
@import '@/assets/scss/main.scss';
</style>
