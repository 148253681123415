import gql from 'graphql-tag'

const editableEventQuery = gql`
  query editableEventQuery($eventId: Int!) {
    editableEvent(eventId: $eventId) {
      id
      nameEs
      nameEn
      photo
      date
      speaker
      videoLink
      calendarLink
      liveLink
      descriptionEs
      descriptionEn
      category
      subCategories
      speakerJobEn
      speakerJobEs
      speakerAboutEn
      speakerAboutEs
      speakerPhoto
      whatWillLearnEs
      whatWillLearnEn
    }
  }
`

export default editableEventQuery
