<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="fill-current"
    fill="none"
    viewBox="0 0 18 25"
  >
    <path
      d="M10.5 6.875V.5H1.125C.46875.5 0 1.01562 0 1.625v21.75c0 .6562.46875 1.125 1.125 1.125h15.75c.6094 0 1.125-.4688 1.125-1.125V8h-6.375c-.6562 0-1.125-.46875-1.125-1.125Zm3.0469 10.1719H10.5v3.75c0 .375-.375.75-.75.75h-1.5c-.42188 0-.75-.375-.75-.75v-3.75H4.40625c-.65625 0-.98437-.8438-.51563-1.3125l4.5-4.5c.32813-.2813.84376-.2813 1.17188 0l4.5 4.5c.4687.4687.1406 1.3125-.5156 1.3125Zm4.125-11.62502L13.0781.828125C12.8438.640625 12.5625.5 12.2812.5H12v6h6v-.28125c0-.28125-.1406-.5625-.3281-.79687Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'UploadIcon'
}
</script>
