import ApolloClient from '../graphql/apolloClient'
import basicInfoQuery from '../graphql/queries/basicUserInfo'

const getUser = async (token) => {
  const user = await ApolloClient.query({
    query: basicInfoQuery,
    fetchPolicy: 'network-only',
    context: {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  })
  return user.data
}

export { getUser }
