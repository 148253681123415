import gql from 'graphql-tag'

const eventQuery = gql`
  query eventQUery($eventId: Int!) {
    event(eventId: $eventId) {
      id
      date
      name
      place
      eventLang
      photo
      category
      subCategories
      inscriptions {
        id
        email
        speciality
        fullname
      }
    }
  }
`

export default eventQuery
