import gql from 'graphql-tag'

const sendUpskillingInterest = gql`
  mutation upskillingInterest(
    $fullname: String
    $email: String
    $phone: String
    $speciality: String
    $english: String
    $seniority: String
    $motivations: String
    $origin: String
  ) {
    upskillingInterest(
      fullname: $fullname
      email: $email
      phone: $phone
      speciality: $speciality
      english: $english
      seniority: $seniority
      motivations: $motivations
      origin: $origin
    )
  }
`

export default sendUpskillingInterest
