<template>
  <ModalProfileTutorial
    v-if="modalTutorialShow === true"
    @cerrarModal="modalTutorialShow = false"
    @handleFooterButton="$router.push({ name: 'Profile' })"
  />
  <main class="main--wrapper">
    <!-- Section Hero -->
    <section class="pt-32 pb-14 lg:pt-40 lg:pb-32">
      <div
        class="
          content--wrapper
          flex
          lg:space-x-10
          flex-wrap
          lg:flex-nowrap
          relative
          items-center
        "
      >
        <div class="w-full lg:w-6/12 mb-12 lg:mb-0">
          <h2
            class="
              dark:text-white
              text-contrast
              font-bold
              mb-2
              lg:mb-3
              title--h2
            "
          >
            {{ $t('nosotros-page.hero-title') }}
          </h2>
          <p class="dark:text-primary-text text-secondary-text text-xl mb-8">
            {{ $t('nosotros-page.hero-description') }}
          </p>
          <div
            class="
              flex flex-wrap
              lg:flex-nowrap
              space-y-6
              lg:space-x-6 lg:space-y-0
            "
          >
            <router-link
              v-if="!isAuthenticated"
              class="btn btn-xl btn-primary btn-rounded-lg w-full lg:w-auto"
              :to="{ name: 'Trabajos' }"
              :title="$t('contrata-devs-page.hero-btn-trabajo')"
              >{{ $t('contrata-devs-page.hero-btn-trabajo') }}</router-link
            >
            <button
              type="button"
              v-else
              class="btn btn-xl btn-primary btn-rounded-lg w-full lg:w-auto"
              :title="$t('contrata-devs-page.hero-btn-trabajo')"
              @click="modalTutorialShow = true"
            >
              {{ $t('contrata-devs-page.hero-btn-trabajo') }}
            </button>
            <router-link
              class="
                btn btn-xl
                border-2
                btn-outline-secondary btn-outline-secondary-glow btn-rounded-lg
                w-full
                lg:w-auto
              "
              :to="{ name: 'Contrata' }"
              :title="$t('contrata-devs-page.hero-btn-contrata')"
              >{{ $t('contrata-devs-page.hero-btn-contrata') }}</router-link
            >
          </div>
        </div>
        <div class="w-full lg:w-6/12">
          <img
            class="mx-auto w-full"
            src="@/assets/images/img-about-us-codys.svg"
            alt="Cody's Team"
          />
        </div>
      </div>
    </section>
    <!-- End Section Hero -->
    <!-- Section Empresas -->
    <section class="mb-24 lg:mb-40">
      <div class="content--wrapper">
        <LogosGrid
          :title="$t('contrata-devs-page.section-empresas-description')"
        />
      </div>
    </section>
    <!-- End Section Empresas -->
    <!-- Section Nuestra Historia -->
    <section class="mb-24 lg:mb-40">
      <div class="content--wrapper">
        <h4
          class="
            title--h4
            font-light
            dark:text-white
            text-contrast text-center
            mb-14
          "
          v-html="$t('nosotros-page.title-historia')"
        ></h4>
        <div
          class="
            flex
            lg:space-x-10
            flex-wrap
            lg:flex-nowrap
            relative
            items-center
          "
        >
          <div class="w-full lg:w-6/12 pattern--oval relative">
            <img
              src="@/assets/images/img-utopicode-founders.jpg"
              class="w-11/12 mx-auto h-full shadow-svg-lg"
              alt="Utopicode's Founders"
            />
          </div>
          <div class="w-full lg:w-6/12 lg:pl-8 lg:mt-0 mt-12">
            <p
              class="
                dark:text-primary-text
                text-secondary-text text-lg
                leading-relaxed
              "
            >
              {{ $t('nosotros-page.text-historia-1') }}
            </p>
            <p
              class="
                dark:text-primary-text
                text-secondary-text text-lg
                leading-relaxed
              "
            >
              {{ $t('nosotros-page.text-historia-2') }}
            </p>
            <div class="grid grid-cols-3 gap-6 items-center justify-center">
              <img
                src="@/assets/images/logo-university-college-london-ucl.svg"
                class="mx-auto"
                alt="University College of London"
              />
              <img
                src="@/assets/images/img-latitud.svg"
                class="mx-auto"
                alt="Latitud"
              />
              <img
                src="@/assets/images/img-google-for-startups.png"
                class="mx-auto"
                alt="Google For Startups"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Nuestra Historia -->
    <!-- Section Nuestra Historia -->
    <section class="mb-24 lg:mb-40">
      <div class="content--wrapper">
        <h4
          class="
            title--h4
            font-light
            dark:text-white
            text-contrast text-center
            mb-10
          "
          v-html="$t('nosotros-page.title-equipo')"
        ></h4>
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-8">
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-nuria-min.jpg"
              class="cards--profile--image"
              alt="Nuria Cristo"
            />
            <h5 class="cards--profile--name">Nuria Cristo</h5>
            <h6 class="cards--profile--position">Co- Founder & CEO</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-maem-min.jpg"
              class="cards--profile--image"
              alt="María Emilia Torres"
            />
            <h5 class="cards--profile--name">María Emilia Torres</h5>
            <h6 class="cards--profile--position">Co- Founder & COO</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-magdaleno-min.jpg"
              class="cards--profile--image"
              alt="Esmeralda Magdaleno"
            />
            <h5 class="cards--profile--name">Esmeralda Magdaleno</h5>
            <h6 class="cards--profile--position">Frontend Developer</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-francisco-min.png"
              class="cards--profile--image"
              alt="Francisco Garduño"
            />
            <h5 class="cards--profile--name">Francisco Garduño</h5>
            <h6 class="cards--profile--position">Product Manager</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-belen-min.png"
              class="cards--profile--image"
              alt="Belén Medrano"
            />
            <h5 class="cards--profile--name">Belén Medrano</h5>
            <h6 class="cards--profile--position">
              Business and Investor Relations Executive
            </h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-ivonne-min.jpg"
              class="cards--profile--image"
              alt="Ivonne Lopez"
            />
            <h5 class="cards--profile--name">Ivonne Lopez</h5>
            <h6 class="cards--profile--position">Commercial Executive</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-toporek-min.jpg"
              class="cards--profile--image"
              alt="Irene Toporek"
            />
            <h5 class="cards--profile--name">Irene Toporek</h5>
            <h6 class="cards--profile--position">
              Director of Instructional Design
            </h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-emmanuel-fernandez-min.jpg"
              class="cards--profile--image"
              alt="Emmanuel Fernández"
            />
            <h5 class="cards--profile--name">Emmanuel Fernández</h5>
            <h6 class="cards--profile--position">Talent Representative</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-patricia-min.png"
              class="cards--profile--image"
              alt="Patricia Lopez"
            />
            <h5 class="cards--profile--name">Patricia López</h5>
            <h6 class="cards--profile--position">Talent Representative</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/equipo/img-luis-min.png"
              class="cards--profile--image"
              alt="Luis Tagliaferro"
            />
            <h5 class="cards--profile--name">Luis Tagliaferro</h5>
            <h6 class="cards--profile--position">Talent Representative</h6>
          </div>
          <div
            class="
              bg-contrast
              rounded-lg
              flex
              justify-center
              items-center
              p-8
              h-48
            "
          >
            <a
              :href="
                this.$i18n.locale === 'es'
                  ? 'https://utopicode.typeform.com/to/oDWL6vae'
                  : 'https://utopicode.typeform.com/to/scrn5vEZ'
              "
              target="_blank"
              class="text-primary text-lg font-bold underline"
            >
              {{ $t('nosotros-page.text-trabaja-con-nosotros') }}
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Nuestra Historia -->
    <!-- Section Nuestros Advisor -->
    <section class="mb-24 lg:mb-40">
      <div class="content--wrapper">
        <h4
          class="
            title--h4
            font-light
            dark:text-white
            text-contrast text-center
            mb-10
          "
          v-html="$t('nosotros-page.title-advisors')"
        ></h4>
        <div
          class="w-full lg:w-9/12 mx-auto grid grid-cols-2 lg:grid-cols-3 gap-8"
        >
          <div class="cards--profile">
            <img
              src="@/assets/images/advisors/img-paolo.jpg"
              class="cards--profile--image"
              alt="Paolo Rizzi"
            />
            <h5 class="cards--profile--name">Paolo Rizzi</h5>
            <h6 class="cards--profile--position">Technical Advisor</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/advisors/img-macarena.jpg"
              class="cards--profile--image"
              alt="Macarena Poo"
            />
            <h5 class="cards--profile--name">Macarena Poo</h5>
            <h6 class="cards--profile--position">Product Advisor</h6>
          </div>
          <div class="cards--profile">
            <img
              src="@/assets/images/advisors/img-chris.jpg"
              class="cards--profile--image"
              alt="Chris Mion"
            />
            <h5 class="cards--profile--name">Chris Mion</h5>
            <h6 class="cards--profile--position">Business Coach</h6>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Nuestros Advisor -->
    <!-- Section Nuestras Oficinas -->
    <section>
      <div class="content--wrapper">
        <h4
          class="
            title--h4
            font-light
            dark:text-white
            text-contrast text-center
            mb-10
          "
          v-html="$t('nosotros-page.title-mapa')"
        ></h4>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div class="cards--offices">
            <img
              src="@/assets/images/map-utopicode-united-kingdom-min.jpg"
              class="cards--offices--mapa"
              alt="Utopicode United Kingdom"
            />
            <div class="cards--offices--body">
              <h5 class="cards--offices--place">London</h5>
              <h6 class="cards--offices--address">
                4-5 Bonhill St, Shoreditch, London EC2A 4BX, United Kingdom.
              </h6>
            </div>
          </div>
          <div class="cards--offices">
            <img
              src="@/assets/images/map-utopicode-mexico-min.jpg"
              class="cards--offices--mapa"
              alt="Utopicode Mexico"
            />
            <div class="cards--offices--body">
              <h5 class="cards--offices--place">Mexico City</h5>
              <h6 class="cards--offices--address">
                Paseo de las Palmas 405 -1801, Lomas de Chapultepec, 11700.
              </h6>
            </div>
          </div>
          <div class="cards--offices">
            <img
              src="@/assets/images/map-utopicode-argentina-min.jpg"
              class="cards--offices--mapa"
              alt="Utopicode Argentina"
            />
            <div class="cards--offices--body">
              <h5 class="cards--offices--place">Argentina</h5>
              <h6 class="cards--offices--address">
                9 de Julio 939, Rosario - Santa Fe, 2000
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Section Nuestras Oficinas -->
  </main>
</template>

<script>
import ROUTES from '../constants/routes'
import LogosGrid from '@/components/LogosGrid'
import ModalProfileTutorial from '@/components/ModalProfileTutorial'

import { mapGetters } from 'vuex'

export default {
  components: {
    LogosGrid,
    ModalProfileTutorial
  },
  data() {
    return {
      ROUTES,
      modalTutorialShow: false
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
}
</script>
