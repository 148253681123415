<template>
  <div class="content--wrapper m-6">
    <Tabs class="dark:text-white tabs-left" :tabList="tabList">
      <template #options class="">
        <div class="flex flex-grow gap-4 justify-end">
          <router-link
            :to="{ name: 'CreatePool' }"
            class="btn btn-sm btn-rounded-lg btn-secondary"
            >{{ $t('candidates-pool.btn-new-pool') }}</router-link
          >
          <router-link
            :to="{ name: 'CreatePosition' }"
            class="btn btn-sm btn-rounded-lg btn-secondary"
            >{{ $t('candidates-pool.btn-new-position') }}</router-link
          >
        </div>
      </template>
      <template v-slot:tabPanel-1> <Pool /> </template>
      <template v-slot:tabPanel-2>
        <CardPage
          ><template #default>
            <div class="pt-8 pr-8 pl-8 pb-3"><SearchTable /></div></template
        ></CardPage>
      </template>
      <template v-slot:tabPanel-3>
        <CardPage
          ><template #default>
            <div class="pt-8 pr-8 pl-8 pb-3"><SearchTable /></div></template
        ></CardPage>
      </template>
    </Tabs>
  </div>
</template>
<script>
import Tabs from '@/components/Tabs'
import Pool from '@/components/empresas/Pool'
import SearchTable from '@/components/empresas/SearchTable'
import CardPage from '@/components/CardPage'

export default {
  name: 'DetalleBusqueda',
  components: {
    Tabs,
    Pool,
    SearchTable,
    CardPage
  },
  data() {
    return {
      tabList: ['Pool', 'Busquedas Activas', 'Busquedas Pasadas']
    }
  }
}
</script>
