<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('terminos-page.title')"
      section="pageHero"
      :hero-image="require('@/assets/images/hero/bg-terminos-condiciones.svg')"
      class="hero--sm"
    />
    <div class="content--wrapper dark:text-primary-text text-secondary-text">
      <CardPage class="p-8 lg:w-9/12 mx-auto">
        <template #default>
          <h6 class="mb-6 text-secondary-text font-bold">
            {{ $t('terminos-page.fecha-actualización') }}: 12/07/2023
          </h6>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-introduccion') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t('terminos-page.description-introduccion-1') }}
            <a href="https://www.utopicode.io" class="text-secondary"
              >www.utopicode.io</a
            >
            {{ $t('terminos-page.description-introduccion-2') }}
          </p>
          <p class="mb-10">
            {{ $t('terminos-page.description-introduccion-3') }}
            <a
              href="mailto:mariaemilia.torres@utopicode.com"
              class="text-secondary"
              >mariaemilia.torres@utopicode.com</a
            >.
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-uso-terminos') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t('terminos-page.description-uso-terminos-1') }}
          </p>
          <p>
            {{ $t('terminos-page.description-uso-terminos-2') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-aceptacion-terminos') }}</h4>
            </template>
          </Title>
          <ol class="list--number list--number--secondary mb-14">
            <li class="mb-4">
              {{ $t('terminos-page.description-aceptacion-terminos-1') }}
            </li>
            <li class="mb-4">
              {{ $t('terminos-page.description-aceptacion-terminos-2')
              }}<router-link :to="{ name: 'Politica' }" class="text-secondary">
                {{ $t('footer.politica-privacidad') }}
              </router-link>
              {{ $t('terminos-page.description-aceptacion-terminos-3') }}
            </li>
          </ol>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-uso-personal-terminos') }}</h4>
            </template>
          </Title>
          <p>{{ $t('terminos-page.description-uso-personal-1') }}</p>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="(index, key) in 4" :key="key">
              {{
                $t(`terminos-page.description-uso-personal-punto-${key + 1}`)
              }}
            </li>
          </ul>
          <p>{{ $t('terminos-page.description-uso-personal-2') }}</p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-registro') }}</h4>
            </template>
          </Title>
          <p
            v-for="(registro, key) in 2"
            :key="key"
            :class="{ 'mb-10': key === 3 - 1 }"
          >
            {{ $t(`terminos-page.description-registro-${key + 1}`) }}
          </p>
          <p>
            {{ $t(`terminos-page.description-registro-3`) }}
            <a href="mailto:contact@utopicode.com" class="text-secondary"
              >contact@utopicode.com</a
            >.
          </p>
          <p>
            {{ $t(`terminos-page.description-registro-4`) }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-derecho-registro') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t(`terminos-page.description-derecho-registro-1`) }}
          </p>
          <p>
            {{ $t(`terminos-page.description-derecho-registro-2`) }}
            <a href="mailto:contact@utopicode.com" class="text-secondary"
              >contact@utopicode.com</a
            >.
          </p>
          <p>
            {{ $t(`terminos-page.description-derecho-registro-3`) }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-responsabilidad') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t(`terminos-page.description-responsabilidad-1`) }}
          </p>
          <p>
            {{ $t(`terminos-page.description-responsabilidad-2`) }}
          </p>
          <p>
            {{ $t(`terminos-page.description-responsabilidad-3`) }}
          </p>

          <!-- Talento Tech -->
          <h3 class="title--h6 text-white mb-6 font-bold">
            {{ $t('terminos-page.title-para-talento-tech') }}
          </h3>
          <p>
            {{ $t(`terminos-page.description-talento-tech`) }}
          </p>

          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-uso-tech') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t('terminos-page.description-uso-tech-1') }}
          </p>
          <p>
            {{ $t('terminos-page.description-uso-tech-2') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-tarifas') }}</h4>
            </template>
          </Title>
          <p class="mb-10">{{ $t('terminos-page.description-tarifas') }}</p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-acuerdo-alcance') }}</h4>
            </template>
          </Title>
          <p class="mb-10">
            {{ $t('terminos-page.description-acuerdo-alcance') }}
          </p>
          <!-- Para Empresas -->
          <h3 class="title--h6 text-white mb-6 font-bold">
            {{ $t('terminos-page.title-para-empresa') }}
          </h3>
          <p>
            {{ $t(`terminos-page.description-empresas`) }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-plataforma-servicios') }}</h4>
            </template>
          </Title>
          <p
            v-for="(plataforma, key) in 2"
            :key="key"
            :class="{ 'mb-8': key === 2 - 1 }"
          >
            {{
              $t(`terminos-page.description-plataforma-servicios-${key + 1}`)
            }}
          </p>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="(plataforma, key) in 4" :key="key">
              {{ $t(`terminos-page.list-plataforma-servicios-${key + 1}`) }}
            </li>
          </ul>
          <p
            v-for="(plataforma, key) in 2"
            :key="key"
            :class="{ 'mb-10': key === 2 - 1 }"
          >
            {{
              $t(`terminos-page.description-plataforma-servicios-${key + 3}`)
            }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-tarifas-empresas') }}</h4>
            </template>
          </Title>
          <p>{{ $t('terminos-page.description-tarifas-empresa-1') }}</p>
          <p>{{ $t('terminos-page.description-tarifas-empresa-2') }}</p>
          <table class="table--default table-fixed mb-8">
            <thead>
              <tr>
                <th>{{ $t('terminos-page.table-opciones') }}</th>
                <th>{{ $t('terminos-page.table-descripcion') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="w-1/5">
                  {{ $t('terminos-page.tabla-tarifas-opcion-1') }}
                </td>
                <td>{{ $t('terminos-page.tabla-tarifas-descripcion-1') }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('terminos-page.tabla-tarifas-opcion-2') }}
                </td>
                <td>{{ $t('terminos-page.tabla-tarifas-descripcion-2') }}</td>
              </tr>
              <tr>
                <td>
                  {{ $t('terminos-page.tabla-tarifas-opcion-3') }}
                </td>
                <td>{{ $t('terminos-page.tabla-tarifas-descripcion-3') }}</td>
              </tr>
            </tbody>
          </table>
          <p>{{ $t('terminos-page.description-tarifas-empresa-3') }}</p>
          <p>{{ $t('terminos-page.description-tarifas-empresa-4') }}</p>

          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-declaraciones') }}</h4>
            </template>
          </Title>
          <p>{{ $t('terminos-page.description-declaraciones-1') }}</p>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="(garantias, key) in 4" :key="key">
              {{ $t(`terminos-page.list-declaraciones-${key + 1}`) }}
            </li>
          </ul>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-garantias') }}</h4>
            </template>
          </Title>
          <p>{{ $t('terminos-page.description-garantias-1') }}</p>
          <ul class="list--bullet list--bullet--secondary mb-8">
            <li v-for="(garantias, key) in 4" :key="key">
              {{ $t(`terminos-page.list-garantias-${key + 1}`) }}
            </li>
          </ul>
          <p>
            {{ $t('terminos-page.description-garantias-2') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-uso-info') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t('terminos-page.description-uso-info-1') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-notificaciones') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t('terminos-page.description-notificaciones-1') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-plataforma') }}</h4>
            </template>
          </Title>
          <p>
            {{ $t('terminos-page.description-plataforma-1') }}
            <a
              href="mailto:mariaemilia.torres@utopicode.com"
              class="text-secondary"
              >mariaemilia.torres@utopicode.com</a
            >.
            {{ $t('terminos-page.description-plataforma-2') }}
          </p>
          <p>
            {{ $t('terminos-page.description-plataforma-3') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-propiedad-intelectual') }}</h4>
            </template>
          </Title>
          <p
            v-for="(propiedad, key) in 2"
            :key="key"
            :class="{ 'mb-10': key === 2 - 1 }"
          >
            {{
              $t(`terminos-page.description-propiedad-intelectual-${key + 1}`)
            }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-datos-personales') }}</h4>
            </template>
          </Title>
          <p class="mb-10">
            {{ $t('terminos-page.description-datos-personales-1') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-revelacion-info') }}</h4>
            </template>
          </Title>
          <p class="mb-10">
            {{ $t('terminos-page.description-revelacion-info') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-leyes-disputas') }}</h4>
            </template>
          </Title>
          <p class="mb-10">
            {{ $t('terminos-page.description-leyes-disputas') }}
          </p>
          <Title class="mb-6">
            <template #title>
              <h4>{{ $t('terminos-page.title-miscelaneos') }}</h4>
            </template>
          </Title>
          <p class="mb-10">
            {{ $t('terminos-page.description-miscelaneos-1') }}
          </p>
          <p class="mb-10">
            {{ $t('terminos-page.description-miscelaneos-2') }}
          </p>
          <p class="mb-10">
            {{ $t('terminos-page.description-miscelaneos-3') }}
          </p>
          <p class="mb-10">
            {{ $t('terminos-page.description-miscelaneos-4') }}
            <a href="mailto:contact@utopicode.com" class="text-secondary"
              >contact@utopicode.com</a
            >.
          </p>
          <p class="mb-10">
            {{ $t('terminos-page.description-miscelaneos-5-1') }}
            <router-link :to="{ name: 'Politica' }" class="text-secondary">
              {{
                $t('terminos-page.description-privacidad-proteccion-datos-2')
              }} </router-link
            >,
            {{ $t('terminos-page.description-miscelaneos-5-2') }}
          </p>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardPage from '@/components/CardPage'
import ROUTES from '@/constants/routes'

export default {
  data() {
    return {
      ROUTES
    }
  },
  components: {
    Hero,
    CardPage,
    Title
  }
}
</script>
