<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper flex flex-wrap">
      <CardPage class="w-full lg:w-6/12 mx-auto order-2 lg:order-none">
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <Form @submit="validar">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h3>{{ $t('modal-certificaciones-cursos.title') }}</h3>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-certificaciones-cursos.description') }}
              </p>
              <div class="form--group">
                <label class="form--label" for="nombreDelCurso">{{
                  $t('modal-certificaciones-cursos.lbl-nombre-del-curso')
                }}</label>
                <Field
                  name="nombreDelCurso"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="certificaciones[certificaciones.length - 1].name"
                />
                <ErrorMessage name="nombreDelCurso" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="empresaEmisora">{{
                  $t('modal-certificaciones-cursos.lbl-empresa-emisora')
                }}</label>
                <Field
                  name="empresaEmisora"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="
                    certificaciones[certificaciones.length - 1].certifiedBy
                  "
                />
                <ErrorMessage name="empresaEmisora" class="form--error" />
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <div
                  class="form--group"
                  :class="
                    !certificaciones[certificaciones.length - 1].expires
                      ? ''
                      : 'col-span-full'
                  "
                >
                  <h4
                    class="
                      dark:text-primary-text
                      text-secondary-text
                      font-bold
                      mb-2
                      lg:col-span-2
                    "
                  >
                    {{
                      $t('modal-experiencia-profesional.title-fecha-de-inicio')
                    }}
                  </h4>
                  <Field
                    name="startDate"
                    as="label"
                    v-slot="{ field }"
                    v-model="
                      certificaciones[certificaciones.length - 1].startDate
                    "
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      v-bind="field"
                      type="date"
                      :max="new Date().toISOString().split('T')[0]"
                    />
                  </Field>
                  <ErrorMessage name="startDate" class="form--error" />
                </div>
                <div
                  class="form--group"
                  v-if="!certificaciones[certificaciones.length - 1].expires"
                >
                  <h4
                    class="
                      dark:text-primary-text
                      text-secondary-text
                      font-bold
                      mb-2
                      lg:col-span-2
                    "
                  >
                    {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
                  </h4>
                  <Field
                    name="endDate"
                    as="label"
                    v-slot="{ field }"
                    v-model="
                      certificaciones[certificaciones.length - 1].endDate
                    "
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      v-bind="field"
                      type="date"
                      :min="
                        certificaciones[certificaciones.length - 1].startDate ||
                        new Date().toISOString().split('T')[0]
                      "
                    />
                  </Field>
                  <ErrorMessage name="endDate" class="form--error" />
                </div>
              </div>
              <div class="form--group lg:col-span-2">
                <label class="form--checkbox"
                  ><input
                    type="checkbox"
                    id="remember"
                    value="first_checkbox"
                    @change="checkExpires"
                    v-model="
                      certificaciones[certificaciones.length - 1].expires
                    "
                  /><span>{{
                    $t('modal-certificaciones-cursos.lbl-credencial-no-caduca')
                  }}</span></label
                >
              </div>
              <div class="form--group">
                <label class="form--label" for="idCertificacion">{{
                  $t('modal-certificaciones-cursos.lbl-id-certificacion')
                }}</label>
                <Field
                  name="idCertificacion"
                  type="text"
                  class="form--element w-full"
                  v-model="certificaciones[certificaciones.length - 1].certId"
                />
                <ErrorMessage name="idCertificacion" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="urlCertificacion">{{
                  $t('modal-certificaciones-cursos.lbl-url-certificacion')
                }}</label>
                <Field
                  name="urlCertificacion"
                  type="text"
                  class="form--element w-full"
                  :rules="validateUrl"
                  v-model="certificaciones[certificaciones.length - 1].certUrl"
                />
                <ErrorMessage name="urlCertificacion" class="form--error" />
              </div>
              <button
                class="btn btn-rounded-lg btn-secondary btn-lg"
                @click="addRow"
                type="button"
                role="button"
                :aria-label="$t('general.agregar-experiencia')"
              >
                {{ $t('general.agregar-experiencia') }}
              </button>
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="
                  btn btn-outline btn-outline-primary btn-rounded-lg btn-lg
                "
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.volver') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </Form>
        </template>
      </CardPage>
      <!-- List certificaciones -->
      <ul
        class="list--education list--education--small w-full lg:w-6/12 lg:pl-10"
        v-if="certificaciones.length > 1"
      >
        <li
          v-for="(certificacion, index) in sortCertificaciones"
          :key="index"
          class="pb-8"
        >
          <div class="flex">
            <div class="flex-grow">
              <span
                class="list--education--bullets"
                :class="certificacion.active ? 'active' : ''"
              ></span>
              <time class="dark:text-primary-text text-secondary-text text-sm">
                {{ formatedDate(certificacion.startDate) }}
                <span v-if="certificacion.expires"
                  >| {{ $t('general.no-caduca') }}</span
                >
                <span v-else>| {{ formatedDate(certificacion.endDate) }}</span>
              </time>
              <h4
                class="text-white font-bold text-lg mb-2 uppercase"
                v-if="certificacion.name || certificacion.certifiedBy"
              >
                {{ certificacion.name }} |
                <i class="text-secondary">{{ certificacion.certifiedBy }}</i>
              </h4>
              <div
                class="
                  dark:text-primary-text
                  text-secondary-text text-sm
                  uppercase
                  mb-4
                "
              >
                {{ certificacion.certId }}
              </div>
              <a :href="certificacion.certUrl" class="text-primary text-sm">{{
                certificacion.certUrl
              }}</a>
            </div>
            <div class="flex space-x-3 pl-6">
              <button
                type="button"
                @click="editRow(index)"
                class="
                  w-9
                  h-9
                  flex
                  justify-center
                  items-center
                  rounded-md
                  btn-secondary
                "
              >
                <img src="@/assets/images/icn-edit.svg" />
              </button>
              <button
                class="
                  w-9
                  h-9
                  flex
                  justify-center
                  items-center
                  rounded-md
                  btn-primary
                "
                @click="removeRow(index)"
              >
                <img src="@/assets/images/icn-remove.svg" />
              </button>
            </div>
          </div>
        </li>
      </ul>
      <!-- End List Certificaciones -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { Field, Form, ErrorMessage } from 'vee-validate'
import ROUTES from '@/constants/routes'
import apolloClient from '@/graphql/apolloClient'
import SaveCertsMutation from '@/graphql/mutations/saveCerts'
import { mapActions, mapGetters } from 'vuex'
import { validateDefault, validateUrl } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

const emptyCert = {
  name: null,
  certifiedBy: null,
  startDate: null,
  expires: false,
  endDate: null,
  certId: null,
  certUrl: null
}

export default {
  name: 'EditCertificacionesyCursos',
  components: {
    Title,
    CardPage,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      certificaciones: [
        {
          ...emptyCert,
          active: true
        }
      ],
      ROUTES
    }
  },
  mounted() {
    this.initializeData()
  },
  computed: {
    ...mapGetters(['token', 'user']),
    sortCertificaciones() {
      return [...this.certificaciones].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validar() {
      try {
        this.setLoadingOn()
        const saveCertificaciones = await apolloClient.mutate({
          mutation: SaveCertsMutation,
          variables: {
            certs: this.sortCertificaciones.map(({ active, ...rest }) => rest)
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveCertificaciones.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.$router.push({ name: 'Profile' })
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.certificaciones.forEach((item) => {
        item.active = false
      })
      this.certificaciones.push({
        ...emptyCert,
        active: true
      })
    },
    removeRow(index) {
      const removePosition = this.certificaciones.indexOf(
        this.sortCertificaciones[index]
      )
      this.certificaciones.splice(removePosition, 1)
    },
    editRow(index) {
      const editPosition = this.certificaciones.indexOf(
        this.sortCertificaciones[index]
      )
      this.certificaciones.forEach((item) => {
        item.active = false
      })
      this.certificaciones[editPosition].active = true
      this.certificaciones.push(this.certificaciones[editPosition])
      this.certificaciones.splice(editPosition, 1)
      const row = this.certificaciones[editPosition]
      if (row.endDate === null) {
        row.expires = true
      }
    },
    initializeData() {
      if (this.user.certsAndCourses) {
        this.user.certsAndCourses.forEach((val) => {
          this.certificaciones.unshift({
            name: val.name,
            certifiedBy: val.certifiedBy,
            startDate: val.startDate,
            endDate: val.endDate,
            certId: val.certId,
            certUrl: val.certUrl,
            active: false
          })
        })
      }
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return this.$t('general.no-caduca')
      }
    },
    // RULES
    validateDefault,
    validateUrl,
    checkExpires() {
      if (
        this.certificaciones[this.certificaciones.length - 1].expires === true
      ) {
        this.certificaciones[this.certificaciones.length - 1].endDate = null
      }
    }
  }
}
</script>
