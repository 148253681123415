import gql from 'graphql-tag'

export default gql`
  query getUsers($id: Int) {
    candidate(id: $id) {
      id
      userId
      email
      name
      publicUri
      country
      updatedAt
      phonenumber
      socialNetworks {
        linkedin
        behance
        github
        dribble
        twitter
        bitbucket
      }
      technologies {
        techName
        level
        expYears
      }
      takenAssesments {
        id
        assesment {
          id
          name
          testLength
          photo
        }
        totalScore
        subAssesmentsResult {
          id
          assesment {
            id
            name
            testLength
          }
          totalScore
          description
        }
        keyPercentil
      }
      about
      jobStatus
      idealJobType
      availability
      yearsOfExp
      minSalary
      openToNegotiate
      personsInCharge
      speciality
      englishLevel
      industries
      idealCompany
      pastJobs {
        position
        company
        companyLocation
        startDate
        endDate
        whatBuilt
        technologies
        appOrRepo
      }
      formalEducation {
        grade
        school
        startDate
        endDate
      }
      certsAndCourses {
        name
        startDate
        endDate
        certifiedBy
        certId
        certUrl
      }
      registeredEvents
      status
      notes
    }
  }
`
