<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <CardPage class="w-full lg:w-6/12 xl:w-5/12 mx-auto">
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <Form @submit="updateUserProfile">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>{{ $t('modal-profile.title') }}</h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <div class="form--group">
                <label class="form--label" for="nombre">{{
                  $t('modal-profile.lbl-nombre')
                }}</label>
                <Field
                  name="fullname"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="fullname"
                />
                <ErrorMessage name="fullname" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="especialidad">{{
                  $t('modal-profile.lbl-especialidad')
                }}</label>
                <Field
                  name="especialidad"
                  as="select"
                  class="form--element form--select w-full"
                  :rules="validateDefault"
                  v-model="especialidad"
                >
                  <option value="" disabled></option>
                  <option
                    :value="especialidad"
                    v-for="especialidad in especialidadLista"
                    :key="especialidad"
                  >
                    {{ especialidad }}
                  </option>
                </Field>
                <ErrorMessage name="especialidad" class="form--error" />
              </div>
              <!-- If Other Selected -->
              <div class="form--group" v-if="especialidad === 'Other/Otro'">
                <label class="form--label" for="otro">
                  {{ $t('contrata-devs-page.option-otro') }}
                  {{ $t('modal-profile.lbl-especialidad') }}
                </label>
                <Field
                  name="otro"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="otraEspecialidad"
                />
                <ErrorMessage name="otro" class="form--error" />
              </div>
              <!-- End Other Selected -->
              <div class="form--group">
                <label class="form--label" for="pais">{{
                  $t('registrarse-page.lbl-pais')
                }}</label>
                <Field
                  name="country"
                  as="select"
                  class="form--element form--select w-full"
                  :rules="validateDefault"
                  v-model="country"
                >
                  <option value="" disabled></option>
                  <option
                    :value="pais"
                    v-for="pais in paisesLista[$i18n.locale]"
                    :key="pais"
                  >
                    {{ pais }}
                  </option>
                </Field>
                <ErrorMessage name="country" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="telefono">{{
                  $t('modal-profile.lbl-telefono')
                }}</label>
                <div class="form--phone">
                  <Field
                    name="phoneCode"
                    type="text"
                    :placeholder="$t('general.codigo-internacional')"
                    list="phoneCodeNumberList"
                    class="form--element form--select"
                    :rules="validateDefaultPhone"
                    v-model.lazy="phoneCodeNumber"
                  />
                  <Field
                    name="phone"
                    type="text"
                    class="form--element w-full"
                    :rules="validatePhone"
                    v-model="phonenumber"
                  />
                  <datalist id="phoneCodeNumberList">
                    <option value="" disabled></option>
                    <option
                      :value="code.dialCode"
                      v-for="(code, index) in phoneCodes"
                      :key="index"
                    >
                      {{ code.emoji }} {{ code.name }}
                    </option>
                  </datalist>
                </div>
                <ErrorMessage name="phone" class="form--error" />
                <ErrorMessage name="phoneCode" class="form--error" />
                <div class="form--error" v-if="error">
                  {{ error }}
                </div>
              </div>
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="
                  btn btn-outline btn-outline-primary btn-rounded-lg btn-lg
                "
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.volver') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </Form>
        </template>
      </CardPage>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import paisesLista from '@/components/json/countries.json'
import especialidadLista from '@/components/json/especialidad.json'
import phoneCodes from '@/components/json/phoneCodes.json'
import { Field, Form, ErrorMessage } from 'vee-validate'

import ApolloClient from '@/graphql/apolloClient'
import savePersonalInfoMutation from '@/graphql/mutations/savePersonalInfo'
import { mapActions, mapGetters } from 'vuex'
import { toastNotification } from '@/services/toastNotification'
import {
  validateDefault,
  validateDefaultPhone,
  validatePhone
} from '@/services/validationRules'

export default {
  name: 'EditProfile',
  components: {
    Title,
    CardPage,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      paisesLista,
      especialidadLista,
      phoneCodes,
      fullname: null,
      especialidad: null,
      otraEspecialidad: null,
      country: null,
      phonenumber: '',
      phoneCodeNumber: '',
      error: null
    }
  },
  mounted() {
    this.mapInfo()
    this.splitPhoneNumber()
  },
  computed: {
    ...mapGetters(['token', 'user']),
    phoneNumberComplete() {
      return this.phoneCodeNumber + this.phonenumber
    }
  },
  methods: {
    splitPhoneNumber() {
      const checkNumber = this.phonenumber
      if (checkNumber !== null) {
        this.phoneCodes.forEach((item) => {
          if (checkNumber.includes(item.dialCode)) {
            this.phonenumber = checkNumber.split(item.dialCode)[1]
            this.phoneCodeNumber = item.dialCode
          }
        })
      }
    },
    ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
    updateUserProfile: async function () {
      try {
        this.setLoadingOn()
        const personalInfo = await ApolloClient.mutate({
          mutation: savePersonalInfoMutation,
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          },
          variables: {
            fullname: this.fullname,
            phonenumber: this.phoneNumberComplete,
            country: this.country,
            speciality:
              this.especialidad === 'Other/Otro'
                ? this.otraEspecialidad
                : this.especialidad
          }
        })
        if (!personalInfo.errors) {
          this.setLoadingOff()
          await this.fetchUser()
          this.$router.push({ name: 'Profile' })
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        this.error = e
        toastNotification(e, 'error')
      }
    },
    mapInfo() {
      const { fullname, phonenumber, country, speciality } = this.user
      this.fullname = fullname
      this.country = country
      this.especialidad = speciality
      this.phonenumber = phonenumber
    },
    // RULES
    validateDefault,
    validateDefaultPhone,
    validatePhone
  }
}
</script>
