<template>
  <div
    class="cards cards--skill"
    :class="{ 'cards--skill--horizontal': horizontal }"
  >
    <!-- Image -->
    <BadgeAward
      :imagen="image === null ? 'icn-soft-skill.svg' : image"
      :bloqueado="locked"
      :titulo="title"
    ></BadgeAward>
    <!-- End Image -->
    <!-- Body -->
    <div class="cards--skill--body">
      <h3>{{ title }}</h3>
      <!-- End Title -->
      <!-- Description -->
      <p>{{ description }}</p>
      <!-- End Description -->
    </div>
    <!-- End Body -->
    <!-- Footer -->
    <div class="cards--skill--footer" v-if="footer && link">
      <router-link
        class="btn btn-primary btn-rounded-lg btn-md mx-auto"
        :to="link"
        >{{ cta }}</router-link
      >
    </div>
    <!-- End Footer -->
  </div>
</template>

<script>
import BadgeAward from '@/components/BadgeAward'

export default {
  components: {
    BadgeAward
  },
  name: 'CardSkill',
  props: {
    link: Object,
    image: String,
    title: String,
    description: String,
    horizontal: Boolean,
    footer: Boolean,
    locked: Boolean,
    cta: String
  },
  setup() {
    return {}
  }
}
</script>
