<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4>{{ $t('profile-page.title-mis-badges') }}</h4>
      </template>
      <template #info>
        <div classs="tooltip">
          <button
            type="button"
            role="button area"
            aria-label="Info"
            @mouseenter="tooltipShow = true"
            @mouseleave="tooltipShow = false"
          >
            <img src="@/assets/images/icn-info.svg" class="w-5" />
          </button>
          <transition name="fade">
            <div
              class="tooltip--globe tooltip--xs"
              v-show="tooltipShow == true"
            >
              <div class="title title--wrapper mb-6">
                <h5>{{ $t('badges-detail-page.title-globe') }}</h5>
                <span class="title--line"></span>
              </div>
              <div class="grid grid-cols-2 gap-8">
                <p class="dark:text-primary-text text-secondary-text">
                  {{ $t('badges-detail-page.description-globe') }}
                </p>
                <img
                  src="@/assets/images/utopicode-t-shirt.jpg"
                  alt="Utopicode T-Shirt"
                />
              </div>
              <p
                class="dark:text-primary-text text-secondary-text text-sm last"
              >
                {{ $t('badges-detail-page.description-stock') }}
              </p>
            </div>
          </transition>
        </div>
      </template>
      <template #options>
        <router-link
          :to="{ name: 'BadgesDetail' }"
          class="
            btn btn-sm btn-rounded-md btn-secondary
            transform
            -translate-y-2
          "
        >
          {{ $t('general.ver-todos') }}
        </router-link>
      </template>
    </Title>
    <div class="grid gap-x-6 gap-y-3 grid-cols-3 lg:grid-cols-6">
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipSoftSkillShow = true"
        @mouseleave="tooltipSoftSkillShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-soft-skill.svg"
          :bloqueado="softSkill.length > 0 ? false : true"
          :titulo="$t('habilidades-page.title-soft-skill')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-soft-skill') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipSoftSkillShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="softSkill.length > 0"
            >
              {{ $t('habilidades-page.descripcion-soft-skill') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-soft-skill') }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipComportamientoEmocionalShow = true"
        @mouseleave="tooltipComportamientoEmocionalShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-comportamiento-emocional.svg"
          :bloqueado="comportamientoEmocional.length > 0 ? false : true"
          :titulo="$t('habilidades-page.title-comportamiento-emocional')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-comportamiento-emocional') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipComportamientoEmocionalShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="comportamientoEmocional.length > 0"
            >
              {{ $t('habilidades-page.descripcion-comportamiento-emocional') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{
                $t(
                  'habilidades-page.descripcion-corta-comportamiento-emocional'
                )
              }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipFitCulturalShow = true"
        @mouseleave="tooltipFitCulturalShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-fit-cultural.svg"
          :bloqueado="fitCultural.length > 0 ? false : true"
          :titulo="$t('habilidades-page.title-fit-cultural')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-fit-cultural') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipFitCulturalShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="fitCultural.length > 0"
            >
              {{ $t('habilidades-page.descripcion-fit-cultural') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-fit-cultural') }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipHardSkillShow = true"
        @mouseleave="tooltipHardSkillShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-hard-skill.svg"
          :bloqueado="hardSkill.length > 0 ? false : true"
          :titulo="$t('habilidades-page.title-hard-skill')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-hard-skill') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipHardSkillShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="hardSkill.length > 0"
            >
              {{ $t('habilidades-page.descripcion-hard-skill') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-hard-skill') }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipExperienceShow = true"
        @mouseleave="tooltipExperienceShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-experiencia.svg"
          :bloqueado="userBadges.pastJobs !== null ? false : true"
          :titulo="$t('habilidades-page.title-experiencia')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-experiencia') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipExperienceShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="userBadges.pastJobs !== null"
            >
              {{ $t('habilidades-page.descripcion-experiencia') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-experiencia') }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipLearningShow = true"
        @mouseleave="tooltipLearningShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-aprendizaje.svg"
          :bloqueado="userBadges.certsAndCourses !== null ? false : true"
          :titulo="$t('habilidades-page.title-aprendizaje')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-aprendizaje') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipLearningShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="userBadges.certsAndCourses !== null"
            >
              {{ $t('habilidades-page.descripcion-aprendizaje') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-aprendizaje') }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipTechTalkShow = true"
        @mouseleave="tooltipTechTalkShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-tech-talk.svg"
          :bloqueado="!userBadges.registeredEvents"
          :titulo="$t('habilidades-page.title-tech-talk')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-tech-talk') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipTechTalkShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="userBadges.registeredEvents"
            >
              {{ $t('habilidades-page.descripcion-tech-talk') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-tech-talk') }}
            </p>
          </div>
        </transition>
      </div>
      <div
        class="flex items-center flex-col tooltip"
        @mouseenter="tooltipTechnologiesShow = true"
        @mouseleave="tooltipTechnologiesShow = false"
      >
        <BadgeAward
          class="mx-auto inline-block"
          imagen="icn-tecnologias.svg"
          :bloqueado="userBadges.technologies.length <= 0"
          :titulo="$t('habilidades-page.title-tecnologias')"
        ></BadgeAward>
        <h4
          class="dark:text-primary-text text-secondary-text text-xs text-center"
        >
          {{ $t('habilidades-page.title-tecnologias') }}
        </h4>
        <transition name="fade">
          <div
            class="
              tooltip--globe tooltip--xxs
              lg:transform lg:translate-x-1/2 lg:translate-y-1/3
            "
            v-show="tooltipTechnologiesShow == true"
          >
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-if="userBadges.technologies.length > 0"
            >
              {{ $t('habilidades-page.descripcion-tecnologias') }}
            </p>
            <p
              class="text-xs dark:text-primary-text text-secondary-text last"
              v-else
            >
              {{ $t('habilidades-page.descripcion-corta-tecnologias') }}
            </p>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import Title from '@/components/Title'
import BadgeAward from '@/components/BadgeAward'
import ROUTES from '@/constants/routes'

export default {
  name: 'MisBadgesSection',
  props: ['userBadges'],
  components: {
    Title,
    BadgeAward
  },
  data() {
    return {
      ROUTES,
      tooltipShow: false,
      tooltipSoftSkillShow: false,
      tooltipComportamientoEmocionalShow: false,
      tooltipFitCulturalShow: false,
      tooltipHardSkillShow: false,
      tooltipExperienceShow: false,
      tooltipLearningShow: false,
      tooltipTechTalkShow: false,
      tooltipTechnologiesShow: false
    }
  },
  computed: {
    softSkill() {
      return this.userBadges.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-soft-skill.svg'
      )
    },
    hardSkill() {
      return this.userBadges.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-hard-skill.svg'
      )
    },
    comportamientoEmocional() {
      return this.userBadges.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-comportamiento-emocional.svg'
      )
    },
    fitCultural() {
      return this.userBadges.takenAssesments.filter(
        (item) => item.assesment.photo === 'icn-fit-cultural.svg'
      )
    }
  }
}
</script>
