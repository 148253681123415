<template>
  <section class="cards p-4 flex flex-wrap lg:flex-nowrap w-full">
    <!-- Start Basic Info -->
    <div class="flex items-center gap-4 w-full" v-if="!edit">
      <div
        class="
          bg-primary-text
          w-14
          h-14
          flex
          justify-center
          items-center
          rounded-lg
        "
      >
        <img
          src="https://i0.wp.com/collectible506.com/wp-content/uploads/2018/06/niantic_logo.png?resize=474%2C573&ssl=1"
          class="w-12"
          alt="Utopicode"
        />
      </div>
      <div class="flex-grow">
        <h2 class="text-3xl dark:text-white text-contrast font-bold">
          {{ client.companyName }}
        </h2>
        <p class="dark:text-white text-contrast last">
          {{ client.titleDescription }}
        </p>
      </div>
      <button
        v-if="!isPublic && edit === false"
        @click="edit = true"
        type="button"
        role="button"
        aria-label="Edit"
        class="
          w-9
          h-9
          flex
          justify-center
          items-center
          rounded-md
          btn-secondary
        "
      >
        <img src="@/assets/images/icn-edit.svg" />
      </button>
    </div>
    <Form
      @submit="updateClientProfile"
      v-else
      class="w-full grid grid-cols-1 gap-4 lg:grid-cols-12"
    >
      <div class="lg:col-span-5 justify-center items-center flex">
        <label for="formFile" class="form-label flex flex-col gap-2">
          <span
            class="
              w-16
              h-16
              flex
              justify-center
              items-center
              bg-primary-text
              dark:bg-main-dark
              mx-auto
              rounded-xl
            "
            ><IcnUpload class="text-white w-5"
          /></span>
          <span class="dark:text-secondary-text text-contrast">{{
            $t('clients-profile.lbl-upload-file')
          }}</span>
          <button
            type="file"
            class="
              btn
              dark:bg-secondary-text
              bg-secondary-text
              text-white
              rounded-full
              shadow-header
              btn-md
              mx-auto
            "
            role="button"
            aria-pressed="false"
          >
            {{ $t('clients-profile.btn-browse') }}
          </button></label
        >
        <!-- <input class="" type="file" id="formFile" /> -->
      </div>
      <div class="lg:col-span-7">
        <div class="form--group">
          <label class="form--label" for="fullname">{{
            $t('modal-profile.lbl-nombre')
          }}</label>
          <Field
            name="fullname"
            type="text"
            class="form--element w-full"
            :rules="validateDefault"
            v-model="fullname"
            :placeholder="$t('modal-profile.lbl-nombre')"
          />
          <ErrorMessage name="fullname" class="form--error" />
        </div>
        <div class="form--group">
          <label class="form--label" for="slogan">{{
            $t('modal-profile.lbl-nombre')
          }}</label>
          <Field
            name="slogan"
            type="text"
            class="form--element w-full"
            :rules="validateDefault"
            v-model="slogan"
            :placeholder="$t('modal-profile.lbl-nombre')"
          />
          <ErrorMessage name="slogan" class="form--error" />
        </div>
      </div>
      <div class="flex justify-center space-x-4 lg:col-span-12">
        <button
          type="button"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="edit = false"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </Form>
  </section>
</template>

<script>
// import Title from '@/components/Title'
// import ROUTES from '@/constants/routes'
// import countriesList from '@/components/json/countries.json'
// import specialitiesList from '@/components/json/especialidad.json'
// import phoneCodes from '@/components/json/phoneCodes.json'
import { Field, Form, ErrorMessage } from 'vee-validate'
import IcnUpload from '@/components/svg/icon/icnUpload'
// import ApolloClient from '@/graphql/apolloClient'
// import savePersonalInfoMutation from '@/graphql/mutations/savePersonalInfo'
// import { mapActions, mapGetters } from 'vuex'
// import { toastNotification } from '@/services/toastNotification'
// import {
//   validateDefault,
//   validateDefaultPhone,
//   validatePhone
// } from '@/services/validationRules'
export default {
  name: 'BasicInformationClient',
  props: ['isPublic', 'client'],
  components: {
    Field,
    ErrorMessage,
    Form,
    IcnUpload
  },
  data() {
    return {
      edit: false,
      error: null
    }
  }
  // mounted() {
  //   if (
  //     (this.fullname !== null &&
  //       this.speciality !== null &&
  //       this.country !== null &&
  //       this.phonenumber !== null) ||
  //     this.isPublic
  //   ) {
  //     this.edit = false
  //   } else {
  //     this.edit = true
  //   }
  //   this.splitPhoneNumber()
  // },
  // computed: {
  //   ...mapGetters(['token']),
  //   phoneNumberComplete() {
  //     return this.phoneCodeNumber + this.phonenumber
  //   }
  // },
  // methods: {
  //   splitPhoneNumber() {
  //     const checkNumber = this.phonenumber
  //     if (checkNumber !== null) {
  //       this.phoneCodes.forEach((item) => {
  //         if (checkNumber.includes(item.dialCode)) {
  //           this.phonenumber = checkNumber.split(item.dialCode)[1]
  //           this.phoneCodeNumber = item.dialCode
  //         }
  //       })
  //     }
  //   },
  //   ...mapActions(['setLoadingOn', 'setLoadingOff', 'fetchUser']),
  //   updateUserProfile: async function () {
  //     try {
  //       this.setLoadingOn()
  //       const personalInfo = await ApolloClient.mutate({
  //         mutation: savePersonalInfoMutation,
  //         context: {
  //           headers: {
  //             authorization: `Bearer ${await this.token}`
  //           }
  //         },
  //         variables: {
  //           fullname: this.fullname,
  //           phonenumber: this.phoneNumberComplete,
  //           country: this.country,
  //           id: this.user.id,
  //           speciality:
  //             this.speciality === 'Other/Otro'
  //               ? this.otherSpeciality
  //               : this.speciality
  //         }
  //       })
  //       if (!personalInfo.errors) {
  //         this.setLoadingOff()
  //         await this.fetchUser()
  //         toastNotification(this.$t('general.info-guardada'), 'success')
  //         this.edit = false
  //       }
  //     } catch (e) {
  //       this.error = e
  //       toastNotification(e, 'error')
  //     }
  //   },
  //   // RULES
  //   validateDefault,
  //   validateDefaultPhone,
  //   validatePhone
  // }
}
</script>
