import gql from 'graphql-tag'

const jobQuery = gql`
  query jobQUery($jobId: Int!) {
    job(jobId: $jobId) {
      id
      jobTitle
      jobTime
      jobType
      expRequired
      salaryRange
      techStack
      responsabilities
      benefits
      requirements
      company {
        id
        name
        about
        industry
        size
      }
    }
  }
`

export default jobQuery
