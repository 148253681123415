<template>
  <div class="loading">
    <img
      src="@/assets/images/cody-spinner.svg"
      alt="Cody Loading..."
      class="loading--image"
    />
    <div class="loading--text">Loading...</div>
  </div>
</template>
<script>
export default {
  name: 'Loading'
}
</script>
