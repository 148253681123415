<template>
  <main class="main--wrapper">
    <section class="pt-32 pb-14 lg:pt-40 lg:pb-0">
      <Suscriptions class="lg:w-6/12 mx-auto" />
    </section>
  </main>
</template>
<script>
import Suscriptions from '@/components/SuscriptionSection'

export default {
  name: 'Paywall',
  components: {
    Suscriptions
  }
}
</script>
