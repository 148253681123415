import gql from 'graphql-tag'

const getPositions = gql`
  query positionTypes {
    positionTypes {
      id
      name
    }
  }
`

export default getPositions
