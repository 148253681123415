<template>
  <section class="mb-12">
    <Title class="mb-4">
      <template #title>
        <h4 class="mt-1">{{ $t('profile-page.title-educacion-formal') }}</h4>
      </template>
      <template #options v-if="!isPublic && edit === false">
        <button
          @click="editSection()"
          type="button"
          role="button"
          aria-label="Edit"
          class="
            w-9
            h-9
            flex
            justify-center
            items-center
            rounded-md
            btn-secondary
            transform
            -translate-y-2
          "
        >
          <img src="@/assets/images/icn-edit.svg" />
        </button>
      </template>
    </Title>
    <!-- If not complete the section -->
    <div
      class="text-center"
      v-if="!isPublic && edit === false && formalEducation.length <= 0"
    >
      <div class="flex justify-center space-x-6">
        <BadgeAward
          class="inline-block"
          imagen="icn-experiencia.svg"
          :bloqueado="true"
          :titulo="$t('habilidades-page.title-experiencia')"
        ></BadgeAward>
      </div>
      <p class="text-secondary-text text-center">
        {{ $t('profile-page.description-educacion-formal-badge') }}
      </p>
      <div class="flex justify-center">
        <button
          type="button"
          class="btn btn-md btn-rounded-lg btn-primary mx-auto"
          @click="editSection()"
        >
          {{ $t('general.completar') }}
        </button>
      </div>
    </div>
    <!-- If not complete and have to add info -->
    <Form @submit="validate" v-else-if="!isPublic && edit === true">
      <p class="dark:text-primary-text text-secondary-text">
        {{ $t('modal-educacion-formal.description') }}
      </p>
      <div v-for="(education, index) in formalEducation" :key="index">
        <!-- First Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4 items-end">
          <div class="form--group">
            <label class="form--label" :for="`grade-${index}`">{{
              $t('modal-educacion-formal.lbl-grado')
            }}</label>
            <Field
              :name="`grade-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="education.grade"
            />
            <ErrorMessage :name="`grade-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--label" :for="`school-${index}`">{{
              $t('modal-educacion-formal.lbl-entidad-educativa')
            }}</label>
            <Field
              :name="`school-${index}`"
              type="text"
              class="form--element w-full"
              :rules="validateDefault"
              v-model="education.school"
            />
            <ErrorMessage :name="`school-${index}`" class="form--error" />
          </div>
        </div>
        <!-- End First Row -->
        <!-- Second Row -->
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6">
          <div
            class="form--group"
            :class="!education.expires ? '' : 'col-span-2'"
          >
            <h4
              class="
                dark:text-primary-text
                text-secondary-text
                font-bold
                mb-2
                lg:col-span-2
              "
            >
              {{ $t('modal-experiencia-profesional.title-fecha-de-inicio') }}
            </h4>
            <Field
              :name="`startDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="education.startDate"
              class="form--icon form--icon--left block"
            >
              <span class="icon--wrapper bg-secondary text-white rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                :max="new Date().toISOString().split('T')[0]"
              />
            </Field>
            <ErrorMessage :name="`startDate-${index}`" class="form--error" />
          </div>
          <div class="form--group" v-if="!education.expires">
            <h4
              class="
                dark:text-primary-text
                text-secondary-text
                font-bold
                mb-2
                lg:col-span-2
              "
            >
              {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
            </h4>
            <Field
              :name="`endDate-${index}`"
              as="label"
              v-slot="{ field }"
              v-model="education.endDate"
              class="form--icon form--icon--left block"
            >
              <span class="icon--wrapper bg-secondary text-white rounded-l-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  class="w-4 h-4 fill-current"
                >
                  <path
                    d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                  ></path>
                </svg>
              </span>
              <input
                class="form--element w-full"
                v-bind="field"
                type="date"
                :min="
                  education.startDate || new Date().toISOString().split('T')[0]
                "
              />
            </Field>
            <ErrorMessage :name="`endDate-${index}`" class="form--error" />
          </div>
          <div class="form--group">
            <label class="form--checkbox"
              ><input
                type="checkbox"
                :id="`remember-${index}`"
                value="first_checkbox"
                @change="checkExpires(index)"
                v-model="education.expires"
              /><span>{{
                $t('modal-educacion-formal.lbl-sigo-estudiando')
              }}</span></label
            >
          </div>
        </div>
        <!-- End Second Row -->
        <div class="flex justify-end mb-6">
          <button
            class="
              w-9
              h-9
              flex
              justify-center
              items-center
              rounded-md
              btn-secondary
            "
            @click="removeRow(index)"
          >
            <img src="@/assets/images/icn-remove.svg" />
          </button>
        </div>
        <hr class="line-line border-dashed mb-6" />
      </div>
      <button
        class="btn btn-rounded-lg btn-secondary btn-md mb-8"
        type="button"
        role="button"
        @click="addRow"
        :aria-label="$t('modal-educacion-formal.btn-agregar-otra-educación')"
      >
        {{ $t('modal-educacion-formal.btn-agregar-otra-educación') }}
      </button>
      <div class="flex justify-center space-x-4">
        <button
          type="button"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="cancelEdition"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          type="submit"
          class="btn btn-primary mx-auto btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </Form>
    <!-- Else if the section is complete -->
    <div v-else>
      <ul class="list--education list--education--small">
        <li
          v-for="(education, index) in formalEducation"
          :key="index"
          class="pb-8"
        >
          <span class="list--education--bullets"></span>
          <time class="dark:text-primary-text text-secondary-text text-sm"
            >{{ formatedDate(education.startDate) }} |
            <span v-if="education.endDate">{{
              formatedDate(education.endDate)
            }}</span>
            <span v-else>
              {{ $t('general.presente') }}
            </span>
          </time>
          <h4 class="text-white font-bold text-lg mb-2 uppercase">
            {{ education.school }} |
            <i class="text-secondary">{{ education.grade }}</i>
          </h4>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import BadgeAward from '@/components/BadgeAward'
import Title from '@/components/Title'
import ROUTES from '@/constants/routes'
import { dateFormat } from '@/services/dateFormatting'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import saveFormalEducationMutation from '@/graphql/mutations/saveFormalEducation'
import { mapActions, mapGetters } from 'vuex'
import { validateDefault, validateNumber } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

const emptyEducation = {
  grade: null,
  startDate: null,
  endDate: null,
  school: null,
  expires: false
}

export default {
  name: 'EducaciónFormalSection',
  props: {
    userFormalEducation: Array,
    isPublic: Boolean,
    id: { type: Number, required: false }
  },
  components: {
    Title,
    BadgeAward,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      ROUTES,
      formalEducation: [],
      edit: true
    }
  },
  mounted() {
    this.initializeData()
    if (this.formalEducation !== []) {
      this.edit = false
    } else {
      this.edit = true
    }
  },
  computed: {
    ...mapGetters(['token']),
    sortFormalEducation() {
      return [...this.formalEducation].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  methods: {
    confDate: function (e) {
      return dateFormat(e)
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return 'Actual'
      }
    },
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validate() {
      try {
        this.setLoadingOn()
        const saveFormalEducation = await apolloClient.mutate({
          mutation: saveFormalEducationMutation,
          variables: {
            formalEducation: this.sortFormalEducation,
            id: this.id
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveFormalEducation.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          toastNotification(this.$t('general.info-guardada'), 'success')
          this.edit = false
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.formalEducation.push({
        ...emptyEducation
      })
    },
    removeRow(index) {
      this.formalEducation.splice(index, 1)
    },
    editSection() {
      this.edit = true
      if (this.formalEducation.length <= 0) {
        this.formalEducation.push({
          ...emptyEducation
        })
      }
    },
    cancelEdition() {
      this.formalEducation = []
      if (this.userFormalEducation.length > 0) {
        this.initializeData()
      }
      this.edit = false
    },
    initializeData() {
      if (this.userFormalEducation) {
        this.userFormalEducation.forEach((val) => {
          this.formalEducation.unshift({
            grade: val.grade,
            startDate: val.startDate,
            endDate: val.endDate,
            school: val.school
          })
        })
      }
    },
    // RULES
    validateDefault,
    validateNumber,
    checkExpires(index) {
      if (this.formalEducation[index].expires === true) {
        this.formalEducation[index].endDate = null
      }
    }
  }
}
</script>
