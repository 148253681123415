<template>
  <div class="p-4">
    <iframe
      width="300"
      height="200"
      :src="source"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      class="mb-4"
    ></iframe>
    <p class="text-white font-bold text-lg last">{{ speaker }}</p>
    <p class="text-summit font-bold text-lg">{{ event }}</p>
    <p class="dark:text-primary-text text-secondary-text text-sm">
      {{ description }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'ConferenceVideo',
  props: {
    source: String,
    event: String,
    speaker: String,
    description: String
  }
}
</script>
