<template>
  <blockquote class="blockquote--wrapper mx-auto w-8/12">
    <div class="blockquote--quote-up mx-auto w-3/12">
      <svg
        viewBox="0 0 48 48"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.34 34C17.36 34 18.3 33.42 18.74 32.52L21.58 26.84C21.86 26.28 22 25.68 22 25.06V16C22 14.9 21.1 14 20 14H12C10.9 14 10 14.9 10 16V24C10 25.1 10.9 26 12 26H16L13.94 30.12C13.04 31.9 14.34 34 16.34 34ZM32.34 34C33.36 34 34.3 33.42 34.74 32.52L37.58 26.84C37.86 26.28 38 25.68 38 25.06V16C38 14.9 37.1 14 36 14H28C26.9 14 26 14.9 26 16V24C26 25.1 26.9 26 28 26H32L29.94 30.12C29.04 31.9 30.34 34 32.34 34Z"
          fill="#4F42F2"
        />
      </svg>
    </div>
    <p class="blockquote--cite-center">{{ cite }}</p>
    <footer class="">
      <div class="blockquote--data">
        <div class="blockquote--author-center mb-2">{{ author }}</div>
        <div class="blockquote--position-center" v-if="position">
          {{ position }}
        </div>
      </div>
      <slot></slot>
    </footer>
  </blockquote>
</template>

<script>
export default {
  name: 'Blockquote',
  props: {
    cite: String,
    author: String,
    position: String,
    authorImage: String
  }
}
</script>
