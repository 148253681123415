<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper">
      <div class="w-full lg:w-8/12 xl:w-8/12 mx-auto">
        <!-- Card Page Sign Up -->
        <CardPage>
          <template #default>
            <button
              type="button"
              class="card--close"
              role="button"
              @click="$router.push({ name: 'Habilidades' })"
            >
              <img
                src="@/assets/images/icn-close.svg"
                :alt="$t('general.cerrar')"
              />
            </button>
            <!-- Title -->
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h3>{{ $t('habilidades-test-incompletos-page.title') }}</h3>
                </template>
              </Title>
            </div>
            <!-- End Title -->
            <!-- Body -->
            <div class="card--page--body">
              <div
                class="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-6 items-center"
              >
                <p class="dark:text-primary-text text-secondary-text text-lg">
                  {{ $t('habilidades-test-incompletos-page.description-1') }}
                  <b>{{ $route.params.assessmentName }}.</b>
                  {{ $t('habilidades-test-incompletos-page.description-2')
                  }}<br />
                  {{ $t('habilidades-test-incompletos-page.description-3') }}
                  <a href="mailto:contact@utopicode.com" class="text-primary"
                    >contact@utopicode.com</a
                  >
                  {{ $t('habilidades-test-incompletos-page.description-4') }}
                </p>
                <img src="@/assets/images/img-cody-connection.svg" alt="" />
              </div>
            </div>
            <!-- End Body -->
            <!-- Footer -->
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="btn btn-primary btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
                @click="$router.push({ name: 'Habilidades' })"
              >
                {{ $t('general.volver') }}
              </button>
            </div>
            <!-- End Footer -->
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { mapGetters } from 'vuex'

export default {
  name: 'HabilidadesTestIncompletos',
  components: {
    CardPage,
    Title
  },
  computed: {
    ...mapGetters(['user'])
  }
}
</script>
