<template>
  <ul class="pagination">
    <li>
      <react-router
        :disabled="currentPage <= 1"
        to="/next"
        class="pagination--btn"
        :class="
          currentPage <= 1 ? 'pagination--disabled' : 'pagination--default'
        "
        >&#60;</react-router
      >
    </li>
    <li v-for="(page, index) in totalPages" :key="index">
      <rounter-link
        to="/page"
        class="pagination--btn"
        :class="
          currentPage - 1 == index
            ? 'pagination--active'
            : 'pagination--default'
        "
      >
        {{ index + 1 }}
      </rounter-link>
    </li>
    <li>
      <react-router
        :disabled="currentPage >= totalPages"
        to="/prev"
        class="pagination--btn"
        :class="
          currentPage >= totalPages
            ? 'pagination--disabled'
            : 'pagination--default'
        "
        >&#62;</react-router
      >
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    totalPages: null,
    currentPage: null
  }
}
</script>
