<template>
  <div
    v-if="badges.length > 0"
    class="flex gap-2 flex-wrap overflow-hidden relative pr-6"
    :class="expand ? 'h-auto' : size"
  >
    <span
      v-for="(badge, index) in badges"
      :key="index"
      class="badge badge-main badge-xs badge-rounded-full"
      >{{ badge.techName }}</span
    >
    <button
      class="absolute right-0 bottom-0 text-2xl"
      @click="expand = !expand"
    >
      ...
    </button>
  </div>
</template>

<script>
export default {
  name: 'BadgeCollapse',
  props: {
    badges: Object,
    size: String
  },
  data() {
    return {
      expand: false
    }
  }
}
</script>
