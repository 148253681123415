<template>
  <div class="cards w-auto p-6">
    <h4 class="title-lg dark:text-white text-contrast font-bold mb-6">
      {{ title }}
    </h4>
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 items-start">
      <div class="flex items-center">
        <div
          class="
            w-16
            h-16
            bg-secondary
            rounded-2xl
            mr-6
            flex
            justify-center
            align-center
          "
        >
          <img class="w-8" src="@/assets/images/icn-laptop-code.svg" />
        </div>
        <div class="w-auto">
          <h2 class="text-3xl font-bold dark:text-white text-contrast">
            {{ data.total }}
          </h2>
          <span class="dark:text-white text-contrast">{{ data.city }}</span>
        </div>
      </div>
      <div class="w-full lg:w-12/12">
        <div class="text-justify mb-4">
          <!-- <span v-for="(stack, index) in data.stack">{{ stack.name }}</span> -->
          <div
            class="grid grid-cols-2 gap-4"
            v-for="(value, key) in data.stack"
            :key="value.id"
          >
            <span class="cols-span-7 dark:text-white text-contrast">{{
              key
            }}</span>
            <span class="font-bold dark:text-white text-contrast">{{
              value
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardWidget',
  props: {
    title: String,
    data: Object
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    // getWidgetdata() {
    //   if (this.data === 'soy un chart') {
    //     this.chartdata = true
    //   } else if (this.data === 'soy activity') {
    //     this.activitydata = true
    //   } else {
    //     this.topdata = true
    //   }
    // }
  }
}
</script>
