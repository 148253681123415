<template>
  <div class="donut-wrapper">
    <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
      <circle
        class="donut-ring"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3"
      ></circle>
      <circle
        class="donut-segment"
        cx="20"
        cy="20"
        r="15.91549430918954"
        fill="transparent"
        stroke-width="3"
        :stroke-dasharray="`${
          (respuestasCorrectas * 100) / respuestasTotales
        } ${100 - (respuestasCorrectas * 100) / respuestasTotales}`"
        stroke-dashoffset="20"
      ></circle>
      <g class="donut-meta">
        <rect
          height="30"
          class="transform rotate-45"
          width="30"
          y="50%"
          x="50%"
          fill="#333"
          style="transform-origin: 50% 50%"
        ></rect>
        <text class y="90%">
          <tspan x="50%" text-anchor="middle" class="donut-percent">
            <tspan>{{ respuestasCorrectas }}</tspan>
            /{{ respuestasTotales }}
          </tspan>
        </text>
      </g>
      <g class="donut-icon">
        <image
          y="35%"
          x="35%"
          :href="require(`@/assets/images/${icon}`)"
          alt="Icon"
        />
      </g>
    </svg>
    <div class="donut-text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: {
    icon: String,
    title: String,
    respuestasTotales: Number,
    respuestasCorrectas: Number
  }
}
</script>
