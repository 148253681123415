<template>
  <section class="mb-6">
    <Form @submit="saveSocialNetworks" v-if="editSocialNetwork">
      <div class="grid gap-x-6 gap-y-4 grid-cols-1 mb-8 items-start">
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-linkedin rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-linkedin.svg"
                alt="LinkedIn"
              />
            </span>
            <Field
              name="linkedin"
              type="text"
              :rules="validateUrl"
              class="form--element element--sm w-full"
              :placeholder="$t('profile-page.social-holder-linkedin')"
              v-model="social.linkedin"
            />
          </div>
          <ErrorMessage name="linkedin" class="text-primary text-sm" />
        </div>
        <div class="form--icon form--icon--left">
          <span class="icon--wrapper bg-github rounded-l-lg">
            <a href="">
              <img
                src="@/assets/images/social-media/icn-github.svg"
                alt="Github"
            /></a>
          </span>
          <input
            v-if="editSocialNetwork"
            type="text"
            class="form--element element--sm w-full"
            :placeholder="$t('profile-page.social-holder-github')"
            v-model="social.github"
          />
        </div>
        <div class="form--icon form--icon--left">
          <span class="icon--wrapper bg-twitter rounded-l-lg">
            <img
              src="@/assets/images/social-media/icn-twitter.svg"
              alt="Twitter"
            />
          </span>
          <input
            type="text"
            class="form--element element--sm w-full"
            :placeholder="$t('profile-page.social-holder-twitter')"
            v-model="social.twitter"
          />
        </div>
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-bitbucket rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-bitbucket.svg"
                alt="Bitbucket"
              />
            </span>
            <Field
              name="bitbucket"
              type="text"
              :rules="validateUrl"
              class="form--element element--sm w-full"
              :placeholder="$t('profile-page.social-holder-bitbucket')"
              v-model="social.bitbucket"
            />
          </div>
          <ErrorMessage name="bitbucket" class="text-primary text-sm" />
        </div>
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-dribble rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-dribble.svg"
                alt="Dribble"
              />
            </span>
            <Field
              name="dribble"
              type="text"
              :rules="validateUrl"
              class="form--element element--sm w-full"
              :placeholder="$t('profile-page.social-holder-dribble')"
              v-model="social.dribble"
            />
          </div>
          <ErrorMessage name="dribble" class="text-primary text-sm" />
        </div>
        <div>
          <div class="form--icon form--icon--left">
            <span class="icon--wrapper bg-behance rounded-l-lg">
              <img
                src="@/assets/images/social-media/icn-behance.svg"
                alt="Behance"
              />
            </span>
            <Field
              name="behance"
              type="text"
              :rules="validateUrl"
              class="form--element element--sm w-full"
              :placeholder="$t('profile-page.social-holder-behance')"
              v-model="social.behance"
            />
          </div>
          <ErrorMessage name="behance" class="text-primary text-sm" />
        </div>
      </div>
    </Form>
    <ul
      class="
        flex flex-wrap
        text-base
        dark:text-primary-text
        text-secondary-text
        items-center
        list--social
        gap-2
        mb-6
      "
      v-else
    >
      <li v-if="social.linkedin">
        <span class="bg-linkedin">
          <img
            src="@/assets/images/social-media/icn-linkedin.svg"
            alt="LinkedIn"
          />
        </span>
      </li>
      <li v-if="social.github">
        <span class="bg-github">
          <img src="@/assets/images/social-media/icn-github.svg" alt="Github" />
        </span>
      </li>
      <li v-if="social.twitter">
        <span class="bg-twitter">
          <img
            src="@/assets/images/social-media/icn-twitter.svg"
            alt="Twitter"
          />
        </span>
      </li>
      <li v-if="social.bitbucket">
        <span class="bg-bitbucket">
          <img
            src="@/assets/images/social-media/icn-bitbucket.svg"
            alt="Bitbucket"
          />
        </span>
      </li>
      <li v-if="social.dribble">
        <span class="bg-dribble">
          <img
            src="@/assets/images/social-media/icn-dribble.svg"
            alt="Dribble"
          />
        </span>
      </li>
      <li v-if="social.behance">
        <span class="bg-behance">
          <img
            src="@/assets/images/social-media/icn-behance.svg"
            alt="Behance"
          />
        </span>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
// import apolloClient from '../../graphql/apolloClient'
// import SaveSocialNetworksMutation from '../../graphql/mutations/saveSocialNetworks'
import { Field, Form, ErrorMessage } from 'vee-validate'
// import { validateUrl } from '@/services/validationRules'
// import { toastNotification } from '@/services/toastNotification'

export default {
  name: 'SocialMediaSection',
  components: {
    Field,
    Form,
    ErrorMessage
  },
  props: {
    editSocialNetwork: Boolean,
    socialNetworks: Object,
    id: { type: Number, required: false }
  },
  computed: {
    ...mapGetters(['token'])
  },
  data() {
    return {
      social: {
        linkedin: this.socialNetworks ? this.socialNetworks.linkedin : null,
        github: this.socialNetworks ? this.socialNetworks.github : null,
        twitter: this.socialNetworks ? this.socialNetworks.twitter : null,
        bitbucket: this.socialNetworks ? this.socialNetworks.bitbucket : null,
        dribble: this.socialNetworks ? this.socialNetworks.dribble : null,
        behance: this.socialNetworks ? this.socialNetworks.behance : null
      }
    }
  },
  //   mounted() {
  //     this.getSocialNetworks()
  //   },
  methods: {
    ...mapActions([
      'updateUserSocialNetworks',
      'setLoadingOn',
      'setLoadingOff',
      'fetchUser'
    ])
    // getSocialNetworks() {
    //   if (
    //     this.social.linkedin ||
    //     this.social.github ||
    //     this.social.twitter ||
    //     this.social.bitbucket ||
    //     this.social.dribble ||
    //     this.social.behance !== null
    //   ) {
    //     this.editSocialNetwork = false
    //   } else {
    //     this.editSocialNetwork = true
    //   }
    // },
    // saveSocialNetworks: async function () {
    //   try {
    //     this.setLoadingOn()
    //     const saveSocialNetworks = await apolloClient.mutate({
    //       mutation: SaveSocialNetworksMutation,
    //       context: {
    //         headers: {
    //           authorization: `Bearer ${await this.token}`
    //         }
    //       },
    //       variables: {
    //         linkedin: this.social.linkedin,
    //         github: this.social.github,
    //         twitter: this.social.twitter,
    //         behance: this.social.behance,
    //         dribble: this.social.dribble,
    //         bitbucket: this.social.bitbucket,
    //         id: this.id
    //       }
    //     })
    //     if (!saveSocialNetworks.errors) {
    //       await this.fetchUser()
    //       this.editSocialNetwork = false
    //       this.setLoadingOff()
    //       toastNotification(this.$t('general.info-guardada'), 'success')
    //     }
    //   } catch (e) {
    //     toastNotification(e, 'error')
    //   }
    // },
    // RULES
    // validateUrl
  }
}
</script>
