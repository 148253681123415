import gql from 'graphql-tag'

const saveAnswerMutation = gql`
  mutation saveAnswerMutation(
    $questionId: Int!
    $answer: Int!
    $attemptId: Int!
  ) {
    saveQuestionAnswer(
      questionId: $questionId
      answer: $answer
      attemptId: $attemptId
    ) {
      id
      options
      text
    }
  }
`

export default saveAnswerMutation
