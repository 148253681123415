import gql from 'graphql-tag'

const editEventMutation = gql`
  mutation editEvent(
    $eventId: Int!
    $nameEn: String
    $nameEs: String
    $photo: String
    $date: String
    $speaker: String
    $videoLink: String
    $calendarLink: String
    $liveLink: String
    $descriptionEn: String
    $descriptionEs: String
    $subCategories: String
    $category: String
    $speakerJobEn: String
    $speakerJobEs: String
    $speakerPhoto: String
    $speakerAboutEs: String
    $speakerAboutEn: String
    $whatWillLearnEs: String
    $whatWillLearnEn: String
  ) {
    updateEventData(
      eventId: $eventId
      nameEn: $nameEn
      nameEs: $nameEs
      photo: $photo
      date: $date
      speaker: $speaker
      videoLink: $videoLink
      calendarLink: $calendarLink
      liveLink: $liveLink
      descriptionEn: $descriptionEn
      descriptionEs: $descriptionEs
      subCategories: $subCategories
      category: $category
      speakerJobEn: $speakerJobEn
      speakerJobEs: $speakerJobEs
      speakerPhoto: $speakerPhoto
      speakerAboutEs: $speakerAboutEs
      speakerAboutEn: $speakerAboutEn
      whatWillLearnEs: $whatWillLearnEs
      whatWillLearnEn: $whatWillLearnEn
    )
  }
`

export default editEventMutation
