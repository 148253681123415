<template>
  <main class="main--wrapper py-40">
    <div class="content--wrapper">
      <div class="w-full lg:w-6/12 xl:w-5/12 mx-auto">
        <!-- Card Page Sign Up -->
        <CardPage>
          <template #default>
            <Form @submit="recuperar">
              <!-- Title -->
              <div class="card--page--header">
                <Title>
                  <template #title>
                    <h3>{{ $t('recuperar-contraseña-page.title') }}</h3>
                  </template>
                </Title>
              </div>
              <!-- End Title -->
              <!-- Body -->
              <div class="card--page--body">
                <p class="text-white text-lg">
                  {{ $t('recuperar-contraseña-page.texto-bienvenida') }}
                </p>
                <div class="form--group">
                  <label class="form--label" for="email">{{
                    $t('recuperar-contraseña-page.lbl-email')
                  }}</label>
                  <Field
                    name="email"
                    type="email"
                    class="form--element w-full"
                    :rules="validateEmail"
                  />
                  <ErrorMessage name="email" class="form--error" />
                </div>
                <div class="form--group text-primary" v-if="error !== null">
                  {{ error }}
                </div>
                <div
                  class="form--group text-secondary font-bold"
                  v-if="successMessage !== null"
                >
                  {{ successMessage }}
                </div>
              </div>
              <!-- End Body -->
              <!-- Footer -->
              <div class="card--page--footer">
                <button
                  type="submit"
                  class="btn btn-lg btn-primary btn-rounded-lg mx-auto"
                >
                  {{ $t('recuperar-contraseña-page.btn-recuperar') }}
                </button>
              </div>
              <!-- End Footer -->
            </Form>
          </template>
        </CardPage>
        <!-- End Card Page Sign Up -->
      </div>
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { Field, Form, ErrorMessage } from 'vee-validate'
import firebase from '@/services/firebase'
import { validateEmail } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

export default {
  components: {
    CardPage,
    Title,
    Form,
    Field,
    ErrorMessage
  },
  data() {
    return {
      error: null,
      successMessage: null
    }
  },
  methods: {
    recuperar: async function ({ email }) {
      if (email) {
        try {
          await firebase.sendPasswordResetEmail(email)
          this.successMessage = this.$i18n.t(
            'recuperar-contraseña-page.success-message'
          )
        } catch (e) {
          this.$i18n.t('recuperar-contraseña-page.error-message')
          toastNotification(e, 'error')
        }
      }
    },
    // RULES
    validateEmail
  }
}
</script>
