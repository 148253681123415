import gql from 'graphql-tag'

const assesmentResult = gql`
  query assesmentResult($assesmentId: Int!) {
    assesmentResult(assesmentId: $assesmentId) {
      id
      keyPercentil
      description
      assesment {
        id
        name
        testLength
      }
      totalScore
      subAssesmentsResult {
        id
        keyPercentil
        totalScore
        description
        assesment {
          id
          name
          testLength
          instructions
        }
      }
    }
  }
`

export default assesmentResult
