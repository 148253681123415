import gql from 'graphql-tag'

const userEventsQuery = gql`
  query userEventsQuery {
    user {
      fullname
    }
    userProfile {
      events {
        id
        event(lang: "es") {
          id
          date
          name
          place
          eventLang
          photo
          category
          subCategories
        }
      }
      registeredEvents
    }
  }
`

export default userEventsQuery
