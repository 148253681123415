<template>
  <AdminHeader />
  <router-view></router-view>
</template>

<script>
import AdminHeader from '@/components/AdminHeader'

export default {
  name: 'AdminDashboard',
  components: {
    AdminHeader
  }
}
</script>
