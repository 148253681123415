import gql from 'graphql-tag'

const saveCertsMutation = gql`
  mutation saveFormalEducationMutation(
    $formalEducation: [UserProfileFormalEducationInputType]
    $id: Int
  ) {
    updateEducation(formalEducation: $formalEducation, id: $id) {
      grade
      school
      startDate
      endDate
    }
  }
`

export default saveCertsMutation
