import { createRouter, createWebHistory } from 'vue-router'

// Dashboard
import Admin from '@/views/Admin.vue'
import AdminWelcome from '@/views/AdminWelcome.vue'
import AdminCandidate from '@/views/AdminCandidate.vue'
import AdminCandidates from '@/views/AdminCandidates.vue'
import AdminEvents from '@/views/AdminEvents.vue'
import AdminEventDetail from '@/views/AdminEventDetail.vue'
import AdminEventCreate from '@/views/AdminEventCreate.vue'
import AdminEventEdit from '@/views/AdminEventEdit.vue'

// Sitio
import Home from '@/views/Home.vue'
import Comunidad from '@/views/Comunidad.vue'
import Eventos from '@/views/Eventos.vue'
import EventosAll from '@/views/EventosAll.vue'
import EventoDetail from '@/views/EventoDetail.vue'
import Cursos from '@/views/Cursos.vue'
import CursosAll from '@/views/CursosAll.vue'
import Mentorias from '@/views/Mentorias.vue'
import Habilidades from '@/views/Habilidades.vue'
import HabilidadesTest from '@/views/HabilidadesTest.vue'
import HabilidadesTestIncompletos from '@/views/HabilidadesTestIncompletos.vue'
import Trabajos from '@/views/Trabajos.vue'
import TrabajosDetail from '@/views/TrabajosDetail.vue'
import Nosotros from '@/views/Nosotros.vue'
import Contrata from '@/views/ContrataDevs.vue'
import Faq from '@/views/Faq.vue'
import Terminos from '@/views/TerminosCondiciones.vue'
import Politica from '@/views/PoliticaPrivacidad.vue'
import SignUp from '@/views/SignUp.vue'
import SignIn from '@/views/SignIn.vue'
import RecuperarContraseña from '@/views/RecuperarContraseña.vue'
import Profile from '@/views/Profile.vue'
import ProfileEvents from '@/views/ProfileEvents.vue'
import ResultadoAssesments from '@/views/ResultadoAssesments.vue'
import InstruccionesAssesments from '@/views/InstruccionesAssesments.vue'
import BadgesDetail from '@/views/BadgesDetail.vue'
import MisTests from '@/views/MisTests.vue'
import TrabajosTech from '@/views/TrabajosTech.vue'
import PageNotFound from '@/views/PageNotFound.vue'
// Profile
import EditProfile from '@/views/Profile/EditProfile.vue'
import EditTecnologias from '@/views/Profile/EditTecnologias.vue'
import EditExperienciaProfesional from '@/views/Profile/EditExperienciaProfesional.vue'
import EditEducacionFormal from '@/views/Profile/EditEducacionFormal.vue'
import EditCertificacionesCursos from '@/views/Profile/EditCertificacionesCursos.vue'
import PublicProfile from '@/views/PublicProfile.vue'
import EditProfileAvatar from '@/views/Profile/EditProfileAvatar.vue'

import DashboardProfile from '@/views/Profile/DashboardProfile.vue'

// Dashboard Empresas
import CandidateProfile from '@/views/Empresas/CandidateProfile.vue'
import LandingEmpresas from '@/views/Empresas/Landing.vue'
import Paywall from '@/views/Empresas/Paywall.vue'
import SignInEmpresas from '@/views/Empresas/SignIn.vue'
// import SignUpEmpresas from '@/views/Empresas/SignUp.vue'
import SignUpEmpresas from '@/views/Company/SignUp.vue'
import DashboardPremium from '@/views/Empresas/DashboardPremium.vue'
import DashboardKanban from '@/views/Empresas/DashboardKanban.vue'
import DetalleBusqueda from '@/views/Empresas/DetalleBusqueda.vue'
import CreatePool from '@/views/Empresas/CreateNewPool.vue'
import CreatePosition from '@/views/Empresas/CreateNewPosition.vue'
import ClientsProfile from '@/views/Empresas/Profile.vue'
// Upskilling
import Upskilling from '@/views/Upskilling.vue'
import UpskillingB2C from '@/views/UpskillingB2C.vue'
// Summit
import LandingSummit from '@/views/LandingSummit.vue'

import store from '@/store/index'
import ROUTES from '@/constants/routes'
import { getCurrentUser } from '@/services/firebase'

// layouts
import DefaultLayout from '@/layouts/default.vue'
import ClientsLayout from '@/layouts/layoutClients.vue'
import NoHeaderFooter from '@/layouts/noHeaderFooter.vue'
// import ProfileLayout from '@/layouts/ProfileLayout.vue'
import i18n from '@/plugins/i18n'

import en from '@/locales/en.json'
import es from '@/locales/es.json'

// Swag
import Unboxing from '@/views/Unboxing.vue'

const routes = [
  {
    path: '',
    redirect: '/es'
  },
  {
    path: '/admin',
    component: Admin,
    async beforeEnter(to, from, next) {
      if (await getCurrentUser()) {
        return next()
      } else {
        return next('/es')
      }
    },
    children: [
      {
        path: '',
        component: AdminWelcome,
        name: 'AdminWelcome'
      },
      {
        path: 'candidatos',
        component: AdminCandidates,
        name: 'AdminCandidates'
      },
      {
        path: 'candidatos/:id',
        name: 'AdminCandidate',
        component: AdminCandidate
      },
      {
        path: 'eventos',
        component: AdminEvents,
        name: 'AdminEvents'
      },
      {
        path: 'evento/:id',
        component: AdminEventDetail,
        name: 'AdminEventDetail'
      },
      {
        path: 'evento/edit/:id',
        component: AdminEventEdit,
        name: 'AdminEventEdit'
      },
      {
        path: 'evento/create',
        component: AdminEventCreate,
        name: 'AdminEventCreate'
      }
    ]
  },
  {
    path: '/:locale',
    meta: {
      layout: DefaultLayout
    },
    beforeRouteEnter: (to, from, next) => {
      const locale = to.params.locale
      if (!locale) {
        return next('es')
      }
      const supportedLocales = ['es', 'en']
      if (!supportedLocales.includes(locale)) {
        return next(`/${i18n.global.locale}/${locale}`)
      }
      if (i18n.global.locale !== locale) {
        // 4
        i18n.global.locale = locale
      }
      if (to.meta.unprotected && store.getters.isAuthenticated) {
        return next(ROUTES.home)
      }
      if (to.meta.protected && !store.getters.isAuthenticated) {
        return next({
          path: ROUTES.signin,
          query: { redirectTo: to.fullPath }
        })
      }
      if (to.meta.needsLoading) {
        store.dispatch('setLoadingOn')
      }
      if (to.meta.title) {
        if (locale === 'es') {
          document.title = `UTOPICODE | ${to.meta.title.es}`
        } else {
          document.title = `UTOPICODE | ${to.meta.title.en} `
        }
      } else {
        if (locale === 'es') {
          document.title = `UTOPICODE | ${es['home-page'].title}`
        } else {
          document.title = `UTOPICODE | ${en['home-page'].title}`
        }
      }
      next()
    },
    component: {
      template: '<router-view></router-view>'
    },
    children: [
      {
        path: 'perfil',
        async beforeEnter(to, from, next) {
          if (await getCurrentUser()) {
            return next()
          } else {
            return next('/es')
          }
        },
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          protected: true
        },
        // Profile Pages
        children: [
          {
            path: 'mi-perfil',
            name: 'Profile',
            component: Profile
          },
          {
            path: ROUTES.profileEvents,
            name: 'ProfileEvents',
            component: ProfileEvents
          },
          {
            path: ROUTES.profileBadges,
            name: 'BadgesDetail',
            component: BadgesDetail
          },
          {
            path: ROUTES.profileTests,
            name: 'MisTests',
            component: MisTests
          },
          {
            path: ROUTES.dashboardProfile,
            name: 'DashboardProfile',
            component: DashboardProfile
          },
          {
            path: ROUTES.editProfile,
            name: 'EditProfile',
            component: EditProfile
          },
          {
            path: ROUTES.editProfileAvatar,
            name: 'EditProfileAvatar',
            component: EditProfileAvatar
          },
          {
            path: ROUTES.editTecnologias,
            name: 'EditTecnologias',
            component: EditTecnologias
          },
          {
            path: ROUTES.editExperienciaProfesional,
            name: 'EditExperienciaProfesional',
            component: EditExperienciaProfesional
          },
          {
            path: ROUTES.editEducacionFormal,
            name: 'EditEducacionFormal',
            component: EditEducacionFormal
          },
          {
            path: ROUTES.editCertificacionesCursos,
            name: 'EditCertificacionesCursos',
            component: EditCertificacionesCursos
          }
        ]
      },
      {
        path: 'empresas',
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          layout: DefaultLayout,
          protected: true
        },
        // Empresas Pages
        children: [
          {
            path: ROUTES.landing,
            name: 'Landing',
            component: LandingEmpresas
          },
          {
            path: ROUTES.candidateProfile,
            name: 'CandidateProfile',
            component: CandidateProfile,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.dashboardKanban,
            name: 'DashboardKanban',
            component: DashboardKanban,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.dashboardPremium,
            name: 'DashboardPremium',
            component: DashboardPremium,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.clientsProfile,
            name: 'ClientsProfile',
            component: ClientsProfile,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.paywall,
            name: 'Paywall',
            component: Paywall
          },
          {
            path: ROUTES.signInEmpresas,
            name: 'SignInEmpresas',
            component: SignInEmpresas
          },
          {
            path: ROUTES.signUpEmpresas,
            name: 'SignUpEmpresas',
            component: SignUpEmpresas,
            meta: {
              layout: NoHeaderFooter,
              protected: true
            }
          },
          {
            path: ROUTES.detalleBusqueda,
            name: 'DetalleBusqueda',
            component: DetalleBusqueda,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.detalleBusqueda,
            name: 'CreatePool',
            component: CreatePool,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          },
          {
            path: ROUTES.detalleBusqueda,
            name: 'CreatePosition',
            component: CreatePosition,
            meta: {
              layout: ClientsLayout,
              protected: true
            }
          }
        ]
      },
      {
        path: ROUTES.upskilling,
        name: 'Upskilling',
        component: Upskilling,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.upskillingb2c,
        name: 'UpskillingB2C',
        component: UpskillingB2C,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.summit,
        name: 'LandingSummit',
        component: LandingSummit,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.home,
        name: 'Home',
        component: Home,
        meta: {
          title: {
            es: 'Devs calificados para el mundo remoto | Homepage',
            en: 'Skilled remote software developers for the world | Homepage'
          },
          needsLoading: true
        }
      },
      {
        path: ROUTES.comunidad,
        name: 'Comunidad',
        component: Comunidad,
        meta: {
          needsLoading: true
        }
      },
      // Eventos Pages
      {
        path: ROUTES.eventos,
        component: {
          template: '<router-view :key="$route.fullPath"></router-view>'
        },
        meta: {
          needsLoading: true
        },
        children: [
          {
            path: '',
            name: 'Eventos',
            component: Eventos
          },
          {
            path: 'all',
            name: 'EventosAll',
            component: EventosAll
          },
          {
            path: ':id',
            name: 'EventoDetail',
            component: EventoDetail
          }
        ]
      },
      // Cursos Pages
      {
        path: ROUTES.cursos,
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          needsLoading: true
        },
        children: [
          {
            path: '',
            name: 'Cursos',
            component: Cursos
          },
          {
            path: 'all',
            name: 'CursosAll',
            component: CursosAll
          }
        ]
      },
      {
        path: ROUTES.mentorias,
        name: 'Mentorias',
        component: Mentorias,
        meta: {
          needsLoading: true
        }
      },
      {
        path: ROUTES.tickets,
        name: 'Tickets',
        component: Comunidad,
        meta: {
          needsLoading: true
        }
      },
      // Habilidades pages
      {
        path: ROUTES.habilidades,
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          needsLoading: true
        },
        children: [
          {
            path: ROUTES.cursos,
            async beforeEnter(to, from, next) {
              if (await getCurrentUser()) {
                return next()
              } else {
                return next('/es')
              }
            },
            component: {
              template: '<router-view></router-view>'
            },
            meta: {
              needsLoading: true
            },
            children: [
              {
                path: `${ROUTES.test}/:id`,
                name: 'HabilidadesTest',
                component: HabilidadesTest
              },
              {
                path: ROUTES.habilidadesTestIncompletos,
                name: 'HabilidadesTestIncompletos',
                component: HabilidadesTestIncompletos,
                meta: {
                  protected: true,
                  needsLoading: false,
                  layout: DefaultLayout
                }
              },
              {
                path: ROUTES.resultadoAssesments,
                name: 'ResultadoAssesments',
                component: ResultadoAssesments,
                meta: {
                  protected: true
                }
              }
            ]
          },
          {
            path: '',
            name: 'Habilidades',
            component: Habilidades
          },
          {
            path: `${ROUTES.detallesHabilidad}/:id`,
            name: 'InstruccionesAssesments',
            component: InstruccionesAssesments,
            meta: {
              protected: true
            }
          }
        ]
      },
      {
        path: ROUTES.trabajos,
        component: {
          template: '<router-view></router-view>'
        },
        meta: {
          needsLoading: true,
          layout: DefaultLayout
        },
        children: [
          {
            path: '',
            name: 'Trabajos',
            component: Trabajos,
            meta: {
              unprotected: true
            }
          },
          {
            path: ROUTES.trabajos,
            name: 'Trabajos Tech',
            component: TrabajosTech,
            meta: { layout: DefaultLayout, unprotected: true }
          },
          {
            path: ':id',
            name: 'TrabajosTechDetail',
            component: TrabajosDetail,
            meta: { unprotected: true }
          }
        ]
      },
      {
        path: ROUTES.nosotros,
        name: 'Nosotros',
        component: Nosotros
      },
      {
        path: ROUTES.contrata,
        name: 'Contrata',
        component: Contrata
      },
      {
        path: ROUTES.faq,
        name: 'Faq',
        component: Faq
      },
      {
        path: ROUTES.terminos,
        name: 'Terminos',
        component: Terminos
      },
      {
        path: ROUTES.politica,
        name: 'Politica',
        component: Politica
      },
      {
        path: ROUTES.signup,
        name: 'SignUp',
        component: SignUp,
        meta: {
          unprotected: true
        }
      },
      {
        path: ROUTES.signin,
        name: 'SignIn',
        component: SignIn,
        meta: {
          unprotected: true
        }
      },
      {
        path: ROUTES.recuperarContraseña,
        name: 'RecuperarContraseña',
        component: RecuperarContraseña
      },
      {
        path: ROUTES.publicProfile,
        name: 'PublicProfile',
        component: PublicProfile,
        meta: { layout: NoHeaderFooter }
      }
    ]
  },
  {
    path: ROUTES.unboxingEs,
    name: 'UnboxingEs',
    component: Unboxing,
    meta: {
      layout: NoHeaderFooter
    }
  },
  {
    path: ROUTES.unboxingEn,
    name: 'UnboxingEn',
    component: Unboxing,
    meta: {
      layout: NoHeaderFooter
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound,
    meta: {
      layout: NoHeaderFooter
    }
  }
]

function wait(duration) {
  return new Promise((resolve) => setTimeout(resolve, duration))
}

async function tryScrollToAnchor(hash, timeout = 1000, delay = 100) {
  while (timeout > 0) {
    const el = document.querySelector(hash)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
      break
    }
    await wait(delay)
    timeout = timeout - delay
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      // Required because our <RouterView> is wrapped in a <Transition>
      // So elements are mounted after a delay
      tryScrollToAnchor(to.hash, 2000, 100)
    } else {
      return { left: 0, top: 0 }
    }
  }
})

export default router
