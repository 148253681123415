<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('eventos-page.title')"
      :hero-description="$t('eventos-page.description')"
      section="pageHeroCta"
      :hero-image="require('@/assets/images/hero/bg-comunidad.svg')"
      class="hero--lg"
    >
      <!-- <template v-slot:pageHeroCta>
        <div class="flex">
          <router-link
            :to="{ name: 'EventosAll' }"
            class="btn btn-lg btn-primary btn-rounded-lg"
          >
            {{ $t('welcome-profile-page.cta-enjoy-events') }}
          </router-link>
        </div>
      </template> -->
    </Hero>
    <div class="content--wrapper">
      <!-- Filter -->
      <!-- <section
        class="
          mt-4
          mb-16
          grid
          gap-x-6 gap-y-4
          lg:grid-cols-4
          md:grid-cols-3
          grid-cols-1
        "
      >
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <button type="button" class="btn btn-rounded-lg btn-lg btn-secondary">
          {{ $t('general.resetear') }}
        </button>
      </section> -->
      <!-- End Filter -->
      <!-- Sección Comunidad -->
      <section class="content--comunidad mb-10">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('eventos-page.title-proximos-eventos') }}</h2>
          </template>
        </Title>
      </section>
    </div>
    <splide
      v-if="conferences.length > 0"
      class="splide--default mb-10 lg:mb-32"
      :options="optionsV1"
    >
      <splide-slide
        class="w-full max-w-xs pr-3 mr-3 pb-10"
        v-for="(conference, index) in conferences"
        :key="index"
      >
        <CardComunidad class="h-full" :conferenceData="conference" />
      </splide-slide>
    </splide>
    <div class="content--wrapper">
      <!-- End Sección Comunidad -->
      <!-- Sección Evalúa tus Habilidades -->
      <section class="content--habilidades mb-10">
        <Title class="mb-6">
          <template #title>
            <h2>{{ $t('eventos-page.title-eventos-pasados') }}</h2>
          </template>
        </Title>
      </section>
    </div>
    <splide
      v-if="pastConferences.length > 0"
      class="splide--default mb-10 lg:mb-32"
      :options="optionsV2"
    >
      <splide-slide
        class="w-full max-w-xs pr-3 mr-3 pb-10"
        v-for="(conference, index) in pastConferences"
        :key="index"
      >
        <CardComunidad
          class="h-full"
          :conferenceData="conference"
          :pastEvent="true"
        />
      </splide-slide>
    </splide>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import Title from '@/components/Title'
import CardComunidad from '@/components/CardComunidad'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import ApolloClient from '@/graphql/apolloClient'
import EventsQuery from '@/graphql/queries/events'
import { mapActions } from 'vuex'
import { toastNotification } from '@/services/toastNotification'

export default {
  components: {
    Hero,
    Title,
    CardComunidad,
    Splide,
    SplideSlide
  },
  data: function () {
    return {
      conferences: [],
      pastConferences: [],
      optionsV1: {
        rewind: true,
        perPage: 4,
        pagination: true,
        autoplay: false,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      },
      optionsV2: {
        rewind: true,
        perPage: 4,
        pagination: true,
        autoplay: false,
        pauseOnHover: false,
        arrows: true,
        autoWidth: false,
        padding: '0rem',
        lazyLoad: 'nearby',
        breakpoints: {
          640: {
            perPage: 1
          },
          1024: {
            perPage: 3
          },
          1340: {
            perPage: 4
          }
        }
      }
    }
  },
  mounted() {
    this.getEvents()
  },
  updated() {
    this.getEvents()
  },
  methods: {
    ...mapActions(['setLoadingOff']),
    getEvents: async function () {
      try {
        const eventsQuery = await ApolloClient.query({
          context: {
            headers: {
              lang: this.$root.$i18n.locale
            }
          },
          fetchPolicy: 'network-only',
          query: EventsQuery
        })
        this.pastConferences = eventsQuery.data.pastEvents
        this.conferences = eventsQuery.data.events
        this.setLoadingOff()
      } catch (e) {
        toastNotification(e, 'error')
      }
    }
  }
}
</script>
