<template>
  <div class="w-7 text-center">
    <button
      type="button"
      @click="setThemeColor('dark')"
      v-if="themeColor === 'light'"
    >
      <svg
        width="18"
        height="20"
        class="
          fill-current
          text-contrast
          hover:text-secondary
          transition-all
          duration-300
        "
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.124 20c3.086 0 5.898-1.367 7.773-3.672.274-.351-.039-.86-.468-.781-4.844.937-9.297-2.774-9.297-7.695a7.795 7.795 0 0 1 3.945-6.797.461.461 0 0 0-.156-.86C11.452.078 10.63.04 10.124 0c-5.547 0-10 4.492-10 10a9.97 9.97 0 0 0 10 10Z"
        />
      </svg></button
    ><button
      type="button"
      @click="setThemeColor('light')"
      v-if="themeColor === 'dark'"
    >
      <svg
        width="25"
        height="26"
        class="
          fill-current
          text-white
          hover:text-secondary
          transition-all
          duration-300
        "
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.136 8.477c-2.485 0-4.5 2.062-4.5 4.5 0 2.484 2.015 4.5 4.5 4.5 2.437 0 4.5-2.016 4.5-4.5 0-2.438-2.063-4.5-4.5-4.5Zm11.53 3.796-4.452-2.203 1.593-4.734c.188-.61-.421-1.219-1.03-1.031l-4.735 1.593-2.203-4.453c-.282-.61-1.172-.61-1.453 0L9.182 5.898 4.448 4.305c-.61-.188-1.219.422-1.031 1.03L5.01 10.07.557 12.273c-.609.282-.609 1.172 0 1.454l4.454 2.203-1.594 4.734c-.188.61.422 1.219 1.031 1.031l4.734-1.593 2.204 4.453c.28.61 1.171.61 1.453 0l2.203-4.453 4.734 1.593c.61.188 1.219-.422 1.031-1.03l-1.593-4.735 4.453-2.203c.61-.282.61-1.172 0-1.454Zm-7.312 4.97a5.998 5.998 0 0 1-8.484 0 5.998 5.998 0 0 1 0-8.485 5.998 5.998 0 0 1 8.484 0 5.998 5.998 0 0 1 0 8.484Z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SwitchColorTheme',
  methods: {
    ...mapActions(['setThemeColor'])
  },
  computed: {
    ...mapGetters(['themeColor'])
  }
}
</script>
