<template>
  <main class="main--wrapper py-24 lg:py-40">
    <div class="content--wrapper flex flex-wrap">
      <CardPage class="w-full lg:w-6/12 mx-auto order-2 lg:order-none">
        <template #default>
          <button
            type="button"
            class="card--close"
            role="button"
            @click="$router.back()"
          >
            <img
              src="@/assets/images/icn-close.svg"
              :alt="$t('general.cerrar')"
            />
          </button>
          <Form @submit="validar">
            <div class="card--page--header">
              <Title>
                <template #title>
                  <h4>{{ $t('modal-educacion-formal.title') }}</h4>
                </template>
              </Title>
            </div>
            <div class="card--page--body">
              <p class="dark:text-primary-text text-secondary-text">
                {{ $t('modal-educacion-formal.description') }}
              </p>
              <div class="form--group">
                <label class="form--label" for="grade">{{
                  $t('modal-educacion-formal.lbl-grado')
                }}</label>
                <Field
                  name="grade"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="formalEducation[formalEducation.length - 1].grade"
                />
                <ErrorMessage name="grade" class="form--error" />
              </div>
              <div class="form--group">
                <label class="form--label" for="school">{{
                  $t('modal-educacion-formal.lbl-entidad-educativa')
                }}</label>
                <Field
                  name="school"
                  type="text"
                  class="form--element w-full"
                  :rules="validateDefault"
                  v-model="formalEducation[formalEducation.length - 1].school"
                />
                <ErrorMessage name="school" class="form--error" />
              </div>
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-6">
                <div
                  class="form--group"
                  :class="
                    !formalEducation[formalEducation.length - 1].expires
                      ? ''
                      : 'col-span-full'
                  "
                >
                  <h4
                    class="
                      dark:text-primary-text
                      text-secondary-text
                      font-bold
                      mb-2
                      lg:col-span-2
                    "
                  >
                    {{
                      $t('modal-experiencia-profesional.title-fecha-de-inicio')
                    }}
                  </h4>
                  <Field
                    name="startDate"
                    as="label"
                    v-slot="{ field }"
                    v-model="
                      formalEducation[formalEducation.length - 1].startDate
                    "
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      v-bind="field"
                      type="date"
                      :max="new Date().toISOString().split('T')[0]"
                    />
                  </Field>
                  <ErrorMessage name="startDate" class="form--error" />
                </div>
                <div
                  class="form--group"
                  v-if="!formalEducation[formalEducation.length - 1].expires"
                >
                  <h4
                    class="
                      dark:text-primary-text
                      text-secondary-text
                      font-bold
                      mb-2
                      lg:col-span-2
                    "
                  >
                    {{ $t('modal-experiencia-profesional.title-fecha-de-fin') }}
                  </h4>
                  <Field
                    name="endDate"
                    as="label"
                    v-slot="{ field }"
                    v-model="
                      formalEducation[formalEducation.length - 1].endDate
                    "
                    class="form--icon form--icon--left block"
                  >
                    <span
                      class="icon--wrapper bg-secondary text-white rounded-l-lg"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        class="w-4 h-4 fill-current"
                      >
                        <path
                          d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                        ></path>
                      </svg>
                    </span>
                    <input
                      class="form--element w-full"
                      v-bind="field"
                      type="date"
                      :min="
                        formalEducation[formalEducation.length - 1].startDate ||
                        new Date().toISOString().split('T')[0]
                      "
                    />
                  </Field>
                  <ErrorMessage name="endDate" class="form--error" />
                </div>
              </div>
              <div class="form--group lg:col-span-2">
                <label class="form--checkbox"
                  ><input
                    type="checkbox"
                    id="remember"
                    value="first_checkbox"
                    @change="checkExpires"
                    v-model="
                      formalEducation[formalEducation.length - 1].expires
                    "
                  /><span>{{
                    $t('modal-educacion-formal.lbl-sigo-estudiando')
                  }}</span></label
                >
              </div>
              <button
                class="btn btn-rounded-lg btn-secondary btn-lg"
                type="button"
                role="button"
                @click="addRow"
                :aria-label="$t('general.agregar-experiencia')"
              >
                {{ $t('general.agregar-experiencia') }}
              </button>
            </div>
            <div class="card--page--footer flex justify-center space-x-4">
              <button
                type="button"
                class="
                  btn btn-outline btn-outline-primary btn-rounded-lg btn-lg
                "
                role="button"
                aria-pressed="false"
                @click="$router.back()"
              >
                {{ $t('general.volver') }}
              </button>
              <button
                type="submit"
                class="btn btn-primary mx-auto btn-rounded-lg btn-lg"
                role="button"
                aria-pressed="false"
              >
                {{ $t('general.guardar') }}
              </button>
            </div>
          </Form>
        </template>
      </CardPage>
      <!-- List formalEducation -->
      <ul
        class="list--education list--education--small w-full lg:w-6/12 lg:pl-10"
        v-if="formalEducation.length > 1"
      >
        <li
          v-for="(education, index) in sortFormalEducation"
          :key="index"
          class="pb-8"
        >
          <div class="flex">
            <div class="flex-grow">
              <span
                class="list--education--bullets"
                :class="education.active ? 'active' : ''"
              ></span>
              <time class="dark:text-primary-text text-secondary-text text-sm">
                {{ formatedDate(education.startDate) }}
                <span v-if="education.expires"
                  >| {{ $t('general.presente') }}</span
                >
                <span v-else>| {{ formatedDate(education.endDate) }}</span>
              </time>
              <h4
                class="text-white font-bold text-lg mb-2 uppercase"
                v-if="education.grade || education.school"
              >
                {{ education.grade }} |
                <i class="text-secondary">{{ education.school }}</i>
              </h4>
            </div>
            <div class="flex space-x-3 pl-6">
              <button
                type="button"
                @click="editRow(index)"
                class="
                  w-9
                  h-9
                  flex
                  justify-center
                  items-center
                  rounded-md
                  btn-secondary
                "
              >
                <img src="@/assets/images/icn-edit.svg" />
              </button>
              <button
                class="
                  w-9
                  h-9
                  flex
                  justify-center
                  items-center
                  rounded-md
                  btn-primary
                "
                @click="removeRow(index)"
              >
                <img src="@/assets/images/icn-remove.svg" />
              </button>
            </div>
          </div>
        </li>
      </ul>
      <!-- End List formalEducation -->
    </div>
  </main>
</template>

<script>
import CardPage from '@/components/CardPage'
import Title from '@/components/Title'
import { Field, Form, ErrorMessage } from 'vee-validate'
import apolloClient from '@/graphql/apolloClient'
import saveFormalEducationMutation from '@/graphql/mutations/saveFormalEducation'
import { mapActions, mapGetters } from 'vuex'
import ROUTES from '@/constants/routes'
import { validateDefault, validateNumber } from '@/services/validationRules'
import { toastNotification } from '@/services/toastNotification'

const emptyEducation = {
  grade: null,
  startDate: null,
  endDate: null,
  school: null,
  expires: false
}

export default {
  name: 'EditFormalEducation',
  components: {
    Title,
    CardPage,
    Field,
    Form,
    ErrorMessage
  },
  data() {
    return {
      ROUTES,
      formalEducation: [
        {
          ...emptyEducation,
          active: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['token', 'user']),
    sortFormalEducation() {
      return [...this.formalEducation].sort(
        (a, b) => new Date(b.startDate) - new Date(a.startDate)
      )
    }
  },
  mounted() {
    this.initializeData()
  },
  methods: {
    ...mapActions(['fetchUser', 'setLoadingOn', 'setLoadingOff']),
    async validar() {
      try {
        this.setLoadingOn()
        const saveFormalEducation = await apolloClient.mutate({
          mutation: saveFormalEducationMutation,
          variables: {
            formalEducation: this.sortFormalEducation.map(
              ({ active, ...rest }) => rest
            )
          },
          context: {
            headers: {
              authorization: `Bearer ${await this.token}`
            }
          }
        })
        if (!saveFormalEducation.errors) {
          await this.fetchUser()
          this.setLoadingOff()
          this.$router.push({ name: 'Profile' })
          toastNotification(this.$t('general.info-guardada'), 'success')
        }
      } catch (e) {
        toastNotification(e, 'error')
      }
    },
    addRow() {
      this.formalEducation.forEach((item) => {
        item.active = false
      })
      this.formalEducation.push({
        ...emptyEducation,
        active: true
      })
    },
    removeRow(index) {
      const removePosition = this.formalEducation.indexOf(
        this.sortFormalEducation[index]
      )
      this.formalEducation.splice(removePosition, 1)
    },
    editRow(index) {
      const editPosition = this.formalEducation.indexOf(
        this.sortFormalEducation[index]
      )
      this.formalEducation.forEach((item) => {
        item.active = false
      })
      this.formalEducation[editPosition].active = true
      this.formalEducation.push(this.formalEducation[editPosition])
      this.formalEducation.splice(editPosition, 1)
      const row = this.formalEducation[editPosition]
      if (row.endDate === null) {
        row.expires = true
      }
    },
    initializeData() {
      if (this.user.formalEducation) {
        this.user.formalEducation.forEach((val) => {
          this.formalEducation.unshift({
            grade: val.grade,
            startDate: val.startDate,
            endDate: val.endDate,
            school: val.school,
            active: false
          })
        })
      }
    },
    formatedDate(date) {
      if (date) {
        const month = `${new Date(date).getMonth() + 1}`.padStart(2, '0')
        const year = new Date(date).getFullYear()
        return `${month}-${year}`
      } else {
        return this.$t('general.presente')
      }
    },
    // RULES
    validateDefault,
    validateNumber,
    checkExpires() {
      if (
        this.formalEducation[this.formalEducation.length - 1].expires === true
      ) {
        this.formalEducation[this.formalEducation.length - 1].endDate = null
      }
    }
  }
}
</script>
