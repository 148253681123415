import gql from 'graphql-tag'

const jobsQuery = gql`
  query jobsQUery($limit: Int) {
    jobs(limit: $limit) {
      id
      jobTitle
      jobTime
      jobType
      expRequired
      salaryRange
      techStack
      company {
        id
        name
        about
        industry
      }
    }
  }
`

export default jobsQuery
