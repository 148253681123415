import gql from 'graphql-tag'

const assesmentInfoQuery = gql`
  query assesmentInfoQuery($assesmentId: Int!) {
    assesment(assesmentId: $assesmentId) {
      id
      name
      time
      firstQuestion {
        id
        options
        text
      }
      testLength
      skills
      instructions
      photo
    }
  }
`

export default assesmentInfoQuery
