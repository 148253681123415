<template>
  <div class="cards--skill--image">
    <div class="cards--skill--locked" v-if="bloqueado">
      <img src="@/assets/images/icn-locked.svg" />
    </div>
    <img
      :src="require(`@/assets/images/badges/${imagen}`)"
      :alt="titulo"
      :class="{ locked: bloqueado }"
    />
  </div>
</template>

<script>
export default {
  name: 'BadgeAward',
  props: {
    imagen: String,
    bloqueado: Boolean,
    titulo: String
  }
}
</script>
