import gql from 'graphql-tag'

const adminDashboardQuery = gql`
  query adminDashboard {
    dashboard {
      prevettedCandidates
      totalCandidates
    }
  }
`
export default adminDashboardQuery
