<template>
  <div class="card--page">
    <!-- Default -->
    <slot name="default"></slot>
    <!-- End Default -->
  </div>
</template>

<script>
export default {
  name: 'CardPage'
}
</script>
