<template>
  <main class="m-8 grid gap-6 grid-cols-1 lg:grid-cols-12" v-if="userProfile">
    <div class="lg:col-span-8 flex flex-col gap-6">
      <BasicInformationSection :client="client" :isPublic="false" />
      <CardPage class="p-8">
        <template #default>
          <Tabs class="dark:text-white tabs-left" :tabList="tabList">
            <template v-slot:tabPanel-1>
              <!-- Edit Overview -->
              <div v-if="edit === true" class="w-full">
                <h2
                  class="
                    flex
                    text-contrast
                    dark:text-white
                    font-bold
                    mb-2
                    lg:mb-3 lg:text-2xl
                  "
                >
                  {{ $t('clients-profile.lbl-overview') }}
                </h2>
                <Form
                  @submit="updateClientProfile"
                  class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-12"
                >
                  <div class="grid grid-cols-1 gap-4">
                    <div class="form-group">
                      <label class="form--label" for="fullname">{{
                        $t('modal-profile.lbl-nombre')
                      }}</label>
                      <Field
                        name="fullname"
                        type="text"
                        class="form--element w-full"
                        :rules="validateDefault"
                        v-model="fullname"
                        :placeholder="$t('modal-profile.lbl-nombre')"
                      />
                      <ErrorMessage name="fullname" class="form--error" />
                    </div>
                    <div class="form-group">
                      <label class="form--label" for="fullname">{{
                        $t('modal-profile.lbl-nombre')
                      }}</label>
                      <Field
                        name="fullname"
                        type="text"
                        class="form--element w-full"
                        :rules="validateDefault"
                        v-model="fullname"
                        :placeholder="$t('modal-profile.lbl-nombre')"
                      />
                      <ErrorMessage name="fullname" class="form--error" />
                    </div>
                  </div>
                  <div class="form-group">
                    <label
                      class="form--label"
                      for="experienciaProfesionalDescription"
                      >{{ $t('profile-page.lbl-cuentanos-tu-historia') }}</label
                    >
                    <Field
                      type="text"
                      name="experienciaProfesionalDescription"
                      :rules="validateDefault"
                      v-slot="{ field }"
                      v-model="about"
                    >
                      <textarea
                        class="form--element w-full"
                        v-bind="field"
                        rows="5"
                        maxlength="500"
                        :placeholder="
                          $t('profile-page.lbl-cuentanos-tu-historia')
                        "
                      ></textarea>
                    </Field>
                    <ErrorMessage name="fullname" class="form--error" />
                  </div>
                  <div class="flex justify-center gap-4 col-span-2">
                    <button
                      type="button"
                      class="
                        btn
                        btn-outline
                        btn-outline-primary
                        btn-rounded-lg
                        btn-md
                      "
                      role="button"
                      aria-pressed="false"
                      @click="edit = false"
                    >
                      {{ $t('general.cancelar') }}
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary btn-rounded-lg btn-md"
                      role="button"
                      aria-pressed="false"
                    >
                      {{ $t('general.guardar') }}
                    </button>
                  </div>
                </Form>
                <h2
                  class="
                    flex
                    text-contrast
                    dark:text-white
                    font-bold
                    mb-2
                    lg:mb-3 lg:text-2xl
                  "
                >
                  {{ $t('clients-profile.lbl-founders') }}
                </h2>
                <div
                  class="
                    border
                    rounded-lg
                    dark:border-line-dark
                    border-line-light
                    dark:text-white
                    p-4
                  "
                >
                  <p
                    class="
                      dark:text-primary-text
                      text-secondary-text text-center
                    "
                  >
                    {{ $t('clients-profile.lbl-complete-profile') }}
                  </p>
                  <button
                    type="button"
                    role="button"
                    :aria-label="$t('general.completar')"
                    class="btn btn-md btn-rounded-lg btn-secondary mx-auto"
                  >
                    {{ $t('general.completar') }}
                  </button>
                </div>
              </div>
              <!-- End Edit Overview -->
              <!-- Overview Section -->
              <section v-else>
                <!-- Overview Intro -->
                <div
                  class="
                    flex
                    lg:justify-between
                    flex-wrap
                    lg:flex-nowrap
                    items-center
                    mb-10
                    gap-6
                  "
                >
                  <div class="w-full lg:w-8/12 mb-12 lg:mb-0">
                    <h2
                      class="
                        text-contrast
                        dark:text-white
                        font-bold
                        mb-2
                        lg:mb-3 lg:text-2xl
                      "
                    >
                      {{ client.titleDescription }}
                    </h2>
                    <div class="w-full lg:w-8/12 mb-12 lg:mb-0">
                      <p class="dark:text-primary-text text-secondary-text">
                        {{ client.companyDescription }}
                      </p>
                    </div>
                    <p class="flex">
                      <a href="" target="_blank" class="text-primary underline">
                        {{ $t('clients-profile.link-read-more') }}
                      </a>
                    </p>
                  </div>
                  <div class="w-full lg:w-4/12">
                    <img
                      src="@/assets/images/upskilling/utopicode-para-desarrolladores.png"
                      class="mx-auto w-full"
                      alt="Utopicode"
                    />
                  </div>
                </div>
                <!-- End Overview Intro -->
                <!-- Overview -->
                <div class="flex flex-wrap items-center">
                  <h2
                    class="
                      flex
                      text-contrast
                      dark:text-white
                      font-bold
                      mb-2
                      lg:mb-3 lg:text-2xl
                      gap-4
                      w-full
                    "
                  >
                    {{ $t('clients-profile.lbl-overview') }}
                    <a
                      href="#people"
                      target="_self"
                      class="
                        text-secondary
                        dark:text-primary
                        ml-auto
                        underline
                        text-base
                        font-normal
                      "
                    >
                      {{ $t('clients-profile.link-all-team-members') }}
                    </a>
                  </h2>
                  <div class="w-full lg:w-12/12 flex gap-4">
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/2"
                    />
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/2"
                    />
                  </div>
                </div>
                <!-- End Overview -->
              </section>
              <!-- End Overview Section -->
            </template>
            <template v-slot:tabPanel-2>
              <!-- Edit Founder Section -->
              <div v-if="edit === true" class="w-full">
                <h2
                  class="
                    flex
                    text-contrast
                    dark:text-white
                    font-bold
                    mb-2
                    lg:mb-3 lg:text-2xl
                  "
                >
                  {{ $t('clients-profile.lbl-founders') }}
                </h2>
                <Form @submit="updateClientProfile">
                  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-12">
                    <div class="flex flex-col lg:col-span-1 gap-4 lg:mb-6">
                      <div class="form-group">
                        <label class="form--label" for="fullname">{{
                          $t('modal-profile.lbl-nombre')
                        }}</label>
                        <Field
                          name="fullname"
                          type="text"
                          class="form--element w-full"
                          :rules="validateDefault"
                          v-model="fullname"
                          :placeholder="$t('modal-profile.lbl-nombre')"
                        />
                        <ErrorMessage name="fullname" class="form--error" />
                      </div>
                      <div class="form-group">
                        <label class="form--label" for="fullname">{{
                          $t('modal-profile.lbl-nombre')
                        }}</label>
                        <Field
                          name="fullname"
                          type="text"
                          class="form--element w-full"
                          :rules="validateDefault"
                          v-model="fullname"
                          :placeholder="$t('modal-profile.lbl-nombre')"
                        />
                        <ErrorMessage name="fullname" class="form--error" />
                      </div>
                      <div class="form-group">
                        <label
                          for="formFile"
                          class="form-label form--upload flex flex-nowrap gap-4"
                        >
                          <span
                            class="
                              w-16
                              h-16
                              flex
                              justify-center
                              items-center
                              bg-primary-text
                              dark:bg-main-dark
                              rounded-xl
                            "
                            ><IcnUpload class="text-white w-5"
                          /></span>
                          <span
                            class="dark:text-primary-text text-secondary-text"
                          >
                            Upload Image
                          </span>
                          <input type="file" id="formFile" />
                          <span
                            class="
                              btn btn-md
                              bg-primary-text
                              dark:bg-main-dark
                              text-white
                              rounded-full
                            "
                            >Browse File</span
                          >
                        </label>
                      </div>
                    </div>
                    <div class="form--group lg:col-span-1 lg:mb-6">
                      <label
                        class="form--label"
                        for="experienciaProfesionalDescription"
                        >{{
                          $t('profile-page.lbl-cuentanos-tu-historia')
                        }}</label
                      >
                      <Field
                        type="text"
                        name="experienciaProfesionalDescription"
                        :rules="validateDefault"
                        v-slot="{ field }"
                        v-model="historia"
                      >
                        <textarea
                          class="form--element w-full"
                          v-bind="field"
                          maxlength="500"
                          rows="8"
                          :placeholder="
                            $t('profile-page.lbl-cuentanos-tu-historia')
                          "
                        ></textarea>
                      </Field>
                      <div class="text-right font-bold text-secondary-text">
                        {{ historia.length }}/500
                      </div>
                      <ErrorMessage name="fullname" class="form--error" />
                    </div>
                    <div class="flex justify-center space-x-4 lg:col-span-2">
                      <button
                        type="button"
                        class="
                          btn
                          btn-outline
                          btn-outline-primary
                          btn-rounded-lg
                          btn-md
                        "
                        role="button"
                        aria-pressed="false"
                        @click="edit = false"
                      >
                        {{ $t('general.cancelar') }}
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary btn-rounded-lg btn-md"
                        role="button"
                        aria-pressed="false"
                      >
                        {{ $t('general.guardar') }}
                      </button>
                    </div>
                  </div>
                  <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <h2
                      class="
                        flex
                        text-contrast
                        dark:text-white
                        font-bold
                        mb-2
                        lg:mb-3 lg:text-2xl
                        col-span-2
                      "
                    >
                      {{ $t('clients-profile.lbl-people') }}
                    </h2>
                    <div class="grid grid-cols-2 space-x-4 col-span-2">
                      <div class="grid grid-cols-1 gap-x-4 mb-3">
                        <div class="mb-3">
                          <Field
                            name="fullname"
                            type="text"
                            class="form--element w-full"
                            :rules="validateDefault"
                            v-model="fullname"
                            :placeholder="$t('modal-profile.lbl-nombre')"
                          />
                          <ErrorMessage name="fullname" class="form--error" />
                        </div>
                        <div class="mb-3">
                          <Field
                            name="fullname"
                            type="text"
                            class="form--element w-full"
                            :rules="validateDefault"
                            v-model="fullname"
                            :placeholder="$t('modal-profile.lbl-nombre')"
                          />
                          <ErrorMessage name="fullname" class="form--error" />
                        </div>
                        <div class="form-group">
                          <label
                            for="formFile"
                            class="
                              form-label form--upload
                              flex flex-nowrap
                              gap-4
                            "
                          >
                            <span
                              class="
                                w-16
                                h-16
                                flex
                                justify-center
                                items-center
                                bg-primary-text
                                dark:bg-main-dark
                                rounded-xl
                              "
                              ><IcnUpload class="text-white w-5"
                            /></span>
                            <span
                              class="dark:text-primary-text text-secondary-text"
                            >
                              Upload Image
                            </span>
                            <input type="file" id="formFile" />
                            <span
                              class="
                                btn btn-md
                                bg-primary-text
                                dark:bg-main-dark
                                text-white
                                rounded-full
                              "
                              >Browse File</span
                            >
                          </label>
                        </div>
                      </div>
                      <div class="mb-3">
                        <Field
                          type="text"
                          name="experienciaProfesionalDescription"
                          :rules="validateDefault"
                          v-slot="{ field }"
                          v-model="about"
                        >
                          <textarea
                            class="form--element w-full"
                            v-bind="field"
                            rows="5"
                            maxlength="500"
                            :placeholder="
                              $t('profile-page.lbl-cuentanos-tu-historia')
                            "
                          ></textarea>
                        </Field>
                        <ErrorMessage name="fullname" class="form--error" />
                      </div>
                    </div>
                    <div class="flex justify-center space-x-4 col-span-2">
                      <button
                        type="button"
                        class="
                          btn
                          btn-outline
                          btn-outline-primary
                          btn-rounded-lg
                          btn-md
                        "
                        role="button"
                        aria-pressed="false"
                        @click="edit = false"
                      >
                        {{ $t('general.cancelar') }}
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary btn-rounded-lg btn-md"
                        role="button"
                        aria-pressed="false"
                      >
                        {{ $t('general.guardar') }}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
              <!-- End Edit Founder Section -->
              <!-- Founder Section -->
              <section v-else>
                <div
                  class="
                    flex
                    lg:justify-between
                    flex-wrap
                    relative
                    items-center
                    lg:flex-row-reverse
                  "
                >
                  <div class="w-full lg:w-12/12 mb-12 lg:mb-0">
                    <h2
                      class="
                        flex
                        text-contrast text-left
                        dark:text-white
                        font-bold
                        mb-2
                        lg:mb-3 lg:text-2xl
                      "
                    >
                      {{ $t('clients-profile.lbl-founders') }}
                    </h2>
                  </div>
                  <div
                    class="
                      w-full
                      flex flex-wrap
                      lg:flex-nowrap
                      mb-12
                      lg:mb-6
                      gap-4
                    "
                  >
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/2"
                    />
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/2"
                    />
                  </div>
                  <div class="w-full lg:w-12/12 mb-12 lg:mb-0">
                    <h2
                      class="
                        flex
                        text-contrast text-left
                        dark:text-white
                        font-bold
                        mb-2
                        lg:mb-3 lg:text-2xl
                      "
                    >
                      {{ $t('clients-profile.lbl-people') }}
                    </h2>
                  </div>
                  <div class="w-full lg:w-12/12 flex gap-4">
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/3"
                    />
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/3"
                    />
                    <CardTeamMember
                      :name="team.name"
                      :role="team.role"
                      :description="team.description"
                      class="w-1/3"
                    />
                  </div>
                </div>
              </section>
              <!-- End Founder Section -->
            </template>
            <template v-slot:tabPanel-3>
              <!-- Culture -->
              <section v-if="!edit">
                <div
                  class="
                    flex
                    lg:justify-between
                    flex-wrap
                    relative
                    items-center
                    lg:flex-row-reverse
                  "
                >
                  <div class="w-full lg:w-12/12 mb-12 lg:mb-0">
                    <h2
                      class="
                        flex
                        text-contrast text-left
                        dark:text-white
                        font-bold
                        mb-2
                        lg:mb-3 lg:text-2xl
                      "
                    >
                      {{ $t('clients-profile.lbl-culture') }}
                    </h2>
                    <p class="dark:text-primary-text text-secondary-text">
                      {{ client.companyDescription }}
                    </p>
                    <div class="grid grid-cols-3 gap-6">
                      <img
                        src="@/assets/images/img-example-culture.png"
                        class="w-full"
                        alt=""
                      />
                      <img
                        src="@/assets/images/img-example-culture.png"
                        class="w-full"
                        alt=""
                      />
                      <img
                        src="@/assets/images/img-example-culture.png"
                        class="w-full"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </section>
              <!-- End Culture -->
              <!-- Edit Culture -->
              <Form v-else @submit="updateClientProfile">
                <div class="grid grid-cols-2 space-x-4">
                  <div class="form--group">
                    <Field
                      type="text"
                      name="experienciaProfesionalDescription"
                      :rules="validateDefault"
                      v-slot="{ field }"
                      v-model="about"
                    >
                      <textarea
                        class="form--element w-full"
                        v-bind="field"
                        rows="5"
                        maxlength="500"
                        :placeholder="
                          $t('profile-page.lbl-cuentanos-tu-historia')
                        "
                      ></textarea>
                    </Field>
                    <ErrorMessage name="fullname" class="form--error" />
                  </div>
                  <div class="grid grid-cols-1 lg:gap-x-4 mb-3">
                    <div class="form-group">
                      <label
                        for="formFile"
                        class="form-label form--upload flex flex-nowrap gap-4"
                      >
                        <span
                          class="
                            w-16
                            h-16
                            flex
                            justify-center
                            items-center
                            bg-primary-text
                            dark:bg-main-dark
                            rounded-xl
                          "
                          ><IcnUpload class="text-white w-5"
                        /></span>
                        <span
                          class="dark:text-primary-text text-secondary-text"
                        >
                          Upload Image
                        </span>
                        <input type="file" id="formFile" />
                        <span
                          class="
                            btn btn-md
                            bg-primary-text
                            dark:bg-main-dark
                            text-white
                            rounded-full
                          "
                          >Browse File</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center space-x-4">
                  <button
                    type="button"
                    class="
                      btn btn-outline btn-outline-primary btn-rounded-lg btn-md
                    "
                    role="button"
                    aria-pressed="false"
                    @click="edit = false"
                  >
                    {{ $t('general.cancelar') }}
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-rounded-lg btn-md"
                    role="button"
                    aria-pressed="false"
                  >
                    {{ $t('general.guardar') }}
                  </button>
                </div>
              </Form>
              <!--End Edit Culture -->
            </template>
            <!-- Edit Button -->
            <template #options>
              <button
                v-if="!isPublic && edit === false"
                @click="edit = true"
                type="button"
                role="button"
                aria-label="Edit"
                class="
                  w-9
                  h-9
                  flex
                  justify-center
                  items-center
                  rounded-md
                  btn-secondary
                  ml-auto
                "
              >
                <img src="@/assets/images/icn-edit.svg" />
              </button>
            </template>
            <!-- End Edit Button -->
          </Tabs>
        </template>
      </CardPage>
    </div>
    <div class="lg:col-span-4">
      <AboutClientSection :client="client" :isPublic="false" />
    </div>
  </main>
</template>
<script>
import CardPage from '@/components/CardPage'
import Tabs from '@/components/Tabs'
import CardTeamMember from '@/components/empresas/CardTeamMember'
import BasicInformationSection from '@/components/empresas/BasicInformationSection'
import AboutClientSection from '@/components/empresas/AboutClientSection'
import { Field, Form, ErrorMessage } from 'vee-validate'
import IcnUpload from '@/components/svg/icon/icnUpload'

export default {
  name: 'ClientsProfile',
  components: {
    CardPage,
    Tabs,
    CardTeamMember,
    BasicInformationSection,
    AboutClientSection,
    Field,
    Form,
    ErrorMessage,
    IcnUpload
  },
  data() {
    return {
      userProfile: true,
      edit: false,
      isPublic: false,
      historia: '',
      client: {
        companyName: 'Niantic',
        titleDescription:
          'Building technologies and ideas that move us as the leaders in Augmented Reality.',
        companyDescription:
          'Niantic is the world’s leading AR technology company, sparking creative and engaging journeys in the real world. Our products inspire outdoor exploration, exercise, and meaningful social interaction... ',
        locations: ['Los Angeles', 'Seattle', 'San Francisco'],
        companySize: '501-1000',
        markets: [
          'Development Platforms',
          'Location Based Services',
          'Mobile Games',
          'Augmented Reality'
        ],
        socialNetworks: {
          linkedin: 'niantic',
          behance: 'niantic',
          github: 'niantic',
          dribble: 'niantic',
          twitter: 'niantic',
          bitbucket: 'niantic'
        },
        website: 'nianticlabs.com'
      },
      tabList: ['Overview', 'People', 'Culture'],
      team: {
        name: 'John Hanke',
        role: 'Founder',
        description:
          'Serial entrepreneur - @Keyhole founder (sold to @Google) @Google Geo VP, @Nianticlabs CEO/Founder. UT Plan II...'
      }
    }
  }
}
</script>
