<template>
  <div
    @mouseenter="tooltipShow = true"
    @mouseleave="tooltipShow = false"
    class="cursor-pointer"
  >
    <div class="circle-data-row">
      <span class="circle-data-item">
        {{ info.totalScore }} / {{ info.assesment.testLength }}
      </span>
      {{ info.assesment.name }}
    </div>
    <transition name="fade">
      <div class="tooltip--globe tooltip--sm" v-show="tooltipShow == true">
        <p class="last dark:text-primary-text text-secondary-text">
          {{ info.description }}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'CircleChartItem',
  props: { info: Object },
  data() {
    return {
      tooltipShow: false
    }
  }
}
</script>
