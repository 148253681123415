import gql from 'graphql-tag'

const eventQuery = gql`
  query eventQUery($eventId: Int!) {
    event(eventId: $eventId) {
      id
      name
      date
      place
      photo
      speakers
      eventLang
      description
      expandedSpeakers {
        name
        position
        about
        photoUrl
      }
      category
      subCategories
      liveLink
      calendarLink
      whatWillLearn
    }
  }
`

export default eventQuery
