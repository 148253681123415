<template>
  <div class="hero">
    <div class="hero--container">
      <slot></slot>
    </div>
    <picture class="hero--image" v-if="heroImage">
      <img
        :src="require(`@/assets/images/hero/${heroImage}`)"
        :alt="heroDescription"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'FullHero',
  props: {
    heroImage: String,
    heroDescription: String
  }
}
</script>
