<template>
  <CardPage class="p-6">
    <template #default>
      <div class="content--wrapper">
        <div class="flex justify-between">
          <h2
            class="
              flex
              text-contrast text-left
              dark:text-white
              font-bold
              mb-3
              lg:text-lg
              uppercase
            "
          >
            {{ $t('clients-profile.lbl-about') }} {{ client.companyName }}
          </h2>
          <button
            v-if="!isPublic && editAboutSection === false"
            @click="editAboutSection = true"
            type="button"
            role="button"
            aria-label="Edit"
            class="
              w-9
              h-9
              flex
              justify-center
              items-center
              rounded-md
              btn-secondary
              transform
              -translate-y-2
            "
          >
            <img src="@/assets/images/icn-edit.svg" />
          </button>
        </div>
        <div class="mb-4">
          <h6 class="dark:text-white text-contrast font-bold mb-1">
            {{ $t('clients-profile.lbl-website') }}
          </h6>
          <p v-if="!editAboutSection" class="flex">
            <a
              href="nianticlabs.com"
              target="_blank"
              class="text-secondary dark:text-primary"
            >
              {{ client.website }}
            </a>
          </p>
          <Field
            v-if="editAboutSection === true"
            name="website"
            type="text"
            :rules="validateUrl"
            class="form--element element--sm w-full"
            v-model="website"
          />
        </div>
        <div class="mb-4">
          <h6 class="dark:text-white text-contrast font-bold mb-1">
            {{ $t('clients-profile.lbl-social') }}
          </h6>
          <SocialMediaSection
            :socialNetworks="client.socialNetworks"
            :editSocialNetwork="editAboutSection"
          />
        </div>
        <div class="mb-4">
          <h6 class="dark:text-white text-contrast font-bold mb-1">
            {{ $t('clients-profile.lbl-locations') }}
          </h6>
          <Field
            v-if="editAboutSection === true"
            name="locations"
            as="select"
            :rules="validateUrl"
            class="form--element element--sm w-full"
            v-model="locations"
          >
            <option v-for="item in client.locations" :key="item" :value="item">
              {{ item }}
            </option>
          </Field>
          <p
            v-else
            class="text-secondary-text mb-0"
            v-for="location in client.locations"
            :key="location"
          >
            {{ location }}
          </p>
        </div>
        <div class="mb-4">
          <h6 class="dark:text-white text-contrast font-bold mb-1">
            {{ $t('clients-profile.lbl-company-size') }}
          </h6>
          <p v-if="!editAboutSection" class="text-secondary-text flex">
            {{ client.companySize }}
          </p>
          <Field
            v-if="editAboutSection === true"
            name="companySize"
            as="Select"
            :rules="validateUrl"
            class="form--element element--sm w-full"
            :placeholder="$t('profile-page.social-holder-behance')"
            v-model="companySize"
          >
            <option
              v-for="item in client.companySize"
              :key="item"
              :value="item"
            >
              {{ item }}
            </option>
          </Field>
        </div>
        <div class="mb-4">
          <h6 class="dark:text-white text-contrast font-bold mb-1">
            {{ $t('clients-profile.lbl-markets') }}
          </h6>
          <Field
            v-if="editAboutSection === true"
            name="markets"
            as="Select"
            :rules="validateUrl"
            class="form--element element--sm w-full"
            :placeholder="$t('profile-page.social-holder-behance')"
            v-model="markets"
          >
            <option v-for="item in client.markets" :key="item" :value="item">
              {{ item }}
            </option>
          </Field>
          <div v-else class="flex flex-wrap gap-2">
            <span
              class="
                badge
                dark:bg-main-dark dark:text-white
                text-contrast
                bg-main-light
                badge-sm badge-rounded-full
              "
              v-for="market in client.markets"
              :key="market"
            >
              {{ market }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="editAboutSection === true" class="flex gap-4 justify-center">
        <button
          type="button"
          class="btn btn-outline btn-outline-primary btn-rounded-lg btn-md"
          role="button"
          aria-pressed="false"
          @click="editAboutSection = false"
        >
          {{ $t('general.cancelar') }}
        </button>
        <button
          class="btn btn-md btn-rounded-lg btn-primary"
          type="submit"
          role="button"
          :aria-label="$t('general.guardar')"
        >
          {{ $t('general.guardar') }}
        </button>
      </div>
    </template>
  </CardPage>
</template>
<script>
import CardPage from '@/components/CardPage'
import SocialMediaSection from '@/components/empresas/SocialMediaSection'
import { Field } from 'vee-validate'

export default {
  name: 'AboutClientSection',
  props: {
    client: Object
  },
  components: {
    CardPage,
    SocialMediaSection,
    Field
  },
  data() {
    return {
      editAboutSection: false,
      isPublic: false
    }
  }
}
</script>
