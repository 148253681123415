<template>
  <header class="header--wrapper" :class="{ open: isShowingMenu }">
    <div class="header--container">
      <!-- Logo -->
      <router-link class="header--logo" :to="{ name: 'Home' }">
        <Logo />
      </router-link>
      <!-- End Logo -->
      <!-- Menu -->
      <transition name="fade">
        <div class="header--nav" :class="{ open: isShowingMenu }">
          <div class="header--nav--left">
            <router-link
              :to="{ name: 'Comunidad' }"
              :title="$t('header.comunidad')"
              class="header--nav--link"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.comunidad') }}
              </span>
            </router-link>
            <div class="header--user header--nav--dropdown cursor-pointer">
              <button
                class="header--nav--link"
                @click="isShowingMenuLearn = !isShowingMenuLearn"
                type="button"
              >
                <span class="menu-span">
                  {{ $t('header.aprender') }}
                </span>
              </button>

              <!-- Dropdown Aprender -->

              <transition name="fade">
                <div class="header--dropdown" v-if="isShowingMenuLearn">
                  <router-link class="dropdown--item" :to="{ name: 'Eventos' }">
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('header.eventos') }}
                    </span>
                  </router-link>
                  <router-link
                    class="dropdown--item"
                    :to="{ name: 'UpskillingB2C' }"
                  >
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('header.cursos') }}
                    </span>
                  </router-link>
                  <router-link
                    class="dropdown--item"
                    :to="{ name: 'Upskilling' }"
                  >
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('header.cursos-team') }}
                    </span>
                  </router-link>
                  <router-link
                    class="dropdown--item"
                    :to="{ name: 'LandingSummit' }"
                  >
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('header.summit') }}
                    </span>
                  </router-link>
                </div>
              </transition>
            </div>
            <router-link
              :to="{ name: 'Habilidades' }"
              :title="$t('header.evaluaciones')"
              class="header--nav--link"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.evaluaciones') }}
              </span>
            </router-link>
            <!-- <router-link
              :to="{ name: 'Habilidades' }"
              :title="$t('header.habilidades')"
              class="header--nav--link"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.evaluaciones') }}
              </span>
            </router-link> -->
            <router-link
              :to="{ name: 'Trabajos Tech' }"
              :title="$t('header.trabajos-tech')"
              class="header--nav--link"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.trabajos-tech') }}
              </span>
            </router-link>
            <a
              href="https://blog.utopicode.io"
              @click="handleCloseMenu"
              target="_blank"
              title="Utopi Blog"
              class="header--nav--link"
              >Blog</a
            >
            <!-- <router-link
              :to="{ name: 'Landing' }"
              :title="$t('header.landing')"
              class="header--nav--link"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.landing') }}
              </span>
            </router-link> -->
          </div>
          <div class="header--nav--right">
            <ul class="header--nav--language">
              <li>
                <button
                  title=""
                  class="header--nav--link focus:outline-none"
                  @click="toggleLang('en')"
                  :class="{ active: $i18n.locale === 'en' }"
                >
                  Eng
                </button>
              </li>
              <li>|</li>
              <li>
                <button
                  title=""
                  class="header--nav--link focus:outline-none"
                  @click="toggleLang('es')"
                  :class="{ active: $i18n.locale === 'es' }"
                >
                  Esp
                </button>
              </li>
            </ul>
            <!-- If User not Login -->
            <router-link
              v-if="!isAuthenticated"
              :to="{ name: 'SignIn' }"
              class="header--nav--link--active"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.sign-in') }}
              </span>
            </router-link>
            <router-link
              v-if="!isAuthenticated"
              :to="{ name: 'SignUp' }"
              class="btn btn-rounded-full btn-lg btn-primary"
            >
              <span @click="handleCloseMenu" class="menu-span">
                {{ $t('header.sign-up') }}
              </span>
            </router-link>
            <!-- End If User not Login -->
            <!-- If User is Login -->
            <div
              v-if="isAuthenticated && user"
              class="header--user header--nav--dropdown cursor-pointer"
            >
              <div
                class="header--user--info"
                @click="isShowingMenuProfile = !isShowingMenuProfile"
              >
                <img
                  :src="
                    require(`@/assets/images/avatars/avatar-${
                      user.avatar !== null ? user.avatar : 1
                    }.svg`)
                  "
                  class="header--user--image"
                  :alt="user"
                />
                {{ user.fullname }}
                <img
                  src="@/assets/images/icn-chevron-down.svg"
                  class="ml-2"
                  alt="Dropdown"
                />
              </div>
              <transition name="fade">
                <div class="header--dropdown" v-if="isShowingMenuProfile">
                  <router-link class="dropdown--item" :to="{ name: 'Profile' }">
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('general.text-mi-perfil') }}
                    </span>
                  </router-link>
                  <router-link
                    class="dropdown--item"
                    :to="{ name: 'BadgesDetail' }"
                  >
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('general.text-mis-badges') }}
                    </span>
                  </router-link>
                  <router-link
                    class="dropdown--item"
                    :to="{ name: 'MisTests' }"
                  >
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('general.text-mis-tests') }}
                    </span>
                  </router-link>
                  <router-link
                    class="dropdown--item"
                    :to="{ name: 'ProfileEvents' }"
                  >
                    <span @click="handleCloseMenu" class="menu-span">
                      {{ $t('general.text-mis-eventos') }}
                    </span>
                  </router-link>
                </div>
              </transition>
            </div>
            <button
              v-if="isAuthenticated"
              type="button"
              class="header--nav--link--active"
              @click="signOut"
            >
              Sign Out
            </button>
            <SwitchThemeColor />
            <!-- End If User is Login -->
          </div>
        </div>
      </transition>
      <!-- End Menu -->
      <!-- Mobile Button -->
      <button
        @click="isShowingMenu = !isShowingMenu"
        class="header--toggle"
        :class="{ collapsed: !isShowingMenu }"
      >
        <span class="icon-wrapper">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </span>
      </button>
      <!-- End Mobile Button -->
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ROUTES from '@/constants/routes'
import Logo from '@/components/svg/logo'
import SwitchThemeColor from '@/components/SwitchThemeColor'

export default {
  name: 'Header',
  components: {
    Logo,
    SwitchThemeColor
  },
  computed: {
    ...mapGetters(['user', 'isAuthenticated', 'lang'])
  },
  methods: {
    ...mapActions(['signOut']),
    handleCloseMenu() {
      this.isShowingMenu = false
      this.isShowingMenuProfile = false
      this.isShowingMenuLearn = false
    },
    toggleLang(lang) {
      this.$root.$i18n.locale = lang
      const to = this.$router.resolve({ params: { locale: lang } })
      this.$router.push(to.fullPath)
    }
  },
  data: function () {
    return {
      ROUTES,
      isShowingMenu: false,
      isShowingMenuProfile: false,
      isShowingMenuLearn: false
    }
  }
}
</script>
