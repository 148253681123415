<template>
  <div class="cards cards--comunidad h-full">
    <!-- Image -->
    <div class="cards--comunidad--image">
      <span
        class="badge bg-white text-secondary badge-sm badge-rounded-full"
        v-if="conferenceData.category"
        >{{ conferenceData.category }}
      </span>
      <Share
        button-styles="cards--share"
        :link="`${$i18n.locale}/comunidad/evento/${conferenceData.id}`"
        :title="conferenceData.name"
      >
        <template #button>
          <img src="@/assets/images/icn-share.svg" alt="Share" />
        </template>
      </Share>
      <router-link
        v-if="!pastEvent"
        class="link-image"
        :to="{
          name: 'EventoDetail',
          params: { id: conferenceData.id }
        }"
        :title="conferenceData.name"
      >
        <img
          :src="
            conferenceData.photo !== null
              ? conferenceData.photo
              : require('@/assets/images/img-about-us-codys.svg')
          "
          :alt="conferenceData.name"
        />
      </router-link>
      <a
        href="https://www.youtube.com/channel/UCkSTX4rcQ_KJO6e_OZKF7vA"
        class="link-image"
        target="_blank"
        v-else
      >
        <img
          :src="
            conferenceData.photo !== null
              ? conferenceData.photo
              : require('@/assets/images/img-about-us-codys.svg')
          "
          :alt="conferenceData.name"
        />
      </a>
    </div>
    <!-- End Image -->
    <!-- Body -->
    <div class="cards--comunidad--body">
      <!-- Title -->
      <h3 class="title-lg">
        <a
          href="https://www.youtube.com/channel/UCkSTX4rcQ_KJO6e_OZKF7vA"
          target="_blank"
          v-if="pastEvent"
          :title="conferenceData.name"
          >{{ conferenceData.name }}</a
        >
        <router-link
          v-if="!pastEvent"
          :to="{
            name: 'EventoDetail',
            params: { id: conferenceData.id }
          }"
          :title="conferenceData.name"
          >{{ conferenceData.name }}</router-link
        >
      </h3>
      <!-- End Title -->
      <!-- Badges -->
      <div class="cards--comunidad--badges">
        <template v-for="badge in conferenceData.subCategories" :key="badge">
          <span class="badge badge-secondary badge-xs badge-rounded-full"
            >#{{ badge }}</span
          >
        </template>
        <span
          class="
            flex
            items-center
            dark:text-primary-text
            text-secondary-text
            mb-1
          "
          v-if="conferenceData.duration"
        >
          <svg
            viewBox="0 0 18 18"
            class="
              fill-current
              dark:text-primary-text
              text-secondary-text
              w-4
              mr-2
            "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.00033 2.33268C5.31843 2.33268 2.33366 5.31745 2.33366 8.99935C2.33366 12.6812 5.31843 15.666 9.00033 15.666C12.6822 15.666 15.667 12.6812 15.667 8.99935C15.667 5.31745 12.6822 2.33268 9.00033 2.33268ZM0.666992 8.99935C0.666992 4.39698 4.39795 0.666016 9.00033 0.666016C13.6027 0.666016 17.3337 4.39698 17.3337 8.99935C17.3337 13.6017 13.6027 17.3327 9.00033 17.3327C4.39795 17.3327 0.666992 13.6017 0.666992 8.99935ZM9.00033 3.99935C9.46056 3.99935 9.83366 4.37245 9.83366 4.83268V8.65417L12.0896 10.9101C12.415 11.2355 12.415 11.7632 12.0896 12.0886C11.7641 12.414 11.2365 12.414 10.9111 12.0886L8.41107 9.5886C8.25479 9.43232 8.16699 9.22036 8.16699 8.99935V4.83268C8.16699 4.37245 8.54009 3.99935 9.00033 3.99935Z"
            />
          </svg>
          {{ conferenceData.duration }}
        </span>
      </div>
      <!-- End Badges-->
      <!-- Date -->
      <ul
        class="cards--comunidad--date mt-4"
        v-if="!pastEvent && conferenceData.date"
      >
        <li class="flex items-center" v-if="conferenceData.date">
          <svg
            viewBox="0 0 31 35"
            class="
              fill-current
              dark:text-primary-text
              text-secondary-text
              w-4
              mr-2
            "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.1667 34.1673H3.83333C1.99238 34.1673 0.5 32.6749 0.5 30.834V7.50065C0.5 5.6597 1.99238 4.16732 3.83333 4.16732H7.16667V0.833984H10.5V4.16732H20.5V0.833984H23.8333V4.16732H27.1667C29.0076 4.16732 30.5 5.6597 30.5 7.50065V30.834C30.5 32.6749 29.0076 34.1673 27.1667 34.1673ZM3.83333 14.1673V30.834H27.1667V14.1673H3.83333ZM3.83333 7.50065V10.834H27.1667V7.50065H3.83333ZM23.8333 27.5007H20.5V24.1673H23.8333V27.5007ZM17.1667 27.5007H13.8333V24.1673H17.1667V27.5007ZM10.5 27.5007H7.16667V24.1673H10.5V27.5007ZM23.8333 20.834H20.5V17.5007H23.8333V20.834ZM17.1667 20.834H13.8333V17.5007H17.1667V20.834ZM10.5 20.834H7.16667V17.5007H10.5V20.834Z"
            />
          </svg>
          <time class="mr-2">{{ confTime }}</time>
        </li>
        <li class="flex items-center" v-if="conferenceData.date">
          <svg
            viewBox="0 0 18 18"
            class="
              fill-current
              dark:text-primary-text
              text-secondary-text
              w-4
              mr-2
            "
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.00033 2.33268C5.31843 2.33268 2.33366 5.31745 2.33366 8.99935C2.33366 12.6812 5.31843 15.666 9.00033 15.666C12.6822 15.666 15.667 12.6812 15.667 8.99935C15.667 5.31745 12.6822 2.33268 9.00033 2.33268ZM0.666992 8.99935C0.666992 4.39698 4.39795 0.666016 9.00033 0.666016C13.6027 0.666016 17.3337 4.39698 17.3337 8.99935C17.3337 13.6017 13.6027 17.3327 9.00033 17.3327C4.39795 17.3327 0.666992 13.6017 0.666992 8.99935ZM9.00033 3.99935C9.46056 3.99935 9.83366 4.37245 9.83366 4.83268V8.65417L12.0896 10.9101C12.415 11.2355 12.415 11.7632 12.0896 12.0886C11.7641 12.414 11.2365 12.414 10.9111 12.0886L8.41107 9.5886C8.25479 9.43232 8.16699 9.22036 8.16699 8.99935V4.83268C8.16699 4.37245 8.54009 3.99935 9.00033 3.99935Z"
            />
          </svg>
          {{ confDate }}
        </li>
      </ul>
      <p
        class="dark:text-primary-text text-secondary-text"
        v-if="conferenceData.description"
      >
        {{ conferenceData.description }}
      </p>
      <!-- End Date -->
      <!-- Meta
      <ul class="cards--comunidad--meta">
        <li>
          <span><img src="@/assets/images/icn-lugar.svg" /></span
          >{{ conferenceData.place }}
        </li>
        <li>
          <span><img src="@/assets/images/icn-idioma.svg" /></span
          >{{ conferenceData.eventLang }}
        </li>
        <li>
          <span><img src="@/assets/images/icn-ticket.svg" /></span
          >{{ $t('general.gratis') }}
        </li>
      </ul>
       End Meta -->
    </div>
    <!-- End Body -->
    <!-- Footer -->
    <div class="cards--comunidad--footer">
      <!-- If Past Event -->
      <a
        v-if="pastEvent"
        class="btn btn-primary btn-rounded-lg btn-md mx-auto"
        :href="
          conferenceData.videoLink
            ? conferenceData.videoLink
            : 'https://www.youtube.com/channel/UCkSTX4rcQ_KJO6e_OZKF7vA'
        "
        target="_blank"
        >{{ $t('general.ver-ahora') }}
        <svg
          viewBox="0 0 27 19"
          xmlns="http://www.w3.org/2000/svg"
          class="fill-current w-5 ml-2"
        >
          <path
            d="M25.6279 3.46484C25.3467 2.33984 24.4561 1.44922 23.3779 1.16797C21.3623 0.605469 13.3936 0.605469 13.3936 0.605469C13.3936 0.605469 5.37793 0.605469 3.3623 1.16797C2.28418 1.44922 1.39355 2.33984 1.1123 3.46484C0.549805 5.43359 0.549805 9.65234 0.549805 9.65234C0.549805 9.65234 0.549805 13.8242 1.1123 15.8398C1.39355 16.9648 2.28418 17.8086 3.3623 18.0898C5.37793 18.6055 13.3936 18.6055 13.3936 18.6055C13.3936 18.6055 21.3623 18.6055 23.3779 18.0898C24.4561 17.8086 25.3467 16.9648 25.6279 15.8398C26.1904 13.8242 26.1904 9.65234 26.1904 9.65234C26.1904 9.65234 26.1904 5.43359 25.6279 3.46484ZM10.7686 13.4492V5.85547L17.4248 9.65234L10.7686 13.4492Z"
          />
        </svg>
      </a>
      <!-- end Past Event -->
      <!-- New Event -->
      <router-link
        v-else
        class="btn btn-primary btn-rounded-lg btn-md mx-auto"
        :to="{
          name: 'EventoDetail',
          params: { id: conferenceData.id }
        }"
        >{{ $t('general.saber-mas') }}</router-link
      >
      <!-- End New Event -->
    </div>
    <!-- End Footer -->
  </div>
</template>

<script>
import Share from '@/components/Share'
import { hourFormat, dateFormat } from '@/services/dateFormatting'
import ROUTES from '@/constants/routes'

export default {
  name: 'CardComunidad',
  props: {
    conferenceData: Object,
    pastEvent: Boolean
  },
  data: function () {
    return {
      ROUTES
    }
  },
  components: {
    Share
  },
  computed: {
    confTime: function () {
      return hourFormat(this.conferenceData.date)
    },
    confDate: function () {
      return dateFormat(this.conferenceData.date)
    }
  }
}
</script>
