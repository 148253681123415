<template>
  <main class="main--wrapper">
    <Hero
      :hero-title="$t('trabajos-page.title')"
      section="pageHero"
      :hero-image="require('@/assets/images/hero/bg-trabajos-detail.svg')"
      class="hero--md"
    />
    <div class="content--wrapper">
      <!-- Start Filter -->
      <!-- Filter -->
      <section
        class="
          section--filter
          mt-4
          mb-10
          grid
          gap-x-6 gap-y-4
          lg:grid-cols-4
          md:grid-cols-3
          grid-cols-1
        "
      >
        <input
          name="country"
          type="text"
          list="country"
          :placeholder="$t('admin-dashboard-candidatos.form-buscar-pais')"
          class="form--filter form--select"
          v-model="country"
        />
        <datalist id="country">
          <option
            :value="pais"
            v-for="pais in paisesLista[$i18n.locale ? $i18n.locale : 'es']"
            :key="pais"
          >
            {{ pais }}
          </option>
        </datalist>
        <input
          name="speciality"
          type="text"
          list="speciality"
          :placeholder="
            $t('admin-dashboard-candidatos.form-buscar-especialidad')
          "
          class="form--filter form--select"
          v-model="speciality"
        />
        <datalist id="speciality">
          <option
            :value="speciality"
            v-for="speciality in especialidadLista"
            :key="speciality"
          >
            {{ speciality }}
          </option>
        </datalist>
        <select class="form--filter form--select">
          <option value="test">test</option>
        </select>
        <button
          type="button"
          @click="resetform"
          class="btn btn-rounded-lg btn-lg btn-secondary"
        >
          {{ $t('general.resetear') }}
        </button>
      </section>
      <!-- End Filter -->
      <h3
        class="
          dark:text-primary-text
          text-secondary-text text-normal text-2xl
          lg:text-4xl
          text-center
          mb-20
        "
      >
        <span class="text-white font-bold">{{ jobsExamples.length }}</span>
        {{ $t('trabajos-page.title-01') }}
        <span class="text-white font-bold">
          {{
            country === null || country === ''
              ? $t('trabajos-page.title-02')
              : country
          }}
        </span>
        {{ $t('trabajos-page.title-03') }}
        <span class="text-white font-bold">
          {{
            speciality === null || speciality === ''
              ? $t('trabajos-page.title-04')
              : speciality
          }}
        </span>
        {{ $t('trabajos-page.title-05') }}
        <span class="text-white font-bold">
          {{ jobType === null ? $t('trabajos-page.title-06') : jobtype }}
        </span>
      </h3>
      <!-- End Filter -->
      <!-- Start Trabajos -->
      <section class="section--trabajos">
        <router-link
          class="
            flex
            mb-6
            dark:text-primary-text
            text-secondary-text
            items-center
            hover:text-white
          "
          :to="{
            name: 'Home'
          }"
        >
          <span
            class="
              border
              line-line
              rounded-full
              flex
              justify-center
              items-center
              mr-2
              w-10
              h-10
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              class="fill-current w-4"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M224 512c35.32 0 63.97-28.65 63.97-64H160.03c0 35.35 28.65 64 63.97 64zm215.39-149.71c-19.32-20.76-55.47-51.99-55.47-154.29 0-77.7-54.48-139.9-127.94-155.16V32c0-17.67-14.32-32-31.98-32s-31.98 14.33-31.98 32v20.84C118.56 68.1 64.08 130.3 64.08 208c0 102.3-36.15 133.53-55.47 154.29-6 6.45-8.66 14.16-8.61 21.71.11 16.4 12.98 32 32.1 32h383.8c19.12 0 32-15.6 32.1-32 .05-7.55-2.61-15.27-8.61-21.71z"
              />
            </svg>
          </span>
          {{ $t('general.crear-alerta') }}
        </router-link>
        <div class="grid grid-cols-1 gap-6 mb-8">
          <CardTrabajo
            v-for="job in jobsExamples"
            :key="job.id"
            v-bind:jobData="job"
            horizontal
          />
        </div>
        <Pagination class="justify-center" :totalPages="3" :currentPage="1" />
      </section>
      <!-- End Trabajos -->
    </div>
  </main>
</template>

<script>
import Hero from '@/components/Hero'
import CardTrabajo from '@/components/CardTrabajo'
import Pagination from '@/components/Pagination'
import paisesLista from '@/components/json/countries.json'
import especialidadLista from '@/components/json/especialidad.json'

export default {
  components: {
    Hero,
    CardTrabajo,
    Pagination
  },
  data: function () {
    return {
      jobs: [],
      country: null,
      speciality: null,
      jobType: null,
      paisesLista,
      especialidadLista,
      jobsExamples: [
        {
          id: 1,
          jobTitle: 'Senior Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          jobTime: 'time',
          techStack: ['#Vue.js', '#CSS', '#HTML'],
          company: {
            id: 1,
            name: 'amazon',
            about: 'Tech company that aaims to toganize the world information.',
            industry: 'Healthcare'
          }
        },
        {
          id: 2,
          jobTitle: 'Senior Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          jobTime: 'time',
          techStack: ['#Vue.js', '#CSS', '#HTML'],
          company: {
            id: 1,
            name: 'amazon',
            about: 'Tech company that aaims to toganize the world information.',
            industry: 'Healthcare'
          }
        },
        {
          id: 3,
          jobTitle: 'Senior Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          jobTime: 'time',
          techStack: ['#Vue.js', '#CSS', '#HTML'],
          company: {
            id: 1,
            name: 'amazon',
            about: 'Tech company that aaims to toganize the world information.',
            industry: 'Healthcare'
          }
        },
        {
          id: 4,
          jobTitle: 'Senior Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          jobTime: 'time',
          techStack: ['#Vue.js', '#CSS', '#HTML'],
          company: {
            id: 1,
            name: 'amazon',
            about: 'Tech company that aaims to toganize the world information.',
            industry: 'Healthcare'
          }
        },
        {
          id: 5,
          jobTitle: 'Senior Developer',
          jobType: 'Remoto',
          expRequired: 'Mid-Level, Senior',
          salaryRange: '5000',
          jobTime: 'time',
          techStack: ['#Vue.js', '#CSS', '#HTML'],
          company: {
            id: 1,
            name: 'amazon',
            about: 'Tech company that aaims to toganize the world information.',
            industry: 'Healthcare'
          }
        }
      ]
    }
  },
  methods: {
    resetform() {
      this.country = null
      this.speciality = null
      this.jobType = null
    }
  }
}
</script>
